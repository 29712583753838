import { Component, Input, OnChanges, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, SimpleChange, Output, EventEmitter, NgZone } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { DynamicFormComponent, FormController } from './dynamic-form.component';
import { FormService } from '../services/form.service';
import { ProfileService } from '../services/profile.service';
import { FormField, Visibility, FieldScript, SelectItem } from '../models/form-field';
import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc } from '../models/base';
import { FileFormInfo, AppIDInfo, DataFile } from '../models/file-form-info';
import { ListItem } from '../models/list-item';
import { AccessLevel } from '../models/security-control';
import { LocalizeService } from '../services/localize.service';
import { SettingsService } from '../services/settings.service';
import { PopupCallback } from '../widgets/popup.component';
import { SelectComponent } from '../widgets/select.component';
import { CommandHandler } from '../models/command-handler';
import { FormScripts } from './form-scripts';
import { WindowModalComponent } from '../windows/window-modal.component';
import { ListService } from '../services/list.service';
import { FileDropTargetDirective } from '../directives/drag-target.directive';
import { OOxmlService } from '../services/ooxml.service';
import { EmailHeaders } from '../utils/restful';
import { UploadService } from '../services/upload.service';

class CustomForm {
  constructor(private _fw: FormWrapperComponent) {}
  public setFieldValue(field: string, value: any, makeDirty: boolean): void {
    this._fw.updateControlValue(field, value, makeDirty);
  }
  public getFieldValue(field: string): any {
    return this._fw.dynamicForm.getControlValue(field);
  }
  public setHidden(field: string, hidden: boolean): void {
    this._fw.dynamicForm.setFieldVisibility(field, !hidden);
  }
  public setReadOnly(field: string, readOnly: boolean): void {
    this._fw.dynamicForm.setFieldEditable(field, !readOnly);
  }
}

declare const Office;
declare const Word;
declare const ICC;
const kExtrasShownKey = 'edx_extras_shown';
const noAddFolderIDs: string[] = ['recentedits', 'checkedout', 'downloads', 'imports', 'all', 'deleted', 'templates', ''];

@Component({
  selector: 'edx-form-wrapper',
  styleUrls: ['form-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'form-wrapper.component.html'
})
export class FormWrapperComponent implements OnChanges, PopupCallback, FormController {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @Output() notifyPermissionChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyNameChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() applyAllChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() extrasShownByChild: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() pccExtrasShownByChild: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() formDataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() okEnabled: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifySecurityDirty: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyShareChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyFormChanged: EventEmitter<any> = new EventEmitter<string>();
  @Input() kind = '';
  @Input() desc: BaseDesc;
  @Input() selections?: any[] = [];
  @Input() disableFirstFocus?: boolean = true;
  @Input() readOnly?: boolean = false;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() refresh?: boolean = false;
  @Input() hideChoosers?: boolean = false;
  @Input() hideLocation?: boolean = false;
  @Input() createType?: string = null;
  @Input() formData?: any = {};
  @Input() trustees?: any[] = [];
  @Input() fileFormInfo?: FileFormInfo = null;
  @Input() layout?: string = null;
  @Input() rightWrapper?: FormWrapperComponent = null;
  @Input() mainWrapper?: FormWrapperComponent = null;
  @Input() headerWrapper?: FormWrapperComponent = null;
  @Input() fileList?: File[] = null;
  @Input() filePaths: string[] = null; // like fileList but Electron or Cordova paths
  @Input() dataFiles?: DataFile[] = null;
  @Input() changeListener?: FieldChangeListener = null;
  @Input() applyAllChecked?: boolean = true;
  @Input() formDialog?: CommandHandler = null;
  @Input() inlineParent?: DynamicFormComponent = null;
  @Input() fileDropTarget?: FileDropTargetDirective = null;
  @Input() tabIndex = 0;
  @Input() emailHasAttachments = false;
  @Input('filesChanging') set setter(value: boolean) {
    if (value) {
      const saveData = () => {
        if (!!this.dynamicForm) {
          this.dynamicForm.userChangingProfileForm(false, false);
          this.formChanged = true;
        } else {
          setTimeout(saveData, 100);
        }
      };
      saveData();
    }
  }
  public scriptData: any = {};
  public okDisabled = false;
  public copyFileName: string = null;
  public copyAppID: string = null;
  public disableInherited = false;
  public securityIsReadOnly = false;
  public formTemplate: any = null;
  public relateToStr: string;
  public showRelateTo = true;
  public bMassProfileUpdate = false;
  public includeTemplatesStr: string;
  public showIncludeTemplates = true;
  public copyTree = false;
  public uploadFolderInfoStr: string;
  public uploadFolderInfoImgAltStr: string;
  private extrasShown = false;
  private profileFormList: any[] = [];
  private profileStr = '';
  private saveToStr = '';
  private defForm: string = null;
  private defFormNumber: number = null;
  private defFormTitle: string = null;
  private defaultProfileForm: string = null;
  private allowProfFormPicker = true;
  private ui: UserInterface;
  private officeAddin: boolean;
  private accessInfo: ListItem[];
  private formChanged = false;
  private relateToChecked = false;
  private relateToDesc: BaseDesc = null;
  private includeTemplatesChecked = false;
  private includeTemplatesDesc: BaseDesc = null;
  private applyAllStr = '';
  private originalFormData: any = null;
  private formLayout: string;
  private effectiveRights: any;
  private originalDesc: any = null;
  private bSecurityWasEdited = false;
  private bApplyAllOriginalState: boolean;
  private thirdButtonIsAutoProfile: boolean;
  private customForm: CustomForm = null;
  private immutableCopyData: BaseDesc = null;

  constructor(private cdr: ChangeDetectorRef, private zone: NgZone, private formService: FormService, private localizer: LocalizeService, private profileService: ProfileService, public listService: ListService, private ooxmlService: OOxmlService, private uploadService: UploadService) {
    this.saveToStr = this.localizer.getTranslation('FORMS.BUTTONS.SAVE_TO');
    this.applyAllStr = this.localizer.getTranslation('FORMS.BUTTONS.APPLY_TO_ALL');
    this.ui = Util.Device.ui;
    this.thirdButtonIsAutoProfile = (Util.Device.bIsOfficeAddinWord || Util.Device.bIsOfficeAddinOutlook);
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.formLayout = (this.ui<2 || this.readOnly) ? 'page' : 'column';
    this.showRelateTo = Util.RestAPI.canUserCreateRelations();
  }

  ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    const libraryName = this.desc?.lib;
    if (Util.RestAPI.isLibraryLoaded(libraryName)) {
      this.onChanges(changes);
    } else {
      Util.RestAPI.checkLibrarySettingsByName(libraryName).subscribe((libraryInfo: any) => {
        this.onChanges(changes);
      });
    }
  }

  private onChanges = (changes: { [propertyName: string]: SimpleChange }) => {
    setTimeout(() => {
      if (!!changes['trustees'] && (!!this.createType || this.kind === 'profile_copy')) {
        const myUserID = Util.RestAPI.getUserID().toUpperCase();
        const myUserIDUC = myUserID.toUpperCase();
        let me = null;
        if (!!this.trustees) {
          this.dynamicForm.profileDefaultTrusteeList = Util.deepCopy(this.trustees);
          this.dynamicForm.profileDefaultInfo = Util.deepCopy(this.formData);
          me = this.trustees.find(t => !!t['USER_ID'] && t['USER_ID'].toUpperCase() === myUserIDUC);
        } else {
          this.trustees = [];
        }
        if (!me) {
          this.trustees.push({ flag: 2, USER_ID: myUserID, rights: AccessLevel.ACCESS_LEVEL_CREATOR });
        }
      }
      if (this.kind === 'profile_copy' && !this.immutableCopyData) {
        this.immutableCopyData = Util.deepCopy(this.desc);
      }
      if (this.kind === 'profile_uploadfolders') {
        const foldersCount = Util.RestAPI.getFolderUploadFolders().length;
        const filesCount = Util.RestAPI.getFolderUploadFiles().length;
        const folderString = (this.localizer.getTranslation(foldersCount === 1 ? 'FORMS.LOCAL.LINK.TYPE_FOLDERS' : 'CONFIGURE_RED.FOLDERS')).toLowerCase();
        const fileString = (this.localizer.getTranslation(filesCount === 1 ? 'FORMS.LOCAL.LINK.TYPE_DOCUMENTS' : 'CONFIGURE_RED.DOCUMENTS')).toLowerCase();
        if (this.selections?.[0]?.lib === 'ex_onedrive_business') {
          this.uploadFolderInfoStr = this.localizer.getTranslation('FOLDER_ACTIONS.IMPORT_ONEDRIVE_FOLDER', [this.selections[0].DOCNAME]);
        } else {
          this.uploadFolderInfoStr = this.localizer.getTranslation('FOLDER_ACTIONS.UPLOAD_FOLDERS_COUNT', [foldersCount + '', folderString, filesCount + '', fileString]);
        }
        this.uploadFolderInfoImgAltStr = this.localizer.getTranslation('ALT_TEXT.UPLOAD_FOLDER_INFO');
      }
      if (changes['desc'] || changes['kind'] || changes['formData'] || changes['refresh']) {
        const contentFileList = this.uploadService.getContentFileList();
        if (Util.Device.bIsOfficeAddinOutlook && (contentFileList?.length > 0 && contentFileList[0].hasOwnProperty('attachNum')) && Util.RestAPI.getLastCmdName() !== 'uploadfolders' && !!this.uploadService.getFilesToFormMap()) {
          this.dataFiles = this.uploadService.getFilesToUpload(this.applyAllChecked);
        }
        if (changes['kind'] && changes['kind'].currentValue === 'profile_query_edit' && changes['formData']) {
          Util.RestAPI.conformFulltextCriteria(this.formData);
        }
        if (this.officeAddin && this.desc && this.desc['name'] === 'txt' && this.formData && this.formData['APP_ID']) {
          this.formData['APP_ID'] = '';
        }
        if (!this.formData) {
          this.formData = {};
        }
        this.defForm = null;
        if (!this.customForm && this.kind.startsWith(Util.kCustomFormPrefix)) {
          this.customForm = new CustomForm(this);
        }
        if ((this.kind.startsWith(Util.kLocalFormPrefix) || this.kind.startsWith(Util.kCustomFormPrefix)) && !this.createType) {
          let desc: any = null;
          if (this.selections && this.selections.length) {
            this.formData = Object.assign(this.formData, this.formService.makeFormData(this.kind, this.selections));
            desc = this.selections[0];
          } else if (!changes['desc'] && this.formData && Object.keys(this.formData).length && this.desc && this.kind === '__local_permissions_selector') {
            // send profile_defaults as formdata when defaults are set. as long as the desc has not changed.
          } else if (!!this.desc) {
            if (!this.mainWrapper || this.kind !== '__local_permissions_selector') {
              this.formData = Object.assign(this.formData, this.formService.makeFormData(this.kind, [this.desc]));
            }
            desc = this.desc;
          }
          if (!!desc && (!!desc['checkout'] || !!desc['LOCATION'] || Util.Device.bIsCordova) && this.kind === '__local_checkin') {
            this.formData['STATUS'] = desc['checkout'] ? desc['checkout']['STATUS'] : desc['STATUS'];
            this.formData['%CHECKIN_LOCATION'] = desc['checkout'] ? (desc['checkout']['LOCATION'] || desc['checkout']['%CHECKIN_LOCATION']) : desc['LOCATION'];
            if (!this.formData['%CHECKIN_LOCATION']) {
              this.formData['%CHECKIN_LOCATION'] = Util.RestAPI.getTempPath();
            }
            if (!!desc['checkout']) {
              const docs: any[] = this.formData['DOCUMENTS'];
              const nDocs: number = docs ? docs.length : 0;
              let nDocsWithVersionLabels = 0;
              const setVersLabel = (doc: any, versLabel: string) => {
                if (doc['VERSION_LABEL'] === undefined) {
                  doc['VERSION_LABEL'] = versLabel;
                }
                if (++nDocsWithVersionLabels === nDocs) {
                  this.loadFormTemplate();
                }
              };
              for (let i = 0; i < nDocs; i++) {
                const doc: any = docs[i];
                if (!!doc['checkout']['id']) {
                  doc['id'] = doc['checkout']['id'];
                }
                if (!!doc['checkout']['lib']) {
                  const extAppInfo = Util.RestAPI.findExternalApp(doc['lib']);
                  doc['extAppInfoLib'] = !!extAppInfo ? extAppInfo['lib'] : null;
                  doc['lib'] = doc['checkout']['lib'];
                }
                if (doc['VERSION_LABEL'] === undefined) {
                  setVersLabel(doc, doc['checkout']['VERSION_LABEL']);
                } else {
                  if (++nDocsWithVersionLabels === nDocs) {
                    this.loadFormTemplate();
                  }
                }
              }
            } else {
              this.loadFormTemplate();
            }
            this.okDisabled = true;
          } else if (desc && this.kind === '__local_permissions_selector') {
            if (Util.RestAPI.restAPIVersion() <= 0x00160301) {
              this.disableInherited = true;
            }
            if (!!this.mainWrapper) {
              const waitFormMainWrapperForm = () => {
                if (!!this.mainWrapper.dynamicForm.form) {
                  if (this.mainWrapper.bMassProfileUpdate) {
                    this.formData = Util.deepCopy(this.mainWrapper.formData);
                    this.bMassProfileUpdate = true;
                  } else {
                    if (this.mainWrapper.desc.lib !== this.desc.lib) {
                      this.desc = this.mainWrapper.desc;
                      this.formData = {};
                    } else {
                      this.formData = Object.assign(this.mainWrapper.formData, this.formService.makeFormData(this.kind, [this.desc]));
                    }
                    this.bMassProfileUpdate = false;
                  }
                } else {
                  setTimeout(waitFormMainWrapperForm, 100);
                }
              };
              waitFormMainWrapperForm();
            }
            this.loadFormTemplate();
          } else {
            this.loadFormTemplate();
          }
        } else {
          const isProfileForm: boolean = this.kind.startsWith('profile');
          const isSearchForm: boolean = this.kind.startsWith('profile_query');
          if (isProfileForm && !(this.profileFormList && this.profileFormList.length)) {
            this.profileStr = this.localizer.getTranslation('METADATA.TABS.PROFILE');
            if (isSearchForm) {
              this.profileFormList = Util.RestAPI.getSearchForms();
            } else {
              this.profileFormList = [];
            }
          }
          this.okDisabled = isProfileForm && !isSearchForm;
          this.effectiveRights = Util.RestAPI.getLibraryEffectiveRights(!!this.desc ? this.desc.lib : null) || {};
          if (this.createType && isProfileForm) {
            if (this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc && this.dataFiles[0].fromSaveAsDesc.lib.toUpperCase() !== this.desc.lib.toUpperCase()) {
              const descCpy = Util.deepCopy(this.desc);
              descCpy.lib = this.dataFiles[0].fromSaveAsDesc.lib;
              descCpy.DOCNAME = descCpy.lib;
              this.desc = null;
              setTimeout(() => {
                this.desc = descCpy;
                this.setFormListLoadTemplate();
              }, 100);
            } else {
              this.setFormListLoadTemplate();
            }
          } else if (isProfileForm && !!this.desc && (!this.selections || this.selections.length <= 1 || isSearchForm)) {
            if (this.desc.type === 'folders') {
              this.applyAllStr = this.localizer.getTranslation('FORMS.BUTTONS.PUBLIC_FOLDER');
              this.applyAllChecked = false;
            }
            this.getFormDataAndFormTemplate();
          } else {
            this.setFormListLoadTemplate();
          }
        }
      }
    }, 1);
  };

  private setFormListLoadTemplate(defForm?: string): void {
    let appProfileForms: any[] = null;
    let appType: string = null;
    this.setLoading(true);
    const getProfileForms = (anAppType: string): void => {
      let allProfileForms = [...Util.RestAPI.getProfileForms(this.desc.lib)];
      this.profileFormList = [];
      const isUnmappedAppType = !!anAppType && !allProfileForms.find(f=>f['%FORM_APPLICATION'] === anAppType);
      if (!!anAppType && !isUnmappedAppType) {
        allProfileForms = allProfileForms.sort((f1, f2) => f1['%FORM_APPLICATION'] === anAppType ? -1 : f2['%FORM_APPLICATION'] === anAppType ? 1 : 0);
      }
      if (appType === 'DEFAULT' || isUnmappedAppType) {
        const defaultForm = Util.RestAPI.getDefaultProfileForm(this.desc.lib);
        if (!!defaultForm) {
          this.profileFormList.push(defaultForm);
        }
      } else {
        allProfileForms.forEach(f1 => {
          if (this.profileFormList.findIndex(f2 => f2.DOCNUM === f1.DOCNUM) === -1) {
            this.profileFormList.push(f1);
          }
        });
      }
    };
    const afterGetDefaults = () => {
      if (appProfileForms && appProfileForms.length || (!!appType && appType.indexOf(Util.RestAPI.kMultiAppIdSeparator) !== -1)) {
        getProfileForms(appType); // prefer an app type so the duplicates remove the other types if the doc number matches
        this.fillProfileFormList(appProfileForms, defForm);
        if (!this.defForm && !!this.fileFormInfo && !!this.fileFormInfo.defForm) {
          this.defForm = this.fileFormInfo.defForm;
        }
      } else if (!this.readOnly && !this.createType && !!this.selections && this.selections.length > 1 && this.kind !== 'profile_query') {
        getProfileForms(null);  // Mass Profile Update
      }
      this.loadFormTemplate();
      this.setLoading(false);
    };
    if (this.createType==='folders' && !Util.isExternalLib(this.desc.lib)) {
      appType = 'FOLDER';
      this.applyAllStr = this.localizer.getTranslation('FORMS.BUTTONS.PUBLIC_FOLDER');
      this.applyAllChecked = this.desc.id === 'public';
    } else if (this.createType==='documents') {
      if (!!this.formData && this.formData['STORAGE'] !== 'P') {
        appType = this.appIDForUpload();
        if (!!appType) {
          appType = appType.split(Util.RestAPI.kMultiFileSeparator)[0];
        }
      }
    } else if (this.kind === 'profile_copy' && !!this.desc['APP_ID']) {
      appType = this.desc['APP_ID'];
    }
    if (!!appType) {
      if (!!this.formData && this.formData['APP_ID']) {
        const descMap: string[] = Util.FieldMappings.descMapForField('APP_ID');
        if (descMap && descMap.length) {
          for (const desc of descMap) {
            delete this.formData[desc];
          }
        }
      }
      this.formData['APP_ID'] = appType;
    }
    if (!appType && !this.fileList && !!this.formData && this.formData['STORAGE']==='P') {
      appType = 'Paper';
      appProfileForms = Util.RestAPI.getProfileFormsForApp(appType, this.desc.lib);
      if (appProfileForms && appProfileForms.length) {
        this.formData['FORM'] = appProfileForms[0]['%FORM_NAME'];
      }
      afterGetDefaults();
    } else {
      if (!appType && !!this.formData && !!this.formData['APP_ID'] && this.kind !== 'profile_query') {
        appType = this.formData['APP_ID'];
      }
      if (!!appType && appType.indexOf(Util.RestAPI.kMultiAppIdSeparator) === -1) {
        appProfileForms = Util.RestAPI.getProfileFormsForApp(appType, !!this.desc ? this.getLibrary() : Util.RestAPI.getPrimaryLibrary());
      }
      afterGetDefaults();
    }
  }

  private pickersAreColumnview(): boolean {
    if (!!this.kind && this.kind === 'profile_office_home') {
      return true;
    }
    return this.formLayout==='column';
  }

  private checkFileNameExistsInDM(): void {
    const fromSaveAsDesc = !!this.dataFiles && this.dataFiles.length ? this.dataFiles[0].fromSaveAsDesc : null;
    const docObj: any = this.applyAllChecked ? null : Util.decodeFileName(this.fileNameForUpload(false));
    if (!!fromSaveAsDesc) {
      this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+fromSaveAsDesc.id]);
    } else if (!!docObj && docObj.libExists) {
      this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+docObj.docNum]);
    } else {
      this.relateToStr = null;
    }
  }

  public setRelateToDesc(relateToDesc: BaseDesc): void {
    this.relateToDesc = relateToDesc;
    this.relateToStr = this.localizer.getTranslation('FORMS.LOCAL.RELATIONS.RELATE_TO', ['#'+this.relateToDesc.id]);
  }

  private relateToClicked(event: Event): void {
    this.relateToChecked = !this.relateToChecked;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public setIncludeTemplates(includeTemplatesDesc: BaseDesc): void {
    this.includeTemplatesDesc = includeTemplatesDesc;
    this.includeTemplatesStr = this.localizer.getTranslation('FORMS.LOCAL.COPY_TREE.INCLUDE_TEMPLATES');
  }

  private includeTemplatesClicked(event: Event): void {
    this.includeTemplatesChecked = !this.includeTemplatesChecked;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public applyAllShown(): boolean {
    if (this.kind === 'profile_uploadfolders') {
      return false;
    }
    if (!this.mainWrapper && !this.readOnly && !this.bMassProfileUpdate && !this.copyTree) {
      if (this.createType === 'documents') {
        const nFiles: number = (!!this.fileList && this.fileList.length > 0) ? this.fileList.length : this.filePaths ? this.filePaths.length : (this.dataFiles ? this.dataFiles.length : 0);
        return nFiles > 1 || this.uploadService.getFilesToUpload(false)?.length > 1;
      } else if (this.createType === 'folders' || (!!this.desc && !!this.desc.id && this.desc.type === 'folders' && !!this.formData && !!this.formData['public_system_id'])) {
        return !!this.effectiveRights && this.effectiveRights.ROOT_FOLDER === 'Y';
      }
    }
    return false;
  }

  private applyAllClicked(event: Event): void {
    if (this.bApplyAllOriginalState === undefined) {
      this.bApplyAllOriginalState = this.applyAllChecked;
    }
    this.applyAllChecked = !this.applyAllChecked;
    if (this.createType === 'documents') {
      this.fileList = this.uploadService.getFilesToUpload(this.applyAllChecked);
      if (Util.Device.bIsOfficeAddinOutlook) {
        this.dataFiles = this.uploadService.getFilesToUpload(this.applyAllChecked);
      }
      this.checkFileNameExistsInDM();
      this.dynamicForm.fillNameAndAppID();
      this.applyAllChanged.emit(this.applyAllChecked);
    }
  }

  private getTextForLogging(text: string): string {
    const trimSize = 500;
    if (!!text && text.length > trimSize) {
      return text.substr(0, trimSize) + '....[' + (text.length - trimSize) + ' more characters truncated]';
    }
    return text;
  }

  private getTextLength(text: string): number {
    return !!text ? text.length: 0;
  }

  private autoProfileClicked(): void {
    Util.Notify.info(this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.HEADING'), this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.STARTED'));
    this.setLoading(true);

    const errHandler = (err: any): void => {
      console.log(err);
      Util.Notify.error('Auto profile', err);
      this.setLoading(false);
    };

    const doneHandler = (data: any): void => {
      Util.Notify.success(this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.HEADING'), this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.COMPLETED'));
      const result: any = data;
      const keys: string[] = Object.keys(result);
      this.dynamicForm.validationBlocking(true);
      const formValues: any = this.dynamicForm.getValue();

      const outputMessages = [];

      for (const key of keys) {
        const field: FormField = this.dynamicForm.getField(key);

        if (!!field) {
          // console.log('autoProfileClicked(): Processing field with key: ' + key + '\t\tField details: ' + JSON.stringify(field, null, '\t'));
          const hits: any[] = result[key];
          const firstHit: any = hits[0];
          const firstValue: any = firstHit.value;

          if (!field.parentField) {
            this.dynamicForm.updateControlValue(key, firstValue, true);
            this.dynamicForm.setFieldDesc(key, firstHit.desc);
            outputMessages.push({field: key, oldValue: formValues[key], newValue: firstValue});
            field.hits = hits;
            field.autoValidated = true;
            field.lastLookupValue = firstValue;

            const childHits: any[] = result[field.childField];

            if (field.childField) {
              this.dynamicForm.updateControlValue(field.childField, '', true);
              this.dynamicForm.setFieldDesc(field.childField, '');
              const childField: FormField = this.dynamicForm.getField(field.childField);

              childField.hits = [];
              childField.autoValidated = true;
              childField.lastLookupValue = '';

              if (childHits) {
                for (const childHit of childHits) {
                  if (childHit.parent === firstValue) {
                    const firstChildHit: any = childHit.value[0];
                    this.dynamicForm.updateControlValue(field.childField, firstChildHit.value, true);
                    this.dynamicForm.setFieldDesc(field.childField, firstChildHit.desc);

                    outputMessages.push({field: field.childField, oldValue: formValues[field.childField], newValue: firstChildHit.value});
                    childField.hits = childHits;
                    childField.autoValidated = true;
                    childField.lastLookupValue = firstChildHit.value;
                    break;
                  }
                }
              }
            }
          }
        } else {
          console.log('autoProfileClicked(): Could not find field with key: ' + key);
        }
      }

      ['CLIENT_ID', 'MATTER_ID', 'TYPE_ID'].forEach(key => {
        const formField = this.dynamicForm.getField(key);
        const message = outputMessages.filter(outputMessage => outputMessage.field === key);

        if (message.length > 0) {
          if (message[0].oldValue && message[0].oldValue !== message[0].newValue) {
            formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.CHANGE', [message[0].oldValue]);
            return;
          }
          formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.MATCH', [message[0].newValue]);
          return;
        }

        // If the results returned all the fields it was checking on or this case was not required, we wouldn't need the hardcoded array.
        formField.statusMessage = this.localizer.getTranslation('FORMS.LOCAL.AUTO_PROFILING.FIELD.NO_MATCH', [formField.label]);
      });

      setTimeout(() => {
        this.dynamicForm.validationBlocking(false);
        this.setLoading(false);
      }, 300);
    };
    const doAutoProfile = (input: any): void => {
      this.profileService.getSuggestions(input).then(data => {
        doneHandler(data);
      }, err => {
        errHandler(err);
      });
    };
    const sendToAutoProfile = (addinType: string, lib: string, formName: string, textStr: string): void => {
      Util.RestAPI.getSmartProfilingData(lib, formName).toPromise().then((smartProfilingData: any) => {
        //console.log('autoProfileClicked(): [' + addinType + '] Sending ' + this.getTextLength(textStr) + ' characters of text to smart profiling: "' + this.getTextForLogging(textStr) + '"');
        const profilingObj = new Object({text: textStr, smartProfilingData});
        doAutoProfile(profilingObj);
      }, error => {
        errHandler(error);
      });
    };
    if (Util.Device.bIsOfficeAddinWord) {
      const addinType = 'MS WORD';
      //console.log('autoProfileClicked(): This is a [' + addinType + '] add-in.');
      Word.run(context => {
        const body = context.document.body;
        context.load(body, 'text');
        return context.sync().then(() => {
          sendToAutoProfile(addinType, this.desc.lib, this.getFormName(), body.text);
        });
      }).catch(error => {
        errHandler(error);
      });
    } else if (Util.Device.bIsOfficeAddinOutlook) {
        const addinType = 'OUTLOOK';
        const NEWLINE = '\r\n';
        //console.log('autoProfileClicked(): This is an [' + addinType + '] add-in.');
        const addMailAddress = (label: string, addrs: any[]): string  => {
          let addrsStr = '';
          if (addrs && addrs.length) {
            for (const addr of addrs) {
              addrsStr += label + ': ' + addr.displayName + ' <' + addr.emailAddress + '>' + NEWLINE;
            }
          }
        return addrsStr;
      };
      const addAttachments = (attachments: any[]): string  => {
          let attachmentsStr = '';
          if (attachments && attachments.length) {
            attachmentsStr += 'ATTACHMENT_COUNT: ' + attachments.length + NEWLINE;
            for (const attachment of attachments) {
              //TODO for attachment.id / attachment.contentType / attachment.size / attachment.attachmentType / attachment.isInline
              attachmentsStr += 'ATTACHMENT_NAME: ' + attachment.name + NEWLINE;
            }
          }
        return attachmentsStr;
      };
      const outlookItem = Office.context.mailbox.item;
      outlookItem.body.getAsync('text', asyncResult => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          //TODO for emails APP_ID / PARENTMAIL_ID / EMAIL_RECEIVED ? / MSG_ITEM: '1' / ATTACH_NUM
          const internetMessageId: string = outlookItem.internetMessageId.startsWith('<') ? outlookItem.internetMessageId : ('<' + outlookItem.internetMessageId + '>');
          sendToAutoProfile(addinType, this.desc.lib, this.getFormName(), 'OUTLOOK_ITEM_CLASS: ' + outlookItem.itemClass + NEWLINE + 'EMAIL_ID: ' + internetMessageId + NEWLINE + addMailAddress('EMAIL_FROM', outlookItem.from) + 'EMAIL_DATE_TIME_CREATED: ' + outlookItem.dateTimeCreated + NEWLINE + 'EMAIL_DATE_TIME_MODIFIED: ' + outlookItem.dateTimeModified + NEWLINE + addMailAddress('EMAIL_TO', outlookItem.to) + addMailAddress('EMAIL_CC', outlookItem.cc) + addMailAddress('EMAIL_BCC', outlookItem.bcc) + 'EMAIL_NORMALIZED_SUBJECT: ' + outlookItem.normalizedSubject + NEWLINE + addAttachments(outlookItem.attachments) + asyncResult.value);
        } else {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log('autoProfileClicked(): [' + addinType + '] Failed to retrieve email body text content. ERROR: ' + asyncResult.error.message);
          }
        }
      });
    } else {
      //Log this in case we need to know why the smart profiling is not getting the actual text we expect
      console.log('autoProfileClicked(): Error: Could not retrieve document text for smart profiling. This is not a MS WORD or OUTLOOK Office add-in.');
      doAutoProfile('');
    }
  }

  private toggleExtras(): void {
    this.extrasToggled(!this.extrasShown);
  }

  private formSelectList(): SelectItem[] {
    const selItems: SelectItem[] = [];
    if (this.copyAppID && this.kind === 'profile_copy') {
      this.profileFormList = Util.RestAPI.getProfileFormsForApp(this.copyAppID, this.desc.lib);
      for (const profForm of this.profileFormList) {
        selItems.push({ value: profForm.id, display: profForm.id + ' | ' + profForm.FORM_TITLE });
      }
      if (selItems.length === 0) {
        const defaultForm = Util.RestAPI.getDefaultProfileForm(this.desc.lib);
        this.defForm = defaultForm['%FORM_NAME'];
        selItems.push({ value: defaultForm.id, display: defaultForm.id + ' | ' + defaultForm.FORM_TITLE });
      } else {
        this.defForm = selItems.findIndex(item => item.value === this.defForm) !== -1 ? this.defForm : selItems[0].value;
      }
    } else {
      for (const profForm of this.profileFormList) {
        selItems.push({ value: profForm.id, display: profForm.id + ' | ' + profForm.FORM_TITLE });
      }
      if (this.defFormNumber > 0 && !selItems.some(p => p.value === this.defaultProfileForm)) {
        selItems.splice(0, selItems.length);
        selItems.push({ value: this.defaultProfileForm, display: this.defaultProfileForm + ' | ' + this.defFormTitle });
      }
    }
    return selItems;
  }

  private saveAsSelectList(): SelectItem[] {
    const items: SelectItem[] = [
      { value: 'D', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.DEFAULT') },
      { value: 'P', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.PDF') }
    ];
    if (Util.Device.bIsOfficeAddinWord) {
      items.push({ value: 'T', display: this.localizer.getTranslation('FORMS.LOCAL.OAI_ADD.TEXT') });
    }
    return items;
  }

  private isProfilePickerDisabled(): boolean {
    return this.readOnly || this.kind.startsWith('profile_editdefs_') || ((this.createType || this.kind === 'profile_query') && !Util.RestAPI.canUserChangeProfileFormOnSave()) || ((!this.createType && this.kind !== 'profile_query') && !Util.RestAPI.canUserChangeProfileFormOnEdit())
      || Util.isPaperDocument(this.formData);
  }

  private fillProfileFormList(forms: any[], defForm?: string): void {
    const newList: any[] = [];
    if (!!forms && !!this.profileFormList) {
      for (const form of forms) {
        for (const aForm of this.profileFormList) {
          //Look for %FORM_APPLICATION and compare it, if it is available
          const formApp: any = form['%FORM_APPLICATION'];
          if (newList.findIndex(nForm => nForm.id === aForm.id) === -1 && (!formApp || (formApp && (formApp === aForm['%FORM_APPLICATION']))) && (aForm.id === form.FORM || aForm.id === form.id)) {
            newList.push(aForm);
            break;
          }
        }
      }
    }
    if (newList.length) {
      let formName: string = null;
      if (!defForm && !!this.formData) {
        if (!this.createType && !!this.formData['FORM'] && !isNaN(parseInt(this.formData['FORM']))) {
          const form: any = newList.find(f => f['DOCNUM'] === this.formData['FORM']);
          formName = form ? form.id : Util.RestAPI.getProfileFormName(this.formData['FORM'], this.desc.lib);
        } else if (!!this.formData['FORMNAME']) {
          formName = this.formData['FORMNAME'];
        } else if (this.kind === 'profile_savetoedocs' && !this.formData['FORMNAME']) {
          const appType: string = this.formData['APP_ID'] ? this.formData['APP_ID'] : 'DEFAULT';
          const appProfileForms: any[] = Util.RestAPI.getProfileFormsForApp(appType);
          if (appProfileForms.length > 0) {
            formName = appProfileForms[0]['%FORM_NAME'];
            this.defForm = formName;
          }
        }
      }
      this.defForm = defForm || formName || newList[0].id;
      if (this.kind === 'profile_copy_tree') {
        this.profileFormList = newList.filter(item => item['id'] === this.defForm);
      } else {
        this.profileFormList = newList;
      }
    }
  }

  private getFormDataAndFormTemplate(defForm?: string, prevForm?: string): void {
    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    const kind: string = isProfileForm ? 'profile' : this.kind;
    if (!!defForm) {
      this.defForm = defForm;
    } else if ((isProfileForm || isSearchForm) && !this.defForm && this.desc && this.desc['FORM_NAME']) {
      this.defForm = this.desc['FORM_NAME'];
    }
    this.setLoading(true);
    if (this.kind.startsWith('profile_editdefs_') || (isSearchForm && this.kind !== 'profile_query_edit')) {
      let parts = this.kind.split('profile_editdefs_');
      parts = parts[1]?.split('___');
      const formName = isSearchForm ? defForm || this.desc.id : parts[0];
      const appID = isSearchForm ? 'SEARCH' : parts[1];
      const library = isSearchForm ? this.desc.lib : parts[2];
      Util.RestAPI.getDefsForAppID(formName, appID, library).then((appIDInfo: AppIDInfo): void => {
        this.formData = appIDInfo['profile_defaults'] ?? {};
        this.trustees = appIDInfo['trustees'] ?? [];
        this.dynamicForm.setSecurityList(this.trustees);
        this.formData['FORMNAME'] = formName;
        this.formData['APP_ID'] = appID === 'SEARCH' ? this.formData['APP_ID'] : appID;
        this.setLoading(false);
        this.setFormListLoadTemplate();
      });
    } else if (this.kind !== 'profile_lookup_add') {
      this.formService.getFormData(this.desc, kind, this.defForm).then((formData: any): void => {
        if (!formData) {
          formData = {};
        }
        if (this.kind !== 'profile_query_edit') {
          this.formData = formData;
          if (this.kind === 'profile_copy' && !this.immutableCopyData['FORMNAME']) {
            this.immutableCopyData['FORMNAME'] = this.formData['FORMNAME'];
            this.defForm = this.formData['FORMNAME'];
          }
        }
        if (!isSearchForm && isProfileForm) {
          if (!!formData && formData.forms && formData.forms.length) {
            this.fillProfileFormList(formData.forms, this.defForm);
          } else {
            if (!!formData && !!formData.FORMNAME && !this.defaultProfileForm) {
              this.defaultProfileForm = formData.FORMNAME;
              this.defFormTitle = formData.FORM_TITLE;
              this.defFormNumber = parseInt(formData.FORM);
            } else {
              const defProfileForm: any = Util.RestAPI.getDefaultProfileForm(this.desc.lib);
              if (!!defProfileForm) {
                this.fillProfileFormList([defProfileForm], this.defForm === this.defaultProfileForm ? this.defForm : null);
              }
            }
          }
          if (this.desc.type==='folders') {
            this.applyAllChecked = !!this.formData['public_system_id'] && this.formData['public_system_id'] !=='0';
          }
        }
        //Here we need to fix the email fields based on current form
        const defDataKeys = Object.keys(this.formData);
        const emailFieldMappings = prevForm ? Util.RestAPI.getFormEmailFields(prevForm, this.desc.lib) : null;
        if (!!emailFieldMappings) {
          const emailData = {};
          for (const key of defDataKeys) {
            const emailColumnVal = Util.RestAPI.IsFieldAnEmailFieldInForm(key, emailFieldMappings);
            if (!!emailColumnVal) {
              emailData[emailColumnVal] = this.formData[key];
              if (emailColumnVal === EmailHeaders.DateSent) { //Special case where we have a different DB column specified @EmailDate
                emailData[EmailHeaders.EmailDate] = this.formData[key];
              } else if (emailColumnVal === EmailHeaders.EmailDate) {
                emailData[EmailHeaders.DateSent] = this.formData[key];
                emailData[EmailHeaders.DateReceived] = this.formData[key];
              }
            }
          }
          const emailDataKeys = Object.keys(emailData);
          for (const key of emailDataKeys) {
            this.formData[key] = emailData[key];
          }
        }
        this.setLoading(false);
        if (!this.profileFormList || this.profileFormList.length === 0) {
          let formName = formData?.FORMNAME;
          if (this.kind === 'profile_copy' && this.desc?.['DOCS_LIBRARY_NAME'] !== Util.RestAPI.getPrimaryLibrary()) {
            const appProfileForms = Util.RestAPI.getProfileFormsForApp(this.desc?.['APP_ID'], Util.RestAPI.getPrimaryLibrary());
            if (!!appProfileForms && appProfileForms.length && !this.defForm) {
              formName = appProfileForms[0]['%FORM_NAME'];
            }
          }
          this.setFormListLoadTemplate(formName);
        } else {
          this.loadFormTemplate();
        }
        if (!isSearchForm && isProfileForm && formData && formData.DOCNAME) {
          this.notifyNameChanged.emit(formData.DOCNAME);
        }
        if (!isSearchForm && isProfileForm && formData && formData.IS_SHARED) {
          this.notifyShareChanged.emit(formData);
        }
      });
    } else {
      this.setLoading(false);
      this.loadFormTemplate();
    }
  }

  public showProfilePicker(): boolean {
    return this.kind.startsWith('profile') && (!this.desc || ((this.desc.lib || this.desc.id === 'recentedits') && !Util.isExternalLib(this.desc.lib))) && this.profileFormList && this.profileFormList.length>0 && this.allowProfFormPicker && (!this.readOnly || this.kind === 'profile_readonly');
  }

  public showSaveAsPicker(): boolean {
    return this.kind.startsWith('profile') && !this.readOnly && this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc;
  }

  public showLocationChooser(): boolean {
    return this.kind.startsWith('profile') && this.kind!=='profile_folderfiles' && this.kind!=='profile_copy' && !!this.createType && this.createType !== 'workspaces' && !!this.desc && !Util.isExternalLib(this.desc.lib) && !this.hideLocation;
  }

  private getLibrary(): string {
    return this.kind === 'profile_copy' ? Util.RestAPI.getPrimaryLibrary() : this.desc?.lib;
  }

  public setLibrary(library: string): void {
    if (!!this.desc) {
      this.desc.lib = library;
    }
  }

  public locationChanged(desc: BaseDesc): void {
    const appID = this.appIDForUpload()?.split(Util.RestAPI.kMultiFileSeparator)[0];
    const libChanged = !Util.Transforms.isCaseInsensitiveEqual(this.desc.lib,desc.lib);
    const prevLibrary = libChanged? this.desc.lib : null;
    const oldDefForm = this.defForm;
    this.setLoading(true);
    Util.RestAPI.getFormInfoForAppID(appID, desc).then((ffi: FileFormInfo) => {
      this.fileFormInfo = ffi;
      this.desc = desc;
      this.formChanging(libChanged, libChanged ? ffi.defForm : oldDefForm, oldDefForm, prevLibrary);
      this.setLoading(false);
    });
  }

  private patchGuideText(): void {
    const formData: any = this.formData;
    const replaceGuide = (defs: any[]) => {
      for (const obj of defs) {
        if (Array.isArray(obj)) {
          replaceGuide(obj);
        } else if (obj.fldtype==='text' || obj.fldtype==='guidetext') {
          if (!obj.value || !obj.value.length) {
            const value: string = formData[obj.name];
            if (value && value.length) {
              obj.value = value;
            }
          }
        }
      }
    };
    replaceGuide(this.formTemplate.defs);
  }

  private getScriptGroup(name?: string): any {
    let scriptGroup: any;
    if (!!this.formTemplate && !!this.kind) {
      name = name || this.formTemplate.name;
      if (this.kind.startsWith(Util.kCustomFormPrefix)) {
        let formName = Util.removeCustomFormPrefix(this.kind);
        const firstUS: number = formName.indexOf('_');
        const vendor: string = formName.substring(0, firstUS);
        formName = formName.substr(firstUS+1);
        if (!!ICC && !!ICC[vendor] && !!ICC[vendor][formName]) {
          scriptGroup = ICC[vendor][formName];
        }
      } else {
        scriptGroup = FormScripts[name];
      }
    }
    return scriptGroup;
  }

  private formChanging(libChanged: boolean, formChangedName: string, prevForm: string, prevLib? : string): void {
    this.formChanged = true;
    if (formChangedName !== prevForm && this.applyAllChecked) {
      this.refreshFileToFormMapping(formChangedName, prevForm);
    }
    this.dynamicForm.userSecurityList = []; // user changes profile form so clear previous user security list
    if (this.bMassProfileUpdate || this.desc.id === '0') {
      this.loadFormTemplate();
    } else {
      this.dynamicForm.userChangingProfileForm(libChanged, !!formChangedName);
      if (this.createType || (this.kind==='profile_copy' && libChanged)) {
        this.getNewFormTemplateDefaultsAndTemplate(formChangedName, prevForm, prevLib);
      } else {
        this.getFormDataAndFormTemplate(formChangedName, prevForm);
      }
    }
    this.notifyFormChanged.emit(formChangedName);
  }

  private refreshFileToFormMapping(formChangedName: string, prevForm: string) {
    if (Util.RestAPI.getFolderUploadFiles()?.[0]?.file?.externalName) {
      this.dataFiles = this.uploadService.refreshFileToFormMapping(formChangedName, prevForm, this.applyAllChecked);
    } else {
      this.fileList = this.uploadService.refreshFileToFormMapping(formChangedName, prevForm, this.applyAllChecked);
    }
    this.checkFileNameExistsInDM();
    this.dynamicForm.fillNameAndAppID();
  }

  private changeFormTemplate(selectComponent: SelectComponent): void {
    const newForm: string = selectComponent.value;
    const prevForm: string = this.defForm;
    if (this.defForm !== newForm) {
      this.defForm = newForm;
      this.formChanging(false, newForm, prevForm);
    }
  }

  private getNewFormTemplateDefaultsAndTemplate(defForm: string, prevForm: string, prevLib?: string): void {
    const loadWithFileFormInfo = (ffi: FileFormInfo) => {
      this.fileFormInfo = ffi;
      const info: FileFormInfo = this.fileFormInfo;
      const profileDefaultsData = this.fileFormInfo?.profile_defaults?.[this.fileFormInfo?.forms?.indexOf(defForm)];
      const defDataKeys = Object.assign(this.formData, profileDefaultsData) ?? {};
      let emailFieldMappings =  Util.RestAPI.getFormEmailFields(defForm, prevLib? prevLib : this.getLibrary());
      const setFormFieldsData = () => {
        const immutableData = {};
        for (const key of Object.keys(defDataKeys)) {
          let emailColumnVal = null;
          if (!!emailFieldMappings && !!emailFieldMappings[key]) {
            emailColumnVal = key;
          }
          const currentCol = !!emailColumnVal ? emailColumnVal : null;
          if (!!currentCol) {
            immutableData[currentCol] = defDataKeys[key];
            if (currentCol === EmailHeaders.DateSent) { //Special case where we have a different DB column specified @EmailDate
              immutableData[EmailHeaders.EmailDate] = this.formData[key];
            } else if (currentCol === EmailHeaders.EmailDate) {
              immutableData[EmailHeaders.DateSent] = this.formData[key];
              immutableData[EmailHeaders.DateReceived] = this.formData[key];
            }
          }
        }
        if (!!this.formData  && this.formData['STORAGE'] === 'P') {
          immutableData['STORAGE'] = 'P';
        }
        const immutableDataKeys = Object.keys(immutableData);
        if (!!info) {
          for (let i=0; i<info.forms.length; i++) {
            if (info.forms[i] === (defForm || this.defForm)) {
              this.formData = Util.deepCopy(info.profile_defaults[i] ?? {});
              this.trustees = Util.deepCopy(info.trustees[i] ?? []);
              // update profile default info and trustee list on dynamic form as well
              this.dynamicForm.profileDefaultInfo = Util.deepCopy(this.formData);
              this.dynamicForm.profileDefaultTrusteeList = Util.deepCopy(this.trustees);
              this.dynamicForm.inheritedFlexTrusteeList = [];
              const authorID = this.formData['AUTHOR_ID'];
              const userID = Util.RestAPI.getUserID();
              const list = this.listService.addAuthorTypistAsTrustee(authorID, userID, this.formData['AUTHOR_FULL_NAME'], Util.RestAPI.getUserFullName());
              if (this.trustees.findIndex(trustee => trustee.USER_ID === userID) === -1) {
                this.trustees.push(list[0]);
              }
              if (!!authorID && authorID.toUpperCase() !== userID.toUpperCase() && this.trustees.findIndex(trustee => trustee.USER_ID === authorID) === -1) {
                this.trustees.push(list[1]);
              }
              this.originalFormData = null;
              for (const key of immutableDataKeys) {
                this.formData[key] = immutableData[key];
              }
              break;
            }
          }
        }
        this.setFormListLoadTemplate(defForm);
        this.setLoading(false);
      };
      if (!!emailFieldMappings)
      {
        setFormFieldsData();
      } else {
        this.formService.getFormTemplate(defForm,prevLib,null).then((data) => {
          emailFieldMappings = data?.eMailFields;
          setFormFieldsData();
        })
      }
    };
    this.setLoading(true);
    const libDesc = Util.RestAPI.getAppIDLibAndDesc(this.desc);
    if ((Util.isFolder(this.desc.type) && noAddFolderIDs.indexOf(this.desc.id)===-1 && this.desc.type!=='fileplans') || (!libDesc.desc)) {
      if (!libDesc.desc && this.createType) {
        libDesc.desc = {type:this.createType, id:'0', lib:libDesc.lib};
        Util.RestAPI.updateFileFormInfoForContainer(this.fileFormInfo, this.defForm, libDesc.desc).then(loadWithFileFormInfo).catch(() => {
          loadWithFileFormInfo(this.fileFormInfo);
        });
      } else {
        Util.RestAPI.updateFileFormInfoForContainer(this.fileFormInfo, this.defForm, this.desc).then(loadWithFileFormInfo).catch(() => {
          loadWithFileFormInfo(this.fileFormInfo);
        });
      }
    } else {
      loadWithFileFormInfo(this.fileFormInfo);
    }
  }

  private changeSaveAs(selectComponent: SelectComponent): void {
    let ext: string;
    switch (selectComponent.value) {
    case 'D':
      ext = Util.Device.bIsOfficeAddinWord ? 'docx' : Util.Device.bIsOfficeAddinExcel ? 'xlsx' : Util.Device.bIsOfficeAddinPowerPoint ? 'pptx' : undefined;
      break;
    case 'T':
      ext = Util.Device.bIsOfficeAddinWord ? 'txt' : undefined;
      break;
    case 'P':
      ext = 'pdf';
      break;
    }
    if (!!ext) {
      for (const dataFile of this.dataFiles) {
        const parts: string[] = dataFile.name.split('.');
        if (parts.length > 1) {
          parts.splice(parts.length-1,1);
        }
        parts.push(ext);
        dataFile.name = parts.join('.');
      }
      let appType = this.appIDForUpload();
      if (!!appType) {
        appType = appType.split(Util.RestAPI.kMultiFileSeparator)[0];
        this.dynamicForm.updateControlValue('APP_ID', appType, true);
      }
    }
  }

  private loadFormTemplate(): void {
    let lib: string = this.desc && this.desc.lib ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
    let formName: string = this.kind;
    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    if (isProfileForm) {
      if (isSearchForm) {
        if (!this.defForm) {
          const defSearchForm = Util.RestAPI.getDefaultSearchForm() || {};
          this.defForm = defSearchForm['%FORM_NAME'];
        }
        formName = this.defForm;
      } else if (this.desc) {
        if (this.createType === 'workspaces' || (!this.createType && this.desc.type === 'workspaces')) {
          formName = Util.RestAPI.getDefaultWorkspaceForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (!this.createType && this.desc.type === 'fileplans') {
          formName = Util.RestAPI.getDefaultFilepartForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (!this.createType && this.desc.type === 'boxes') {
          formName = Util.RestAPI.getDefaultBoxForm().id;
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (!this.createType && this.desc.type === 'lookups') {
          formName = this.desc['FORM_NAME'];
          this.defForm = formName;
          this.allowProfFormPicker = false;
        } else if (this.defForm) {
          formName = this.defForm;
        } else if (!!this.formData && !!this.formData['FORMNAME']) {
          formName = this.formData['FORMNAME'];
          this.defForm = formName;
        } else if (this.formData && this.formData['FORM'] && Util.RestAPI.getProfileFormName(this.formData['FORM'], lib)) {
          formName = Util.RestAPI.getProfileFormName(this.formData['FORM'], lib);
          this.defForm = formName;
        } else if (Util.RestAPI.getDefaultProfileForm(lib)) {
          formName = Util.RestAPI.getDefaultProfileForm(lib).id;
          this.defForm = formName;
        }
        if (Util.isExternalLib(this.desc.lib)) {
          if (this.kind === 'profile_savetoedocs') {
            // If we are saving to eDOCS all form and library setting from the item selected
            // in the external app tile must be overriden by DM server default profile form
            // and DM server default library so that the "rightWrapper" will obtain the correct
            // profile form. Hence, work with a copy leaving the original list item intact.
            this.originalDesc = Util.deepCopy(this.desc);
            this.desc =  this.originalDesc;
            const appType: string = this.desc['APP_ID'] ? this.desc['APP_ID'] : 'DEFAULT';
            const appProfileForms: any[] = Util.RestAPI.getProfileFormsForApp(appType);
            if (appProfileForms.length > 0) {
              formName = appProfileForms[0]['%FORM_NAME'];
              this.fillProfileFormList(appProfileForms, formName);
              lib =  appProfileForms[0]['lib'];
              this.defForm = formName;
              this.desc['lib'] = lib;
              this.desc['id'] = '0';
              this.desc['AUTHOR_ID'] =  Util.RestAPI.getUserID();
              this.formData['AUTHOR_ID'] = Util.RestAPI.getUserID();
              this.createType = 'documents';
              this.dataFiles = [];
              for (let i=0; i<this.selections.length; i++) {
                this.dataFiles[i] = new DataFile();
                this.dataFiles[i].name = this.selections[i]['DOCNAME'];
                this.dataFiles[i].url = this.selections[i]['url'] || this.selections[i]['locationUrl'];
                this.dataFiles[i].externalName = this.selections[i]['DOCNAME'];
                this.dataFiles[i].size = Number(this.selections[i].size);
                this.dataFiles[i].externalDocId = this.selections[i]['id'];
                if (Util.RestAPI.isExtAppTeams(this.selections[i]['lib'])) {
                  this.dataFiles[i].url += '&item_id=' + this.selections[i]['id'];
                }
              }
              Util.RestAPI.uploadFilesWithUI(null, null, this.dataFiles, this.desc);
            }
          } else {
            formName = !!this.formData['FORM'] ? this.formData['FORM'] : this.desc.id;
          }
        }
      }
    }
    this.setLoading(true);
    const postLoad = (formTemplate) => {
      this.formTemplate = formTemplate;
      const extrasShownStr = localStorage.getItem(kExtrasShownKey);
      const extrasShownPref = !!extrasShownStr ? JSON.parse(extrasShownStr) : {};
      if (this.formTemplate && this.formTemplate.defs) {
        if (isProfileForm && !isSearchForm) {
          const storageField: any = Util.FieldMappings.templateField(this.formTemplate.defs, 'STORAGE');
          const templateIndex = !!storageField && !!storageField.selections ? storageField.selections.findIndex(selection => selection['value'] === 'T') : -1;
          if (this.effectiveRights.TEMPLATE_MANAGER === 'N' && templateIndex !== -1) {
            storageField.selections.splice(templateIndex, 1);
          }
          if (storageField) {
            storageField.vistriggers = [{
              hidden: true,
              fields: ['RETENTION'],
              values: ['K', 'T']
            }];
          }
          if (extrasShownPref['PROFILE'] !== undefined) {
            this.formTemplate['extrasshown'] = extrasShownPref['PROFILE'];
          }
          if (!!this.formData['PD_VITAL'] && this.formData['PD_VITAL'] === 'N' && !!this.formData['PD_VREVIEW_DATE']) {
            delete this.formData['PD_VREVIEW_DATE'];
          }
          if (!this.readOnly && !this.createType && !this.kind.startsWith('profile_lookup') && !!this.selections && this.selections.length > 1) {
            this.formTemplate.defs.splice(0, 0, {fldtype:'list', name:'$edx_form_list', prompt:this.localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENTS'), listKind:'DOCUMENTS', schemaID:'FORM_DOCUMENT_LIST', flags:0x00000040});
            this.formData['DOCUMENTS'] = this.selections;
            this.applyAllChecked = true;
            this.bMassProfileUpdate = true;
          } else if (!!this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc) {
            this.setRelateToDesc(this.dataFiles[0].fromSaveAsDesc);
          }
          if (this.kind === 'profile_copy_tree') {
            this.copyTree = true;
          }
        } else if (isSearchForm) {
          if (extrasShownPref['SEARCH_PROFILE'] !== undefined) {
            this.formTemplate['extrasshown'] = extrasShownPref['SEARCH_PROFILE'];
          } else {
            this.formTemplate['extrasshown'] = true;
          }
        }
        if (this.kind.startsWith('profile_editdefs_')) {
          this.formTemplate.extrasshown = true;
          Util.FieldMappings.forEachTemplateField(this.formTemplate.defs, false, (field): boolean => {
            if (field.flags & 0x00000040) {
              field.flags ^= 0x00000040;
            }
            if (field.name==='DOCNAME') {
              field.flags = 0x01000000;
            }
            return true;
          });
        } else if (this.kind === '__local_checkin') {
          if ((!!this.formData['%CHECKIN_LOCATION'] && this.formData['%CHECKIN_LOCATION'].toUpperCase().startsWith('HTTP')) ||
              (!!this.formData['DOCUMENTS'][0]['LOCATION'] && this.formData['DOCUMENTS'][0]['LOCATION'].toUpperCase().startsWith('HTTP'))) {
            const locationField: any = Util.FieldMappings.templateField(this.formTemplate.defs, '%CHECKIN_LOCATION');
            locationField.flags = 0x00080000; // READ-ONLY
            if (!this.formData['%CHECKIN_LOCATION'] && this.formData['DOCUMENTS'][0]['LOCATION']) {
              this.formData['%CHECKIN_LOCATION'] = this.formData['DOCUMENTS'][0]['LOCATION'];
            }
          }
        } else if (this.kind === 'profile_savetoedocs') {
          if (!this.originalDesc) {
            // If there is no originalDesc then we are saving individual files to eDOCS and each file pops-up a new form
            // at which point the originalDesc from previous save was lost. Restore it here to current desc so that we can
            // detect any changes to the save destination as it will change this.desc but not the this.originalDesc
            this.originalDesc = Util.deepCopy(this.desc);
            this.desc =  this.originalDesc;
          }
        }
        if ((this.createType === 'documents' || this.createType === 'folders') && !this.kind.startsWith(Util.kLocalFormPrefix) && !this.kind.startsWith(Util.kCustomFormPrefix)) {
          if (this.createType === 'documents') {
            if (this.formData['STORAGE'] === 'P') {
              const field: any = Util.FieldMappings.templateField(this.formTemplate.defs, 'APP_ID');
              if (field) {
                field.flags = 0x02000000;
              }
            } else {
              if (!this.fileList && !this.filePaths && !this.dataFiles && !this.copyFileName) {
                this.formTemplate.defs.splice(0, 0, {
                  fldtype: 'edit',
                  name: '$edx_file_picker',
                  lookup: '$edx_file_picker',
                  prompt: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FILE'),
                  flags: 0x00000042
                });
              } else {
                this.checkFileNameExistsInDM();
              }
            }
            if (this.desc.type === 'fileplans' && this.desc.id.startsWith('FP-FilePart')) {
              const filePartNo: string = this.desc.id.split('FP-FilePart-')[1];
              this.formData['PD_FILEPT_NO'] = filePartNo;
            }
          }
          if (!this.formData['AUTHOR_ID']) {
            this.formData['AUTHOR_ID'] = Util.RestAPI.getUserID();
            this.formData['AUTHOR_FULL_NAME'] = Util.RestAPI.getUserFullName();
          }
          this.formData['FORMNAME'] = formName;
          if (!this.originalFormData) {
            this.originalFormData = this.formData;
          } else {
            // Add original formdata to formdata.
            for (const prop in this.originalFormData) {
              this.formData[prop] = this.originalFormData[prop];
            }
          }
          const isPrimaryLib = Util.Transforms.isCaseInsensitiveEqual(this.desc.lib, Util.RestAPI.getPrimaryLibrary());
          if (this.immutableCopyData && isPrimaryLib) {
            const immutableCopyDataKeys = Object.keys(this.immutableCopyData);
            for (const key of immutableCopyDataKeys) {
              if (!this.formData[key]) {
                this.formData[key] = this.immutableCopyData[key];
              }
            }
            // copying a file into a workspace, file security needs to stay intact
            if (this.desc.type === 'workspaces') {
              this.formData['SECURITY'] = this.immutableCopyData['SECURITY'];
              this.inheritSecurity(this.immutableCopyData);
            }
          }
          this.formData = Util.RestAPI.fillInProfile(this.formTemplate, this.formData);
          this.formData['FORMNAME'] = formName;
        }
        this.patchGuideText();
        if (((isProfileForm && this.readOnly) || this.formTemplate.extrasshown) && !this.extrasShown) {
          this.showExtras(true);
        }
      }
      if (this.formChanged && (this.createType === 'documents' || this.createType === 'folders')) {
        const headerWrapper: FormWrapperComponent = this.headerWrapper;
        if (headerWrapper) {
          const scriptGroupHdr: any = this.getScriptGroup(headerWrapper.formTemplate?.name);
          if (scriptGroupHdr && typeof scriptGroupHdr.init === 'function') {
            const headerInitScript = scriptGroupHdr.init.bind(scriptGroupHdr);
            headerInitScript(headerWrapper, headerWrapper.formTemplate, this.formData, headerWrapper.localizer);
          }
        }
      }
      const scriptGroup: any = this.getScriptGroup();
      if (scriptGroup && typeof scriptGroup.init === 'function') {
        const initScript = scriptGroup.init.bind(scriptGroup);
        initScript(this.customForm || this, this.formTemplate, this.formData, this.localizer);
      }
      if (scriptGroup && typeof scriptGroup.getFormData === 'function') {
        const getFormDataScript = scriptGroup.getFormData.bind(scriptGroup);
        this.formData = getFormDataScript(this.customForm || this, this.formTemplate);
      }
      this.cdr.markForCheck();
      const waitForRender = () => {
        if (!!this.dynamicForm && !!this.dynamicForm.form && !!this.dynamicForm.form.controls) {
          const keys: string[] = Object.keys(this.formData);
          for (const key of keys) {
            const field = this.dynamicForm.getField(key);
            const control: AbstractControl = this.dynamicForm.getControl(key);
            if (!!field && field.visibilityTriggers && !!control) {
              this.runVisTriggers(field, control);
            }
          }
          // Code for 16.7.1.1 (Hague) to repopulate profile defaults with additional lookup and auxiliary fields
          // This should be removed once DM server can return profile defaults with all dependent fields
          if (this.dynamicForm.formKind.startsWith('profile') || this.kind === '__local_rm_removefrombox') {
            this.dynamicForm.scanFieldsForRevalidatonOrEvaluation(this.formData);
          }
          this.setLoading(false);
        } else {
          setTimeout(waitForRender, 10);
        }
      };
      waitForRender();
    };
    if (this.formData['formTemplate']) {
      postLoad(this.formData['formTemplate']);
    } else {
      this.formService.getFormTemplate(formName, lib, isSearchForm || this.formData['STORAGE'] === 'P').then(template => {
        if (template.name === 'preferences_general') {
          const settingsService = new SettingsService();
          settingsService.read('General').then(() => {
            postLoad(template);
          }, err => {
            postLoad(template);
          });
        } else {
          postLoad(template);
        }
      });
    }
    if (this.applyAllChecked && !this.formData.length) {
      this.formData = { ...this.formData, ...this.uploadService.getServerData() };
    }
  }

  private destroyScript(confirmed: boolean, allData: any, dirtyData: any): any {
    if (this.formTemplate && this.formTemplate.name) {
      const scriptGroup: any = this.getScriptGroup();
      if (scriptGroup && typeof scriptGroup.destroy === 'function') {
        const destroyScript = scriptGroup.destroy.bind(scriptGroup);
        const isFooterOptions = this.formTemplate.name === 'footer_options';
        destroyScript(this.customForm || this, confirmed, allData, dirtyData, this.localizer, isFooterOptions ? this.ooxmlService : undefined);
      }
    }
  }

  public onResize(): void {
    setTimeout(() => {
      this.cdr.markForCheck();
      if (this.dynamicForm) {
        this.dynamicForm.onResize();
      }
    }, 1);
  }

  public setLoading(loading: boolean): void {
    this.dynamicForm.setLoading(loading);
    this.cdr.markForCheck();
  }

  public permissionChanged(newVal: string): void {
   this.notifyPermissionChanged.emit(newVal);
  }

  public inheritSecurity(desc): void {
    this.listService.getListData(desc,'security',true,'LAST_EDIT_DATE',null,0,100).then(data => {
      this.dynamicForm.setSecurityList(data.list);
    });
  }

  public useDefaultTrustees(): void {
    if (this.formData) {
      const defaultTrustees: any[] = this.listService.addAuthorTypistAsTrustee(this.formData['AUTHOR_ID'], Util.RestAPI.getUserID(),  this.formData['AUTHOR_FULL_NAME'], Util.RestAPI.getUserFullName());
      if (!!this.trustees && this.trustees.length) {
        if (Array.isArray(this.trustees)) {
          this.trustees = this.trustees;
        }
        this.trustees.forEach(t => {
          if (!defaultTrustees.find(d => d.USER_ID === t.USER_ID)) {
            defaultTrustees.push(t);
          }
        });
      }
      this.dynamicForm.setSecurityList(defaultTrustees);
    }
  }

  public editSecurity(): void {
    this.dynamicForm.doEditSecurity();
  }

  public saveSecurity(): void {
    let cmd: string = null;
    const curWindow: WindowModalComponent = Util.RestAPI.getCurWindow();
    const securityControl: AbstractControl = this.dynamicForm.getControl('SECURITY');
    const value: any = securityControl ? securityControl.value : (this.desc.type==='workspaces' || this.desc.type==='searches') ? '1' : '0';
    // Restore selected choice if it was cleared due to an error previous save (it is cleared in the error handler to force trystee list reload)
    this.desc['edx_selected_security_choice'] = value;
    if (!!curWindow) {
      if (value === '1' || value === '3') {
        cmd = 'security_save';
        curWindow.doCommand(cmd);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
      } else if (value === '2') {
        const serverData: any = {'%INHERIT_LOOKUP_SECURITY_FROM':'0'};
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, null, null);
        cmd = 'security_save';
        curWindow.doCommand(cmd);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
      } else {
        curWindow.settingUnrestrictedSecurity();
        const notifyTitle: string = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.SECURITY_REMOVED');
        const notifyErrorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.PERMISSIONS_SELECTOR.SECURITY_NOT_REMOVED');
        const serverData: any = {'%INHERIT_LOOKUP_SECURITY_FROM':'-1',SECURITY:'0' ,SEC_REASON_LINK:'0'};
        this.formService.putToServer(this.desc, serverData, 'profile', null, false, notifyTitle, null, notifyErrorTitle);
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
        this.securityDirty(false);
      }
      curWindow.refresh(cmd, value);
    }
  }

  public resetSecurity(): void {
    this.dynamicForm.setFieldVisibility('$edx_permissions_edit', false);
    this.dynamicForm.setFieldVisibility('$edx_permissions_cancel', false);
    Util.RestAPI.refreshCurWindow('security_reset');
  }

  public securityDirty(dirty: boolean): void {
    const securityControl: AbstractControl = this.dynamicForm.getControl('SECURITY');
    const value: any = securityControl ? securityControl.value : (this.desc.type==='workspaces' || this.desc.type==='searches') ? '1' : '0';
    if (this.inDialog) {
      this.dynamicForm.setFieldVisibility('$edx_permissions_edit', value === '1');
    } else {
      if (!Util.Device.isPhoneLook()) {
        this.dynamicForm.setFieldVisibility('$edx_permissions_edit', dirty);
        this.dynamicForm.setFieldLabel('$edx_permissions_edit', 'FORMS.LOCAL.PERMISSIONS_SELECTOR.SAVE', true);
        this.dynamicForm.setFieldVisibility('$edx_permissions_cancel', dirty);
      }
    }
    this.bSecurityWasEdited = dirty;
    if (!!this.mainWrapper) {
      this.mainWrapper.bSecurityWasEdited = dirty;
    }
    setTimeout(() => {
      this.notifySecurityDirty.emit(dirty);
    }, dirty ? 1 : 100);
  }

  public securityReadOnly(readOnly: boolean): void {
    this.securityIsReadOnly = readOnly;
    const securityField: FormField = this.dynamicForm.getField('SECURITY');
    if (!!securityField) {
      let selectionMap: SelectItem[] = securityField.selectionMap;
      const selectionMapCopy = Util.deepCopy(selectionMap);
      if (!!selectionMap) {
       selectionMap = selectionMap.filter(i => i.value==='1'); // Force Angular to rerender the view
       securityField.selectionMap = selectionMap;
       securityField.selectionMap = selectionMapCopy;
      }
      securityField.isEnabled = !this.securityIsReadOnly;
      if (securityField.isEnabled) {
        securityField.isReadonly = false;
      }
    }
    this.permissionChanged(this.desc['SECURITY']);
  }

  public allowFirstFocus(): boolean {
    return !this.disableFirstFocus && !this.readOnly;
  }

  public layoutChanged(layout: string): void {
    this.formLayout = layout;
  }

  private saveProfileDefs(formName: string, appID: string, lib: string, editableData: any, allData: any, masterDynamicForm: DynamicFormComponent): Promise<boolean> {
    return new Promise<boolean>((resovle, reject) => {
      const yesBtn: string = this.localizer.getTranslation('FORMS.BUTTONS.YES');
      const notifyTitle: string = this.localizer.getTranslation('PROFILE_DEFAULTS.DEFAULT_SETTINGS');
      const description: string = Util.RestAPI.getAppIDDescription(appID);
      const editableDataKeys = Object.keys(editableData);
      let notifyBody: string = this.localizer.getTranslation('PROFILE_DEFAULTS.ASK_CHANGE', [description, formName]);
      Util.Notify.confirm(notifyTitle, notifyBody, yesBtn, null, true, true, true).then(confirmed => {
        if (confirmed && confirmed.confirm) {
          if (this.bSecurityWasEdited) {
            if (allData['SECURITY'] === '1') {
              editableData['_restapi'] = {security: masterDynamicForm.getSecurityList()};
              // If there is flex folder inheritance, send the flag to server
              if (masterDynamicForm.inheritSecurityIsChecked) {
                editableData['_restapi']['%INHERIT_LOOKUP_SECURITY_FROM'] = '0';
                delete editableData['_restapi']['security'];
              } else if (!masterDynamicForm.inheritSecurityIsChecked) {
                editableData['_restapi']['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1';
              }
            } else if (allData['SECURITY'] === '0') {
              editableData['_restapi'] = {security: []};
              editableData['_restapi']['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1';
            } else {
              delete editableData['SECURITY'];
            }
          } else if (editableDataKeys?.length === 1 && editableDataKeys[0] === 'SECURITY') { // user is trying to clean profile defaults and forgets to reset security
            delete editableData['SECURITY'];
          }
          Util.RestAPI.setProfileDefaults(formName, appID, editableData, lib).then(data => {
            notifyBody = this.localizer.getTranslation('PROFILE_DEFAULTS.DEFAULT_SAVED');
            Util.Notify.success(notifyTitle, notifyBody);
            resovle(true);
          }, error => {
            Util.Notify.warning(notifyTitle, error);
            resovle(error);
          });
        } else {
          resovle(false);
        }
      });
    });
  }

  // **** PopupCallback implementation
  popupThirdBtn(): void {
    if (this.thirdButtonIsAutoProfile && this.kind === 'profile_office_home') {
      this.autoProfileClicked();
    } else if (this.kind.startsWith('profile_editdefs_')) {
      this.dynamicForm.resetFormFields();
    } else {
      // 3rd button to set profile defaults during a document save - ticket# DMIC-4207
      let masterDynamicForm: DynamicFormComponent = this.dynamicForm;
      if (this.rightWrapper && this.rightWrapper.dynamicForm) {
        masterDynamicForm = this.rightWrapper.dynamicForm;
      }
      const includeDescriptionOfFields = true;
      const editableData: any = masterDynamicForm.getEditableValues(includeDescriptionOfFields);
      const allData: any = masterDynamicForm.getAllData();
      const appID = allData['APP_ID'];
      const formName = this.getFormName();
      this.saveProfileDefs(formName, appID, this.desc.lib, editableData, allData, masterDynamicForm).then(() => {},err => {});
    }
  }

  private setRecentlyUsedLocationInServerData(allData, serverData): void {
    if (!!allData['%RECENTLY_USED_LOCATION'] && !!serverData) {
      serverData['RECENT_TYPE'] = 2;
      serverData['%RECENTLY_USED_LOCATION'] = allData['%RECENTLY_USED_LOCATION'];
    }
  }

  popupOK(): void {
    const primaryLibUC: string = Util.RestAPI.getPrimaryLibrary().toUpperCase();
    const isPrimaryLib = !!this.desc && (!this.desc.lib || this.desc.lib.toUpperCase() === primaryLibUC);
    const isSearchForm: boolean = this.kind.startsWith('profile_query') || this.kind === (Util.kLocalFormPrefix + 'activitysearch');
    const bRMEnabled: boolean = !!Util.RestAPI.getLoginReply() && !!Util.RestAPI.getLoginReply().RM_ENABLED;
    let notifyTitle: string = null;
    let notifyBody: string = null;
    let notifyError: string = null;
    const checkinData: any = null;
    let masterDynamicForm: DynamicFormComponent = this.dynamicForm;
    if (this.rightWrapper && this.rightWrapper.dynamicForm) {
      masterDynamicForm = this.rightWrapper.dynamicForm;
    }
    const allData: any = masterDynamicForm.getAllData();
    let failedCheckedinCount = 0;
    let serverData: any = masterDynamicForm.getDirtyValue();
    let crossLibRoot: string = null;
    for (const key of Object.keys(serverData)) {
      if (masterDynamicForm.getField(key)?.controltype === 'editdate') {
        serverData[key] = Util.Transforms.localizedToDMDate(serverData[key]);
        if (!Util.Transforms.isSpecialDateFormat(serverData[key])) {
          serverData[key] = !Util.Transforms.splitableDateFormat(serverData[key]) ? Util.Transforms.toDMDateString(serverData[key]) : Util.Transforms.formatSplitableDate(serverData[key], true, true);
        }
      }
    }
    if (serverData['%CHECKIN_DATE'] === '') {
      serverData['%CHECKIN_DATE'] = Util.Transforms.toDMDateString(Util.Transforms.getTomorrowDate());
    }
    if (!!serverData['ITEM_TYPE'] && serverData['ITEM_TYPE'].indexOf('D') !== -1) {
      //  When looking for documents include paper type docs also
      serverData['ITEM_TYPE'] += ',M';
    }
    if (serverData['APP_ID'] === 'MS OUTLOOK') {
      serverData['ITEM_TYPE'] = 'E';
      serverData['MSG_ITEM'] = '1';
    }
    if (!isSearchForm && bRMEnabled) {
      if (!!serverData['PD_VITAL']) {
        if (serverData['PD_VITAL'] === 'N') {
          serverData['%CHANGE_VITAL_STATUS_NEWSTATUS'] = 2;
          if (!!serverData['PD_VREVIEW_DATE']) {
            delete serverData['PD_VREVIEW_DATE'];
          }
        } else {
          serverData['%CHANGE_VITAL_STATUS_NEWSTATUS'] = 1;
        }
      }
      if (!!serverData['PD_VREVIEW_DATE']) {
        serverData['%CHANGE_VITAL_REVIEW_DATE'] = '1';
      }
    }
    if (this.formTemplate.localonly) {
      if (this.kind === '__local_group_tile_settings' || this.kind === '__local_group_footer_defaults' || (this.kind === '__local_preferences_general' && Util.RestAPI.isAdminPage()) ) {
        this.formDataChanged.emit(allData);
      } else {
        this.formDataChanged.emit(serverData);
      }
      this.destroyScript(true, allData, serverData);
    } else if (this.kind.startsWith('profile_editdefs_')) {
      const includeDescriptionOfFields = true;
      const editableData: any = masterDynamicForm.getEditableValues(includeDescriptionOfFields);
      let parts = this.kind.split('profile_editdefs_');
      parts = parts[1].split('___');
      this.saveProfileDefs(parts[0], parts[1], this.desc.lib, editableData, allData, masterDynamicForm).then(() => {
        this.destroyScript(true, allData, serverData);
      }, err => {
        this.destroyScript(true, allData, serverData);
      });
    } else if (this.desc?.type === 'lookups') {
      let queryArgs = this.desc.lib !== Util.RestAPI.getPrimaryLibrary() ? `?library=${this.desc.lib}&` : '';
      queryArgs += 'profile=' + allData['form_name'];
      const tableName = this.formTemplate?.table;
      const action = this.kind.split('_')[2].toUpperCase();
      const editableData = { ...masterDynamicForm.getEditableValues(false), ...serverData };
      const formFields = Object.keys(editableData);
      for (const key of formFields) {
        if (!Util.FieldMappings.templateField(this.formTemplate?.defs, key)) {
          delete editableData[key];
        }
      }
      notifyTitle = this.localizer.getTranslation('ADMIN.LOOKUPS.' + action, [this.desc['DOCNAME'] || '']);
      notifyBody = this.localizer.getTranslation('ADMIN.LOOKUPS.SUCCESS_MESSAGE_' + action, [this.desc['DOCNAME'] || '']);
      switch (this.kind) {
        case 'profile_lookup_add':
        case 'profile_lookup_copy':
          this.formService.postToServer(this.desc.type, editableData, tableName, queryArgs, false, notifyTitle, notifyBody);
          break;
        case 'profile_lookup_edit':
          const itemId = this.desc['DOCNUM'];
          this.formService.putToServer(this.desc.type, editableData, tableName + '/' + itemId, queryArgs, false, notifyTitle, notifyBody);
          break;
      }
      setTimeout(() => {
        Util.RestAPI.refreshCurWindow();
      }, 10);
    } else {
      let localFormName: string = null;
      let params: string = null;
      let version: string;
      let versionIDOverride: string;
      let attachVersion: string;
      let sendRaw = false;
      const downloadList: any[] = [];
      let queryargs: string = null;
      let formName: string;

      if (!serverData._restapi && !isSearchForm) {
        serverData._restapi = {};
      }
      if (this.kind === 'profile_savetoedocs') {
        localFormName = 'savetoedocs';
      } else if (this.kind === 'profile_folderfiles' && this.dataFiles?.[0]?.externalName) {
        localFormName = 'savetoedocs';
        formName = allData['form_name'] || this.getFormName();
        this.setRecentlyUsedLocationInServerData(allData, serverData);
        let secList: any[] = null;
        if (this.createType === 'documents') {
          secList = this.uploadService.getSecurityData();
          if (this.applyAllChecked) {
            if (allData['SECURITY']?.toString() === '1' || allData['SECURITY']?.toString() === '2') {
              if (secList === null) {
                secList = masterDynamicForm.getSecurityList();
              }
              if (!!secList) {
                serverData._restapi['security'] = secList;
              }
            }
            this.uploadService.setData(undefined, undefined, secList, false);
          }
        }
      } else if (this.kind.startsWith(Util.kLocalFormPrefix)) {
        localFormName = Util.removeLocalFormPrefix(this.kind);
      } else if (this.kind.startsWith('profile')) {
        if (!isSearchForm) {
          formName = allData['form_name'] || this.getFormName();
          if (formName) {
            let appID: string = null;
            if (this.createType?.startsWith('documents')) {
              appID = allData['APP_ID'] || 'DEFAULT';
            } else if (!this.createType && this.desc?.type == 'documents' && !this.bMassProfileUpdate) {
              const isPaperDoc: boolean = !this.fileList && allData['STORAGE'] === 'P';
              appID = allData['APP_ID'] || (isPaperDoc ? 'Paper' : null);
            }
            this.updateDocumentsFormInfo(formName, appID, serverData);
          }
          this.setRecentlyUsedLocationInServerData(allData, serverData);
          let secList: any[] = null;
          if (this.createType === 'documents') {
            secList = this.uploadService.getSecurityData();
            if (this.applyAllChecked) {
              this.uploadService.setData(undefined, undefined, secList, false);
            }
          }
          if (allData['SECURITY']?.toString() === '1' || (allData['SECURITY']?.toString() === '2' && this.bMassProfileUpdate && this.desc.type === 'workspaces')) {
            if (this.createType === 'documents') {
              if (secList === null) {
                secList = masterDynamicForm.getSecurityList();
                if (this.applyAllChecked) {
                  this.uploadService.setData(undefined, undefined,secList, false);
                }
              }
            } else {
              secList = masterDynamicForm.getSecurityList();
            }
            if (!secList || secList.length === 0 || this.bMassProfileUpdate) {
              const author = serverData['AUTHOR_ID'];
              if (!!author && (this.bMassProfileUpdate || (!!masterDynamicForm.originalAuthor && author !== masterDynamicForm.originalAuthor))) {
                if (!secList) {
                  secList = [{flag: 2, USER_ID: author, rights: AccessLevel.ACCESS_LEVEL_CREATOR}];
                }
                if (this.bMassProfileUpdate) {
                  if (!masterDynamicForm.trusteesDirective) {
                    masterDynamicForm.trusteesDirective = 'add';
                  }
                } else {
                  queryargs = 'add';
                }
              }
            }
            if (!!secList && secList.length) {
              serverData._restapi['security'] = secList;
              if (masterDynamicForm.inheritSecurityIsChecked) {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '0';
                delete serverData._restapi['security'];
              } else if (!masterDynamicForm.inheritSecurityIsChecked && this.canInheritSecurity() && !this.bMassProfileUpdate && this.desc.type !== 'workspaces') {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1';
              }
            }
          } else if (allData['SECURITY'] === '0' && this.canInheritSecurity() && !this.bMassProfileUpdate && !masterDynamicForm.inheritSecurityIsChecked) {
            serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1';
          }
          if (this.desc['edx_selected_security_choice']) {
            // Delete this to trigger reload of trustee list if re-editing security wihtout leaving the modal view
            delete this.desc['edx_selected_security_choice'];
          }
          if (serverData.DOCNAME || serverData['X1237']) {
            const curWindow = Util.RestAPI.getCurWindow();
            if (!!curWindow && Util.isSameDesc(this.desc, curWindow.getDesc())) {
              curWindow.changedName(serverData.DOCNAME  || serverData['X1237']);
            }
          }
        }
        if (this.createType) {
          if (this.createType === 'workspaces') {
            // do not send the location to create a workspace as they are no where
            // We need to find all the casees where refid is not valid
            if (!isPrimaryLib) {
              queryargs = '?library=' + this.desc.lib;
            }
            if (!serverData['DOCNAME']) {
              serverData['DOCNAME'] = serverData['X1237'];
            }
            serverData['FILE_EXTENSION'] = 'WORKSPACE';
            serverData['APP_ID'] = 'FOLDER';
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
          } else {
            if (Util.isFolder(this.desc.type) && noAddFolderIDs.indexOf(this.desc.id)===-1 && (this.createType!=='documents' || this.desc.type!=='flexfolders') && this.desc.type!=='fileplans') {
              serverData._restapi['ref'] = this.desc;
              if (!isPrimaryLib && this.createType.indexOf('?library=') === -1) {
                this.createType += '?library='+this.desc.lib;
              }
            } else if (!isPrimaryLib) {
              if (this.createType.indexOf('?library=') === -1) {
                this.createType += '?library='+this.desc.lib;
              }
              crossLibRoot = this.desc.lib;
            }
            if (!!serverData['SECURITY'] && (this.createType.startsWith('documents') || this.createType.startsWith('folders'))) {
              if (!!serverData['PD_FILEPT_NO']) {
                serverData['SECURITY'] = '3';
                if (this.createType.startsWith('folders') && !!serverData['_restapi']) {
                  delete serverData._restapi['security'];
                }
              }
            }
            if (this.createType.startsWith('documents')) {
              const keys: string[] = Object.keys(allData);
              for (const key of keys) {
                if (!!key && Util.isEmailField(key) && !!allData[key] && !serverData[key] && !!Util.FieldMappings.templateField(this.formTemplate.defs, key)) {
                  // add all mail fields filled in by outlook if the fields exist on the profile form
                  serverData[key] = allData[key];
                }
              }
              if (allData['STORAGE'] === 'P' || (this.originalFormData && this.originalFormData['STORAGE'] === 'P')) {
                serverData['STORAGE'] = 'P';
                serverData['FILE_EXTENSION'] = '%FORM_PAPER_APPLICATION';
                serverData['APP_ID'] = '';
              } else {
                const nDataFiles = this.dataFiles ? this.dataFiles.length : 0;
                if (this.relateToChecked && !this.applyAllChecked) {
                  const docObj: any = Util.decodeFileName(this.fileNameForUpload(false));
                  if (!!docObj) {
                    serverData._restapi['associate'] = { lib: docObj.lib, id: docObj.docNum };
                  } else if (!!this.relateToDesc) {
                    serverData._restapi['associate'] = { lib: this.relateToDesc.lib, id: this.relateToDesc.id };
                  }
                }
                let checkinInfo: any = null;
                if (this.kind === 'profile_checkin') {
                  checkinInfo = this.getCheckInInfoFromFileName();
                  if (!!checkinInfo) {
                    serverData['DOCNUM'] = checkinInfo['DOCNUMS'];
                    serverData['LIBS'] = checkinInfo['LIBS'];
                    serverData._restapi = Object.assign(serverData._restapi, checkinInfo);
                  }
                }
                if (nDataFiles) {
                  if (Util.Device.bIsOfficeAddinOutlook) {
                    serverData = this.uploadService.updateAttachNum(serverData, this.dataFiles, this.emailHasAttachments);
                  }
                  for (let i=0; i<nDataFiles; i++) {
                    if (!!this.dataFiles[i]['DOCNUM']) {
                      serverData['DOCNUM'] += this.dataFiles[i]['DOCNUM'];
                      if (i<nDataFiles-1) {
                        serverData['DOCNUM'] += Util.RestAPI.kMultiFileSeparator;
                      }
                    } if (!!this.dataFiles[i].templateDesc && !!serverData.DOCNAME) {
                      const nameParts = this.dataFiles[i].name.split('.');
                      let ext = nameParts.length > 1 ? nameParts[nameParts.length-1] : null;
                      if (!!ext) {
                        const extUpper = ext.toUpperCase();
                        const docNameUpper = serverData.DOCNAME.toUpperCase();
                        if (docNameUpper.endsWith('.'+extUpper)) {
                          ext = null;
                        } else if (docNameUpper.endsWith('_'+extUpper)) {
                          serverData.DOCNAME = serverData.DOCNAME.substr(0, serverData.DOCNAME.length - (ext.length + 1));
                        }
                      }
                      this.dataFiles[i].name = serverData.DOCNAME + (!!ext ? ('.' + ext) : '');
                    }
                  }
                  if (this.applyAllChecked) {
                    this.uploadService.setServerData(serverData);
                  }
                  if (Util.Device.bIsOfficeAddinOutlook && !!this.dataFiles && this.dataFiles.length > 1 && !this.applyAllChecked) {
                    Util.RestAPI.uploadFilesWithUI(null, null, this.dataFiles, null, null, null, true, false);
                    Util.RestAPI.uploadDataFiles(null, serverData, [this.dataFiles[0]], false, null, !isPrimaryLib ? ('?library=' + this.desc.lib) : null);
                  } else {
                    Util.RestAPI.uploadDataFiles(null, serverData, this.dataFiles, false, null, !isPrimaryLib ? ('?library=' + this.desc.lib) : null);
                  }
                } else if (this.fileList) {
                  let fileListToUpload: File[];
                  if (this.applyAllChecked===false) {
                    fileListToUpload = [];
                    fileListToUpload.push(this.fileList[0]);
                  } else {
                    fileListToUpload = this.fileList;
                  }
                  const queryArgs = Util.Device.bIsOfficeAddinOutlook && fileListToUpload.length && (fileListToUpload[0].name.toUpperCase().endsWith('.MSG') || fileListToUpload[0].name.toUpperCase().endsWith('.EML')) ? 'outlook' : null;
                  if (this.applyAllChecked) {
                    this.uploadService.setServerData(serverData);
                  }
                  if (!Util.RestAPI.isFolderFilesProfile()) {
                    Util.RestAPI.uploadFilesWithBrowser(this.createType, serverData, fileListToUpload, null, queryArgs);
                  } else {
                    Util.RestAPI.setFolderDropDone(false);
                    Util.RestAPI.uploadFolderPendingFiles(serverData, fileListToUpload, this.applyAllChecked);
                  }
                } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova || Util.RestAPI.hasPFTA())) {
                  let filePathsToUpload: string[] = this.filePaths;
                  if (this.applyAllChecked===false) {
                    filePathsToUpload = [];
                    filePathsToUpload.push(this.filePaths[0]);
                  } else {
                    filePathsToUpload = this.filePaths;
                  }
                  if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
                    Util.RestAPI.uploadFilesWithAppWorks(filePathsToUpload, serverData, false, false, undefined, undefined, undefined, undefined, !isPrimaryLib?this.desc.lib:undefined);
                  } else {
                    Util.RestAPI.uploadFilesWithPFTA(filePathsToUpload, serverData, !isPrimaryLib?this.desc.lib:undefined);
                  }
                }
                if (this.kind === 'profile_checkin' && !!checkinInfo) {
                  return this.finishPopOKWithRefresh(allData, serverData);
                }
                // RestAPI takes care of upload and profile data so just get out
                this.destroyScript(true, allData, serverData);
                return;
              }
            } else {
              if (this.createType.startsWith('folders')) {
                notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
                if (this.applyAllChecked && this.desc.id !== 'public') {
                  // not in public folders but user wants the folder to be public
                  if (!!serverData._restapi['ref'] && Util.RestAPI.restAPIVersion()>=0x00200400) {
                    const curRef = serverData._restapi['ref'];
                    serverData._restapi['ref'] = [curRef, {type: 'folders', id: 'public', lib: this.desc.lib}];
                  } else {
                    serverData._restapi['ref'] = {type: 'folders', id: 'public', lib: this.desc.lib};
                  }
                } else if (!this.applyAllChecked && this.desc.id === 'public') {
                  // in pubilc folders but user does not want this to be public
                  delete serverData._restapi.ref;
                }
              }
            }
          }
        } else {
          params = 'profile';
          if (this.desc.type==='folders' && this.bApplyAllOriginalState !== undefined && this.bApplyAllOriginalState !== this.applyAllChecked) {
            setTimeout(() => {
              if (this.applyAllChecked) {
                // not in public folders but user wants the folder to be public
                const data: any = [{id:'public', type:'folders', lib:this.desc.lib}];
                Util.RestAPI.post(this.desc, data, 'references').subscribe((response) => {
                }, error => {
                  Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
                });
              } else {
                // in pubilc folders but user does not want this to be public
                const query: string = 'reftype=folders&refid=public&reflib=' + this.desc.lib + '&refsysid=' + this.formData['public_system_id'];
                Util.RestAPI.delete(this.desc, 'references', query).subscribe((response) => {
                }, error => {
                  Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), error);
                });
              }
            }, 1);
          }
        }
      }
      let canSubmit = true;
      if (localFormName) {
        let searchCriteria: any = localFormName === 'advancedsearch' ? this.createSearchCriteria(serverData, allData?.['FORM_NAME']) : null;
        switch (localFormName) {
          case 'url':
            this.selections = []; // ignore selections when creating a new url
            if (this.desc.type !== 'flexfolders' && this.desc.type !== 'fileplans') {
              serverData._restapi['ref'] = this.desc;
            }
            notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.URL_CREATED');
            break;
          case 'activitysearch':
            this.formService.updateSearchCriteria(serverData, this.formTemplate);
            searchCriteria = {
              DESCRIPTION: this.formService.getSearchDescription(serverData, masterDynamicForm),
              criteria: serverData
            };
            searchCriteria['$edx_extra_query'] = '&search=activity';
            // fall thru
          case 'advancedsearch':
            setTimeout(() => {
             Util.RestAPI.navToSearchURL('folders', searchCriteria);
            }, Util.kPopupDismissMS);
            // we are navigating to not try and post or put any thing to the server that is contents of this form
            this.destroyScript(true, allData, serverData);
            return;
          case 'editsecurity':
          case 'setsecurity':
            this.selections = []; // ignore selections when setting flexfolder security
            canSubmit = (this.desc.type === 'flexfolders' && (masterDynamicForm.isOkClicked || masterDynamicForm.form.value['HAS_SECURITY'] === '0' || masterDynamicForm.form.value['SECURITY'] === '0'));
            if (canSubmit) {
              serverData._restapi['security'] = {};
              if (allData['SECURITY'] !== '0') {
                serverData['SEC_REASON_LINK'] = '1';
                this.desc['SEC_REASON_LINK'] = '1';
                // Delete this to trigger reload of trustee list if re-editing security wihtout leaving the modal view
                delete this.desc['edx_selected_security_choice'];
                serverData._restapi['security'] = masterDynamicForm.getSecurityList();
              } else if (this.desc['SEC_REASON_LINK'] === '1' && this.desc['HAS_SECURITY'] !== '0' && masterDynamicForm.form.value['SECURITY'] !== '0') {
                // Has security but the form never loaded the trustee list (hence SECURITY == '0') yet OK was klicked.
                // So, take no action just exit - nothing changed !
                this.destroyScript(true, allData, serverData);
                return;
              } else {
                serverData['SEC_REASON_LINK'] = '0';
                this.desc['SEC_REASON_LINK'] = '0';
              }
              params = 'security';
              notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_SECURITY');
              notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.SECURITY_EDITED');
            }
            break;
          case 'createobject':
            serverData['DESCRIPTION'] = serverData['$edx_obj_desc'];
          case 'createfolder':
            params = 'folders';
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.SUCCESS_CREATION',[serverData['DOCNAME']]);
            const notifyErrorTitle: string = this.localizer.getTranslation('FORMS.LOCAL.UPLOAD.FAILED_CREATION',[serverData['DOCNAME']]);
            this.formService.postToServer(this.desc, serverData, params, queryargs, sendRaw, notifyTitle, null, notifyErrorTitle);
            this.destroyScript(true, allData, serverData);
            return;
          case 'savetoedocs':
            if (this.dataFiles && this.dataFiles.length >= 1) {
              const securityList: ListItem[] = this.dynamicForm.getSecurityList();
              const descOrCreateType: any = 'documents';
              const isCheckin: any = ((!!this.dataFiles && !!this.dataFiles[0].externalName) && (this.dataFiles[0].name !== this.dataFiles[0].externalName));
              serverData['_restapi']['items'] = [];
              if (!!this.applyAllChecked) {
                let separator = '';
                serverData['DOCNUM'] = '';
                serverData['LIBS'] = '';
                for (let i=0; i<this.dataFiles.length; i++) {
                  serverData['_restapi'].items[i] = {
                    LOCATION: this.dataFiles[i].url, 
                    DOCNAME: this.dataFiles[i].name, 
                    id : this.dataFiles[i].externalDocId
                  };
                  if (!!this.dataFiles[i]['_restapi'] && !!this.dataFiles[i]['_restapi']['checkinData']) {
                    // Multi-file checkin/upload so keep DOCNUM in with DOCNAME and APP_ID pattern o semicolon separated values
                    serverData['DOCNUM'] += separator + this.dataFiles[i]['_restapi']['checkinData']['DOCNUM'];
                    serverData['LIBS'] += separator + this.dataFiles[i]['_restapi']['checkinData']['lib'];
                    serverData['_restapi'].items[i].checkinData = this.dataFiles[i]['_restapi']['checkinData'];
                  }
                  separator = Util.RestAPI.kMultiFileSeparator;
                }
              } else {
                serverData['_restapi'].items[0] = {
                  LOCATION: this.dataFiles[0].url,
                  DOCNAME: this.dataFiles[0].name, 
                  id : this.dataFiles[0].externalDocId
                };
                if (!!this.dataFiles[0]['_restapi'] && !!this.dataFiles[0]['_restapi']['checkinData']) {
                  serverData['DOCNUM'] = this.dataFiles[0]['_restapi']['checkinData']['DOCNUM'];
                  serverData['_restapi'].items[0].checkinData = this.dataFiles[0]['_restapi']['checkinData'];
                }
              }
              const curDesc = Util.RestAPI.getCurDescFromUrl();
              if (!!curDesc  || isCheckin) {
                const extAppInfo: any = Util.RestAPI.findExternalApp(curDesc?.lib);
                if ((!!extAppInfo && 
                  ((extAppInfo['apptype'] === 'onedrive' && Util.RestAPI.getPreference('delete_original') === '1') || 
                  (extAppInfo['apptype'] === 'teams' && Util.RestAPI.getPreference('delete_original_teams') === '1'))) ||
                  isCheckin) {
                  serverData['_restapi']['delete_original'] = '1';
                }
              }
              if (!Util.isSameDesc(this.desc, this.originalDesc) || (Util.isFolder(this.desc.type) && noAddFolderIDs.indexOf(this.desc.id)===-1 && (this.createType!=='documents' || this.desc.type!=='flexfolders') && this.desc.type!=='fileplans')) {
                serverData['_restapi']['ref'] = this.desc;
                if (this.desc.lib.toUpperCase() !== primaryLibUC) {
                  queryargs = '?library=' + this.desc.lib;
                }
              }
              if (securityList && securityList.length) {
                serverData['SECURITY'] = '1';
                serverData['_restapi']['security'] = securityList;
              }
              formName = allData['form_name'] || this.getFormName();
              if (formName) {
                const appID = (this.createType.startsWith('documents')) ? allData['APP_ID'] ? allData['APP_ID'] : 'DEFAULT' : null;
                this.updateDocumentsFormInfo(formName, appID, serverData);
              }
              let fileListToUpload: DataFile[] = [];
              if (!!this.applyAllChecked) {
                fileListToUpload = this.dataFiles;
              } else {
                fileListToUpload[0] = this.dataFiles[0];
              }
              if (this.kind === 'profile_folderfiles') {
                if (this.applyAllChecked) {
                  this.uploadService.setServerData(serverData);
                }
                Util.RestAPI.setFolderDropDone(false);
                Util.RestAPI.uploadFolderPendingFiles(serverData, fileListToUpload, this.applyAllChecked);
              } else {
                Util.RestAPI.uploadFromOneDrive(descOrCreateType, serverData, fileListToUpload, params, queryargs);
              }
              this.destroyScript(true, allData, serverData);
              return;
            }
            break;
          case 'exportresults':
            canSubmit = false;
            let fileName = serverData['$edx_file_name'] || 'download';
            const nameParts: string[] = fileName.split('.');
            if (nameParts.length > 1) {
              nameParts.splice(nameParts.length - 1, 1);
            }
            nameParts.push('csv');
            fileName = Util.Transforms.escapeFileName(nameParts.join('.'));
            const exportFields = {};
            const columnKeys = Object.keys(allData).filter(key => key.startsWith('column__'));
            columnKeys.forEach(column => {
              const columnName = column.replace('column__', '');
              if (allData['chk__' + columnName] === '1') {
                exportFields[columnName] = allData[column];
              }
            });
            const appDesc = Util.RestAPI.getAppDesc();
            const exportFieldsB64: string = Util.Transforms.b64EncodeUnicode(JSON.stringify(exportFields));
            const filters: string = Util.RestAPI.stringifyFiltersFromDesc(appDesc);
            const sortKeyQS = this.formData?.sortKey ? '&' + this.formData?.sortKey : '';
            const filterQS = filters ? '&filter=' + filters : '';
            const subFolderContent = serverData['sub_folder_content'] === '1' ? '&subfoldercontent' : '';
            const queryArgs = 'filename=' + encodeURIComponent(fileName) + filterQS + sortKeyQS + '&exportfields=' + exportFieldsB64 + subFolderContent;
            Util.RestAPI.downloadFile(appDesc, null, queryArgs, '', false, true, null, false, null, fileName);
            this.destroyScript(true, allData, serverData);
            break;
        }
      }
      if (localFormName && this.selections && this.selections.length) {
        let sendItem = true;
        let downloadItem = false;
        let downloadQueryargs: string = null;
        let downloadPath: string = null;
        let downloadLaunch = false;
        let downloadShortName = false;
        let isExternalCheckout = false;
        let formData: any = {};
        let securityList: ListItem[] = null;
        const firstItem = this.selections[0];
        switch (localFormName) {
          case 'checkout':
            serverData['%STATUS'] = '%LOCK_FOR_CHECKOUT';
            const folderLoc: string = allData['$edx_folder_loc'] ? allData['$edx_folder_loc'] : 'LOCAL';
            if (folderLoc === 'LOCAL' || folderLoc === 'NONE') {
              if (folderLoc === 'LOCAL') {
                downloadPath = allData['%CHECKIN_LOCATION'];
                downloadItem = true;
              } else {
                delete serverData['%CHECKIN_LOCATION'];
                serverData['%STATUS'] = '%LOCK';
              }
            } else {
              isExternalCheckout = true;
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
              serverData._restapi['ref'] = this.scriptData['CREATEDESC'];
            }
            if (!!allData['DOCUMENTS'] && allData['DOCUMENTS'].length && !!allData['DOCUMENTS'][0]['VERSION_ID']) {
              serverData['%VERSION_ID'] = allData['DOCUMENTS'][0]['VERSION_ID'];
            }
            params = 'profile';
            notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDOUT');
            break;
          case 'checkin':
            serverData['%STATUS'] = '%UNLOCK';
            if (allData['%CHECKIN_LOCATION'] && allData['%CHECKIN_LOCATION'].startsWith('file:')) {
              serverData['%CHECKIN_LOCATION'] = '';
            }
            notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDIN');
            break;
          case 'newversion':
            notifyTitle = this.localizer.getTranslation('METADATA.VERSIONS_ACTIONS_ADD.NEW_VERSION');
            break;
          case 'rm_request':
            if (!!serverData['USER_ID']) {
              serverData['%REQUESTFOR_USERID'] = serverData['USER_ID'];
              delete serverData['USER_ID'];
            }
            break;
          case 'rm_loanrequest':
            if (!!serverData['USER_ID']) {
              serverData[ '%BORROW_USERID'] = serverData['USER_ID'];
              delete serverData['USER_ID'];
            }
            break;
          case 'export':
            sendItem = false;
            if (Util.Device.bIsElectron || Util.RestAPI.hasPFTA()) {
              downloadPath = allData['$edx_download_location'];
            }
            if (allData['$edx_download_type'] !== 'DOWNLOAD') {
              downloadQueryargs = allData['$edx_download_type'];
            }
            if (this.scriptData['$edx_version_ID'] === 'none') {
              delete allData['$edx_version_ID'];  // user changed version ignore what we had
            }
            if (allData['$edx_version'] !== 'none') {
              version = allData['$edx_version'];
              if (!!allData['$edx_version_ID']) {
                versionIDOverride = allData['$edx_version_ID'];
              } else if (!!this.formData['$edx_version_ID']) {
                versionIDOverride = this.formData['$edx_version_ID'];
              }
              downloadItem = true;
            }
            if (allData['$edx_attachment'] !== 'none') {
              attachVersion = allData['$edx_attachment'];
              if (allData['$edx_version'] === 'none') {
                versionIDOverride = allData['$edx_version_ID'];
              } else {
                if (!!versionIDOverride) {
                  versionIDOverride += ';' + attachVersion;
                }
              }
              downloadItem = true;
            }
            if ((version==='all' && !!this.scriptData['VERSIONS']) || (attachVersion==='all' && !!this.scriptData['ATTACHMENTS'])) {
              const title: string = this.localizer.getTranslation('FORMS.LOCAL.DOWNLOAD.DOWNLOADING_ALL');
              let zipQuery: string = (attachVersion==='all' ? (version==='all' ? '&attachments&versions' : '&attachments') : null);
              const allDocs: any[] = [];
              let extraVers: string = null;
              let dlType: string = allData['$edx_download_type'];
              let list: any[];
              if (allData['$edx_download_type'] === 'linkonly') {
                dlType = null;
                if (!!zipQuery) {
                  zipQuery += '&links';
                } else {
                  zipQuery = '&links';
                }
              }
              if (!!version) {
                list = version === 'all' || version === 'all_separate' ? this.scriptData['VERSIONS'] : this.scriptData['VERSIONS'].filter(v => v.VERSION_ID === version);
                allDocs.splice(0,0,...list);
                if (!!attachVersion && (attachVersion !== 'all' && attachVersion !== 'all_separate')) {
                  extraVers = attachVersion;
                }
              }
              if (!!attachVersion) {
                list = attachVersion === 'all' || attachVersion === 'all_separate' ? this.scriptData['ATTACHMENTS'] : this.scriptData['ATTACHMENTS'].filter(v => v.VERSION_ID === attachVersion);
                allDocs.splice(0,0,...list);
                if (!!version && (version !== 'all' && version !== 'all_separate')) {
                  extraVers = version;
                }
              }
              const exportFormData: any = { DOCUMENTS:allDocs, DOCNAME:firstItem.DOCNAME, desc:firstItem, $edx_download_type:dlType, $edx_download_location:allData['$edx_download_location'], $edx_zip_type:zipQuery, $edx_extraVers:extraVers };
              Util.Notify.progress(title,'__local_downloadzip',null,exportFormData,false,false,false).then(confirmed => { });
              // dialog takes care of download when zipping so just get out
              this.destroyScript(true, allData, serverData);
              return;
            } else if (Util.RestAPI.hasPFTA() && (version==='all' && !!this.selections && this.selections.length > 1) || (attachVersion==='all' && !!this.selections && this.selections.length > 1)) {
              const downloadDocs: any[] = [];
              let isAttachments = attachVersion==='all' && version!=='all';
              const nSelections = this.selections.length;
              let iSelection = 0;
              const getVersions = () => {
                const sel = this.selections[iSelection];
                const versDesc = {readDesc: true, desc:{id:sel.id, type:sel.type, lib:sel.lib}};
                FormScripts._shared.get_versions_or_attachments(isAttachments, this, versDesc).then(list => {
                  for (const item of list) {
                    downloadDocs.push({id:versDesc.desc.id, lib:versDesc.desc.lib, type:versDesc.desc.type, ver:item['VERSION_ID']});
                  }
                  ++iSelection;
                  if (iSelection === nSelections) {
                    if (!isAttachments && attachVersion==='all') {
                      isAttachments = true;
                      iSelection = 0;
                      getVersions();
                    } else {
                      const downloadFileName: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.DOCUMENTS')+'.zip';
                      const downloadFileData: any = {filename:downloadFileName, documents:downloadDocs};
                      let downloadQueryArgs = 'documentszip&share';
                      if (version === 'all') {
                        downloadQueryArgs += '&versions';
                      }
                      if (attachVersion === 'all') {
                        downloadQueryArgs += '&attachments';
                      }
                      Util.RestAPI.post('documents/token/zipdownload', downloadFileData, null, downloadQueryArgs).subscribe(tokenData => {
                        if (!!tokenData && tokenData.tokenid) {
                          const downloadFileUrl: string = Util.RestAPI.getServerURLOrigin() + '/documents/' + tokenData.tokenid + '?share';
                          Util.RestAPI.downloadFileUrl(downloadFileUrl, downloadPath, downloadFileName);
                        }
                      });
                    }
                  } else {
                    getVersions();
                  }
                });
              };
              getVersions();
              this.destroyScript(true, allData, serverData);
              return;
            } else {
              let sep = '';
              const addAllSep = (versOrAttachs: any[]): void => {
                // now push the same item to the selections array so the loop to download works with the versions list
                for (const vers of versOrAttachs) {
                  this.selections.push(firstItem);
                  version += sep + vers.VERSION_ID;
                  sep = ';';
                }
              };
              if (!!attachVersion) {
                if (!!version) {
                  if (version === 'all_separate' && attachVersion === 'all_separate') {
                    version = '';
                    this.selections = [];
                    addAllSep(this.scriptData['VERSIONS']);
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else if (version === 'all_separate') {
                    version = attachVersion;
                    sep = ';';
                    addAllSep(this.scriptData['VERSIONS']);
                  } else if (attachVersion === 'all_separate') {
                    sep = ';';
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else {
                    const attachParts: string[] = attachVersion.split(';');
                    const versParts: string[] = version.split(';');
                    versParts.splice(0,0,...attachParts);
                    version = versParts.join(';');
                    // now push the same item to the selections array so the loop to download works with the versions list
                    const selections: any[] = [];
                    selections.splice(0,0,...this.selections);
                    for (const attach of attachParts) {
                      selections.push(firstItem);
                    }
                    this.selections = selections;
                  }
                } else {
                  if (attachVersion === 'all_separate') {
                    version = '';
                    this.selections = [];
                    addAllSep(this.scriptData['ATTACHMENTS']);
                  } else {
                    version = attachVersion;
                  }
                }
              } else if (version === 'all_separate') {
                version = '';
                this.selections = [];
                addAllSep(this.scriptData['VERSIONS']);
              }
            }
            break;
          case 'shareonedrive':
            if (allData['$edx_share_type'] === 'invite') {
              const recipients: string[] = allData['$edx_email_to'].split(',');
              serverData = {};
              serverData['roles'] = [allData['$edx_link_scope'].split('&')[0].split('=')[1]];
              serverData['message'] = allData['$edx_share_message'];
              serverData['requireSignIn'] = true;
              serverData['sendInvitation'] = true;
              serverData['recipients'] = [];
              for (const recipient of recipients) {
                serverData['recipients'].push({email: recipient});
              }
              params = 'invite';
              const item: ListItem = this.selections[0];
              const desc: BaseDesc = {id:item.id,lib:item.lib,type:item.type};
              notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE');
              notifyBody  = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE_SENT');
              notifyError = this.localizer.getTranslation('FORMS.LOCAL.SHARE.INVITE_ERROR');
              this.formService.postToServer(desc, serverData, 'invite', null, false, notifyTitle, notifyBody, notifyError);
              this.destroyScript(true, allData, serverData);
              return;
            }
            // fall thru
          case 'email':
          case 'outlook_email':
            let nSecuredDocs = 0;
            const attachments: any[] = [];
            const keys: string[] = Object.keys(serverData);
            let emailQueryArgs: string;
            let optionKey = 'DOCUMENTS';
            const optionParams = [];
            const isMultiVersionEmail = !!allData['$edx_version_ID'] && allData['$edx_version_ID'].indexOf(';') > -1;
            const bIsOutlookMB: boolean = Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
            if (allData['$edx_version'] !== 'none') {
              version = !!allData['$edx_version_ID'] ? allData['$edx_version_ID'] : allData['$edx_version'];
              if (version === 'all') {
                emailQueryArgs = 'versions';
              }
            }
            if (allData['$edx_attachment'] !== 'none') {
              attachVersion = !!allData['$edx_version_ID'] ? allData['$edx_version_ID'] : allData['$edx_attachment'];
              if (attachVersion === 'all') {
                emailQueryArgs = !!emailQueryArgs ? (emailQueryArgs + '&attachments') : 'attachments';
              }
            }
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_SENT');
            notifyError = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_ERROR');
            const isOutlookActionSucceeded = (result: any) => {
              if (result.status === Office.AsyncResultStatus.Failed) {
                this.zone.run(() => {
                  Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), result);
                });
                return false;
              }
              return true;
            };
            const sendOutlookAttach = (outlookAttachments: any[]) => {
              if (Office.context.mailbox.item.addFileAttachmentAsync) {
                if (allData['$edx_share_type'] === 'url' && Office.context.mailbox.item.body.getTypeAsync) {
                  const emailSubject = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT', [this.localizer.getTranslation('FORMS.LOCAL.SHARE.' + optionKey, optionParams), this.localizer.getTranslation('APP_TITLE')]);
                  const documentURL = this.getDocumentURL(formData['_restapi']['src'], formData['action']);
                  Office.context.mailbox.item.body.getTypeAsync((result: any) => {
                    if (isOutlookActionSucceeded(result) && result.value === Office.MailboxEnums.BodyType.Html) {
                      Util.Transforms.formatHTMLEmail(emailSubject, formData['message'], formData['type'] === 'url', documentURL).then(html => {
                        Office.context.mailbox.item.body.setSelectedDataAsync(html, { coercionType: Office.CoercionType.Html, asyncContext: { app: 'edx' } }, (asyncResult: any) => {
                          isOutlookActionSucceeded(asyncResult);
                        });
                      });
                    }
                  });
                } else {
                  for (const outlookAttach of outlookAttachments) {
                    Office.context.mailbox.item.addFileAttachmentAsync(outlookAttach.url, outlookAttach.name, { asyncContext: { app: 'edx' } }, result => {
                      isOutlookActionSucceeded(result);
                    });
                  }
                }
              } else {
                let subject;
                if (allData['$edx_share_type'] === 'url') {
                  subject = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT_FOR_URL', [this.localizer.getTranslation('FORMS.LOCAL.SHARE.'+optionKey, optionParams), this.localizer.getTranslation('APP_TITLE')]);
                  const documentURL = this.getDocumentURL(formData['_restapi']['src'], formData['action']);
                  Util.Transforms.formatHTMLEmail(subject, formData['message'] ,formData['type'] === 'url', documentURL).then(html => {
                    createOutlookMessage(subject, html, null);
                  });
                } else {
                  subject = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SUBJECT_FORMAT', [this.localizer.getTranslation('FORMS.LOCAL.SHARE.'+optionKey, optionParams), this.localizer.getTranslation('APP_TITLE')]);
                  createOutlookMessage(subject, '', outlookAttachments);
                }
              }
            };
            const createOutlookMessage = (sub: string, messageBody: string, outlookAttachments: any[]) => {
              Office.context.mailbox.displayNewMessageForm( {
                subject: sub,
                htmlBody: messageBody,
                attachments: outlookAttachments
              });
            };
            const doOutlookAttach = () => {
              let nTokens = 0;
              const outlookAttachments: any[] = [];
              for (const attachItem of attachments) {
                const addLink: boolean = attachItem.link;
                Util.RestAPI.getFileToken(attachItem, attachItem.ver, false, (addLink && attachItem.ver === 'all' || ['folders', 'workspaces'].indexOf(attachItem.type) !== -1)).then(tokenData => {
                  if (tokenData && tokenData.url && tokenData.name) {
                    let outlookFileName: string = tokenData.name;
                    let outlookFileUrl: string = tokenData.url;
                    if (allData['$edx_share_type']==='linkonly' || addLink) {
                      outlookFileName += '.drf';
                      outlookFileUrl += '?linkonly&share';
                      optionKey = allData['$edx_share_type']==='linkonly' ? 'REFERENCES' : 'ALL';
                    } else if (allData['$edx_share_type']==='documentszip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?documentszip&share';
                      optionKey = 'DOCUMENTS_ZIP';
                    } else if (allData['$edx_share_type']==='linkonlyzip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?linkonlyzip&share';
                      optionKey = 'REFERENCES_ZIP';
                    } else if (allData['$edx_share_type']==='allzip') {
                      outlookFileName += '.zip';
                      outlookFileUrl += '?allzip&share';
                      optionKey = 'ALL_ZIP';
                    } else if (allData['$edx_share_type']==='url') {
                      optionKey = 'DOCUMENT_URL_WITH_LINK';
                      const linkField = masterDynamicForm.getField('$edx_link_action');
                      const option: any = linkField?.selectionMap?.find(selections => selections.value === formData['action']);
                      optionParams.push(option?.display);
                    } else {
                      outlookFileUrl += '?share';
                    }
                    if (!!emailQueryArgs) {
                      if (emailQueryArgs.startsWith('&')) {
                        outlookFileUrl += emailQueryArgs;
                      } else {
                        outlookFileUrl += '&' + emailQueryArgs;
                      }
                    }
                    if (allData['$edx_share_type'] !== 'url') {
                      outlookAttachments.push({type: 'file', name: outlookFileName, url: outlookFileUrl, isInline: false });
                    }
                  }
                  if (++nTokens === attachments.length) {
                    sendOutlookAttach(outlookAttachments);
                  }
                });
              }
            };
            const doOutlookAttachSingleZip = (key: string) => {
              const outlookFileName: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.'+key)+'.zip';
              const fileData: any = {filename:outlookFileName, documents:attachments};
              Util.RestAPI.post('documents/token/zipdownload',fileData, null, emailQueryArgs).subscribe(tokenData => {
                if (!!tokenData && tokenData.tokenid) {
                  const outlookFileUrl: string = Util.RestAPI.getServerURLOrigin() + '/documents/' + tokenData.tokenid + '?share';
                  sendOutlookAttach([{type: 'file', name: outlookFileName, url: outlookFileUrl, isInline: false }]);
                }
              });
            };
            const doShare = () => {
              const postGetHTML = (html: string) => {
                if (!!html) {
                  formData['html'] = html;
                }
                if (localFormName !== 'shareonedrive' && Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.mailbox) {
                  if (Util.RestAPI.restAPIVersion() >= 0x00160702 && (allData['$edx_share_type']==='documentszip' || allData['$edx_share_type']==='linkonlyzip' || allData['$edx_share_type']==='allzip')) {
                    optionKey = allData['$edx_share_type']==='documentszip' ? 'DOCUMENTS_ZIP' : allData['$edx_share_type']==='linkonlyzip' ? 'REFERENCES_ZIP' : 'ALL_ZIP';
                    doOutlookAttachSingleZip(allData['$edx_share_type']==='documentszip' ? 'DOCUMENTS' : allData['$edx_share_type']==='linkonlyzip' ? 'REFERENCES' : 'ALL');
                  } else {
                    doOutlookAttach();
                  }
                } else if (localFormName === 'outlook_email') {
                    emailQueryArgs = '?library=ex_outlook';
                    this.formService.postToServer('documents/share', formData, null, emailQueryArgs, false, notifyTitle, null, notifyError);
                } else {
                  this.formService.postToServer('documents/share', formData, null, emailQueryArgs, false, notifyTitle, null, notifyError);
                }
              };
              let subject: string = formData['subject'];
              let message: string;
              if (localFormName === 'shareonedrive') {
                message = `<div>${subject}</div><br><div style="font-weight:700;">${this.localizer.getTranslation('FORMS.LOCAL.SHARE.MESSAGE')}:</div><div>${formData['message']}</div><br>${allData['$edx_share_html_link']}`;
                subject = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE_NOTIFICATION');
                delete formData['$edx_share_html_link'];
              } else {
                message = formData['message'];
              }
              let documentURL = [];
              if (formData['type'] === 'url' && !!formData['_restapi']['src']) {
                documentURL = this.getDocumentURL(formData['_restapi']['src'], formData['action']);
              }
              Util.Transforms.formatHTMLEmail(subject, message,formData['type'] === 'url', documentURL).then(html => {
                postGetHTML(html);
                Util.RestAPI.addHistoryEntries(formData['type'], formData['_restapi']['src']);

              }, err => {
                postGetHTML(null);
              });
            };
            const afterAddAttachments = () => {
              formData['_restapi'] = {src: attachments};
              if (nSecuredDocs && Util.RestAPI.getPreference('warn_share_secured')==='1') {
                const title: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SECURITY_WARNING');
                const okBtn: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.SHARE');
                const body: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.CHECK_SECURE');
                Util.Notify.warning(title,body,okBtn,null,true,true,true).then(confirmed => {
                  if (confirmed && confirmed.confirm) {
                    doShare();
                  }
                });
              } else {
                doShare();
              }
            };
            const addSelectionsToAttachments = () => {
              let selections = this.selections;
              if (this.formData.isVersionEmail) {
                const curItem: any = Util.RestAPI.getCurItem();
                selections = [curItem];
              }
              const nSelections = selections.length;
              let i = 0;
              const addVersions = () => {
                const item = selections[i++];
                const afterAddItem = () => {
                  if (i < nSelections) {
                    addVersions();
                  } else {
                    afterAddAttachments();
                  }
                };
                if (item['SECURITY'] >= '1') {
                  ++nSecuredDocs;
                }
                const addVersion = (versionToAdd: string, versionLabel?: string) => {
                  if (allData['$edx_share_type']!=='linkonly' && (allData['$edx_share_type']!=='linkonlyzip' || !bIsOutlookMB) && (!bIsOutlookMB || ['all', 'allzip'].indexOf(allData['$edx_share_type']) === -1 || versionToAdd !== 'all')) {
                    attachments.push({
                      ver: versionToAdd,
                      verLabel: versionLabel,
                      id: item.id,
                      type: item.type,
                      lib: item.lib,
                      DOCNAME: item.DOCNAME
                    });
                  }
                  if (allData['$edx_share_type']==='linkonly' || (((['all', 'allzip'].indexOf(allData['$edx_share_type']) !== -1 && (versionToAdd === 'all' || (version !== 'all' && attachVersion !== 'all'))) || allData['$edx_share_type']==='linkonlyzip') && bIsOutlookMB)) {
                    attachments.push({
                      ver: versionToAdd,
                      verLabel: versionLabel,
                      id: item.id,
                      type: item.type,
                      lib: item.lib,
                      DOCNAME: item.DOCNAME,
                      link: true
                    });
                  }
                };
                if (bIsOutlookMB && ((version==='all' && !!this.scriptData['VERSIONS']) || (attachVersion==='all' && !!this.scriptData['ATTACHMENTS'])) || isMultiVersionEmail) {
                  let list: any[];
                  if (version==='all') {
                    list = this.scriptData['VERSIONS'];
                    if (attachVersion==='all') {
                      list.splice(0,0,...this.scriptData['ATTACHMENTS']);
                    }
                  } else if (isMultiVersionEmail) {
                    list = this.selections;
                  } else {
                    list = this.scriptData['ATTACHMENTS'];
                  }
                  const nItems = list.length;
                  for (let iItem=nItems-1; iItem>=0; iItem--) {
                    const versItem = list[iItem];
                    const label: string = versItem.VERSION_LABEL.toLowerCase();
                    if (label !== 'pr1' && versItem.DOCNUMBER === item.id) {
                      addVersion(versItem.VERSION_ID, versItem.VERSION_LABEL);
                    }
                  }
                  if (!isMultiVersionEmail) {
                    if (!!version && version !== 'all') {
                      addVersion(version);
                    }
                    if (!!attachVersion && attachVersion !== 'all') {
                      addVersion(attachVersion);
                    }
                    if (!!version && version === 'all' && ['all', 'allzip'].indexOf(allData['$edx_share_type']) !== -1) {
                      addVersion('all');
                    }
                  }
                } else if ((Util.isContainer(item.type) && !Util.RestAPI.findExternalApp(item.lib)) || version === 'C' || version === 'P') {
                  addVersion(version === 'P' ? 'P' : 'C');
                  if (!!attachVersion) {
                    addVersion(attachVersion);
                  }
                } else {
                  if (version==='all' || attachVersion==='all') {
                    addVersion('all');
                    if (version==='all' && !!attachVersion && attachVersion!=='all') {
                      addVersion(attachVersion, this.getVersionLabel('ATTACHMENTS', attachVersion));
                    } else if (attachVersion==='all' && !!version && version!=='all') {
                      addVersion(version, this.getVersionLabel('VERSIONS', version));
                    }
                  } else {
                    if (!!version) {
                      addVersion(version, this.getVersionLabel('VERSIONS', version));
                    }
                    if (!!attachVersion) {
                      addVersion(attachVersion, this.getVersionLabel('ATTACHMENTS', attachVersion));
                    }
                  }
                }
                afterAddItem();
              };
              addVersions();
            };
            for (let key of keys) {
              if (key === '$edx_version' || key === '$edx_attachment') {
                continue;
              }
              let data: any = serverData[key];
              if (key.startsWith('$edx_')) {
                if (key.startsWith('$edx_email')) {
                  data = data.replace(/\s/g, '');
                } else if (key.startsWith('$edx_outlook_email')) {
                  data = data.replace(/\s/g, '').replace(/,/g, ';').split(';').filter(e => e).join(';');
                }
                const parts: string[] = key.split('_');
                key = parts[parts.length-1];
              }
              formData[key] = data;
            }
            addSelectionsToAttachments();
            // dialog takes care of sharing so just get out
            this.destroyScript(true, allData, serverData);
            return;
          case 'copy':
            const createDesc = this.scriptData['CREATEDESC'];
            if (this.rightWrapper && this.rightWrapper.dynamicForm) {
              serverData = this.rightWrapper.dynamicForm.getDirtyAndRequiredValue();
              this.setRecentlyUsedLocationInServerData(allData, serverData);
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
              securityList = this.rightWrapper.dynamicForm.getSecurityList();
            } else {
              serverData = { _restapi: {} };
              securityList = null;
            }
            if (serverData && 'DEF_LOOK' in serverData) {
              delete serverData['DEF_LOOK'];
            }
            if (this.desc['SEC_REASON_LINK'] === '3' && !!this.desc['FP.PD_FILEPT_NO']) {
              serverData['SECURITY'] = '3';
            } else if (securityList && securityList.length) {
              serverData['SECURITY'] = '1';
              serverData._restapi['security'] = securityList;
              /* user doesn't make any changes to the secured flex items or does not
                 click on edit security so inheritSecurityIs Checked could be false
                 but the source file has inherited security  */
              if (masterDynamicForm.inheritSecurityIsChecked ||
                (!masterDynamicForm.isSecurityChanged && this.desc['SEC_REASON_LINK'] === '2')) {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '0';
                delete serverData._restapi['security'];
              } else if (!masterDynamicForm.inheritSecurityIsChecked && this.canInheritSecurity()) {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1'; // SEC_REASON_LINK === '2' means the source file has inherited security
              }
            } else {
              serverData['SECURITY'] = '0';
            }
            if (!!createDesc) {
              if (this.desc.type!=='flexfolders' && this.desc.type!=='fileplans' && createDesc.type!=='fileplans' && noAddFolderIDs.indexOf(createDesc.id)===-1) {
                serverData._restapi['ref'] = createDesc;
                if (!isPrimaryLib || createDesc.lib.toUpperCase() !== primaryLibUC) {
                  queryargs = '?library=' + createDesc.lib;
                }
                if (this.rightWrapper.applyAllChecked && this.desc.type === 'folders' && this.rightWrapper.desc.id !== 'public') {
                    // not in public folders but user wants the folder to be public
                  if (!!serverData._restapi['ref'] && Util.RestAPI.restAPIVersion()>=0x00200400) {
                    const curRef = serverData._restapi['ref'];
                    serverData._restapi['ref'] = [curRef, {type: 'folders', id: 'public', lib: this.desc.lib}];
                  } else {
                    serverData._restapi['ref'] = {type: 'folders', id: 'public', lib: this.desc.lib};
                  }
                } else if (!this.rightWrapper.applyAllChecked && serverData._restapi['ref'] && serverData._restapi['ref'].id === 'public' && this.desc.type === 'folders') {
                  delete serverData._restapi['ref'];
                }
              } else if (createDesc.lib.toUpperCase() !== primaryLibUC) {
                queryargs = '?library=' + createDesc.lib;
                crossLibRoot = createDesc.lib;
              }
            }
            serverData['DOCNAME'] = this.dynamicForm.getFieldValue('DOCNAME');
            serverData['STATUS'] = '0';
            if (this.desc['ITEM_TYPE']==='W') {  // workspace
              const wsForm: any = Util.RestAPI.getDefaultWorkspaceForm();
              serverData['ITEM_TYPE'] = 'W';
              serverData['FILE_EXTENSION'] = 'WORKSPACE';
              serverData['APP_ID'] = 'FOLDER';
              serverData._restapi['form_name'] = wsForm.id;
              serverData['FORM'] = wsForm.SYSTEM_ID;
            } else {
              serverData['FULLTEXT'] = 'Y';
              serverData._restapi['form_name'] = this.rightWrapper.defForm;
            }
            if (this.relateToChecked && !!this.relateToDesc) {
              serverData._restapi['associate'] = { lib: this.relateToDesc.lib, id: this.relateToDesc.id };
            }
            if ( this.dynamicForm.getFieldValue('edx_copy_profile') !== 'N' && (!createDesc || createDesc.type!=='fileplans')) {
              // Copy all RM specific fields if present in the original data
              this.copyAll_RM_SpecificFields(allData,serverData);
            } else if (!!createDesc && createDesc.type==='fileplans') {
              // Copy all RM specific fields from the new file-part
              this.copyAll_RM_SpecificFields(createDesc,serverData);
            }
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.COPY.COPY');
            break;
          case 'copytree':
            const createDescription = this.scriptData['CREATEDESC'];
            if (this.rightWrapper && this.rightWrapper.dynamicForm) {
              serverData = this.rightWrapper.dynamicForm.getDirtyAndRequiredValue();
              this.setRecentlyUsedLocationInServerData(allData, serverData);
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
              securityList = this.rightWrapper.dynamicForm.getSecurityList();
            } else {
              serverData = { _restapi: {} };
              securityList = null;
            }
            if (securityList && securityList.length) {
              serverData['SECURITY'] = '1';
              serverData._restapi['security'] = securityList;
              if (masterDynamicForm.inheritSecurityIsChecked) {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '0';
                delete serverData._restapi['security'];
              } else if (!masterDynamicForm.inheritSecurityIsChecked && this.canInheritSecurity() && this.desc['SECURITY'] === '2') {
                serverData['%INHERIT_LOOKUP_SECURITY_FROM'] = '-1';
              }
            }
            if (!!createDescription) {
              serverData._restapi['ref'] = createDescription;
            }
            serverData['DOCNAME'] = this.dynamicForm.getFieldValue('DOCNAME');
            serverData._restapi['form_name'] = this.rightWrapper.defForm;
            serverData._restapi['copytree'] = 'Y';
            if (this.includeTemplatesChecked && !!this.includeTemplatesDesc) {
              serverData._restapi['includetemplates'] = 'Y';
            }
            if (allData['SECURITY'] === '2') {
              delete serverData['SECURITY']; // User chose 'No change' for security
            }
            notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.COPY_TREE.RESULTS');
            break;
          case 'rm_request':
            if (!serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] || !!serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] && serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] === '') {
              serverData['%REQUEST_NOTREQUIREDAFTER_DATE'] = '1899-12-30'; // This is variant date zero
            }
            break;
          case 'url':
          case 'link':
          case 'fp_box_rm_request':
          case 'rm_vitalreviewdate':
          case 'fp_box_rm_loanrequest':
          case 'rm_loanrequest':
          case 'rm_putinbox':
          case 'rm_removefrombox':
          case 'versions_editprofile':
          case 'shareonedrive':
          case 'exportresults':
            break;
          default:
            Util.Notify.error('Client Coding Error','FormWrapperComponent: missing case for local form in popupOK');
            return;
        }
        const handleSelectedItems = () => {
          for (let i=0; i<this.selections.length; i++) {
            const savedServerData: any = serverData;
            const item = this.selections[i];
            let descOrCreateType: any = this.createType;
            let filesMatchDocuments = false;
            notifyBody = item.DOCNAME;
            sendRaw = false;
            if (localFormName==='checkin') {
              let externalLocation: any = null;
              if (!!item.checkout && !!item.checkout['LOCATION'] && item.checkout['LOCATION'].toUpperCase().startsWith('HTTP')) {
                externalLocation = item.checkout['LOCATION'];
              } else if (!!item['LOCATION'] && item['LOCATION'].toUpperCase().startsWith('HTTP')) {
                externalLocation = item['LOCATION'];
              }
              const checkinKind: string = allData['$edx_new_version'];
              if (checkinKind==='NEW_DOCUMENT' && (this.fileList || this.filePaths || externalLocation)) {
                let intoThisFolder: any = Util.RestAPI.getCurListComponent() ? Util.RestAPI.getCurListComponent().desc : null;
                serverData['%STATUS'] = '%UNLOCK';
                params = 'profile';
                descOrCreateType = null;
                if (!!this.selections && this.selections.length>0 &&!!intoThisFolder && intoThisFolder.id==='recentedits' && intoThisFolder.lib !== this.selections[0].lib) {
                  intoThisFolder.lib = this.selections[0].lib;
                  intoThisFolder.DOCNAME = intoThisFolder.lib;
                }
                intoThisFolder = !!intoThisFolder && Util.isUploadTarget(intoThisFolder) && (!!intoThisFolder.id && (intoThisFolder.type!=='folders' || (intoThisFolder!=='public' && intoThisFolder!=='checkedout'))) ? intoThisFolder : null;
                if (externalLocation) {
                  const extension: any = Util.Transforms.getExtensionFromAppID(this.selections[i].APP_ID);
                  let docObj: any = Util.decodeFileName(this.selections[i].DOCNAME);
                  if (!docObj) {
                    docObj = {name:this.selections[i].DOCNAME,ext:extension};
                  }
                  if (!this.dataFiles) {
                    this.dataFiles = [];
                  }
                  this.dataFiles[i] = {name:docObj.name + '.' + docObj.ext};
                  this.dataFiles[i].url = this.selections[i]['checkout']['LOCATION'];
                  this.dataFiles[i].externalName = this.selections[i].DOCNAME;
                  this.dataFiles[i].size = Number(this.selections[i].size);
                  this.dataFiles[i]['_restapi'] = {};
                  this.dataFiles[i]['_restapi'].checkinData = {};
                  this.dataFiles[i]['_restapi'].checkinData['%STATUS'] = '%UNLOCK';
                  this.dataFiles[i]['_restapi'].checkinData['DOCNUM'] = this.selections[i].id;
                  this.dataFiles[i]['_restapi'].checkinData['lib'] = this.selections[i].lib;
                  intoThisFolder = {id:'recentedits',type:'folders',lib:Util.RestAPI.getPrimaryLibrary()};
                  this.filePaths = null;
                } else {
                  this.dataFiles = null;
                }
                if (i===this.selections.length-1) {
                  setTimeout(() => {
                    Util.RestAPI.uploadFilesWithUI(this.fileList, this.filePaths, this.dataFiles, intoThisFolder);
                  }, 500);
                }
              } else {
                if (!Util.RestAPI.hasPFTA() && ((this.filePaths && this.filePaths.length !== this.selections.length) || (this.fileList && this.fileList.length !== this.selections.length))) {
                  Util.Notify.error(this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.CHECK_IN'),this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ERROR_FILE_MATCH'));
                  this.destroyScript(true, allData, serverData);
                  return;
                }
                if (this.fileList) {
                  if (!filesMatchDocuments) {
                    [filesMatchDocuments, failedCheckedinCount] = this.selectedFilesMatchSelectedDocuments();
                    if (!filesMatchDocuments) {
                      const notifyBodyNoMatch: string = this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.ERROR_FILE_COUNT',[failedCheckedinCount.toString()]);
                      Util.Notify.error(this.localizer.getTranslation('FORMS.LOCAL.CHECK_IN.CHECK_IN'),notifyBodyNoMatch);
                      this.destroyScript(true, allData, serverData);
                      return;
                    }
                  }
                  // file name is such: DM16L-#33484-v77916-bands.xlsx
                  formData = new FormData();
                  const file = this.findCheckinDiskFile(item);
                  if (!!item.checkout && !!item.checkout['VERSION_LABEL']) {
                    version = item.checkout['VERSION_LABEL'];
                  } else if (!!item['VERSION_LABEL']) {
                    version = item['VERSION_LABEL'];
                  }
                  descOrCreateType = checkinKind==='REPLACE_VERSION' ? null : item;
                  formData.append('file', file);
                  formData.append('data', JSON.stringify(serverData));
                  serverData = formData;
                  sendRaw = true;
                  if (checkinKind!=='NEW_VERSION' && version && version.length) {
                    params = 'versions/' + version;
                  } else {
                    params = null;
                  }
                } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova) && !externalLocation) {
                  // RestAPI takes care of upload and profile data so just get out
                  Util.RestAPI.uploadFilesWithAppWorks(this.filePaths, serverData, checkinKind==='REPLACE_VERSION', checkinKind!=='NEW_VERSION', undefined, this.selections);
                  return this.finishPopOKWithRefresh(allData, serverData);
                } else if (Util.RestAPI.hasPFTA() && !externalLocation) {
                  Util.RestAPI.checkinDocsWithPFTA(this.selections, serverData, checkinKind==='REPLACE_VERSION', checkinKind!=='NEW_VERSION');
                  return this.finishPopOKWithRefresh(allData, serverData);
                } else if (externalLocation) {
                  formData = new FormData();
                  if (checkinKind==='REPLACE_VERSION' || checkinKind==='NEW_SUB_VERSION') {
                    if (checkinKind==='NEW_SUB_VERSION') {
                      descOrCreateType = item;
                    } else {
                      descOrCreateType = null;
                    }
                    if (!!item.checkout && !!item.checkout['VERSION_LABEL']) {
                      version = item.checkout['VERSION_LABEL'];
                    } else if (!!item['VERSION_LABEL']) {
                      version = item['VERSION_LABEL'];
                    }
                  } else {
                    descOrCreateType = item;
                  }
                  serverData['_restapi'] = {LOCATION: externalLocation, DOCNAME: item['DOCNAME'], driveItems:[]};
                  if (allData?.DOCUMENTS?.length > 0) {
                    for (let i = 0; i < allData?.DOCUMENTS?.length; i++) {
                      serverData['_restapi'].driveItems[i] = {
                        DOCNAME: allData.DOCUMENTS[i].DOCNAME, 
                        id : allData.DOCUMENTS[i].externalDocId
                      };
                    }
                  }
                  formData.append('data', JSON.stringify(serverData));
                  serverData = formData;
                  sendRaw = true;
                  if (checkinKind!=='NEW_VERSION' && version && version.length) {
                    params = 'versions/' + version;
                  } else {
                    params = null;
                  }
                  if (!!item['openWindow']) {
                    Util.Help.closeURL(item['openWindow']);
                  }
                }
              }
            } else if ((localFormName==='copy') || (localFormName==='newversion')) {
              const destName: string = !!this.docNameFromRef(serverData) ? this.docNameFromRef(serverData) : !!crossLibRoot ? crossLibRoot : Util.RestAPI.getPrimaryLibrary();
              const newName: string = serverData['DOCNAME'];
              const docNum: string = item['DOCNUMBER'] || item['DOCNUM'];
              const verLbl: string = item['VERSION_LABEL'] || item['ver'];
              notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.DONE',[item.DOCNAME,destName,newName]);
              version = null;
              if (localFormName==='newversion') {
                serverData._restapi = {};
                item['id'] = docNum;
                item['type'] = 'documents';
                version = item['VERSION_ID'];
                if (allData['$edx_new_version']==='NEW_VERSION') {
                  params = docNum;
                  if (item.lib.toUpperCase() !== primaryLibUC) {
                    queryargs = '?library=' + item.lib;
                  }
                  notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.VERSION_DONE',[verLbl]);
                } else if (allData['$edx_new_version']==='NEW_SUB_VERSION') {
                  params = docNum + '/versions/' + verLbl;
                  if (item.lib.toUpperCase !== primaryLibUC) {
                    queryargs = '?library=' + item.lib;
                  }
                  notifyBody = this.localizer.getTranslation('FORMS.LOCAL.COPY.SUB_VERSION_DONE',[verLbl]);
                }
                if (allData['%CHECKIN_LOCATION']) {
                  delete serverData['%CHECKIN_LOCATION'];
                  if (this.fileList) {
                    let fileName: string = allData['%CHECKIN_LOCATION'];
                    formData = new FormData();
                    formData.append('file', this.fileList[0]);
                    formData.append('data', JSON.stringify(serverData));
                    serverData = formData;
                    sendRaw = true;
                    notifyTitle = this.localizer.getTranslation('DOC_STATUS.CHECKEDIN');
                    if (fileName.startsWith('file:')) {
                      fileName = fileName.substring(5);
                    }
                    notifyBody = this.localizer.getTranslation(allData['$edx_new_version']==='NEW_VERSION'?'FORMS.LOCAL.COPY.VERSION_DONE_FILE':'FORMS.LOCAL.COPY.SUB_VERSION_DONE_FILE',[fileName]);
                  } else if (this.filePaths && (Util.Device.bIsElectron || Util.Device.bIsCordova)) {
                    // RestAPI takes care of upload and profile data so just get out
                    Util.RestAPI.uploadFilesWithAppWorks(this.filePaths, serverData, false, allData['$edx_new_version']==='NEW_SUB_VERSION' && !!verLbl, verLbl, this.selections, notifyTitle);
                    this.destroyScript(true, allData, serverData);
                    return;
                  }
                }
              } else {
                version = this.dynamicForm.getFieldValue('$edx_version');
              }
              if (!serverData._restapi) {
                serverData._restapi = {};
              }
              version = version || (!!item['VERSION_ID'] ? item['VERSION_ID']: 'C');
              serverData._restapi['src'] = {
                ver: version,
                id: item.id,
                type: item.type,
                lib: localFormName ==='copy' && this.relateToDesc?.lib !== item.lib ? this.relateToDesc?.lib : item.lib
              };
              descOrCreateType = item.type;
              if (localFormName==='copy' && serverData._restapi['ref'] && serverData._restapi['ref'].length === 1) {
                if (serverData._restapi['ref'].lib.toUpperCase() !== primaryLibUC) {
                  queryargs = '?library=' + serverData._restapi['ref'].lib;
                }
                if (!serverData._restapi['ref'].id || serverData._restapi['ref'].id.substring(0,3)==='DV-') {
                  // copy to root
                  delete serverData._restapi['ref'];
                }
              }
            } else if (localFormName === 'copytree') {
              serverData._restapi['src'] = {
                id: item.id,
                type: item.type,
                lib: item.lib
              };
              descOrCreateType = item.type;
            } else if (localFormName==='checkout') {
              if (isExternalCheckout) {
                if (serverData._restapi) {
                  version = !!item['VERSION_ID'] ? item['VERSION_ID']: 'C';
                  serverData._restapi['src'] = {
                    ver: version,
                    id: item.id,
                    type: item.type,
                    lib: item.lib
                  };
                  const extAppInfo: any = Util.RestAPI.findExternalApp(serverData._restapi['ref']['lib']);
                  if (extAppInfo) {
                    serverData['DESCRIPTION'] = this.localizer.getTranslation('HISTORY_ACTIONS.999',[extAppInfo['name']]);
                  }
                }
              } else {
                version = downloadItem && item['VERSION_ID'] ? item['VERSION_ID'] : undefined;
              }
              if (item['ITEM_TYPE'] === 'M') {
                serverData['%STATUS'] = '%LOCK';
                downloadItem = false;
              }
            } else if (localFormName === 'rm_request') {
              params = 'requests';
              descOrCreateType = item;
            } else if (localFormName === 'rm_loanrequest') {
              params = 'requests';
              serverData['%BORROW_ITEM'] = '1';
              notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.LOANED_TITLE');
              notifyBody = this.selections.length === 1 ? this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.LOANED', [item.DOCNUM]) : this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.LOANED_MANY', [this.selections.length.toString()]);
            } else if (localFormName === 'rm_vitalreviewdate') {
              params = 'records/';
              serverData['%CHANGE_VITAL_REVIEW_DATE'] = '1';
            } else if (localFormName === 'rm_putinbox') {
              params = 'boxoperations';
              descOrCreateType = item;
              serverData['%PUT_IN_BOX'] = serverData['PD_BOX'];
              notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.KEPT_IN_BOX');
              notifyBody = this.selections.length === 1 ? this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.PUT_IN_SUCCESS', [item['PD_FILEPT_NO'], serverData['PD_BOX']]): this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.PUT_IN_SUCCESS_MANY', [this.selections.length, serverData['PD_BOX']]);
            } else if (localFormName === 'rm_removefrombox') {
              params = 'boxoperations';
              descOrCreateType = item;
              serverData['%REMOVE_FROM_BOX'] = serverData['PD_LOCATION_CODE'];
              notifyTitle = this.localizer.getTranslation('METADATA.RECORDS_ACTIONS.REMOVED_FROM_BOX');
              notifyBody = this.selections.length === 1 ? this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REMOVE_FROM_SUCCESS', [item['PD_FILEPT_NO'], serverData['PD_LOCATION_CODE']]) : this.localizer.getTranslation('FORMS.LOCAL.RM_REQUEST.REMOVE_FROM_SUCCESS_MANY', [this.selections.length, serverData['PD_LOCATION_CODE']]);
            } else if (localFormName === 'versions_editprofile') {
              params = 'versions/' + item['VERSION_ID'] + '/profile';
            }
            const versToUse = versionIDOverride || version;
            let singleVers: string = versToUse;
            if (!!versToUse && versToUse.indexOf(';') >= 0) {
              singleVers = versToUse.split(';')[i];
            }
            const sendItemAndDownload = (useTempFile?: boolean, versionInfoData?: any): any => {
              let doContinue = false;
              if (sendItem) {
                if (descOrCreateType) {
                  this.formService.postToServer(descOrCreateType, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody, null, localFormName === 'copytree' ? 'copytree_results' : null);
                } else {
                  if (localFormName === 'checkin' && allData['$edx_new_version'] === 'NEW_DOCUMENT') {
                    doContinue = true;
                  } else {
                    if (localFormName === 'checkout' && !!versionInfoData) {
                      serverData['FILE_EXTENSION'] = versionInfoData['FILE_EXTENSION'];
                    }
                    this.formService.putToServer(item, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
                  }
                }
              }
              if (downloadItem) {
                if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
                  downloadList.push(item);
                  if (Util.Device.bIsElectron) {
                    if (localFormName === 'export' && Util.isExternalLib(item.lib)) {
                      downloadLaunch = true;
                      downloadShortName = true;
                    } else if (localFormName === 'checkout') {
                      downloadLaunch = true;
                    }
                  }
                } else {
                  if (localFormName === 'export' && downloadQueryargs === 'linkonly' && ['C', 'P'].indexOf(singleVers) !== -1) {
                    item.ver = singleVers;
                    item.link = true;
                  }
                  if (Util.RestAPI.hasPFTA() && localFormName === 'checkout' && !downloadPath) {
                    Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, 'temp', false, true);
                  } else {
                    const template = Util.RestAPI.getSystemDefaultSetting('ShortFileName_Template');
                    if (Util.RestAPI.hasPFTA() && localFormName === 'export' && !downloadPath) {
                      Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, 'downloads', !!template);
                    } else {
                      Util.RestAPI.downloadFile(item, singleVers, downloadQueryargs, downloadPath, !!template && !!localFormName && localFormName === 'export', false, null, useTempFile, versionInfoData);
                    }
                  }
                }
              }
              return doContinue;
            };
            if (Util.RestAPI.hasPFTA() && localFormName === 'checkout' && !!downloadPath) {
              Util.RestAPI.checkTempFile(item, downloadPath, singleVers).then(result => {
                if (!result.ignoreSelection) {
                  sendItemAndDownload(result.useTempFile, result.versionInfoData);
                }
              });
            } else {
              if (sendItemAndDownload(false, null)) {
                continue;
              }
            }
            serverData = savedServerData;
          }
        };
        if (localFormName !== 'exportresults') {
          if (this.selections.length > 1 && Util.RestAPI.hasPFTA() && localFormName === 'checkout' && !!downloadPath) {
            Util.RestAPI.canCheckoutSelectedDocuments(this.selections, downloadPath, 'C').then(canCheckout => {
              if (canCheckout) {
                handleSelectedItems();
              }
            });
          } else {
            handleSelectedItems();
          }
          if (downloadList.length) {
            Util.RestAPI.downloadFilesWithAppWorks(downloadList, downloadPath, undefined, versionIDOverride || version, downloadQueryargs, downloadLaunch, false, false, false, downloadShortName);
          }
        }
      } else {
        if (this.createType || isSearchForm) {
          if (isSearchForm) {
            // if we are a search form for execute, massage the save criteria for execute
            if (this.formData) {
              // this is how edit profile defaults works
              const includeDescriptionOfFields = true;
              const editableData: any = masterDynamicForm.getEditableValues(includeDescriptionOfFields);
              serverData = {...editableData, ...serverData};
            }
            if (this.desc.type==='forms') {
              this.formService.updateSearchCriteria(serverData, this.formTemplate);
            }
            const searchCriteria: any = {
              FORM_NAME: allData['form_name'] || this.getFormName(),
              DESCRIPTION: this.formService.getSearchDescription(serverData, masterDynamicForm),
              criteria: serverData
            };
            if (this.kind === 'profile_query_edit') {
              searchCriteria['DESCRIPTION'] = Util.Transforms.restoreQueryValue(this.desc['DESCRIPTION']||this.desc['DOCNAME']);
              notifyTitle = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_SEARCH');
              notifyBody = this.localizer.getTranslation('FOLDER_ACTIONS.EDIT_CRITERIA_SUCCESS', [searchCriteria['DESCRIPTION']]);
              params = 'profile';
              this.formService.putToServer(this.desc, searchCriteria, params, null, sendRaw, notifyTitle, notifyBody);
            } else {
              if (searchCriteria.criteria.STATUS==='0') {
                searchCriteria.criteria['READONLY'] = 'N';
              }
              setTimeout(() => {
               Util.RestAPI.navToSearchURL('folders', searchCriteria);
              }, Util.kPopupDismissMS);
            }
          } else {
            if (this.kind === 'profile_uploadfolders') {
              Util.RestAPI.uploadFolders(serverData, this.desc.lib);
            } else {
              this.formService.postToServer(this.createType, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
            }
          }
          if (this.kind === 'profile_query') {
            setTimeout(() => {
              Util.RestAPI.resetDialogKind()
            }, 2000);
          }
        } else {
          if (this.bMassProfileUpdate) {
            serverData = Object.keys(serverData)
              .filter(key => serverData[key])
              .reduce((obj, key) => {
                obj[key] = serverData[key];
                return obj;
              }, {});
            if (Object.keys(serverData).filter(x => !['FORM', '_restapi'].includes(x)).length > 0) {
              serverData._restapi['multipletargets'] = this.selections.map(s => s.id).join(';');
              serverData._restapi['trusteesdirective'] = masterDynamicForm.trusteesDirective;
              notifyTitle = this.localizer.getTranslation('FORMS.LOCAL.MASS_PROFILE.RESULTS');
              this.formService.putToServer(this.desc.type + '/multiple', serverData, params, '?library=' + this.desc.lib, sendRaw, notifyTitle, notifyBody, null, 'massprofile_results');
            } else {
              const response = this.selections.map(s => ({ DOCNUM: s.id }));
              Util.Notify.info(notifyTitle, Util.kLocalFormPrefix + 'massprofile_results', null, response);
            }
          } else if (canSubmit) {
            this.formService.putToServer(this.desc, serverData, params, queryargs, sendRaw, notifyTitle, notifyBody);
          }
        }
      }
      this.destroyScript(true, allData, serverData);
    }
  }

  private getVersionLabel(listName: string, versionId: string): string {
    return this.scriptData[listName]?.find(item => item.VERSION_ID === versionId)?.VERSION_LABEL;
  }

  private finishPopOKWithRefresh(allData: any, serverData: any): void {
    this.destroyScript(true, allData, serverData);
    Util.RestAPI.refreshCurWindow();
  }

  private docNameFromRef(serverData: any): string {
    if (!!serverData && !!serverData._restapi && !!serverData._restapi['ref']) {
      const refs = Array.isArray(serverData._restapi['ref']) ? serverData._restapi['ref'] : [serverData._restapi['ref']];
      for (const ref of refs) {
        if (!!ref.DOCNAME) {
          return ref.DOCNAME;
        }
      }
    }
    return null;
  }

  private getCheckInInfoFromFileName(): any {
    let checkinInfo: any = null;
    const docObjs: any[] = [];
    const nFiles: number = this.fileList ? this.fileList.length : this.filePaths ? this.filePaths.length : this.dataFiles ? this.dataFiles.length : 0;
    for (let i=0; i<nFiles; i++) {
      if (this.fileList) {
        docObjs[i] = Util.decodeFileName(this.fileList[i].name);
      } else if (this.dataFiles) {
        docObjs[i] = Util.decodeFileName(this.dataFiles[i].name);
      } else if (this.filePaths) {
        const name = Util.RestAPI.getFileNameFromPath(this.filePaths[i]);
        docObjs[i] = Util.decodeFileName(name);
      }
    }
    let separator = '';
    checkinInfo = {DOCNUMS:'',LIBS:'',items:[]};
    for (let i=0; i<nFiles; i++) {
      checkinInfo['DOCNUMS'] += separator + docObjs[i].docNum;
      checkinInfo['LIBS'] += separator + docObjs[i].lib;
      checkinInfo.items[i] = {checkinData:{}};
      checkinInfo.items[i].checkinData['DOCNUM'] = docObjs[i].docNum;
      checkinInfo.items[i].checkinData['lib'] = docObjs[i].lib;
      checkinInfo.items[i].checkinData['%STATUS'] = '%UNLOCK';
      separator = Util.RestAPI.kMultiFileSeparator;
    }
    return checkinInfo;
  }

  private copyAll_RM_SpecificFields(sorc: any, dest: any): void {
    // Copy all RM specific fields if present in the source data
    Util.copyFieldIfExists(sorc,dest,'PD_VREVIEW_DATE');
    Util.copyFieldIfExists(sorc,dest,'PD_VITAL');
    Util.copyFieldIfExists(sorc,dest,'PD_FILEPT_NO');
    Util.copyFieldIfExists(sorc,dest,'PD_FILE_PART');
    Util.copyFieldIfExists(sorc,dest,'PD_FILE_NAME');
    Util.copyFieldIfExists(sorc,dest,'PD_SUSPEND');
    Util.copyFieldIfExists(sorc,dest,'PD_LOCATION_CODE');
    Util.copyFieldIfExists(sorc,dest,'PD_ADDRESSEE');
    Util.copyFieldIfExists(sorc,dest,'PD_ORIGINATOR');
    Util.copyFieldIfExists(sorc,dest,'PD_ORGANIZATION');
    Util.copyFieldIfExists(sorc,dest,'X1125'); // BORROWER
    Util.copyFieldIfExists(sorc,dest,'ABSTRACT'); //COMMENT
  }

  // File-picker list of files is in arbitrary order. Find the selected file
  // that matches the dmSelectedFile
  private findCheckinDiskFile(dmFileItem: any): File {
    let i = 0;
    let file: File = null;
    let found = false;
    if (this.fileList.length > 1) {
      for (i; i<this.fileList.length; i++) {
        const fileName: string = this.fileList[i].name;
        const docObj: any = Util.decodeFileName(fileName);
        if (!!docObj) {
          if (dmFileItem.lib === docObj.lib && dmFileItem.id === docObj.docNum && (!dmFileItem['VERSION_LABEL'] || dmFileItem['VERSION_LABEL']===docObj.vers)) {
            found = true;
            break;
          }
        }
      }
    } else if (this.fileList.length === 1) {
      // For single check-in allow an arbitrary disk file name
      found = true;
    }
    if (found) {
      file = this.fileList[i];
    }
    return file;
  }

  private selectedFilesMatchSelectedDocuments(): any {
    let allMatch = true;
    let noMatchCount = 0;
    for (const sel of this.selections) {
      const file = this.findCheckinDiskFile(sel);
      if (file) {
        continue;
      } else {
        allMatch = false;
        noMatchCount = noMatchCount + 1;
      }
    }
    return [allMatch,noMatchCount];
  }

  popupCancel(): void {
    if (this.kind === 'profile') {
      if (!!this.dataFiles && this.dataFiles.length && !!this.dataFiles[0].fromSaveAsDesc && !!this.dataFiles[0].name && !!this.dataFiles[0].url) {
        const desc = this.dataFiles[0].fromSaveAsDesc;
        Util.RestAPI.monitorFile(this.dataFiles[0].name, this.dataFiles[0].url, desc.vers, desc);
      }
    }
    if (this.kind === '__local_checkin') {
      const docs: any[] = this.formData['DOCUMENTS'];
      const nDocs: number = docs ? docs.length : 0;
      for (let i = 0; i < nDocs; i++) {
        const doc: any = docs[i];
        if (!!doc['extAppInfoLib']) {
          doc['lib'] = doc['extAppInfoLib'];
        }
      }
    }
    if (this.kind === 'profile_uploadfolders') {
      Util.RestAPI.resetFolderUploadFolders();
      Util.RestAPI.resetFolderUploadFiles();
      Util.RestAPI.showFolderUploadSpinner(false);
    }
    if (this.kind === 'profile_folderfiles' && Util.RestAPI.getFolderUploadFiles().length > 0) {
      if (Util.RestAPI.nFolderUploadTotalProcessedFiles > 0) {
        Util.RestAPI.showFolderUploadDescription(null);
      }
      Util.RestAPI.resetFolderUploadFiles();
    }
    this.uploadService.resetData();
    if (!!this.desc) {
      delete this.desc['edx_selected_security_choice'];
    }
    this.destroyScript(false, null, null);
    Util.Notify.close();
  }

  public isOutlookMB(): boolean {
    return Util.Device.bIsOfficeAddin && !!Office && !!Office.context && !!Office.context.mailbox;
  }

  public showExtras(show: boolean): void {
    this.extrasToggled(show);
    this.extrasShownByChild.emit(show);
    this.pccExtrasShownByChild.emit(show);
  }

  public extrasToggled(shown: boolean): void {
    this.extrasShown = shown;
    if (this.dynamicForm) {
      this.dynamicForm.dynamicExtrasToggled(shown);
      this.cdr.markForCheck();
    }
    if (this.rightWrapper) {
      this.rightWrapper.extrasToggled(shown);
    }

    const isProfileForm: boolean = this.kind.startsWith('profile');
    const isSearchForm: boolean = this.kind.startsWith('profile_query');
    const extrasShownStr = localStorage.getItem(kExtrasShownKey);
    const extrasShownPref = !!extrasShownStr ? JSON.parse(extrasShownStr) : {};
    let keyForChange: string;
    if (isProfileForm && !isSearchForm) {
      keyForChange = 'PROFILE';
    } else if (isSearchForm) {
      keyForChange = 'SEARCH_PROFILE';
    }
    if (keyForChange) {
      extrasShownPref[keyForChange] = shown;
      localStorage.setItem(kExtrasShownKey, JSON.stringify(extrasShownPref));
    }
  }

  private runScript(field: FormField, control: AbstractControl, formFields: FormField[]): void {
    const trigger: FieldScript = field.scriptTrigger;
    let runIt = true;
    if (trigger.values) {
      const value: string = control.value;
      runIt = (trigger.values.indexOf(value)!==-1);
    }
    const scriptGroup: any = this.getScriptGroup();
    if (runIt && scriptGroup) {
      let script = scriptGroup[trigger.script];
      if (typeof script === 'function') {
        script = script.bind(scriptGroup);
        if (!!this.customForm) {
          script(this.customForm, field.name);
        } else {
          script(this, formFields, field, control, this.localizer);
        }
      }
    }
  }

  private runVisTriggers(field: FormField, control: AbstractControl): void {
    const triggers: Visibility[] = field.visibilityTriggers;
    const value: string = control.value;
    for (const trigger of triggers) {
      let hidden: boolean;
      if (triggers.values) {
        hidden = trigger.hidden === (trigger.values.indexOf(value)!==-1);
      } else if (field.controltype==='checkbox') {
        const on: boolean = control.value===field.checkedValue;
        hidden = trigger.hidden === !on;//element.classList.contains('checked');
      } else {
        hidden = trigger.hidden === !value;
      }
      for (const name of trigger.fields) {
        const triggeredField: FormField = this.dynamicForm.getField(name);
        if (!!triggeredField) {
          triggeredField.isVisible = !hidden;
        }
      }
    }
    this.cdr.markForCheck();
  }

  public fieldChanged(field: FormField, control: AbstractControl, formFields: FormField[]): boolean {
    let rc = true;
    if (field.visibilityTriggers || field.lookup) {
      if ((field.visibilityTriggers)) {
        let bRunVisTrigger: boolean =  true;
        if(!!field.name && field.name.startsWith('$edx_email') && field.errormessage) {
          bRunVisTrigger = false;
        }
        if(bRunVisTrigger){
          this.runVisTriggers(field, control);
        }
      } 
      this.cdr.markForCheck();
      rc = false;
    }
    if (field.scriptTrigger && this.formTemplate && this.formTemplate.name) {
      this.runScript(field, control, formFields);
    }
    if (this.changeListener) {
      this.changeListener.fieldChanged(field, control, this);
    }
    return rc;
  }

  public updateControlValue(name: string, value: any, makeDirty?: boolean, translate?: boolean, transArgs?: string[]): void {
    this.dynamicForm.updateControlValue(name, value, makeDirty, translate, transArgs);
  }

  public buttonClicked(field: FormField, control: AbstractControl, formFields: FormField[]): void {
    if (field.scriptTrigger && this.formTemplate && this.formTemplate.name) {
      this.runScript(field, control, formFields);
    }
  }

  public uploadFiles(files: File[], filePaths?: string[]): void {
    this.fileList = files;
    this.filePaths = filePaths;
  }

  public getApplyAllChecked(): boolean {
    return this.applyAllChecked;
  }

  public hasBrowserUploadFiles(): boolean {
    return (this.fileList && this.fileList.length > 0);
  }

  public geteDocsUploadFiles(): string[] {
    return this.filePaths;
  }

  public appIDForUpload(): string {
    let appID: string = this.copyAppID;
    let multiAppFileName: string;
    if (!appID) {
      let nFiles: number = this.fileList && this.fileList.length > 0 ? this.fileList.length : this.filePaths && this.filePaths.length > 0 ? this.filePaths.length : this.dataFiles ? this.dataFiles.length : 0;
      let totalFiles = nFiles;
      if (nFiles) {
        nFiles = this.applyAllChecked ? nFiles : 1;
        appID = '';
        if (Util.RestAPI.restAPIVersion()<0x00160301) {
          nFiles = 1;
        }
        for (let i=0; i<nFiles; i++) {
          let name: string = null;
          if (this.fileList && this.fileList.length > 0) {
            const file: File = this.fileList[i];
            name = file.name;
          } else if (this.filePaths && this.filePaths.length > 0) {
            name = Util.RestAPI.getFileNameFromPath(this.filePaths[i]);
          } else  if (this.dataFiles && this.dataFiles.length > 0) {
            if (!!this.dataFiles[i].templateDesc && !!this.dataFiles[i].templateDesc['APP_ID']) {
              if (appID.length) {
                appID += Util.RestAPI.kMultiFileSeparator;
              }
              appID += this.dataFiles[i].templateDesc['APP_ID'];
            } else {
              name = this.dataFiles[i].name;
            }
          }
          if (!!name) {
            if (appID.length) {
              appID += Util.RestAPI.kMultiFileSeparator;
            }
            appID += Util.RestAPI.getAppIDForFile(name, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary());
            if (nFiles > 1 || totalFiles > 1) {
              appID = appID.split(Util.RestAPI.kMultiAppIdSeparator)[0];
            }
            multiAppFileName = name;
          }
        }
      }
      if (!appID || !appID.length) {
        appID = this.formData['APP_ID'];
      }
      if (nFiles === 1 && appID.indexOf(Util.RestAPI.kMultiAppIdSeparator) !== -1 && !!multiAppFileName) {
        this.fillProfileFormList(Util.RestAPI.getProfileFormsForFileExt(multiAppFileName, !!this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary()), this.defForm);
      }
    }
    return appID;
  }

  public fileNameForUpload(decodeFileName: boolean = true): string {
    return this.uploadService.fileNameForUpload(this.applyAllChecked, this.kind, this.fileList, this.filePaths, this.dataFiles, this.copyFileName, decodeFileName);
  }

  public enableOK(enable: boolean, dirty: boolean): void {
    this.okDisabled = !enable;
    this.okEnabled.emit({enable, dirty});
  }

  public getFormName(): string {
    return this.defForm;
  }

  public getValue(): any {
    return this.dynamicForm.getValue();
  }

  public getDirtyValue(): any {
    return this.dynamicForm.getDirtyValue();
  }

  public getDirtyAndRequiredValue(): any {
    return this.dynamicForm.getDirtyAndRequiredValue();
  }

  public setEditable(editable: boolean): void {
    if (this.readOnly===editable) {
      this.readOnly = !editable;
      this.dynamicForm.setEditable(editable);
      this.cdr.markForCheck();
    }
  }

  public revert(desc: any): void {
    const kind: string = this.kind.startsWith('profile') ? 'profile' : this.kind;
    this.formService.getFormData(desc, kind).then((formData) => {
      this.formData = formData;
      this.cdr.markForCheck();
    });
  }

  public getRightForm(): DynamicFormComponent {
    if (this.rightWrapper) {
      return this.rightWrapper.dynamicForm;
    }
    return null;
  }

  public getHeaderForm(): DynamicFormComponent {
    if (this.headerWrapper) {
      return this.headerWrapper.dynamicForm;
    }
    return null;
  }

  private validateSearchValue(valueString: string): string {
    // Split semicolon sparated values
    let semicolon = '';
    let validatedValueString = '';
    const values: any = valueString.split(';');
    for (const value of values) {
      validatedValueString += semicolon + Util.Transforms.validateQueryValue(value,'',false);
      semicolon = ';';
    }
    return validatedValueString;
  }

  public getDocumentURL(documents: any, action: string): any {
    const documentURL = [];
    for (const document of documents) {
      const link = Util.RestAPI.makeDeepLink(document, action, false,true);
      if (!Util.Transforms.isDMManagedFile(document['DOCNAME'])) {
        document['DOCNAME'] = Util.Transforms.getDMDocName(document['lib'], document['id'], document['DOCNAME'], ['p', 'P'].indexOf(document['ver']) !== -1 ? 'P' : document['verLabel']);
      }
      const object = {
        DOCNAME: document['DOCNAME'],
        url: link
      };
      documentURL.push(object);
    }
    return documentURL;
  }

  public createSearchCriteria(dirtyFields, formName: string): any {
    const criteria: any = {};
    let criteriaValue = '';
    let value: any;
    const AND = '[AND]'; const OR = '[OR]'; const NOT = '[NOT]';
    let validatedFields = '';

    for (const key in dirtyFields) {
      switch (key) {
        case '$edx_advsearch_exact_phrase':
        case '$edx_advsearch_all_these_words':
          validatedFields = this.validateSearchValue(dirtyFields[key]);
          value = validatedFields.replace(/;/g,AND).trim();
          if (value!=='') {
            value = '(' + value + ')';
            criteriaValue += criteriaValue === '' ? value :  AND + value;
          }
          break;
      case '$edx_advsearch_nearby_words':
          const proxValue = dirtyFields['$edx_advsearch_word_prox'] || 5;
          validatedFields = this.validateSearchValue(dirtyFields[key]);
          value = validatedFields.replace(/;/g, '[NEAR/' + proxValue + ']').trim();
        if (value!=='') {
          value = '(' + value + ')';
          criteriaValue += criteriaValue === '' ? value: AND + value;
        }
        break;
      case '$edx_advsearch_any_of_these_words':
        validatedFields = this.validateSearchValue(dirtyFields[key]);
        value = validatedFields.replace(/;/g,OR).trim();
        if (value!=='') {
          value = '(' + value + ')';
          criteriaValue += criteriaValue === '' ? value :OR + value;
        }
        break;
      case '$edx_advsearch_not_these_words':
        validatedFields = this.validateSearchValue(dirtyFields[key]);
        value = validatedFields.replace(/;/g,AND + NOT).trim();
        if (value!=='') {
          value = '(' + NOT + value + ')';
          criteriaValue += criteriaValue === '' ? value : AND + value;
        }
        break;
      }
    }
    // Removed the condition to check if criteria is empty so that search criteria can be maintained in case of empty search as well.
    criteria['FULLTEXT_CONTENT_PROFILE'] = criteriaValue;

    return { FORM_NAME: formName, DESCRIPTION: criteriaValue, criteria };
  }

  public isFormInvalid(): boolean {
    const controls = this.dynamicForm?.form?.controls;
    const isInvalid = !controls ? false : Object.keys(controls).some(key => controls[key].status === 'INVALID');
    return isInvalid;
  }

  public getInfoIconPath(): string {
    return Util.Transforms.getInfoIconPath();
  }

  public canInheritSecurity(): boolean {
    if (!!this.dynamicForm.securedFlexFolderLookupIDs) {
      if (this.desc.id === '0' && this.desc['type'] !== 'preferences') {
        return (this.dynamicForm.inheritedFlexTrusteeList?.length > 0);
      } else {
        return (
          (!!this.desc[':INH_LUP_SEC_FROM'] && this.desc[':INH_LUP_SEC_FROM'] !== '0') ||
          (!!this.desc['INH_LUP_SEC_FROM'] && this.desc['INH_LUP_SEC_FROM'] !== '0') ||
           this.dynamicForm.inheritedFlexTrusteeList?.length > 0
        );
      }
    } else {
      return false;
    }
  }

  private updateDocumentsFormInfo(formName: string, appID: string, serverData: any) {
    const library = this.desc.lib?.toUpperCase();
    this.uploadService.updateDocumentsFormInfo(formName, appID, serverData, library);
  }
}

export interface FieldChangeListener {
  fieldChanged?(field: FormField, control: AbstractControl, formWrapper: FormWrapperComponent): void;
}
