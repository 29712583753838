import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, AfterViewChecked, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { WindowModalComponent } from './window-modal.component';
import { Util, OrientationListener } from '../utils/utils.module';
import { AppComponent } from '../app.component';
import { GestureListener, GestureKind } from '../directives/gesture.directive';
import { FilelistReceiver } from '../widgets/filelist-sidebar.component';
import { TabSelectorComponent } from '../widgets/tab-selector.component';
import { ActionBarComponent } from '../widgets/action-bar.component';
import { FooterDisplayItem } from '../widgets/list-info-footer.component';
import { TabItem, TabReceiver } from '../models/tab-item';
import { ListItem } from '../models/list-item';
import { MenuItem } from '../models/menu-item';
import { BaseDesc, ListData } from '../models/base';
import { SecurityControl, AccessRights, AccessLevel } from '../models/security-control';
import { CommandHandler, MenuItemSetter } from '../models/command-handler';
import { ColFormat } from '../models/column';
import { FormService } from '../services/form.service';
import { ListService } from '../services/list.service';
import { TileService } from '../services/tile.service';
import { MenuId } from '../services/menu.service';
import { SchemaDef } from '../services/schema.service';
import { LocalizeService } from '../services/localize.service';
import { OOxmlService } from '../services/ooxml.service';
import { ListTableParent, ListTableComponent } from '../lists/list-table.component';
import { FilterTarget, FilterSidebarComponent } from '../forms/filter-sidebar.component';
import { ListSecurityComponent } from '../lists/list-security.component';
import { FormWrapperComponent } from '../forms/form-wrapper.component';
import { MenuComponent } from '../widgets/menu.component';
import { VersionViewer } from '../widgets/version-viewer.component';
import { ShortcutsService, KeyCommand } from '../services/shortcuts.service';
import { Subscription } from 'rxjs';

declare let Office;
declare const brava: any;
let bravaSession: any;
const TAB_ID = {
  PROFILE: 'profile',
  PREVIEW: 'preview',
  VIEWER : 'viewer',
  VERSIONS: 'versions',
  ATTACHMENTS: 'attachments',
  HISTORY: 'history',
  WHERE_USED: 'whereused',
  RELATED: 'related',
  SECURITY: 'security'
};

@Component({
  selector: 'edx-window-modal-metadata',
  styleUrls: [ 'window-modal.component.scss', 'window-modal-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'window-modal-metadata.component.html'
})
export class WindowModalMetadataComponent extends WindowModalComponent implements OnInit, OnDestroy, AfterViewChecked, FilelistReceiver, TabReceiver, ListTableParent, CommandHandler, GestureListener, OrientationListener, FilterTarget {
  @ViewChild('contentPanel') contentPanel: ElementRef;
  @ViewChild('previewIFrame') previewIFrame: ElementRef;
  @ViewChild('headerTextDiv') headerTextDiv: ElementRef;
  @ViewChild('dragCover') dragCover: ElementRef;
  @ViewChild('formWrapper') formWrapper: FormWrapperComponent;
  @ViewChild('listtable') listTable: ListTableComponent;
  @ViewChild('tabselector') tabSelector: TabSelectorComponent;
  @ViewChild('actionbar') actionBar: ActionBarComponent;
  @ViewChild('headerWrapper') private headerWrapper: FormWrapperComponent;
  @ViewChild('security') private security: ListSecurityComponent;
  @ViewChild('menuDiv') menuDiv: MenuComponent;
  @ViewChild('versionViewer') versionViewer: VersionViewer;
  @ViewChild(FilterSidebarComponent) private filters: FilterSidebarComponent;
  @Input() splitView = false;
  @Input('securedFlexKeyValueList') securedFlexKeyValueList = [];
  @Input('inheritFromPrimaryKey') inheritFromPrimaryKey = '';
  public portrait: boolean = Util.Device.isPortrait();
  public headerIcon = '';
  public headerText = '';
  public headerMenuID = 0;
  public headerFontSize = 'inherit';
  public actionId = -1;
  public tabId: string;
  public disabledNav = false;
  public bravaEnabled = false;
  public showFilter = false;
  public filtered = false;
  private tabList: TabItem[] = [
    { title: 'METADATA.TABS.PROFILE', id: 'profile' },
    { title: 'METADATA.TABS.PREVIEW', id: 'preview' },
    { title: 'METADATA.TABS.VIEWER', id: 'viewer' },
    { title: 'METADATA.TABS.VERSIONS', id: 'versions' },
    { title: 'METADATA.TABS.ATTACHMENTS', id: 'attachments' },
    { title: 'METADATA.TABS.HISTORY', id: 'history' },
    { title: 'METADATA.TABS.WHERE_USED', id: 'whereused' },
    { title: 'METADATA.TABS.RELATED', id: 'related' },
    { title: 'METADATA.TABS.SECURITY', id: 'security' }
  ];
  private filterKeys: string;
  private fileList: ListItem[] = [];
  private containerRights: number = AccessLevel.ACCESS_LEVEL_FULL;
  private currentFile: ListItem = null;
  private currentFileRights: SecurityControl = new SecurityControl();
  private appComponent: AppComponent = null;
  private previewIFrameLoading = false;
  private previewIFrameScale = 1;
  private previewIFrameTransform: SafeStyle;
  private selectIndex = 0;
  private selectedListItems: ListItem[];
  private footerItems: FooterDisplayItem[] = [];
  private footerHeading: string = null;
  private rightsLabels: any[];
  private allowedList: string = null;
  private deniedList: string = null;
  private profileRefresh = false;
  private isRestricted = false;
  private isReadOnly = false;
  private securityListVisible = true;
  private settingSecurity = false;
  private autoAddUsers = false;
  private isWorkspaceOnMobile = false;
  private layoutComplete = false;
  private bLoadingBrava = false;
  private sidebarWidth: number;
  private sidebarDragStartLeft: number;
  private rerender = 0;
  public versionCompare = false;
  public compareUrl: string = null;
  public isGraphicalCompare = false;
  public goBackAltText: string;
  public isEffectiveRightsLoaded = false;
  private shortcutsSubscription: Subscription;
  public videoUrl = '';
  public videoMime = '';
  public totalVersions = 0;
  public versions = [];
  public zoomInText = '';
  public zoomOutText = '';
  private propertiesNeedLatestData = ['%EFFECTIVE_RIGHTS', '%SECURITY', 'SECURITY', 'STATUS'];
  constructor(protected location: Location, protected router: Router, protected route: ActivatedRoute, private sanitizer: DomSanitizer,
              private cdr: ChangeDetectorRef, private localizer: LocalizeService, private formService: FormService, protected listService: ListService,
              private tileService: TileService, private ooxmlService: OOxmlService,
              private shortcutsService: ShortcutsService) {
    super(location,router,route);
    const url: string = router.url;
    const decodedUrl: string = Util.RestAPI.decodeChildRouteURL(this.router.url);
    const tab: string = Util.RestAPI.getQueryFromURL(decodedUrl, 'tab');
    const sbWidth: string = localStorage.getItem('meta_data_window_sidebarwidth');
    this.tabId = tab ? tab : 'profile';
    this.sidebarWidth = sbWidth ? (parseInt(sbWidth) < Util.remsToPx(14) ? Util.remsToPx(14) : parseInt(sbWidth)) : Util.remsToPx(18);
    this.appComponent = Util.RestAPI.getAppComponent();
    this.appComponent.registerWindow(this, url);
    Util.Device.registerOrientationListener(this);
    this.setList();
    this.setActionID();
    this.rawUrl = url; // rawUrl is affecting the currentFile, hence url is assigned
    this.chooseInitialFile();
    this.setHeaderIcon();
    this.setFooterLabels();
    this.setPreviewIFrameTransform();
    this.onResize();
    this.bravaEnabled = Util.RestAPI.isBravaEnabled();
    this.isWorkspaceOnMobile = !!this.currentFile && this.currentFile.type === 'workspaces' && Util.Device.isPhoneLook();// (this.ui !== 2 && this.ui !== 4);
    this.goBackAltText = this.localizer.getTranslation('TOOLTIP.GO_BACK');
    this.zoomInText = this.localizer.getTranslation('ALT_TEXT.ZOOM_OUT');
    this.zoomOutText = this.localizer.getTranslation('ALT_TEXT.ZOOM_OUT');
  }

  ngOnInit() {
    this.shortcutsSubscription = this.shortcutsService.commands.subscribe(c => this.handleCommand(c));
    super.ngOnInit();
  }

  ngOnDestroy() {
    Util.Device.deregisterOrientationListener(this);
    this.appComponent.deregisterWindow(this);
    if (this.disabledNav) {
      this.appComponent.blockForDirty(this, false);
      this.disabledNav = false;
      this.disableShortcutKeysUsage(false);
    }
    if (this.shortcutsSubscription) {
      this.shortcutsSubscription.unsubscribe();
    }
  }

  handleCommand(command: KeyCommand): void {
    if (command) {
      let elementId: string;
      let isClassElement: boolean;
      let id: string;
      switch (command.name) {
        case 'doc-profiler':
          id = TAB_ID.PROFILE;
          break;
        case 'doc-preview':
          id = TAB_ID.PREVIEW;;
          break;
        case 'doc-view':
          id = TAB_ID.VIEWER;
          break;
        case 'doc-versions':
          id = TAB_ID.VERSIONS;
          elementId = 'img[id='+'edx_action_submenu'+']';
          isClassElement = true;
          break;
        case 'doc-attachments':
          id = TAB_ID.ATTACHMENTS;
          elementId = 'img[id='+'edx_action_submenu'+']';
          isClassElement = true;
          break;
        case 'doc-history':
          id = TAB_ID.HISTORY;
          elementId = 'edx_action_showfilter';
          break;
        case 'doc-where-used':
          id = TAB_ID.WHERE_USED;
          elementId = 'edx_action_whereused_add';
          break;
        case 'doc-related':
          id = TAB_ID.RELATED;
          elementId = 'edx_action_related_add';
          break;
        case 'doc-security':
          id = TAB_ID.SECURITY;
          break;
        default:
          if (command.name.split('-')[0] === 'doc') {
            const cmd = command.name.replace('-', '_');
            if (this.commandEnabled(cmd)) {
              this.doCommand(cmd);
            }
          }
          break;
      }
      if (!!id && this.tabSelector) {
        this.tabSelector.selectTabById(id);
        setTimeout(() => {
          if (elementId && isClassElement) {
            document.querySelector<HTMLInputElement>(elementId)?.focus();
          } else if (elementId) {
            (document.getElementById(elementId) as HTMLElement)?.focus();
          } else {
            this.focusOnSelectedTab();
          }
        }, 100);
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.ui>=2 && !this.layoutComplete) {
      setTimeout(() => {
        this.checkHeaderTextLen();
      }, 1);
    }
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.portrait = isPortrait;
  }

  @HostListener('window:resize')
  private onResize(): void {
    if (this.ui>=2) {
      this.headerFontSize = '1rem';
      this.layoutComplete = false;
      this.checkHeaderTextLen();
    }
  }

  private setFooterLabels(): void {
    this.rightsLabels = [];
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_PROFILE, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_PROFILE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_VIEW_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.VIEW_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_RETRIEVE_DOCUMENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.RETRIEVE_DOCUMENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_EDIT_CONTENT, label: this.localizer.getTranslation('SECURITY.RIGHTS.EDIT_CONTENT')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_COPY, label: this.localizer.getTranslation('SECURITY.RIGHTS.COPY')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_DELETE, label: this.localizer.getTranslation('SECURITY.RIGHTS.DELETE')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_CONTROL_ACCESS, label: this.localizer.getTranslation('SECURITY.RIGHTS.CONTROL_ACCESS')});
    this.rightsLabels.push({ value: AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED, label: this.localizer.getTranslation('SECURITY.RIGHTS.ALLOW_VIEW_PUBLISHED')});
  }

  private getSidebarWidth(): string {
    return ''+this.sidebarWidth+'px';
  }

  private setList(): void {
    const list: ListItem[] = Util.RestAPI.getCurList();
    this.fileList = [];
    if (list && list.length) {
      for (const item of list) {
        if (item.type!=='urls' && item.type!=='flexfolders' && (item.type!=='searches' || this.tabId==='security')) {
          this.fileList.push(item);
        }
      }
    }
  }

  private setActionID(): void {
    switch (this.tabId) {
      case 'related':
        this.actionId = this.ui<2 ? MenuId.MENU_RELATED_ACTIONS : MenuId.MENU_MOBILE_RELATED_ACTIONS;
        break;
      case 'whereused':
        this.actionId = this.ui < 2 ? MenuId.MENU_WHERE_USED_ACTIONS : MenuId.MENU_MOBILE_WHERE_USED_ACTIONS;
        break;
      case 'versions':
        this.actionId = this.ui<2 ? MenuId.MENU_VERSIONS_ACTIONS : MenuId.MENU_MOBILE_VERSIONS_ACTIONS;
        break;
      case 'attachments':
        this.actionId = this.ui<2 ? MenuId.MENU_ATTACHMENTS_ACTIONS : MenuId.MENU_MOBILE_ATTACHMENTS_ACTIONS;
        break;
      case 'history':
        this.actionId = this.ui<2 ? MenuId.MENU_HISTORY_ACTIONS : MenuId.MENU_MOBILE_HISTORY_ACTIONS;
        break;
        case 'preview':
        case 'viewer':
        case 'profile':
        // fall thru intentional
      default:
        this.actionId = this.ui<2 ? -1 : MenuId.MENU_MOBILE_METADATA;
        break;
    }
  }

  public refresh(cmd?: string, value?: any): void {
    cmd = cmd || this.lastCmd;
    if (cmd==='delete' && !(this.tabId === 'versions' && this.lastCmd === 'versions_delete') && !(this.tabId === 'attachments' && this.lastCmd === 'attachments_delete')) {
      Util.RestAPI.navBack();
      Util.RestAPI.refreshLists();
    } else {
      // If last command was doc_checkout or doc_checkin then set doc status to "locked"/"available"
      // so mobile devices can update the action bar on-the-fly with correct options
      if (this.lastCmd === 'doc_checkout' || this.lastCmd === 'versions_checkout' || this.lastCmd === 'versions_open') {
        this.currentFile['STATUS'] = '3';
        this.currentFile['checkout'] = {TYPIST_ID: Util.RestAPI.getUserID(), FULL_NAME: Util.RestAPI.getUserID(), CHECKOUT_DATE:  Util.Transforms.currentDateTime()};
        if (value) {
          if (!!value['%CHECKIN_LOCATION']) {
            this.currentFile['checkout']['%CHECKIN_LOCATION'] = value['%CHECKIN_LOCATION'];
          }
          if (!!value['VERSION_LABEL']) {
            this.currentFile['checkout']['VERSION_LABEL'] = value['VERSION_LABEL'];
          }
          if (!!value['FILE_EXTENSION']) {
            this.currentFile['checkout']['FILE_EXTENSION'] = value['FILE_EXTENSION'];
          }
        }
        Util.RestAPI.setCurItem(this.currentFile);
      } else if (this.lastCmd === 'doc_checkin' || this.lastCmd === 'doc_unlock') {
        this.currentFile['STATUS'] = '0';
        if (!!this.currentFile['checkout']) {
          delete this.currentFile['checkout'];
        }
      } else if (this.lastCmd === 'doc_rm_putinbox') {
        this.currentFile['PD_STATUSES'] = '2';
      } else if (this.lastCmd === 'doc_rm_removefrombox') {
        this.currentFile['PD_STATUSES'] = '0';
      } else if (this.lastCmd === 'doc_rm_loanrequest') {
        this.currentFile['PD_STATUSES'] = '1';
      } else if (this.lastCmd === 'doc_rm_returnrequest') {
        this.currentFile['PD_STATUSES'] = '3';
      } else if (cmd === 'security_save' && this.desc.type==='workspaces') {
        // Workspace is NOT shared if only author is in the security list so reset security to zero
        if (this.security.securityList.length < 2) {
          value = '0';
        }
        if (value !== undefined && value !== this.currentFile['IS_SHARED']) {
          this.currentFile['IS_SHARED'] = value;
          Util.RestAPI.shareChanged(this.currentFile);
          Util.RestAPI.reloadTiles();
          ++this.rerender;
        }
      }
      if (this.listTable) {
        this.listTable.reloadList();
      }
      if (cmd==='security_reset') {
        if (this.disabledNav) {
          this.appComponent.blockForDirty(this, false);
          this.disabledNav = false;
          this.disableShortcutKeysUsage(false);
        }
        if (this.security && this.currentFile['SECURITY']!=='0') {
          if (this.security.isIneritCheckClicked) {
            this.security.isCancelClicked = true;
            this.security.isChecked = this.security.previousCheckStatus === 'true'? true : false;
            this.security.securityReadOnly(this.security.isChecked);
          }
          this.security.reloadList();
        } else {
          this.fileSelected(this.fileList.indexOf(this.currentFile));
        }
        this.inheritedToRestricted();
      } else {
        if (this.security && !cmd) {
          this.security.reloadList();
        }
        setTimeout(() => {
          // force reload of the profile form so angular will rebuild the template
          this.profileRefresh = !this.profileRefresh;
          if (this.actionBar) {
            this.actionBar.updateActions();
          }
          cmd = cmd ? cmd : this.lastCmd;
          this.checkSecurityStatus(cmd);
          this.settingSecurity = false; // Turn of spinned if security was set to unrestricted
        }, 1000);
        // Profile form updates, footer needs to update after refresh
        if (this.isOfficeAddin && this.ooxmlService.isAlwaysUpdateFooterEnabled() && this.formService.formUpdatedSuccessfully && !!this.formWrapper && !!this.formWrapper['desc']['checkout']) {
          setTimeout(() => {
            //dynamicForm has the latest values but doesn't have version label
            this.formWrapper['dynamicForm']['data']['VERSION_LABEL'] = this.formWrapper['desc']['checkout']['VERSION_LABEL'];
            const officeItem = Util.RestAPI.getOfficeItem() || this.formWrapper['dynamicForm']['data'];
            this.ooxmlService.addFooter(officeItem, true).then(() => {
              this.formService.formUpdatedSuccessfully = false;
            }, footerError => {
              console.log(footerError);
            });
          }, 2000);
        }
      }
    }
  }

  private disableShortcutKeysUsage(disableShortcuts: boolean): void {
    this.shortcutsService.setDisableShortcuts(disableShortcuts);
  }

  public changedName(newName: string): void {
    this.onNameChanged(newName);
  }

  private onNameChanged(newName: string): void {
    if (this.currentFile && this.currentFile.DOCNAME !== newName) {
      this.appComponent.curWindowChangedTitle(newName, this.currentFile.DOCNAME);
      this.currentFile.DOCNAME = newName;
      this.appComponent.setActionMenu(this, this.isOfficeAddin || this.ui<2 ? -1 : 28, this.getName(), this.headerIcon);
    }
  }

  private onShareChanged(data: any): void {
    const activeFile: ListItem = this.currentFile;
    activeFile['IS_SHARED'] = data['IS_SHARED'];
    ++this.rerender;
    this.headerIcon = Util.Transforms.iconUrlFromDesc(activeFile);
    this.headerText = Util.Transforms.iconAltTextFromDesc(activeFile);
    this.cdr.markForCheck();
    if (this.tileService.getTile(this.currentFile)) {
      Util.RestAPI.shareChanged(data);
      Util.RestAPI.reloadTiles();
    }
  }

  private checkSecurityStatus(cmd?: string): void {
    const formName: string = this.currentFile['ITEM_TYPE'] ==='W' ? 'WORKSPACE_PROF' : Util.RestAPI.getDefaultProfileForm() ? Util.RestAPI.getDefaultProfileForm().id : '';
    const activeFile: ListItem = this.currentFile;
    this.formService.getFormData(activeFile, 'profile', formName).then((data) => {
      if (data) {
        Util.RestAPI.calculateReadonlyStatus(data);
        const profileStatus = data['STATUS'];
        if (profileStatus) {
          activeFile['STATUS'] = profileStatus;
          if (profileStatus !== '3') {
            delete activeFile['checkout'];
          }
        }
        if (data['SECURITY']) {
          activeFile['SECURITY'] = data['SECURITY'];
        }
        if (data['SEC_REASON_LINK']) {
          activeFile['SEC_REASON_LINK'] = data['SEC_REASON_LINK'];
        }
        if (data[':INH_LUP_SEC_FROM']) {
          activeFile[':INH_LUP_SEC_FROM'] = data[':INH_LUP_SEC_FROM'];
        }
        if (data['INH_LUP_SEC_FROM']) {
          activeFile['INH_LUP_SEC_FROM'] = data['INH_LUP_SEC_FROM'];
        }
        this.inheritedToRestricted();
        if (!!this.lastCmd && this.lastCmd.endsWith('declarerecord')) {
          this.isReadOnly = data['RECORD'] === 'Y' ? true : false;
          this.onSecurityReadOnly(this.isReadOnly);
          this.security?.securityReadOnly(this.isReadOnly);
          this.security?.reloadList();
        }
        if (data['IS_SHARED']) {
          activeFile['IS_SHARED'] = data['IS_SHARED'];
          ++this.rerender;
        }
        if (cmd && cmd.indexOf('readonly') >= 0) {
          activeFile['READONLY'] = data['READONLY'] || data[':READONLY'] || activeFile['READONLY'];
        }
        if ((cmd === 'editprofile' || cmd === 'doc_editprofile')) {
          Util.copyFieldIfExists(data,activeFile,'AUTHOR_ID');
          Util.copyFieldIfExists(data,activeFile,'AUTHOR');
          Util.copyFieldIfExists(data,activeFile,'AUTHOR_FULL_NAME');
          if (this.headerWrapper) {
            const security: string = activeFile['SECURITY'];
            setTimeout(() => {
              this.permissionChanged(security);
              this.cdr.markForCheck();
            }, 1000);
            this.headerWrapper.dynamicForm.updateControlValue('SECURITY', security, true);
          }
        }
        if (cmd === 'doc_editprofile') {
          Util.copyFieldIfExists(data,activeFile,'PD_FILE_PART');
          if (!!data[':PD_FILE_PART']) {
            activeFile['PD_FILE_PART'] = data[':PD_FILE_PART'];
          }
        }
        this.headerIcon = Util.Transforms.iconUrlFromDesc(activeFile);
        this.headerText = Util.Transforms.iconAltTextFromDesc(activeFile);
        if (this.actionBar) {
          this.actionBar.updateActions();
        }
        if (cmd === 'security_save' && this.security?.isIneritCheckClicked) {
          this.reloadForTabOrFile();
        }
        if (this.tabId === 'security' && cmd && (['removereadonly','unlock'].includes(cmd) || Util.RestAPI.itemReadonlyOrCheckedout(data))) {
          this.onSecurityReadOnly(this.isReadOnly);
          this.security?.securityReadOnly(this.isReadOnly);
          this.security?.reloadList();
        }
        this.cdr.markForCheck();
      }
    });
  }

  private togglePreviewProfile(event: Event): void {
    if (this.tabId==='profile') {
      this.tabSelected('preview');
      this.appComponent.setHeaderTitle(this.localizer.getTranslation('METADATA.TABS.PREVIEW'));
    } else {
      this.tabSelected('profile');
      this.appComponent.setHeaderTitle(this.localizer.getTranslation('FOLDER_ACTIONS.VIEW_PROFILE'));
    }
  }

  private previewProfileIconSrc(): string {
    if (this.tabId==='profile') {
      return 'assets/images/preview24.svg';
    }
    return 'assets/images/metadata.svg';
  }

  private canShowPreviewIcon(): boolean {
    let canShowIcon = false;
    if  (this.tabId==='profile' && !(this.desc && Util.isContainer(this.desc.type))) {
      canShowIcon = Util.RestAPI.canShowPreview();
    } else if (this.tabId!=='profile' || !(this.desc && Util.isContainer(this.desc.type))) {
      canShowIcon = true;
    }
    return canShowIcon;
  }

  private checkHeaderTextLen(): void {
    if (this.ui>=2 && !this.layoutComplete) {
      if (this.getName() && this.headerTextDiv && this.headerTextDiv.nativeElement) {
        const hdrTextHeight: number = this.headerTextDiv.nativeElement.offsetHeight;
        if (hdrTextHeight > 48) {
          const fontSize: number = parseFloat(this.headerFontSize.split('rem')[0]);
          this.headerFontSize = ''+(fontSize*.9375)+'rem';
          this.cdr.markForCheck();
        } else {
          this.layoutComplete = true;
        }
      }
    }

    if (this.layoutComplete) {
      // Highlight the select document on the filelist sidebar.
      const selectedElement: any = document.getElementsByClassName('selected fileitem') as HTMLCollection;
      if (selectedElement && selectedElement[0]) {
        selectedElement[0].scrollIntoViewIfNeeded();
      }
    }
  }

  private setHeaderIcon(): void {
    if (!!this.currentFile) {
      this.headerIcon = Util.Transforms.iconUrlFromDesc(this.currentFile);
      this.headerText = Util.Transforms.iconAltTextFromDesc(this.currentFile);
      this.headerMenuID = this.currentFile.type !== 'searches' ? this.ui<2 ? MenuId.MENU_METADATA_HEADER : MenuId.MENU_OAI_METADATA_TABS : 0;
    } else {
      this.headerMenuID = 0;
    }
  }

  private chooseInitialFile(): void {
    // select current file
    if (!!this.rawUrl) {
      const url = Util.RestAPI.decodeChildRouteURL(this.rawUrl);
      const queries: any = Util.RestAPI.getQueriesFromURL(url);
      let curItem: ListItem = Util.RestAPI.getCurItem();
      if (!curItem && !!queries['id'] || (!!queries['lib'] && !!queries['id'] && (curItem['lib'] !== queries['lib'] || curItem['id'] !== queries['id']))) {
        try {
          curItem = new ListItem({
            id: queries['id'],
            type: queries['type'],
            lib: decodeURIComponent(queries['lib']),
            DOCNAME: decodeURIComponent(queries['docname']),
            DOCNUM: queries['id'],
            APP_ID: decodeURIComponent(queries['appid'])
          });
        } catch (e) {
          curItem = undefined;
        }
      }
      if (curItem !== this.currentFile) {
        this.currentFile = curItem;
        if (!this.currentFile) {
          if (this.fileList && this.fileList.length) {
            this.currentFile = this.fileList[0];
          } else {
            this.currentFile = new ListItem(this.desc);
          }
          Util.RestAPI.setCurItem(this.currentFile);
        }
        this.videoUrl = '';
        // get its name
        // find its index
        let index = 0;
        this.selectIndex = 0;
        if (!!this.currentFile && (!this.fileList || !this.fileList.length)) {
          this.fileList = [this.currentFile];
        }
        if (!!this.currentFile && !!this.fileList) {
          for (const item of this.fileList) {
            if (item === this.currentFile || (Util.isSameDesc(item, this.currentFile))) {
              if (item !== this.currentFile) {
                this.currentFile = item; // fatten desc from file list
                this.videoUrl = '';
              }
              this.selectIndex = index;
              break;
            }
            index++;
          }
        }
        this.setHeaderIcon();
      }
      // calculate user's rights on file
      const access = queries['rights'];
      if (!!access) {
        this.containerRights = parseInt(access);
      } else {
        this.containerRights = AccessLevel.ACCESS_LEVEL_FULL;
      }
      if (!!this.currentFile) {
        this.calculateSelectionRights();
      }
    }
  }

  protected loadURL(url: string): void {
     // block the base class from loading the url do not call super
    setTimeout(() => {
      this.chooseInitialFile(); // incase the url changed
      if (this.ui>=2 || this.tabId === 'preview' || this.tabId === 'viewer') {
        if (this.ui>=2) {
          this.appComponent.setHeaderTitle(this.titleForTab());
        }
        if (this.tabId === 'preview' || this.tabId === 'viewer') {
          if (this.tabId === 'viewer') {
            this.loadVersionsForViewer(null);
          } else {
            this.loadPreviewOrViewerOrVideo();
          }
        }
        this.cdr.markForCheck();
        this.appComponent.setActionMenu(this, this.isOfficeAddin ? -1 : this.actionId, this.getName(), this.headerIcon);
      }
      if (this.tabId === 'security' && url.indexOf('share=true') !== -1) {
        this.autoAddUsers = true;
      }
      if (!!this.tabSelector) {
        this.tabSelector.selectTabById(this.tabId);
      }
    }, 1);
  }

  public getName(): string {
    if (this.currentFile && this.currentFile.id && this.currentFile.id.indexOf('FilePart') > -1 && this.desc['name']) {
      return this.desc['name'];
    } else {
      return this.currentFile && this.currentFile.DOCNAME ? this.currentFile.DOCNAME : this.name;
    }
  }

  public getTooltip(): string {
    return this.currentFile && this.currentFile.DOCNAME ? this.currentFile.DOCNAME : this.name;
  }

  // navigate back to list
  private navBack(): boolean {
    Util.RestAPI.navBack();
    return false;
  }

  private checkForValidTab(): boolean {
    let wasValid = true;
    if (!this.tabEnabled(this.tabId)) {
      wasValid = false;
      if (this.tabSelector) {
        for (const item of this.tabList) {
          if (this.tabEnabled(item.id)) {
            this.tabId = item.id;
            this.tabSelector.selectTabById(this.tabId);
            this.cdr.markForCheck();
            break;
          }
        }
      }
    }
    return wasValid;
  }

  public handleGesture(gesture: GestureKind, elem: ElementRef, target: any): boolean {
    if (Util.Device.bIsTouchDevice) {
      if (gesture===GestureKind.DoubleTap) {
        this.previewIFrameScale = 1;
        this.setPreviewIFrameTransform();
        return true;
      } else if (gesture===GestureKind.PinchOut || gesture===GestureKind.PinchIn) {
        this.previewIFrameScale *= (gesture===GestureKind.PinchOut ? 1.10101 : 0.9);
        if (this.previewIFrameScale<1.0) {
          this.previewIFrameScale = 1.0;
        } else if (this.previewIFrameScale>10) {
          this.previewIFrameScale = 10;
        }
        this.setPreviewIFrameTransform();
        return true;
      }
    }
    return false;
  }

  private showIframeCover(): boolean {
    return !this.bravaEnabled && Util.Device.bIsTouchDevice && Util.Device.isMobile() && !Util.Device.bIsOfficeAddin && !this.videoUrl;
  }

  private setPreviewIFrameTransform(): void {
    const scale: number = this.previewIFrameScale;
    let xTrans = 0;
    let yTrans = 0;
    if (this.previewIFrame && this.previewIFrame.nativeElement && this.contentPanel && this.contentPanel.nativeElement) {
      const iFrameEl = this.previewIFrame.nativeElement;
      const contentEl = this.contentPanel.nativeElement;
      xTrans = iFrameEl.offsetWidth/2 * (scale-1);
      yTrans = iFrameEl.offsetHeight/2 * (scale-1);
      setTimeout(() => {
        contentEl.scrollLeft = contentEl.offsetWidth/2 * (scale-1);
        contentEl.scrollTop = contentEl.offsetHeight/2 * (scale-1);
      }, 1);
    }
    const value: string = 'translate('+xTrans+'px,'+yTrans+'px) scale('+scale+')';
    this.previewIFrameTransform = this.sanitizer.bypassSecurityTrustStyle(value);
  }

  private loadPreviewOrViewerOrVideo(versionId?: string): void {
    if (this.tabId === 'viewer' && this.currentFile['STATUS'] !== '16') {
      if (!!this.currentFile['FILE_EXTENSION'] && Util.Transforms.isFileVideo(this.currentFile['FILE_EXTENSION']) && !!this.videoUrl) {
        return;
      }
      this.videoUrl = '';
      Util.RestAPI.getFileToken(this.desc).then(tokenData => {
        if (tokenData && tokenData.url && tokenData.name) {
          this.currentFile['FILE_EXTENSION'] = tokenData.FILE_EXTENSION;
          if (Util.Transforms.isFileVideo(tokenData.FILE_EXTENSION)) {
            let fileUrl: string = tokenData.url;
            if (fileUrl.indexOf('?') !== -1) {
              fileUrl += '&share';
            } else {
              fileUrl += '?share';
            }
            fileUrl += '&videosrc';
            this.videoUrl = fileUrl;
            this.videoMime = Util.Transforms.getVideoMimeType(tokenData.FILE_EXTENSION);
            this.cdr.markForCheck();
          } else {
            this.loadPreviewOrViewer(versionId);
          }
        } else {
          this.loadPreviewOrViewer(versionId);
        }
      });
    } else {
      this.loadPreviewOrViewer(versionId);
    }
  }

  private loadPreviewOrViewer(versionId ?: string): void {
    let attachName: string = this.tabId==='preview' ? 'pr1' : 'html';
    if ((this.tabId==='viewer' || this.versionCompare) && this.bravaEnabled) {
      if (this.currentFile['STATUS'] === '16') {
        this.displayViewerError(this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_BEING_ARCHIVED', [this.currentFile.DOCNUM, this.currentFile.DOCNAME]));
      } else {
        this.loadBravaViewer();
      }
    } else {
      let gotBlob = false;
      const iFrameEl = this.previewIFrame?.nativeElement;
      const iFrameDoc = iFrameEl?.contentDocument || iFrameEl?.contentWindow?.document;
      const viewable = this.currentFile['preview'] && !!this.currentFile['preview']['inline'];
      let versionExtension = '';
      let versionAppID = '';
      if (this.tabId === 'viewer') {
        versionId =  versionId || this.versions[0]['VERSION_ID'];
        versionExtension = this.versions.find(version => versionId === version['VERSION_ID'])['FILE_EXTENSION']; 
        versionAppID = Util.RestAPI.getAppIDForFile(versionExtension, this.desc.lib);
      }
      setTimeout(() => {
        this.dispatchKeyEventsFromIframes(iFrameEl);
      }, 1000);
      if (!!iFrameDoc && !!iFrameDoc.body) {
        iFrameDoc.body.innerHTML = '';
        iFrameDoc.body.style = '';
      }
      this.previewIFrameLoading = true;
      if (this.tabId==='viewer' && (viewable || (this.currentFile['STATUS'] !== '16' && (this.currentFile.APP_ID==='MSPHOTOED' || versionAppID === 'ACROBAT' || this.currentFile.APP_ID==='DOCSIMAGE')))) {
        const cantView = () => {
          iFrameDoc.body.innerHTML = this.tabId==='preview' ? this.localizer.getTranslation('METADATA.ERRORS.NO_PREVIEW') : this.localizer.getTranslation('METADATA.ERRORS.NO_VIEWER');
          this.previewIFrameLoading = false;
          this.cdr.markForCheck();
        };
        iFrameEl.onerror = cantView;
        iFrameEl.onload = () => {
          if (gotBlob) {
            this.previewIFrameLoading = false;
            this.cdr.markForCheck();
          }
        };
        iFrameEl.src = '';
        if (Util.Device.bIsElectron && !viewable && this.currentFile.APP_ID==='ACROBAT') {
          setTimeout(() => {
          cantView();
          }, 1);
        } else {
          Util.RestAPI.getBlobUrlForFile(this.currentFile,`versions/${versionId ? versionId : 'C'}`).then(url => {
            gotBlob = true;
            iFrameEl.src = url;
          });
        }
      } else {
        if (this.currentFile['STATUS'] === '16') {
          this.displayViewerError(this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_BEING_ARCHIVED', [this.currentFile.DOCNUM, this.currentFile.DOCNAME]));
        } else {
          const queryArgs = !!versionId ? `versionid=${versionId}` : null
          Util.RestAPI.get(this.currentFile, 'attachments/' + attachName, queryArgs, { responseType: 'text' }).subscribe((text: string) => {
            iFrameDoc.body.innerHTML = Util.Transforms.pr1AttachmentToHTML(text, iFrameDoc.body.offsetWidth, this.tabId === 'preview');
            this.previewIFrameLoading = false;
            this.cdr.markForCheck();
          }, error => {
            if (!!error.error && typeof error.error === 'string') {
              error = JSON.parse(error.error);
            }
            if (!!error && !!error.ERROR && !!error.ERROR.rapi_code && error.ERROR.rapi_code === '0X8004013A') {
              this.showRestrictedWarning();
            } else {
              this.previewIFrameLoading = false;
              iFrameDoc.body.innerHTML = this.tabId === 'preview' ? this.localizer.getTranslation('METADATA.ERRORS.NO_PREVIEW') : this.localizer.getTranslation('METADATA.ERRORS.NO_VIEWER');
              this.cdr.markForCheck();
            }
          });
        }
      }
    }
  }

  private loadBravaViewer(): void {
    if (!this.bLoadingBrava) {
      this.bLoadingBrava = true;
      this.previewIFrameLoading = false;
      this.previewIFrame = null;
      let markupIsDirty: boolean;
      let searchText: string;
      let dst = '';
      let userId = '';
      let dmAuth = '';

      // Get search text if it is CONTENT or CONTENT AND PROFILE Search
      const currentSet: any = Util.RestAPI.getCurSet();
      if (currentSet && currentSet.search && currentSet.search.criteria && ['0', '2'].indexOf(currentSet.search.criteria['SEARCH_IN']) > -1 && currentSet.search.criteria['FULLTEXT_EDIT']) {
        searchText = 'search=' + currentSet.search.criteria['FULLTEXT_EDIT'];
      }
      let signedConfigUrl: string = Util.RestAPI.getServerURLOrigin() + Util.RestAPI.makeURL(this.currentFile, 'bravaHTML', searchText);
      if (!!this.compareUrl && this.tabId === 'versions') {
        signedConfigUrl = signedConfigUrl + '&' + this.compareUrl;
      }
      // Read DST and send it to the Brava Config Service
      const loginReply: any = Util.RestAPI.getLoginReply();
      if (loginReply && loginReply['HEADERS']) {
        dst = loginReply['HEADERS']['X-DM-DST'];
        userId = loginReply['HEADERS']['X-DM-USERID'];
        dmAuth = loginReply['HEADERS']['X-DM-AUTH'];
      }
      const options = {
        signedConfigUrl,
        isCrossOrigin: true,
        requestHeaders: { 'X-DM-DST': dst },
        xmlPostType: 'text/xml'
      };
      if (!!userId) {
        options.requestHeaders['X-DM-USERID'] = userId;
      }
      if (!!dmAuth) {
        options.requestHeaders['X-DM-AUTH'] = dmAuth;
      }
      if (!!this.compareUrl && this.tabId === 'versions' && this.compareUrl.indexOf('cm=cr') === -1) {
        options['clientType'] = 'compare';
      }
      // Check for Brava API Session and check for Brava client, create a new client otherwise.
      if (bravaSession && bravaSession.clientId && !!document.getElementById(bravaSession.clientId)) {
        // first, close any markup files opened for review
        this.bLoadingBrava = false;
        bravaSession.closeAllMarkupFilesForReview().then(() => {
          const closeMarkupFile = (dirty?: boolean) => {
            markupIsDirty = dirty;
            bravaSession.closeMarkupFile({ forceClose: true }).then(() => {
              bravaSession.loadConfiguration(options);
            });
          };
          if (markupIsDirty) {
            const title: string = this.localizer.getTranslation('BRAVA.SAVE_MARKUP.TITLE');
            const body: string = this.localizer.getTranslation('BRAVA.SAVE_MARKUP.MESSAGE');
            Util.Notify.confirm(title, body, null, null, true, true, true).then(response => {
              if (response && response.confirm) {
                bravaSession.saveMarkupFile().then((value: any) => {
                  if (value) {
                    closeMarkupFile();
                  }
                });
              } else {
                closeMarkupFile();
              }
            });
          } else if (markupIsDirty !== undefined) {
            closeMarkupFile(markupIsDirty);
          } else {
            bravaSession.loadConfiguration(options);
          }
        });
      } else {
        options['path'] = 'assets/bravaviewer';
        options['container'] = 'viewerContainer';
        options['nodeType'] = 'iframe';
        brava.htmlClients.create('viewer', options).then((response: any) => {
          bravaSession = response;
          this.bLoadingBrava = false;
          bravaSession.on('MarkupEditClosed', () => {
            markupIsDirty = undefined;
          });
          bravaSession.on('StampEditClosed', () => {
            markupIsDirty = undefined;
          });
          bravaSession.on('MarkupEditOpened', () => {
            markupIsDirty = false;
          });
          bravaSession.on('StampEditOpened', () => {
            markupIsDirty = false;
          });
          bravaSession.on('MarkupSaved', () => {
            markupIsDirty = false;
          });
          bravaSession.on('StampSaved', () => {
            markupIsDirty = false;
          });
          bravaSession.on('MarkupDirty', () => {
            markupIsDirty = true;
          });
          bravaSession.on('PageChanged', () => {
            setTimeout(() => {
              const iframesInBrava = document.getElementById('viewerContainer').getElementsByTagName('IFRAME') as HTMLCollectionOf<HTMLIFrameElement>;
              if (iframesInBrava.length > 0) {
                this.dispatchKeyEventsFromIframes(iframesInBrava[0]);
              }
            }, 1000);
          });
          bravaSession.on('LoadingError', (error: any) => {
            this.bLoadingBrava = false;
            Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), this.localizer.getTranslation('BRAVA.ERRORS.LOADING'));
            brava.htmlClients.destroy(bravaSession.clientId);
            if (this.tabId === 'versions' && this.versionCompare) {
              this.tabSelected('versions');
              this.versionCompare = false;
              this.compareUrl = null;
            }
          });
        }, (error: any) => {
          this.bLoadingBrava = false;
          Util.Notify.error(this.localizer.getTranslation('GENERIC_ERRORS.ERROR'), this.localizer.getTranslation('BRAVA.ERRORS.CLIENT_CREATION', [error]));
        });
      }
    }
  }

  private dispatchKeyEventsFromIframes(iframesEl: HTMLIFrameElement): void {
    const iFrameDoc = iframesEl?.contentDocument || iframesEl?.contentWindow?.document;
    const iFrameBody = iFrameDoc?.body || iFrameDoc?.documentElement;
    const innerIframesInBrava = iFrameDoc.getElementsByTagName('IFRAME') as HTMLCollectionOf<HTMLIFrameElement>;
    Array.from(innerIframesInBrava).forEach(innerIframe => {
      this.dispatchKeyEventsFromIframes(innerIframe);
    });
    iFrameBody.removeEventListener('keypress', this.keypressListener);
    iFrameBody.addEventListener('keypress', this.keypressListener);
  }

  private keypressListener(evt: KeyboardEvent) {
    setTimeout(() => {
      window.top.document.dispatchEvent(evt);
    }, 0);
  }

  private displayViewerError(message: string): void {
    const iFrameEl = this.previewIFrame.nativeElement;
    const iFrameDoc = iFrameEl.contentDocument || iFrameEl.contentWindow.document;
    this.previewIFrameLoading = false;
    iFrameDoc.body.innerHTML = message || '';
    this.cdr.markForCheck();
  }

  private reloadForTabOrFile(): void {
    this.jsonResponseData = '';
    this.selectedListItems = [];

    this.cdr.markForCheck();
    if (!this.checkForValidTab()) {
      this.menuDiv.hideMenuIfEmpty();
      return;
    }
    if (this.tabId==='preview') {
      this.previewIFrameLoading = true;
    } else if (this.tabId==='security') { // We need to get the  updated security info, currentFile security could be outdate if security change is made from profile
      this.securedFlexKeyValueList = !!this.formWrapper ? this.formWrapper.dynamicForm.securedLookupKeyList : this.securedFlexKeyValueList;
      this.currentFile['edx_selected_security_choice'] = '';
      const securityValue: string = this.currentFile['SEC_REASON_LINK'] || this.currentFile['SECURITY'];
      this.permissionChanged(securityValue);
      if (this.security && this.security.securityList) {
        // ListSecurityComponent is always undefined when selecting a security tab. However, selecting a different
        // document from the left pane WHILE on security tab leaves ListSecurityComponent of the previous document
        // and populates the security tab of the selected document with incorrect data (list, etc), so delete the list.
        this.security.securityList = [];
      }
    }
    setTimeout(() => {
      if (this.tabId==='preview' || this.tabId==='viewer' || this.versionCompare) {
        this.versions = [];
        if (this.tabId === 'viewer') {
          this.loadVersionsForViewer(null);
        } else {
          this.loadPreviewOrViewerOrVideo();
        }        
      }
      if (this.actionBar) {
        this.actionBar.updateActions();
      }
      if (this.tabSelector) {
        this.tabSelector.updateTabs();
      }
      this.updateListFooter();
    }, 10);
  }

  private titleForTab(): string {
    const overrideProfile: boolean = this.ui<2;
    let title: string;
    if (overrideProfile && this.tabId==='profile') {
      title = 'FOLDER_ACTIONS.PROFILE';
    } else {
      const tab: TabItem = this.tabList.find(t => t.id===this.tabId);
      title = tab.title;
    }
    return this.localizer.getTranslation(title);
  }

  // TablistReceiver interface
  public tabSelected(id: string): void {
    const oldTabId: string = this.tabId;
    // display selected tab content
    this.tabId = id;
    this.compareUrl = null;
    this.versionCompare = false;
    this.setActionID();
    this.versions = [];
    if (this.tabId !== 'history') {
      this.showFilter = false;
      this.filtered = false;
      this.filterKeys = null;
    }
    if (oldTabId !== this.tabId) {
      this.reloadForTabOrFile();
    }
    if (oldTabId === 'security') {
      // IF we just changed from security tab get updated security info
      this.checkSecurityStatus();
    }
    if (this.ui>=2) {
      const app: AppComponent = Util.RestAPI.getAppComponent();
      app.setHeaderTitle(this.titleForTab());
    }
    if (this.tabId === 'viewer') {
      this.loadVersionsForViewer(null);
    }
  }

  public tabEnabled(id: string): boolean {
    let enabled = false;
    const loginReply: any = Util.RestAPI.getLoginReply();
    const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
    const isGuestUser: boolean = Util.RestAPI.isGuestUser();
    if (!this.isEffectiveRightsLoaded) {
      this.isEffectiveRightsLoaded = true;
      const updateRights = (formData: any) => {
        const effectiveRights = Util.RestAPI.calculateRights(this.currentFile);
        this.currentFileRights = new SecurityControl(effectiveRights);
        this.tabSelector?.updateTabs();
      };
      const formName: string = this.currentFile['ITEM_TYPE'] === 'W' ? 'WORKSPACE_PROF' : Util.RestAPI.getDefaultProfileForm()?.id || '';
      this.formService.getFormData(this.currentFile, 'profile', formName).then(formData => {
        const isSecurityInherited =
          (!!formData['INH_LUP_SEC_FROM'] &&
          formData['INH_LUP_SEC_FROM'] !== '0') ||
          (!!formData[':INH_LUP_SEC_FROM'] &&
          formData[':INH_LUP_SEC_FROM'] !== '0');
        if (isSecurityInherited && this.headerWrapper && this.ui === 0) {
          const primaryKey = formData['INH_LUP_SEC_FROM'] || formData[':INH_LUP_SEC_FROM'];
          this.saveSecuredFlexLookupInfo(formData['FORMNAME'], primaryKey);
        }
        Object.keys(formData).forEach(key => {
          if (!(key in this.currentFile) || this.propertiesNeedLatestData.indexOf(key) !== -1) {
            this.currentFile[key] = formData[key];
          }
        });
        Util.RestAPI.calculateReadonlyStatus(this.currentFile);
        updateRights(this.currentFile);
      });
    }
    if (!!this.currentFile) {
      const isDoc = this.currentFile.type === 'documents';
      switch (id) {
        case 'profile':
          enabled = this.currentFile.type !== 'flexfolders' && this.currentFile.type !== 'searches';
          break;
        case 'history':
          enabled = this.currentFile.type !== 'flexfolders' && this.currentFile.type !== 'searches' && !isGuestUser;
          break;
        case 'whereused':
          enabled = this.currentFile.type !== 'flexfolders' && this.currentFile.type !== 'searches' && this.currentFile.type !== 'workspaces' && this.currentFile.type !== 'fileplans' && this.currentFile.type !== 'boxes' && !isGuestUser;
          break;
        case 'security':
          enabled = (Util.RestAPI.calculateRights(this.currentFile) >= AccessLevel.ACCESS_LEVEL_MANAGE) && !(this.currentFile.type === 'fileplans' || this.currentFile.type === 'boxes');
          break;
        case 'viewer':
        case 'preview':
          enabled = isDoc && (Util.RestAPI.canShowPreview() || id === 'viewer') && !(this.currentFile['ITEM_TYPE'] && this.currentFile['ITEM_TYPE'] === 'M') && this.currentFileRights.canViewDocument && (this.currentFile && this.currentFile['STATUS'] !== '18');
          break;
        case 'attachments':
          enabled = (loginReply['SYSTEM_DEFAULTS']?.['SHOW_ATTACHMENTSTAB'] || 'Y').toUpperCase() === 'Y';
          enabled = enabled && isDoc && this.currentFileRights.canViewDocument && (this.currentFile && this.currentFile['STATUS'] !== '18') && !(this.currentFile['ITEM_TYPE'] && this.currentFile['ITEM_TYPE'] === 'M') && !isGuestUser;
          break;
        case 'versions':
          enabled = isDoc && this.currentFileRights.canViewDocument && (this.currentFile && this.currentFile['STATUS'] !== '18') && !(this.currentFile['ITEM_TYPE'] && this.currentFile['ITEM_TYPE'] === 'M') && !isGuestUser;
          break;
        case 'related':
          enabled = Util.RestAPI.canUserShowRelations() && ((isDoc && this.currentFileRights.canViewDocument) || (this.currentFile.type === 'folders'));
          break;
      }
    }
    if (enabled && extAppInfo) {
      enabled = this.extAppAccessEnabled(id, extAppInfo);
    }
    return enabled;
  }

  public focusOnContent() {
    const element = (!!this.actionBar ? this.actionBar.container.nativeElement : this.contentPanel.nativeElement) as HTMLElement;
    if (!!element) {
      element.focus();
    }
  }

  public focusOnSelectedTab() {
    const element = document.getElementsByClassName('tabitem selected')[0] as HTMLElement;
    if (!!element) {
      element.focus();
    }
  }

  // FilelistReceiver interface
  public fileSelected(index: number): void {
    // load a file from the list into the content panel
    if (index >= 0 && index < this.fileList.length) {
      this.selectIndex = index;
      this.currentFile = this.fileList[index];
      this.videoUrl = '';
      this.desc.id = this.currentFile.id;
      this.desc.lib = this.currentFile.lib;
      this.desc.type = this.currentFile.type ? this.currentFile.type : 'documents';
      this.isEffectiveRightsLoaded = false;
      Util.RestAPI.setCurItem(this.currentFile);
      this.setHeaderIcon();
      if (this.listTable) {
        this.listTable.desc = this.currentFile;
      }
      this.calculateSelectionRights();
      this.reloadForTabOrFile();
    }
  }

  // **** ListTableParent implementation
  public listUpdated(table: ListTableComponent): void {
    if (this.listTable) {
       this.listTable.clearSelection();
    }
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
    ++this.rerender;
    setTimeout(() => {
      if (this.filters && this.showFilter) {
        if (this.filters.getAndSetFormData() && !this.filtered) {
          this.filtered = true;
          this.filterKeys = this.filters.getFilterList();
          this.cdr.markForCheck();
        }
      } else if (this.filtered) {
        this.filtered = false;
        this.filterKeys = null;
        this.cdr.markForCheck();
      }
      ++this.rerender;
    }, 300);
  }

  public selectionsUpdated(table: ListTableComponent): void {
    if (this.listTable) {
      this.selectedListItems = this.listTable.getSelections();
    }
    if (this.actionBar) {
      this.actionBar.updateActions();
    }
    if (this.tabSelector) {
      this.tabSelector.updateTabs();
    }
    this.updateListFooter();
  }

  private updateListFooter(): void {
    this.footerItems = [];
    this.footerHeading = null;
    if (this.selectedListItems && this.selectedListItems.length === 1) {
      const listItem: ListItem = this.selectedListItems[0];
      if (this.tabId === 'versions' || this.tabId === 'attachments') {
        this.footerHeading = this.tabId === 'attachments' ? this.localizer.getTranslation('COLUMN_HEADINGS.ATTACHMENTS.ATTACHMENT_NO', [ listItem['VERSION_LABEL'] ]) : this.localizer.getTranslation('METADATA.FOOTER.VERSIONS.HEADER', [ listItem['VERSION_LABEL'] ]);
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.VERSIONS.COMMENT'), value: listItem['COMMENTS'] });
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.VERSIONS.VERS_ID'), value: listItem['VERSION_ID'] });
      } else if (this.tabId === 'security') {
        this.enumerateRights(listItem['rights']);
        this.footerHeading = this.localizer.getTranslation('METADATA.FOOTER.SECURITY.HEADER', [ listItem['USER_ID'] ]);
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.ALLOW'), value: this.allowedList });
        this.footerItems.push({ label: this.localizer.getTranslation('METADATA.FOOTER.SECURITY.DENY'), value: this.deniedList });
      }
    }
  }

  private enumerateRights(rights: number): void {
    this.allowedList = this.rightsLabels.filter(r => rights & r.value).map(r => r.label).join(', ');
    this.deniedList = this.rightsLabels.filter(r => (rights >> 16) & r.value).map(r => r.label).join(', ');
  }

  public onPermissionChanged(newVal: string): void {
    this.permissionChanged(newVal);
  }

  private permissionChanged(newVal: string): void {
    switch (newVal) {
      case '0': {
        this.isRestricted = false;
        this.isReadOnly = Util.RestAPI.itemReadonlyOrCheckedout(this.currentFile);
        this.securityListVisible = false;
        break;
      }
      case '1':
      case '2':
      case '3': {
        this.isRestricted = true;
        /* In case file has inherited security, inheritance checkbox is checked
            so the file needs to be read only and once user unchecks the inheritance
            checkbox, readonly will be set to false */
        const isInherited = (!!this.currentFile['INH_LUP_SEC_FROM'] &&
                              this.currentFile['INH_LUP_SEC_FROM'] !== '-1' &&
                              this.currentFile['INH_LUP_SEC_FROM'] !== '0') ||
                            (!!this.currentFile[':INH_LUP_SEC_FROM'] &&
                              this.currentFile[':INH_LUP_SEC_FROM'] !== '-1' &&
                              this.currentFile[':INH_LUP_SEC_FROM'] !== '0');
        if (isInherited) {
          this.isReadOnly = true;
        } else {
          this.isReadOnly = false;
        }
        this.securityListVisible = true;
        break;
      }
    }
  }

  public settingUnrestrictedSecurity(): void {
    this.settingSecurity = true;
  }

  private isCurrentFileReadOnlyOrCheckedOut(): boolean {
    return (['3', '16', '19'].indexOf(this.currentFile['STATUS']) === -1 && this.currentFile['READONLY'] !== 'Y');
  }

  private isItemEditable(item: ListItem): boolean {
    const status = item?.['STATUS'];
    return !['19','20'].includes(status) && item?.['READONLY'] !== 'Y';
  }

  public onSecurityDirty(dirty: boolean, fromHeader?: boolean): void {
    if (this.headerWrapper && !fromHeader) {
      this.headerWrapper.securityDirty(dirty);
    }
    this.disabledNav = dirty;
    this.disableShortcutKeysUsage(dirty);
    this.appComponent.blockForDirty(this, dirty, 'security_save');
    setTimeout(() => {
      if (this.security) {
        this.security.setDirty(dirty);
      }
    },1);
  }

  public onSecurityReadOnly(readOnly: boolean): void {
    if (this.headerWrapper) {
      this.headerWrapper.securityReadOnly(readOnly);
    }
  }

  // calculate effective rights from container and/or selected items
  public calculateSelectionRights(): void {
    const selectionRights: SecurityControl = new SecurityControl(this.containerRights);
    if (this.currentFile['%SECURITY']) {
      const itemAccess = +this.currentFile['%SECURITY'];
      if (itemAccess > 0) {
        selectionRights.access &= itemAccess;
      }
    }
    this.currentFileRights = selectionRights;
  }

  private actionHandler(cmd: string): void {
    if (this.commandEnabled(cmd)) {
      this.doCommand(cmd);
    }
  }

  // **** CommandHandler implementation
  public doCommand(cmd: string): boolean {
    let handled = false;
    let data = null;
    let newcmd: string = cmd;

    this.lastCmd = cmd;
    if (cmd && (cmd.startsWith('versions_') || cmd.startsWith('related_') || cmd.startsWith('attachments_'))) {
      if (cmd.startsWith('versions_')) {
        newcmd = cmd.substr(9);
      }
      Util.RestAPI.setCurList(this.selectedListItems, this.listTable.getSet());
    }

    if (cmd && cmd.startsWith('_custom_')) {
      const doFunc = Util.RestAPI.getCustomCommandFunction(cmd, 'do');
      if (!!doFunc) {
        doFunc(this.desc, [this.currentFile]);
      }
      return true;
    } else if (cmd && cmd.startsWith('tab_')) {
      this.tabSelected(cmd.substr(4));
      handled = true;
      cmd = null;
    } else if (!!cmd && cmd.startsWith('list_')) {
      if (!!this.listTable) {
        this.listTable.doCommand(cmd);
      }
      return true;
    }

    switch (cmd) {
      // **** document dropdown menu
      case 'doc_editprofile':
      case 'doc_view':
        // set current list item then defer to global command handler
        Util.RestAPI.setCurItem(this.currentFile);
        break;
      case 'doc_open':
        if (this.tabId==='attachments') {
          newcmd = 'doc_view';
        }
        // set current list item then defer to global command handler
        Util.RestAPI.setCurItem(this.currentFile);
        break;
      case 'doc_email':
      case 'doc_addto':
      case 'doc_checkout':
      case 'doc_print':
      case 'doc_delete':
      case 'doc_checkin':
      case 'doc_copy':
      case 'doc_link':
        break;
      case 'doc_share':
        if (this.tabEnabled('security')) {
          this.tabSelected('security');
          this.autoAddUsers = true;
          handled = true;
        }
        break;
      // **** versions tab actions
      case 'versions_export':
      case 'versions_delete':
      case 'attachments_delete':
      case 'versions_checkout':
        break;
      case 'versions_new':
        newcmd = 'newversion';
        // fall thru intentional
      case 'newversion':
        if (this.selectedListItems && (this.selectedListItems.length > 0)) { // Version from an specific version
          Util.RestAPI.setCurList([this.selectedListItems[0]], this.listTable.getSet());
        } else {
          Util.RestAPI.setCurList([this.listTable.getLatestVerionItem()], this.listTable.getSet()); // version from latest version
        }
        break;
      case 'related_open':
        for (const listItem of this.selectedListItems) {
          this.listTable.openListItem(listItem, null, false, true);
        }
        handled = true;
        break;
      case 'versions_open':
        if (this.canOpenDocument(cmd)) {
          for (const listItem of this.selectedListItems) {
            const versionFile = JSON.parse(JSON.stringify(this.currentFile));
            if (listItem['STATUS'] === '19') {
              versionFile['STATUS'] = listItem['STATUS'];
              versionFile['READONLY'] = 'Y';
            }
            if (listItem['STATUS'] === '20') {
              versionFile['STATUS'] = listItem['STATUS'];
              versionFile['isPublished'] = true;
            }
            this.listTable.openListItem(versionFile, listItem['VERSION_ID'], false, true);
          }
        }
        handled = true;
        break;
      case 'attachments_open':
        for (const listItem of this.selectedListItems) {
          this.listTable.openListItem(this.currentFile, listItem['VERSION_ID'], true, true);
        }
        break;
      case 'versions_view':
        for (const listItem of this.selectedListItems) {
          Util.RestAPI.viewFile(this.currentFile, listItem['VERSION_ID'], null, null, true);
        }
        handled = true;
        break;
      case 'versions_publish':
        data = { '%VERSION_DIRECTIVE': '%PUBLISH_VERSION', '%VERSION_ID':this.selectedListItems[0]['VERSION_ID'] };
        break;
      case 'versions_unpublish':
        data = { '%VERSION_DIRECTIVE': '%UNPUBLISH_VERSION', '%VERSION_ID':this.selectedListItems[0]['VERSION_ID'] };
        break;
      case 'versions_readonly':
        data = { '%VERSION_DIRECTIVE': '%MAKE_READ_ONLY', '%VERSION_ID':this.selectedListItems[0]['VERSION_ID'] };
        break;
      case 'versions_removereadonly':
        data = { '%VERSION_DIRECTIVE': '%REMOVE_READ_ONLY', '%VERSION_ID':this.selectedListItems[0]['VERSION_ID'] };
        break;
      case 'versions_insertintodocument':
      case 'versions_insertlinkintodocument':
        data = { '%VERSION_ID':this.selectedListItems[0]['VERSION_ID'] };
        break;
      case 'versions_editprofile':
        newcmd = cmd;
        break;
      case 'versions_email':
        Util.RestAPI.setCurItem(this.currentFile);
        Util.RestAPI.setCurList(this.listTable.getList(), this.listTable.getSet());
        data = { isVersionEmail: true, attachments: this.tabId === 'attachments' };
        break;
      // **** Attachments tab actions
      case 'attachments_new':
        Util.RestAPI.pickFiles(null, (files: File[], paths: string[], success: boolean) => {
          const partialUrl = 'documents/'+this.desc.id+'/attachments?library='+this.desc.lib;
          if (success && !!files && files.length) {
            Util.RestAPI.uploadFilesWithBrowser(partialUrl, {COMMENT:''}, files);
          } else if (success && !!paths && paths.length) {
            if (Util.Device.bIsElectron || Util.Device.bIsCordova) {
              Util.RestAPI.uploadFilesWithAppWorks(paths, {COMMENT:''}, false, false, undefined, undefined, undefined, undefined, undefined, partialUrl);
            }
          }
        });
        handled = true;
        break;

      // **** where used tab actions
      case 'whereused_remove':
        newcmd = 'removeitem';
        data = { desc: this.currentFile };
        break;
      case 'whereused_add':
        break;
      case 'whereused_open':
        for (const listItem of this.selectedListItems) {
          this.listTable.openListItem(listItem);
        }
        handled = true;
        break;

      // **** Related tab actions
      case 'related_remove':
      case 'related_add':
      case 'related_info':
      case 'related_open':
        Util.RestAPI.setCurItem(this.currentFile);
        data = { desc: this.currentFile };
        break;

      // **** Security tab actions
      case 'security_add':
      case 'security_edit':
      case 'security_remove':
        handled = this.security ? this.security.doCommand(cmd) : false;
        break;
      case 'security_save':
        handled = this.security ? this.security.doCommand(cmd) : false;
        if (!handled && this.headerWrapper) {
          this.headerWrapper.saveSecurity();
          handled = true;
        }
        break;

      // *** History tab actions
      case 'showfilter':
        if (this.tabId==='history') {
          if (this.showFilter && !!this.filters && !this.filters.isFilterOpen()) {
            this.filters.open();
            this.filters.getAndSetFormData();
            this.cdr.markForCheck();
          } else {
            this.showFilter = !this.showFilter;
            this.cdr.markForCheck();
            if (this.showFilter) {
              const waitForFilters = () => {
                if (!!this.filters) {
                  this.filters.open();
                  this.cdr.markForCheck();
                } else {
                  setTimeout(waitForFilters, 100);
                }
              };
              waitForFilters();
            }
          }
          handled = true;
        }
        break;
      case 'clearfilter':
        this.clearFilters();
        if (this.actionBar) {
          setTimeout(() => {
            this.actionBar.updateActions();
          }, 300);
        }
        break;
      // *** Nav acticons
      case 'back':
        this.tabSelected('profile');
        break;
      case 'cancel':
        if (this.tabId==='security') {
          this.refresh('security_reset');
          handled = true;
        } else if (this.disabledNav) {
          this.appComponent.blockForDirty(this, false);
          this.disabledNav = false;
          this.disableShortcutKeysUsage(false);
          handled = true;
        }
        break;
      case 'versions_compare':
      case 'versions_comparereport':
        this.versionCompare = true;
        this.isGraphicalCompare = cmd === 'versions_compare';
        this.compareUrl = this.getVersionsCompareUrl(this.listTable.getSelections())+(cmd==='versions_comparereport'?'&cm=cr':'');
        this.reloadForTabOrFile();
        handled = true;
    }
    if (!handled) {
      handled = this.appComponent.doCommand(newcmd, data);
    }
    if (handled) {
      if (this.listTable) {
        this.listTable.clearSelection();
      }
      this.selectedListItems = []; // clear the selection
    }
    return handled;
  }

  public getVersionsCompareUrl(versionList: ListItem[]): string {
    const verNumArray = versionList.map(ele => parseInt(ele['VERSION_ID'])).sort();
    return 'ver='+verNumArray[0]+'&cver='+verNumArray[1];
  }

  public closeCompare(): void {
    this.versionCompare = false;
    this.tabSelected('versions');
  }

  public commandEnabled(cmd: string): boolean {
    let enabled = false;
    const loginReply: any = Util.RestAPI.getLoginReply();
    const effectiveRights: any = Util.RestAPI.getLoginReply() && Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS ? Util.RestAPI.getLoginReply().EFFECTIVE_RIGHTS : {};
    const lastListDesc: any = Util.RestAPI.getCurDesc();
    const bIsCheckedOutFolder: boolean = !!lastListDesc && (lastListDesc.type==='searches' || lastListDesc.id==='checkedout' || lastListDesc.id.startsWith('evaluation'));
    const list: ListItem[] = this.listTable ? this.listTable.getList() : null;
    const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
    const isExternalContainer: boolean = Util.isExternalLib(this.desc.lib) ? true : false;

    if (cmd && cmd.startsWith('_custom_')) {
      const enableFunc = Util.RestAPI.getCustomCommandFunction(cmd, 'enable');
      if (!!enableFunc) {
        enabled = enableFunc(this.desc, [this.currentFile], effectiveRights);
      }
      return true;
    } else if (cmd && cmd.startsWith('tab_')) {
      enabled = this.tabEnabled(cmd.substr(4));
    } else if (cmd && cmd.startsWith('list_')) {
      enabled = !!this.listTable && this.listTable.commandEnabled(cmd);
    } else if (!!this.currentFile) {
      switch (cmd) {
        // **** document dropdown menu
        case 'doc_editprofile':
          enabled = this.currentFileRights.canEditProfile && !isExternalContainer && this.currentFile['STATUS'] !== '18' && this.currentFile['READONLY'] !== 'Y' && (!loginReply['RM_ENABLED'] || this.currentFile['RECORD'] !== 'Y') && this.currentFile.type !== 'fileplans' && this.currentFile.type !== 'boxes' && this.currentFile.type !== 'searches';
          if (enabled &&  this.currentFile['STORAGE'] === 'T' && effectiveRights.TEMPLATE_MANAGER==='N') {
            enabled = false;
          }
          break;
        case 'doc_open':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && (Util.Device.bIsElectron || (Util.Device.bIsCordova && effectiveRights.ALLOW_MOBILE_DOWNLOAD==='Y') || (!Util.Device.bIsCordova && !Util.Device.bIsElectron && (Util.RestAPI.hasPFTA() || Util.Device.canDownloadOfficeDoc(this.currentFile)))) && this.currentFileRights.canViewDocument && this.currentFileRights.canRetrieveDocument && this.currentFile['STATUS'] !== '18' && (this.currentFile.type !== 'workspaces') && (this.currentFile.type !== 'folders') && (this.currentFile.type !== 'fileplans') && (this.currentFile.type !== 'boxes') && (this.currentFile.type !== 'searches'); break;
        case 'doc_view':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && (Util.Device.bIsElectron || (Util.Device.bIsCordova && !Util.Device.bIsAndroid && effectiveRights.ALLOW_MOBILE_DOWNLOAD==='Y')) && this.currentFileRights.canViewDocument && this.currentFile['STATUS'] !== '18' && (this.currentFile.type !== 'workspaces') && (this.currentFile.type !== 'folders'); break;
        case 'doc_link':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && !Util.Device.bIsElectron && !Util.Device.bIsCordova && this.currentFileRights.canCopy && (this.currentFile.type === 'documents' || this.currentFile.type === 'folders' || this.currentFile.type === 'workspaces') && this.currentFile['STATUS'] !== '18'; break;
        case 'doc_email':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && this.currentFileRights.canCopy && Util.RestAPI.canUserEmail() && (this.currentFile.type === 'documents' || this.currentFile.type === 'folders' || this.currentFile.type === 'workspaces') && this.currentFile['STATUS'] !== '18'; break;
        case 'doc_share':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && this.currentFile['ITEM_TYPE'] !== 'F' && !(this.currentFile.type === 'documents') && ((this.currentFile.type === 'workspaces' && this.currentFile['rights'] >= AccessLevel.ACCESS_LEVEL_MANAGE) || ((this.currentFile.type === 'searches' && this.currentFile['UNSAVED'] !== 'Y') && Number(this.currentFile['rights']) === AccessRights.ACCESS_EDIT_PROFILE));break;
        case 'doc_addto':
          enabled = this.currentFileRights.canCopy && !(this.currentFile.type === 'workspaces' || this.currentFile.type === 'fileplans' || this.currentFile.type === 'boxes' || (this.currentFile.type === 'searches' && this.currentFile['UNSAVED'] === 'Y')) ; break;
        case 'doc_checkout':
          enabled = effectiveRights.CHECKOUT === 'Y' && this.currentFileRights.canEditContent && (!this.selectedListItems || this.selectedListItems.length===0) && (!Util.Device.bIsOfficeAddinDesktop || Util.RestAPI.hasPFTA()) && (this.currentFile.type !== 'workspaces' && this.currentFile.type !== 'fileplans' && this.currentFile.type !== 'boxes') && this.currentFile['STATUS']==='0' && this.currentFile['READONLY'] !== 'Y';
          if (enabled &&  this.currentFile['STORAGE'] === 'T' && effectiveRights.TEMPLATE_MANAGER==='N') {
            enabled = false;
          }
          break;
        case 'doc_print':
          enabled = false; break;
        case 'doc_delete':
          enabled = this.currentFileRights.canDelete && !(this.currentFile.type === 'fileplans' || this.currentFile.type === 'boxes') && (!this.selectedListItems || this.selectedListItems.length===0) && (['0', '16', '18'].indexOf(this.currentFile['STATUS']) !== -1) && this.currentFile['STATUS'] !== '3' && this.currentFile['READONLY'] !== 'Y' && (effectiveRights.ALLOW_CONTENT_DEL === 'Y' || effectiveRights.ALLOW_DOC_DELETE === 'Y' || (effectiveRights.ALLOW_QUEUE_DEL === 'Y' && this.currentFile['STORAGE']!=='D'));
          if (enabled &&  this.currentFile['STORAGE'] === 'T' && effectiveRights.TEMPLATE_MANAGER==='N') {
            enabled = false;
          }
          break;
        case 'doc_unlock':
          enabled = this.currentFileRights.canEditContent && (bIsCheckedOutFolder || this.currentFile['STATUS']==='3') && !(this.currentFile.type === 'fileplans' || this.currentFile.type === 'boxes') &&  (!!this.currentFile['checkout'] );
          if (enabled && effectiveRights.OTHER_CHECKIN !== 'Y') {
            enabled = (!!this.currentFile['checkout'] && this.currentFile['checkout']['TYPIST_ID'].toUpperCase() === Util.RestAPI.getUserID());
          }
          break;
        case 'doc_checkin':
          //do not enable check-in, if the document is LOCKED(%LOCK)
          enabled = this.currentFileRights.canEditContent && !isExternalContainer && ((bIsCheckedOutFolder && !!this.currentFile['VERSION_LABEL']) || (this.currentFile['STATUS']==='3' && !!this.currentFile['checkout'] && !!this.currentFile['checkout']['VERSION_LABEL']));
          enabled = enabled && (!!this.currentFile['checkout'] && this.currentFile['checkout']['TYPIST_ID'].toUpperCase() === Util.RestAPI.getUserID());
          break;
        case 'doc_copy':
          enabled = effectiveRights.ALLOW_DOC_CREATE === 'Y' && this.currentFile['ITEM_TYPE'] !== 'M' && this.currentFileRights.canCopy && ['16', '18'].indexOf(this.currentFile['STATUS']) === -1 && ((this.currentFile.type === 'documents') || (this.currentFile.type === 'folders')) && (this.currentFile['STATUS'] === '3' ? effectiveRights['COPY_IN_USE'] === 'Y' : true); break;
        case 'doc_copytree':
          enabled =
            this.ui === 0 &&
            Util.Transforms.isCaseInsensitiveEqual(this.desc.lib, Util.RestAPI.getPrimaryLibrary()) &&
            effectiveRights.ALLOW_DOC_CREATE === 'Y' &&
            this.currentFile['ITEM_TYPE'] !== 'M' &&
            this.currentFileRights.canCopy &&
            ['16', '18'].indexOf(this.currentFile['STATUS']) === -1 &&
            this.currentFile.type === 'folders' &&
            (this.currentFile['STATUS'] === '3'
              ? effectiveRights['COPY_IN_USE'] === 'Y'
              : true);
          break;
        case 'doc_subscribe':
          enabled = this.currentFileRights.canViewDocument  && (this.currentFile.type === 'documents' || this.currentFile.type === 'folders') && this.currentFile['STATUS'] !== '18';
          if (enabled && loginReply && loginReply.FLEX_RIGHTS) {
            enabled = Object.keys(loginReply.FLEX_RIGHTS).length>0;
          }
          break;
        case 'addto':
          enabled = this.currentFileRights.canCopy && ((this.currentFile.type === 'documents') || ((this.currentFile.type === 'folders')  && !(this.currentFile.id === '')) || (this.currentFile.type === 'searches' && this.currentFile['UNSAVED'] !== 'Y'  && !isNaN(parseInt(this.currentFile.id)))) ; break;
        case 'rename':
        case 'doc_rename':
        case 'editsearch':
        case 'doc_editsearch':
          enabled = this.currentFileRights.canEditProfile  && this.currentFile.type === 'searches' && this.currentFile['UNSAVED'] !== 'Y' && !isNaN(parseInt(this.currentFile.id)); break;
        case 'doc_export':
        case 'export':
          enabled = this.currentFile['ITEM_TYPE'] !== 'M' && this.currentFileRights.canCopy && (!this.selectedListItems || this.selectedListItems.length===0) && (!(Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) || effectiveRights.ALLOW_MOBILE_DOWNLOAD === 'Y') && (!Util.Device.bIsOfficeAddinDesktop || Util.RestAPI.hasPFTA()) && (this.currentFile.type === 'documents' && ['16', '18'].indexOf(this.currentFile['STATUS']) === -1) ; break;
        case 'doc_exportfolder':
          enabled = this.currentFileRights.canCopy && this.ui === 0
              && (!this.selectedListItems || this.selectedListItems.length === 0)
              && (this.currentFile.type === 'folders' || this.currentFile.type === 'workspaces');
          break;
        case 'doc_removereadonly':
        case 'removereadonly':
          enabled = this.currentFileRights.canEditContent && (effectiveRights.REMOVE_READ_ONLY ==='Y') && (!this.selectedListItems || this.selectedListItems.length===0) &&  this.currentFile['RECORD'] !== 'Y' && (this.currentFile['READONLY'] === 'Y' || this.currentFile['STATUS'] === '19'); break;
        case 'doc_readonly':
        case 'readonly':
          enabled = this.currentFileRights.canEditContent && this.currentFile['ITEM_TYPE'] !== 'M' && (this.currentFile['RECORD'] === undefined || this.currentFile['RECORD'] === 'N') && this.currentFileRights.canEditProfile && (effectiveRights.MAKE_READ_ONLY ==='Y') && (!this.selectedListItems || this.selectedListItems.length===0) && ((this.currentFile.type === 'documents') || this.currentFile.type === 'folders') && this.currentFile['STATUS'] === '0' && this.currentFile['READONLY'] !== 'Y';
          if (enabled &&  this.currentFile['STORAGE'] === 'T' && effectiveRights.TEMPLATE_MANAGER==='N') {
            enabled = false;
          }
          break;
        case 'doc_create_tile':
          enabled = (!this.tileService.getTile(this.currentFile) && this.currentFile['STATUS'] !== '18' && ((this.currentFile.type === 'folders') || (this.currentFile.type === 'workspaces') || (this.currentFile.type === 'fileplans') || (this.currentFile.type === 'searches' && this.currentFile['UNSAVED'] === 'N'))); break;
        case 'attachments_new':
        case 'attachments_newmenu':
            enabled = this.tabId === 'attachments' && this.currentFileRights.canEditContent && (!this.selectedListItems || (this.selectedListItems.length === 0)) && (this.isCurrentFileReadOnlyOrCheckedOut());
          // **** versions tab actions
        case 'versions_new':
          if (this.tabId !== 'versions') {
            break;
          }
          // fall thru intentional
        case 'versions_newmenu':
          // user cannot create new version if the main document is readonly or checkedout documents
          enabled = this.currentFileRights.canEditContent && (!this.selectedListItems || (this.selectedListItems.length === 0)) && (this.isCurrentFileReadOnlyOrCheckedOut());
          if (enabled && !!list) {
            enabled = Util.RestAPI.canAddNewVersion(list, this.currentFile['TYPE_ID']) || Util.RestAPI.canAddNewSubversion(list, null, this.currentFile['TYPE_ID']);
          }
          break;
        case 'newversion':
          // "MAKE NEW VERSION FROM ANY VERSION" permission doesnt apply to latest version.
          const latestVersionItem: ListItem = this.listTable ? this.listTable.getLatestVerionItem() : null;
          const latestVersionId: number = latestVersionItem ? latestVersionItem['VERSION_ID'] : null;
          enabled = this.currentFileRights.canEditContent && (this.selectedListItems && (this.selectedListItems.length === 1 && ((latestVersionId === this.selectedListItems[0]['VERSION_ID']) || (effectiveRights.NEW_VERSIONS === 'Y')))) && (this.isCurrentFileReadOnlyOrCheckedOut());
          if (enabled && !!list) {
            enabled = Util.RestAPI.canAddNewVersion(list, this.currentFile['TYPE_ID']) || Util.RestAPI.canAddNewSubversion(list, (this.selectedListItems && this.selectedListItems.length === 1) ? this.selectedListItems[0]['VERSION'] : null, this.currentFile['TYPE_ID']);
          }
          break;
        case 'versions_open':
        case 'related_open':
        case 'attachments_open':
          enabled = (this.selectedListItems && this.selectedListItems.length === 1) && (this.currentFile.type === 'documents' || this.currentFile.type === 'folders') && !!(Util.Device.bIsElectron || (Util.Device.bIsCordova && this.selectedListItems.length===1 && !(effectiveRights.ALLOW_MOBILE_DOWNLOAD!=='Y')) || (!Util.Device.bIsCordova && !Util.Device.bIsElectron && Util.RestAPI.hasPFTA()));
          if (enabled && cmd === 'versions_open' && !Util.RestAPI.canUserEditPreviousVersions()) {
            const version: any = this.selectedListItems[0] || {};
            if (!!list && list.some(v => (parseInt(v['VERSION']) > parseInt(version.VERSION) || (v['VERSION'] === version.VERSION && v['VERSION_LABEL'] > version.VERSION_LABEL)))) {
              enabled = false;
            }
          }
          break;
        case 'versions_view':
          enabled = !!(Util.Device.bIsCordova && !(effectiveRights.ALLOW_MOBILE_DOWNLOAD!=='Y') && !Util.Device.bIsAndroid && this.selectedListItems && this.selectedListItems.length===1) && (this.currentFile.type === 'documents') ; break;
        case 'versions_separator':
          enabled = false; break;
        case 'versions_checkout':
          enabled = this.currentFileRights.canEditContent && effectiveRights.CHECKOUT === 'Y' && this.selectedListItems && this.selectedListItems.length===1 && this.currentFile && (this.currentFile['STATUS'] === '0');
          if (enabled && !Util.RestAPI.canUserEditPreviousVersions()) {
            const version: any = this.selectedListItems[0] || {};
            if (!!list && list.some(v => (parseInt(v['VERSION']) > parseInt(version.VERSION) || (v['VERSION'] === version.VERSION && v['VERSION_LABEL'] > version.VERSION_LABEL)))) {
              enabled = false;
            }
          }
          break;
        case 'versions_checkin':
          // There is no code wired to this !?
          enabled = false; break;
        case 'versions_email':
          enabled = Util.RestAPI.canUserEmail() && this.currentFileRights.canCopy && this.currentFile['ITEM_TYPE'] !== 'M' && this.currentFile.type === 'documents' && this.currentFile['STATUS'] !== '18' && !!this.selectedListItems && this.selectedListItems.length > 0; break;
        case 'versions_removereadonly':
          //users cannot remove read only if the main document is read only.
          enabled = this.currentFileRights.canEditContent && (effectiveRights.MAKE_VER_WRITABLE ==='Y') && !!this.selectedListItems && this.selectedListItems.length===1 && (this.allSelectedItemsHaveProperty('STATUS', '19') && this.currentFile['STATUS'] !== '19' && this.currentFile['READONLY'] === 'N'); break;
        case 'versions_readonly':
          enabled = this.currentFileRights.canEditContent && (effectiveRights.MAKE_VER_READONLY ==='Y') && !!this.selectedListItems && this.selectedListItems.length===1 && (this.allSelectedItemsHaveProperty('STATUS', '0') && this.currentFile['STATUS'] === '0' && this.currentFile['READONLY'] === 'N'); break;
        case 'versions_delete':
          //Users cannot delete a version if the main document is checked out or there is only one version.
          enabled = this.currentFileRights.canEditContent && Util.RestAPI.canUserDeleteVersions() && !!list && list.length>1 && !!this.selectedListItems && !!this.selectedListItems.length && this.selectedListItems.length!==list.length && this.isCurrentFileReadOnlyOrCheckedOut(); break;
        case 'versions_publish':
          enabled = (effectiveRights.PUBLISH_VERSION ==='Y') && this.currentFileRights.canEditProfile && (this.selectedListItems && (this.selectedListItems.length === 1) && ((this.selectedListItems[0]['STATUS'] !== '19' && this.selectedListItems[0]['STATUS'] !== '20') && (this.isCurrentFileReadOnlyOrCheckedOut())));break;
        case 'versions_unpublish':
          enabled = (effectiveRights.UNPUBLISH_VERSION ==='Y') && (this.selectedListItems && (this.selectedListItems.length === 1) && (this.selectedListItems[0]['STATUS'] === '20')); break;
        case 'versions_export':
          enabled = (!(Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) || effectiveRights.ALLOW_MOBILE_DOWNLOAD === 'Y') && (!Util.Device.bIsOfficeAddinDesktop || Util.RestAPI.hasPFTA()) && !!this.selectedListItems && !!this.selectedListItems.length && this.currentFileRights.canCopy && (this.currentFile.type === 'documents' && this.currentFile['STATUS'] !== '16'); break;
        case 'versions_editprofile':
          enabled = this.currentFileRights.canEditContent && this.selectedListItems && this.selectedListItems.length === 1 &&
              this.isItemEditable(this.currentFile) && this.isItemEditable(this.selectedListItems[0]); break;
        case 'versions_insertintodocument':
        case 'versions_insertlinkintodocument':
          enabled = this.currentFileRights.canCopy  && (!!this.selectedListItems && this.selectedListItems.length === 1) && Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.requirements && (Office.context.requirements.isSetSupported('WordApi', '1.2') || Office.context.requirements.isSetSupported('ExcelApi', '1.2') || (cmd === 'versions_insertintodocument' && Office.context.requirements.isSetSupported('PowerPointApi', '1.1'))) && this.currentFileHasPropertyValueFromArray('STATUS', ['0', '3', '16', '19']);
          if (enabled && cmd === 'versions_insertintodocument') {
            enabled = this.canInsertIntoDocument();
          }
          break;
        case 'versions_compare':
        case 'versions_comparereport':
          enabled = Util.RestAPI.canCompareVersions() && this.bravaEnabled && this.currentFileRights.canViewDocument && !!this.selectedListItems && this.selectedListItems.length === 2;
          break;
        case 'insertintodocument':
          if (!this.currentFileHasPropertyValueFromArray('type', ['documents'])) {
            break;
          }
        case 'insertlinkintodocument':
          enabled = this.currentFileRights.canCopy && Util.Device.bIsOfficeAddin && Office && Office.context && Office.context.requirements && (Office.context.requirements.isSetSupported('WordApi', '1.2') || Office.context.requirements.isSetSupported('ExcelApi', '1.2')) && ['documents', 'folders', 'workspaces'].indexOf(this.currentFile['type']) !== -1 && ['0', '3', '19'].indexOf(this.currentFile['STATUS']) !== -1 && this.currentFile['ITEM_TYPE']!== 'M';
          if (enabled && cmd === 'insertintodocument') {
            enabled = this.canInsertIntoDocument();
          }
          break;
          // **** Attachments tab actions
        case 'attachments_delete':
          enabled = this.currentFileRights.canEditContent && Util.RestAPI.canUserDeleteVersions() && !!this.selectedListItems && !!this.selectedListItems.length; break;

        // **** where used tab actions
        case 'whereused_add':
          enabled = !(this.selectedListItems && this.selectedListItems.length); break;
        case 'whereused_separator':
        case 'whereused_remove':
          enabled = !!(this.selectedListItems && this.selectedListItems.length); break;
        case 'whereused_open':
          enabled = (this.selectedListItems && this.selectedListItems.length===1) && Util.isContainer(this.selectedListItems[0].type); break;

        // **** Related tab actions
        case 'related_info':
          enabled = false; break;
        case 'related_add':
          enabled = effectiveRights['CREATE_RELATION'] === 'Y'; break;
        case 'related_separator':
        case 'related_remove':
          enabled = !!(this.selectedListItems && this.selectedListItems.length) && effectiveRights['REMOVE_RELATION'] === 'Y'; break;

        // **** Security tab actions
        case 'security_add':
          enabled = true; break;
        case 'security_separator':
        case 'security_edit':
        case 'security_remove':
          enabled = !!(this.selectedListItems && this.selectedListItems.length); break;
        case 'security_save':
          enabled = this.security ? this.security.commandEnabled(cmd) : true; break;

        // *** History tab actions
        case 'showfilter':
          enabled = this.tabId==='history'; break;
        case 'clearfilter':
          enabled = !!this.filters; break;

        case 'doc_declarerecord':
          if (Util.RestAPI.restAPIVersion() < 0x00160500) {
            break;
          }
          enabled = this.currentFileRights.canControlAccess && effectiveRights.DECLARE_RECORD === 'Y' && this.currentFile.type === 'documents' && this.currentFile['RECORD'] === 'N' && !(this.currentFile['READONLY'] === 'Y') && this.currentFile['STATUS'] === '0';
          enabled = enabled && ((this.existsInRMsetting(loginReply,'RequireRecordInFile','Y') && this.isIn_RM()) || (this.existsInRMsetting(loginReply,'RequireRecordInFile','N')));
          break;
        case 'doc_undeclarerecord':
          if (Util.RestAPI.restAPIVersion() < 0x00160500) {
            break;
          }
          enabled = this.currentFileRights.canControlAccess && effectiveRights.UNDECLARE_RECORD === 'Y' && this.currentFile.type === 'documents' && this.currentFile['RECORD'] === 'Y'; break;

        // **** Records MENU_RECORDS_MANAGEMENT
        case 'doc_rm_request':
          enabled = loginReply['RM_ENABLED'] && (this.isIn_RM() || this.isRMItem()) && loginReply.RM_SECURITY['REQUEST'] === 'Y' && this.currentFile['STATUS'] !== '18' && ((this.currentFile['type'] === 'documents') || Util.isRMFilePart(this.currentFile) || this.currentFile.type === 'boxes') && this.currentFile['STORAGE'] !== 'K'; break;
        case 'doc_rm_changevitalstatus':
          enabled = this.currentFileRights.canEditProfile && loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['CHANGE_VITAL_STATUS'] === 'Y' && this.currentFile['type'] === 'documents' && this.currentFile['STATUS'] !== '18'; break;
        case 'doc_rm_vitalreviewdate':
          enabled = this.currentFileRights.canEditProfile && loginReply['RM_ENABLED'] && loginReply.RM_SECURITY['CHANGE_VITAL_REVIEW_DATE'] === 'Y' && this.currentFile['type'] === 'documents' && this.currentFile['PD_VITAL'] === 'Y' && this.currentFile['STATUS'] !== '18'; break;
        case 'doc_rm_printlabel':
          enabled = false;break; // = loginReply['RM_ENABLED'] && this.currentFile["type"] === 'documents'; break;
        case 'doc_rm_suspend':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['SUSPEND'] === 'Y' && this.currentFile['STATUS'] !== '18' && (this.currentFile.type === 'documents' || (Util.isRMFilePart(this.currentFile))) && !(this.currentFile['PD_SUSPEND'] === 'Y') && this.currentFile['STATUS'] !== '18'; break;
        case 'doc_rm_release':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['RELEASE'] === 'Y' && (this.currentFile.type === 'documents' || (Util.isRMFilePart(this.currentFile))) && this.currentFile['PD_SUSPEND'] === 'Y'; break;
        case 'doc_rm_supersede':
          enabled = loginReply['RM_ENABLED'] && (!this.existsInRMsetting(loginReply, 'RequireRecordInFile','Y') || (this.existsInRMsetting(loginReply, 'RequireRecordInFile','Y') && this.isIn_RM())) && loginReply.RM_SECURITY['SUPERSEDE'] === 'Y' && this.currentFile['type'] === 'documents' && this.currentFile['RECORD'] === 'Y' && this.currentFile['PD_SUPSEDED'] !== 'Y'; break;
        case 'doc_rm_putinbox':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['PUT_IN_BOX'] === 'Y' && this.currentFile['id'].indexOf('FilePart') > -1 && (this.currentFile['PD_STATUSES'] !== '2' && this.currentFile['PD_STATUSES'] !== '3' && this.currentFile['STATUS'] !== '18'); break;
        case 'doc_rm_removefrombox':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['REMOVE_FROM_BOX'] === 'Y' && this.currentFile['id'].indexOf('FilePart') > -1 && (this.currentFile['PD_STATUSES'] === '2' || this.currentFile['PD_STATUSES'] === '3'); break;
        case 'doc_rm_disposalinstructions':
          enabled = false;break; // = loginReply['RM_ENABLED'] && this.currentFile["type"] === 'documents'; break;
        case 'doc_rm_changefilepart':
          enabled = loginReply['RM_ENABLED'] && !(this.currentFile['PD_FILE_PART'] === '' || this.currentFile['PD_FILE_PART'] === '0') && (this.isRMItem() || this.currentFile['STORAGE'] === 'P' || this.currentFile['STORAGE'] === 'K') && !(this.currentFile['PD_SUSPEND'] === 'Y') && loginReply.RM_SECURITY['CHANGE_FILEPART'] === 'Y' && this.currentFile['type'] === 'documents'; break;
        case 'doc_rm_assignfilepart':
          enabled = loginReply['RM_ENABLED'] && (this.currentFile['PD_FILE_PART'] === '' || this.currentFile['PD_FILE_PART'] === '0') && (this.isRMItem() || this.currentFile['STORAGE'] === 'P' || this.currentFile['STORAGE'] === 'K') && !(this.currentFile['PD_SUSPEND'] === 'Y') && loginReply.RM_SECURITY['CHANGE_FILEPART'] === 'Y' && this.currentFile['type'] === 'documents'; break;
        case 'doc_rm_returnrequest':
          enabled = loginReply['RM_ENABLED'] && (this.isIn_RM() || this.isRMItem()) && (((this.currentFile['ITEM_TYPE'] === 'M' || this.currentFile['ITEM_TYPE'] === 'D') && this.currentFile['type'] === 'documents' && this.currentFile['PD_STATUSES'] === '1') || (Util.isRMFilePart(this.currentFile)) || this.currentFile.type === 'boxes') && (this.currentFile['PD_STATUSES'] ==='1' || this.currentFile['PD_PART_STATUS'] === 'BORROWED'); break;
        case 'doc_rm_loanrequest':
          enabled = loginReply['RM_ENABLED']  && loginReply.RM_SECURITY['BORROW'] === 'Y' && this.currentFile['STATUS'] !== '18' && (this.currentFile['type'] === 'documents' && this.isIn_RM()
            && !(this.currentFile['PD_STATUSES'] === '1' && this.existsInRMsetting(loginReply,'ReturnOnBorrow','Y'))
            && ((this.existsInRMsetting(loginReply, 'CanBorrowElectronicRecords','Y') && this.currentFile['ITEM_TYPE'] === 'D') || (this.currentFile['ITEM_TYPE'] === 'M')))
            || (!((this.currentFile['PD_STATUSES'] ==='1' || this.currentFile['PD_PART_STATUS'] ==='BORROWED' || this.currentFile['STATUS'] === '18') && this.existsInRMsetting(loginReply,'ReturnOnBorrow','N')) && this.isRMItem()); break;
        case 'doc_rm_close':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['CLOSE_FILEPART'] === 'Y' && this.currentFile['NodeLevel'] === 'File Part' && this.currentFile['PD_ACTIVE_STATUS'] !== 'C'; break;
        case 'doc_rm_reopen':
          enabled = loginReply['RM_ENABLED'] && this.isIn_RM() && loginReply.RM_SECURITY['REOPEN_FILEPART'] === 'Y' && this.currentFile['NodeLevel'] === 'File Part' && this.currentFile['PD_ACTIVE_STATUS'] === 'C' && this.currentFile['STATUS'] !== '18'; break;
      }
    }
    if (enabled && extAppInfo) {
      enabled = this.extAppAccessEnabled (cmd,extAppInfo);
    }
    return enabled;
  }

  private extAppAccessEnabled(id: string, extAppInfo: any): boolean {
    let enabled = false;
    let rawid: string = id;
    if (id.startsWith('doc_')) {
      rawid = id.split('doc_')[1];
    }
    if ((!!extAppInfo['access'][rawid] && extAppInfo['access'][rawid] === true) || (!!extAppInfo['access'][rawid+'-from'] && extAppInfo['access'][rawid+'-from'] === true)) {
      enabled = true;
    }
    // Now test for exceptions and if selections are local
    switch (rawid) {
      case 'checkin':
        enabled = !!this.currentFile['checkout'];
        break;
      case 'editprofile':
        enabled = !!!this.currentFile['checkout'];
        break;
    }
    return enabled;
  }

  public commandChecked(cmd: string): boolean {
    if (cmd && cmd.startsWith('tab_')) {
      return this.tabId === cmd.substr(4);
    } else if (cmd.startsWith('list_choosecol_')) {
      if (!!this.listTable) {
        return this.listTable.getSortCol() === cmd.substr(15);
      }
    }
    return false;
  }

  public menuOpening(menuItemSetter: MenuItemSetter, id: number): void {
    if (id===MenuId.MENU_MOBILE_CHOOSE_COLUMN) {
      const schema: SchemaDef = !!this.listTable ? this.listTable.getSchema() : null;
      if (schema) {
        const menuItems: MenuItem[] = [];
        for (const col of schema.columns) {
          if ((col.format!== ColFormat.SELECTOR && col.format!==ColFormat.OBJTYPE) && !!col.label) {
            menuItems.push(new MenuItem({name: col.label, cmd: 'list_choosecol_'+col.property, checked: true}));
          }
        }
        menuItemSetter.setMenuItems(menuItems);
      }
    }
  }

  private isIn_RM(): boolean {
    return !(this.currentFile['PD_FILE_PART'] === '' || this.currentFile['PD_FILE_PART'] === '0');
  }

  private isRMItem(): boolean {
    return this.currentFile['PD_OBJ_TYPE'] === '4' || this.currentFile['PD_OBJ_TYPE'] === '5';
  }

  private existsInRMsetting(loginReply: any, setting: string, value: string): boolean {
    if (!!loginReply.RM_SETTINGS) {
      if (loginReply.RM_SETTINGS[setting] === value) {
        return true;
      }
    }
    return false;
  }

  private currentFileHasPropertyValueFromArray(key: string, values: any[]): boolean {
    if (values.indexOf(this.currentFile[key]) < 0) {
      return false;
    }
    return true;
  }

  private allSelectedItemsHaveProperty(key: string, value: any): boolean {
    if (this.selectedListItems) {
      for (const listItem of this.selectedListItems) {
        if (listItem[key]!==value) {
          return false;
        }
      }
      return this.selectedListItems.length!==0;
    }
    return false;
  }

  private allSelectedItemsHavePropertyValueFromArray(key: string, values: any[]): boolean {
    if (this.selectedListItems) {
      for (const listItem of this.selectedListItems) {
        if (values.indexOf(listItem[key]) < 0) {
          return false;
        }
      }
      return this.selectedListItems.length !== 0;
    }
    return false;
  }

  private canInsertIntoDocument(): boolean {
    return (Util.Device.bIsOfficeAddinWord && this.currentFile['APP_ID'] === 'MS WORD') || this.currentFileHasPropertyValueFromArray('APP_ID', ['DOCSIMAGE', 'MSPHOTOED']);
  }

  // **** drag source for sidebar width
  public canDrag(x: number, y: number, element: ElementRef): boolean {
    if (!this.bravaEnabled || this.tabId!=='viewer') {
      if (!!this.dragCover && !!this.dragCover.nativeElement) {
        this.dragCover.nativeElement.classList.remove('edx_hidden');
      }
      return true;
    }
    return false;
  }

  public allowVertical(element: ElementRef): boolean {
    return false;
  }

  public dragStarted(element: ElementRef): void {
    setTimeout(() => {
      this.sidebarDragStartLeft = element.nativeElement.offsetLeft;
    }, 1);
  }

  public dragEnded(event: any, element: ElementRef): void {
    const ne = element.nativeElement;
    let delta: number = ne.offsetLeft - this.sidebarDragStartLeft;
    const width: number = ne.offsetWidth;
    delta += delta > 0 ? width : -width;
    this.sidebarWidth += delta;
    if (this.sidebarWidth < Util.remsToPx(14)) {
      this.sidebarWidth = Util.remsToPx(14);
    }
    localStorage.setItem('meta_data_window_sidebarwidth', ''+this.sidebarWidth);
    if (!!this.dragCover && !!this.dragCover.nativeElement) {
      this.dragCover.nativeElement.classList.add('edx_hidden');
    }
    this.cdr.markForCheck();
  }

  private canOpenDocument(cmd?: string): boolean {
    if (cmd === 'versions_open' && !Util.RestAPI.canUserEditPreviousVersions()) {
      const version: any = this.selectedListItems[0] || {};
      const list: ListItem[] = this.listTable ? this.listTable.getList() : null;
      if (!!list && list.some(v => (parseInt(v['VERSION']) > parseInt(version.VERSION) || (v['VERSION'] === version.VERSION && v['VERSION_LABEL'] > version.VERSION_LABEL)))) {
        // User is not allowed to edit previous versions, hence warning
        Util.Notify.warning(this.localizer.getTranslation('FOLDER_ACTIONS.OPEN'), this.localizer.getTranslation('FORMS.LOCAL.OPEN.ERROR_OPEN_VERSION'));
        return false;
      }
    }
    //If document is checked out, anyone can open the document as readonly.
    return true;
  }

  //FilterTarget
  private shouldShowFacets(): boolean {
    const loginReply = Util.RestAPI.getLoginReply();
    const bHasFacets = (!!loginReply && loginReply.SEARCH_CAPABILITIES && loginReply.SEARCH_CAPABILITIES.SUMMARY === 'Y');
    return bHasFacets;
  }
  public getDesc(): BaseDesc {
    return this.desc;
  }
  public getListData(): ListData {
    const list = !!this.listTable ? this.listTable.getList() : [];
    const set = !!this.listTable ? this.listTable.getSet() : {};
    if (!set['search']) {
      set['search'] = {};
    }
    set['search']['FORM_NAME'] = '__local_filters_history';
    if (this.shouldShowFacets()) {
      set['datefacets'] = ['START_DATE'];
    }
    return { set, list };
  }
  public setListData(data: ListData, isFiltered: boolean): void {
    this.filtered = isFiltered;
    if (!!this.listTable) {
      this.listTable.loadListContent(data);
      this.filterKeys = this.filtered && this.filters ? this.filters.getFilterList() : null;
    }
  }
  public applyFilters(filters: any): void {
    this.filtered = (!!filters && filters.length);
    if (!!this.listTable) {
      if (this.filtered) {
        this.listTable.applyFilters(filters);
      } else {
        this.listTable.reloadList();
      }
    }
    this.filterKeys = this.filtered && !!this.filters ? this.filters.getFilterList() : null;
  }
  public filterShown(shown: boolean): void {
    if (!!this.listTable) {
      this.listTable.changedSchema();
    }
  }
  public startSearching(): void {
    if (!!this.listTable) {
      this.listTable.startSearching();
    }
  }
  public getSortKey(): string {
    if (!!this.listTable) {
      return this.listTable.getSortKey();
    }
    return '';
  }
  public resetSortParams(): void {
    if (!!this.listTable) {
      this.listTable.resetSortParams();
    }
  }
  public clearFilters(): void {
    if (this.filters) {
      this.filters.reset();
      this.filters.close();
      this.filtered = false;
    }
    this.filterKeys = null;
  }
  public hasFacets(): Promise<boolean> {
    return Promise.resolve(this.shouldShowFacets());
  }

  public showRestrictedWarning(): void {
    const title = this.localizer.getTranslation('GENERIC_ERRORS.RESTRICTED');
    const body = this.localizer.getTranslation('GENERIC_ERRORS.NOT_AUTHORIZED');
    Util.RestAPI.navToURL(Util.RestAPI.getHomeURL());
    Util.Notify.warning(title, body, null, null, false, false, true);
  }

  public displayMiniStatus(): boolean {
    return Util.RestAPI.displayMiniStatus(this.currentFile, this.desc);
  }

  public displayTopMiniStatus(): boolean {
    return Util.RestAPI.displayTopMiniStatus(this.currentFile, this.desc);
  }

  public formatStatusIcon(): string {
    return Util.RestAPI.formatStatusIcon(this.currentFile, this.desc);
  }

  public formatTopStatusIcon(): string {
    return Util.RestAPI.formatTopStatusIcon(this.currentFile, this.desc);
  }

  public formatStatusText(): string {
    return Util.RestAPI.formatStatusText(this.currentFile);
  }

  /* Store secured flex folder lookup info if inheritance is involved
     and user chooses a different file from fileList sidebar component */
  private saveSecuredFlexLookupInfo(formName: string, inheritFromKey: string) {
    Util.RestAPI.getFormTemplate(formName, this.desc.lib).subscribe((template: any) => {
      if (template && template.defs) {
        const securityProp = {
          securityInherited : true,
          securityField : this.headerWrapper.dynamicForm.fields,
          form: this.headerWrapper.dynamicForm.form
        };
        /* Taking advantage of createFieldsFromTemplate instead of writing a new method
           injects new form fields into dynamic form and therefore passing in
           security field and form to it to replace it with new form fields */
        this.headerWrapper.dynamicForm.createFieldsFromTemplate(template.defs, securityProp);
      }
    }, error => {
    });
    if (inheritFromKey) {
      this.headerWrapper.dynamicForm.findSecuredFlexLookup();
      /* To force angular to rebuild the template, refresh the security tab */
      setTimeout(() => {
        this.securedFlexKeyValueList = this.headerWrapper.dynamicForm.securedLookupKeyList;
        this.inheritFromPrimaryKey = this.headerWrapper.dynamicForm.inheritFromPrimaryKey;
        this.refresh();
      }, 1000);
    }
  }

  /* We do not use INHERITED value of 2 for existing docs anymore.
     It is always restricted except when creating a doc. */
  private inheritedToRestricted() {
    if (!!this.headerWrapper && !!this.headerWrapper.dynamicForm) {
      if (this.currentFile['SECURITY'] === '2') {
        this.currentFile['SECURITY'] = '1';
      }
      this.headerWrapper.dynamicForm.updateControlValue('SECURITY', this.currentFile['SECURITY'], true);
      this.headerWrapper.notifyPermissionChanged.emit(this.currentFile['SECURITY']);
    }
  }
  
  public updateZoom(zoom: string): void {
    if (zoom === '1') {
      this.previewIFrameScale = this.previewIFrameScale + 0.1;
    } else {
      this.previewIFrameScale = Math.max(0.1, this.previewIFrameScale - 0.1);;
    }
    this.previewIFrame.nativeElement.contentDocument.activeElement.style.transform = `scale(${this.previewIFrameScale})`;
    this.previewIFrame.nativeElement.contentDocument.activeElement.style.transformOrigin = 'top left';
  }

  public navigateToSelectedVersion(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    document.getElementById('edx-selected-version')?.focus();
    
  }

  public displayZoomButtons(): boolean {
    return this.ui>1 && !this.bravaEnabled && !!this.currentFile && this.currentFile['APP_ID'] !== 'ACROBAT';
  }

  public loadVersionsForViewer(loadMore: any): void {
    if (!this.bravaEnabled && !this.videoUrl) {
      const start = loadMore?.start || 0;
      const end = loadMore?.end || 5;
      const queryArgs = `start=${start}&max=${end}&descending=VERSION_LABEL`;
      const params = 'versions';
      const promiseRequest = Util.RestAPI.get(this.desc, params, queryArgs);
      let newData = null;
      promiseRequest.toPromise().then((listData: ListData) => {
        if (!!listData && !!listData.list && listData.list.length > 0) {
          newData = listData.list;
          newData.forEach((version) => {
            version['status_src'] = (Util.RestAPI.displayStatusIcon(version, this.desc) ? Util.RestAPI.formatStatusIcon(version, this.desc) : '')
          })
        }
        this.totalVersions = listData?.set?.total;
        if (!!loadMore || (!loadMore && this.versions.length === 0)) {
          this.versions = this.versions.concat(newData);
        }
        if (!loadMore) {
          this.loadPreviewOrViewerOrVideo(this.versions[0]['VERSION_ID']);
        }
        this.cdr.markForCheck();
      }, err => {
      });
    } else {
      this.loadPreviewOrViewerOrVideo();
    }
  }
}
