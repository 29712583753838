import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { animate, transition, style, state, trigger } from '@angular/animations';
import { LocalizeService } from '../services/localize.service';
import { CommandHandler } from '../models/command-handler';
import { AppComponent } from '../app.component';
import { Util, UserInterface } from '../utils/utils.module';
import { DataFile, FileFormInfo } from '../models/file-form-info';
import { ShortcutsService } from '../services/shortcuts.service';

export interface PopupCallback {
  popupCancel?(): void;
  popupOK?(): void;
  popupThirdBtn?(): void;
  extrasToggled?(shown: boolean): void;
  getLookupBtnId?(): string;
}

@Component({
  selector: 'edx-popup',
  styleUrls: ['popup.component.scss'],
  animations: [
    trigger('showBody', [
      state('true', style({ height: '*', opacity: '1.0' })),
      state('false', style({ height: '0', opacity: '0.0'})),
      transition('false => true', animate('200ms ease-out')),
      transition('true => false', animate('200ms ease-in'))
    ])
  ],
  template: `
  <div *ngIf="!disabled && !isPhoneLook()" class="overlay" [ngClass]="{shown:shown, onpopup:onPopup(), oai:isOfficeAddin, preferences:kind==='__local_preferences', filtersb:kind==='profile_query_filter', hasfooter:hasFooter}"></div>
  <div #popup  tabindex="0" role="dialog" aria-modal="true" [title]="stripLabel(title)" class="popup" [style.width]="getWidth()" [ngClass]="{shown:shown, readonly:disabled, mobile:ui>=2, phone:isPhoneLook(), cordova:ui>=4, ios:iOS, onpopup:onPopup(), oai:isOfficeAddin, preferences:kind==='__local_preferences', filtersb:kind==='profile_query_filter',hasfooter:hasFooter}" (keydown.shift.tab)="loopFocus($event)">
    <div *ngIf="!isPhoneLook()" class="header" tabindex="0" [ngClass]="{underpopup:underPopup()}">
      <div class="title">{{stripLabel(title)}}</div>
      <div *ngIf="headerformOnRight" class="header2right">
        <ng-content select="[rightheader]"></ng-content>
      </div>
      <div *ngIf="levelDropdownOnRight" class="dropdown2Right">
        <ng-content select="[levelDropdown]"></ng-content>
      </div>
    </div>
    <div *ngIf="isPhoneLook() && headerformOnRight && !underPopup()" class="header2phone">
      <ng-content select="[phoneheader]"></ng-content>
    </div>
    <div *ngIf="secondtitle" class="header2 text" [ngClass]="{underpopup:underPopup()}">
      <div *ngIf="secondtitle" class="header2text">{{secondtitle}}</div>
    </div>
    <div *ngIf="headerform" class="header2" [ngClass]="{underpopup:underPopup()}">
      <ng-content select="[secondheader]"></ng-content>
    </div>
    <div [@showBody]="showBody" (@showBody.done)="animationDone($event)" class="bodycontainer" [ngClass]="{secondheader:(secondtitle||headerform||(isPhoneLook() && headerformOnRight && !underPopup())), adjustheight: resetForm}">
      <div #body class="body" [ngClass]="{secondheader:(secondtitle||headerform),phoneextras:isPhoneLook()&&extras&&!disabled}" id="edx_form_body">
        <ng-content></ng-content>
      </div>
      <div *ngIf="isPhoneLook() && extras && !disabled" class="extras" role="button" (click)="toggleExtras()" (keyup.space)="toggleExtras()">
        <div class="extras-caret" tabindex="1" [ngClass]="{up:extrasShown}"></div>
        <span *ngIf="!extrasShown || !extrasAlt" class="extras-title">{{extras}}</span>
        <span *ngIf="extrasShown && extrasAlt" class="extras-title">{{extrasAlt}}</span>
      </div>
    </div>
    <div *ngIf="!isPhoneLook()" class="footer" [ngClass]="{underpopup:underPopup()}">
      <div *ngIf="extras && !disabled" class="extras" tabindex="0" (click)="toggleExtras()" role="button" (keyup.space)="toggleExtras()">
      <div [title]="(!extrasShown||!extrasAlt)?extras:extrasAlt" class="extras-caret" [ngClass]="{up:extrasShown}"></div>
      <span *ngIf="!extrasShown || !extrasAlt" [title]="extras" class="extras-title">{{extras}}</span>
      <span [title]="extrasAlt" *ngIf="extrasShown && extrasAlt" class="extras-title">{{extrasAlt}}</span>
    </div>
      <div *ngIf="kind==='shortcuts'" class="extras" [ngClass]="{disabled: isShortcutsConfigInvalid}" tabindex="0">
        <span class="shortcuts"> {{shortcutsInfoText}}
          <span class="blue-link" tabindex="0" role="button" (keydown.enter)="toggleShortcuts()" (keyup.space)="toggleShortcuts()" (click)="toggleShortcuts()">
            {{shortcutsLinkText}}
          </span>
        </span>
      </div>
      <button *ngIf="!hideCancel && !disabled" #cancelbutton role="button" [title]="cancel" class="secondary" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:hideCancel}" tabindex="0" (click)="doCancel()" (keydown.tab)="(okDisabled && ui<2)?loopFocus($event):null" id ="secondaryBtn-{{cancel}}">{{cancel}}</button>
      <button *ngIf="!hideOK && !disabled" #okbutton role="button"  [title]="ok" class="primary" [disabled]="okDisabled" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:hideOK, readonly:okDisabled}" tabindex="0" (click)="doOK()" (keydown.tab)="(ui<2)?loopFocus($event):null">{{ok}}</button>
      <button *ngIf="thirdtitle && !disabled" #thirdbutton role="button" [title]="thirdtitle" class="secondary" [ngClass]="{mobile:ui>=2, oai:isOfficeAddin, edx_hidden:!thirdtitle}" tabindex="0" (click)="doThirdBtn()" (keydown.tab)="(thirdtitle && ui<2)?loopFocus($event):null">{{thirdtitle}}</button>
    </div>
    <div class="bottom-controls" *ngIf="resetForm">
      <button (click)="doThirdBtn()" role="button" [title]="thirdtitle" class="secondary" tabindex="0">{{thirdtitle}}</button>
    </div>
  </div>
  `
})
export class PopupComponent implements OnInit, OnDestroy, CommandHandler  {
  @Input() width?: number = 800;
  @Input() disabled?: boolean = false;
  @Input() extrasShown?: boolean = false;
  @Input() ok?: string = '';
  @Input() cancel?: string = '';
  @Input() hideOK?: boolean = false;
  @Input() hideCancel?: boolean = false;
  @Input() okDisabled?: boolean = false;
  @Input() hasFooter?: boolean = false;
  @Input() headerform?: boolean = false;
  @Input() headerformOnRight?: boolean = false;
  @Input() levelDropdownOnRight?: boolean = false;
  @Input() title?: string = '';
  @Input() secondtitle?: string;
  @Input() thirdtitle?: string;
  @Input() extras?: string;
  @Input() extrasAlt?: string;
  @Input() kind?: string = '';
  @Input() desc?: any = {};
  @Input() readOnly?: boolean = false;
  @Input() kind2?: string = null;
  @Input() desc2?: any = null;
  @Input() readOnly2?: boolean = false;
  @Input() createType?: string = null;
  @Input() trustees?: any[] = null;
  @Input() fileFormInfo?: FileFormInfo = null;
  @Input() fileList?: FileList = null;
  @Input() filePaths?: string[] = null;
  @Input() dataFiles?: DataFile[] = null;
  @Input() callback: PopupCallback;
  @Input() selections: any[] = [];
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('body') body: ElementRef;
  @ViewChild('okbutton') okButton: ElementRef;
  @ViewChild('cancelbutton') cancelButton: ElementRef;
  @ViewChild('thirdbutton') thirdButton: ElementRef;
  static nPopupsShown = 0;
  static PopupStack: PopupComponent[] = [];
  public shown = false;
  public popupIndex: number;
  public showBody = 'true';
  public ui: UserInterface;
  public iOS: boolean;
  public isOfficeAddin: boolean;
  public resetForm = false;
  private isClosing: boolean;
  private isShortcutsEnabled = true;
  private shortcutsInfoText = '';
  private shortcutsLinkText = '';
  private isShortcutsConfigInvalid = false;

  constructor(private localizer: LocalizeService,  private shortcutService: ShortcutsService) {
    this.popupIndex = PopupComponent.nPopupsShown++;
    PopupComponent.PopupStack.push(this);
    this.isClosing = false;
    this.ui = Util.Device.ui;
    this.iOS = Util.Device.bIsIOSDevice;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.enableInputs(false);
  }

  ngOnInit() {
    this.shortcutService.setDisableShortcuts(true);
    if (!this.isPhoneLook() && (this.kind && this.kind.startsWith('list') || this.iOS)) {
      setTimeout(() => {
        const ele = this.popup.nativeElement;
        let parentHeight = 0;
        let parent: any = ele.parentElement;
        while (parent) {
            const classList = parent.classList;
            if (classList && (classList.contains('popup') || classList.contains('window-modal'))) {
              parentHeight = parent.clientHeight;
              if (this.onPopup()) {
                parentHeight -= Util.remsToPx(this.secondtitle||this.headerform ? 7 : 4);
              }
              break;
            }
            parent = parent.offsetParent ? parent.offsetParent : parent.parentElement;
        }
        if (parentHeight === 0) {
          parentHeight = window.innerHeight * 0.80;
        }
        let height: number = parentHeight - (this.secondtitle ? 208 : 160);
        if (!this.onPopup()) {
          height = height < 400 ? 400 : height;
        }
        this.body.nativeElement.style.height = height.toString() + 'px';
      }, 1);
    }
    if (!this.isPhoneLook() && (this.kind && this.kind === 'shortcuts')) {
      this.isShortcutsEnabled = Util.RestAPI.getPreference('enable_shortcuts') !== '0';
      this.updateShortcutText();
      this.cancel = this.localizer.getTranslation('FORMS.BUTTONS.CLOSE');
    }
    if ((Util.Device.teamsContext) && (this.kind && this.kind === '__local_link')) {
        this.hideOK = true;
    }
    if (this.ok === '' && this.ok.length===0) {
      // you can pass null and only have cancel
      this.ok = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    }
    if (this.cancel === '' && this.cancel.length===0) {
      // you can pass null and only have ok
      this.cancel = this.localizer.getTranslation('FORMS.BUTTONS.CANCEL');
    }
    this.shown = true;
    if (Util.Device.isMobile()) {
      const app: AppComponent = Util.RestAPI.getAppComponent();
      if (this.isPhoneLook()) {
        app.pushCommandHandler(this, 'popupok', this.hideCancel ? null : this.cancel, this.title, this.ok);
      } else {
        app.pushCommandHandler(this, 'popupok', null, null, null);
        app.maskHeader(true);
      }
      this.shown = true;
    } else {
      setTimeout(() => {
        // delay half a second for auto-close on auto-upload formwrappers
        this.shown = true;
      }, Util.kPopupDismissMS);
    }
    this.resetForm = this.isOfficeAddin && this.kind.startsWith('profile_editdefs');
    setTimeout(() => {
      const element = this.popup.nativeElement;
      if (!!element) {
        element.focus();
      }
    }, 1);
  }

  ngOnDestroy() {
    this.isClosing = true;
    this.enableInputs(true);
    PopupComponent.nPopupsShown--;
    PopupComponent.PopupStack.splice(PopupComponent.PopupStack.indexOf(this),1);
    if (Util.Device.isMobile()) {
      const app: AppComponent = Util.RestAPI.getAppComponent();
      if (!this.isPhoneLook()) {
        app.maskHeader(false);
      }
      app.nullTopCommandHandler();
      app.navBack();
    }
    if (PopupComponent.PopupStack.length === 0) {
      this.shortcutService.setDisableShortcuts(false);
    }
    this.retainFocusOnDestroy();
  }

  public loopFocus(event: KeyboardEvent): void {
    if (!!event && (!event.shiftKey || (event.shiftKey && this.popup.nativeElement === document.activeElement))) {
      event.preventDefault();
      setTimeout(() => {
        const element = event.shiftKey ? (!!this.thirdtitle ? this.thirdButton : (!this.hideOK && !this.okDisabled) ? (this.okButton || this.getHdrBtn('right')) : (this.cancelButton || this.getHdrBtn('left'))) : this.popup;
        if (!!element) {
          return element instanceof HTMLElement ? element.focus() : (!!element.nativeElement? element.nativeElement.focus(): null);
        }
      }, 1);
    }
  }

  public retainFocusOnDestroy(): void {
    let elementId: string;
    const popupLength = PopupComponent.PopupStack.length;
    if ((this.ui < 2 && popupLength > 0) || (this.ui >= 2 && popupLength >= 0)) {
      if (this.callback.getLookupBtnId) {
        elementId = this.callback.getLookupBtnId();
      }
    } else {
      elementId = this.ui < 2 ? 'edx_list_container' : '';
    }
    setTimeout(() => {
      if ((elementId === undefined || elementId === '') && (this.ui < 2 && popupLength > 0)) {
        const element = PopupComponent.PopupStack[popupLength - 1].popup.nativeElement;
        if (!!element) {
          element.focus();
        }
      } else {
        const element = document.getElementById(elementId);
        if (!!element) {
          element.focus();
        }
      }
    }, 100);
  }

  static ElementInTopPopup(element: HTMLElement): boolean {
    const curPopup: PopupComponent = PopupComponent.nPopupsShown>0 ? PopupComponent.PopupStack[PopupComponent.nPopupsShown-1] : null;
    const curPopupBodyEl = curPopup && curPopup.body ? curPopup.body.nativeElement : null;
    if (curPopupBodyEl && element) {
      let curElement = element;
      while (curElement) {
        if (curElement === curPopupBodyEl) {
          return true;
        }
        curElement = curElement.parentElement;
      }
    }
    return false;
  }

  public enableInputs(enable: boolean): void {
    const inputs = document.getElementsByTagName('INPUT');
    const nInputs: number = inputs ? inputs.length : 0;
    for (let i=0; i<nInputs; i++) {
      const input = inputs[i];
      if (enable) {
        input.removeAttribute('disabled');
      } else {
        input.setAttribute('disabled','');
      }
    }
    const selects = document.getElementsByClassName('edx_select,edx_editable_input');
    const nSelects = selects ? selects.length : 0;
    for (let i=0; i<nSelects; i++) {
      selects[i].setAttribute('tabindex', enable ? '1' : '-1');
    }
  }

  public isPhoneLook(): boolean {
    return Util.Device.isPhoneLook();
  }

  public stripLabel(oldLabel: string): string {
    return Util.Transforms.stripLabel(oldLabel);
  }

  @HostListener('document:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (this.popupIndex === PopupComponent.nPopupsShown-1) {
      // only handle keyup events if we are the top level popup
      if (event.key === 'Escape') {
        const element = document.activeElement as HTMLElement;
        if (!element.classList.contains('datepicker')) {
          this.doCancel();
        }
      } else if (event.key === 'Enter') {
        const target: any = event.target;
        if (target.type !== 'textarea') {
          if (this.okDisabled) {
            event.preventDefault();
            event.stopPropagation();
          } else {
            if (Util.Device.isMobile() && this.popupIndex===0) {
              Util.RestAPI.getAppComponent().cordovaRight();
            } else {
              const element = document.activeElement as HTMLElement;
              if (!!element && (element.tagName !== 'BUTTON' && !element.classList.contains('buttoniconic'))) {
                this.doOK();
              }
            }
          }
        }
      }
    }
  }

  public getWidth(): string {
    return !this.isPhoneLook() ? (this.width.toString() + 'px') : '100%';
  }

  public onPopup(): boolean {
    return !this.isPhoneLook() && this.iOS && this.popupIndex>0;
  }

  public underPopup(): boolean {
    return this.iOS && this.popupIndex===0 && PopupComponent.nPopupsShown>1;
  }

  public doCancel(): void {
    setTimeout(() => {
      if (this.callback.popupCancel) {
        this.callback.popupCancel();
      } else if (this.callback.popupOK) {
        this.callback.popupOK();
      }
    }, 150);
  }

  public doOK(): void {
    setTimeout(() => {
      if (this.callback.popupOK) {
        this.callback.popupOK();
      } else if (this.callback.popupCancel) {
        this.callback.popupCancel();
      }
    }, 150);
  }

  public doThirdBtn(): void {
    if (this.callback.popupThirdBtn) {
      this.callback.popupThirdBtn();
    }
  }

  public toggleExtras(): void {
    this.extrasShown = !this.extrasShown;
    this.showBody = 'false';
  }

  public animationDone(event: Event): void {
    if (!this.isClosing) {
      setTimeout(() => {
        if (this.callback.extrasToggled) {
          this.callback.extrasToggled(this.extrasShown);
        }
        this.showBody = 'true';
      }, 100);
    }
  }

  // PopupButtonChanger implementation
  public setOKButton(title?: string, hidden?: boolean): void {
    if (title) {
      this.ok = title;
    }
    if (hidden!==undefined) {
      this.hideOK = hidden;
    }
  }

  public setCancelButton(title?: string, hidden?: boolean): void {
    if (title) {
      this.cancel = title;
    }
    if (hidden!==undefined) {
      this.hideCancel = hidden;
    }
  }

  public setThirdButton(title?: string, hidden?: boolean): void {
    if (hidden) {
      this.thirdtitle = null;
    } else if (title) {
      this.thirdtitle = title;
    }
  }

  public getHdrBtn(id: string): HTMLElement {
    const elementId = 'edx_hdr_btn_'+id;
    const element = document.getElementById(elementId);
    if (!!element) {
      return element;
    }
    return null;
  }
  // CommandHandler implementation
  public doCommand(cmd: string, disable?: boolean): boolean {
    switch (cmd) {
    case 'popupok':
      if (!this.okDisabled) {
        this.doOK();
        return true;
      }
      break;
    case 'back':
      this.doCancel();
      return true;
    case 'disableok':
      this.okDisabled = disable;
      break;
    }
    return false;
  }

  public commandEnabled(cmd: string): boolean {
    switch (cmd) {
    case 'popupok':
      if (!this.okDisabled) {
        return true;
      }
      break;
    case 'back':
      return true;
    }
    return false;
  }

  protected toggleShortcuts(): boolean {
    this.isShortcutsEnabled = !this.isShortcutsEnabled;
    Util.RestAPI.setPreference('enable_shortcuts',(this.isShortcutsEnabled ? '1' : '0'));
    this.updateShortcutText();
    return false;
  }

  private updateShortcutText() {
    this.shortcutsInfoText = !this.isShortcutsEnabled ? this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.DISABLED_SHORTCUTS') : this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.ENABLED_SHORTCUTS');
    this.shortcutsLinkText = this.isShortcutsEnabled ? this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.DISABLE_SHORTCUTS') : this.localizer.getTranslation('FORMS.LOCAL.PREFERENCES.ENABLE_SHORTCUTS');
    this.isShortcutsConfigInvalid = this.shortcutService.isInvalidConfig;
  }

}
