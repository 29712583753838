
    <div class="window-modal" [ngClass]="{ web:ui==0, desktop:ui==1, mobile:ui>=2, oai: isOfficeAddin }" id="edx_window_modal_metadata">
      <div *ngIf="ui<2 || ((ui==3 || ui==5) && !portrait)" class="sidebar open" [style.width]="getSidebarWidth()">
        <edx-filter-sidebar *ngIf="showFilter" [target]="this"></edx-filter-sidebar>
        <ng-template [ngIf]="ui<2 && !showFilter">
          <div class="header backheader" [tabIndex]="disabledNav ? -1 : 21" role="button" [attr.aria-label]="goBackAltText" (click)="disabledNav?null:navBack()" (keyup.enter)="disabledNav?null:navBack()" (keyup.space)="disabledNav?null:navBack()" [ngClass]="{disabled:disabledNav, disableHover:disabledNav}" title="{{this.localizer.getTranslation('TOOLTIP.GO_BACK')}}">
            <img class="backarrow" src="assets/images/arrow_back.svg">
            <div class="headertext">{{name}}</div>
          </div>
        </ng-template>
        <div *ngIf="ui>=2" class="header"></div>
        <edx-filelist-sidebar *ngIf="!showFilter || ((ui==3 || ui==5) && !portrait)" #filelist [receiver]="this" [list]="fileList" [selectIndex]="selectIndex" [desc]="desc" [rerender]="rerender" [disabled]="disabledNav" [tabIndex]="22"></edx-filelist-sidebar>
      </div>
      <edx-filter-sidebar *ngIf="showFilter && ui>=2 && ((ui!==3 && ui!==5) || portrait)" [target]="this"></edx-filter-sidebar>
      <div class="splitter-outter" [ngClass]="{disabled:bravaEnabled && tabId==='viewer'}" [edx-draggable]="bravaEnabled && tabId==='viewer' ? undefined : this">
        <div class="splitter-inner"></div>
      </div>
      <div class="body">
        <div class="header" id="edx_window_modal_metadata_header">
          <img class="headericon"
                src="{{headerIcon}}"
                alt="{{headerText}}">
          <img *ngIf="displayMiniStatus()"
                src="{{formatStatusIcon()}}"
                alt="{{formatStatusText()}}"
                class="overlaystatusicon"
                [ngClass]="{mobile:ui>=2, oai: isOfficeAddin }">
          <img *ngIf="displayTopMiniStatus()"
                src="{{formatTopStatusIcon()}}"
                alt="{{formatStatusText()}}"
                class="overlaystatusicon top"
                [ngClass]="{mobile:ui>=2, oai: isOfficeAddin }">
          <div class="headertextwrapper">
            <div #headerTextDiv class="headertext" title="{{getName()}}">{{getName()}}</div>
          </div>
          <div *ngIf="headerMenuID && !disabledNav" [ngClass]="{headermenu:ui<2, toucharea:ui>=2,right:ui>=2}">
            <edx-menu #menuDiv [id]= "'edx_header_menu'" [menuId]="'edx_header_menu'" [ariaLabel]="this.localizer.getTranslation('ALT_TEXT.HEADER_MENU')" [menuID]="headerMenuID" [callback]="this" [hideIfEmpty]="true" [forceMenuButton]="ui>=2" [tabIndex]="23"></edx-menu>
          </div>
        </div>
        <div *ngIf="ui<2" class="tabcontainer">
          <edx-tab-selector #tabselector [receiver]="this" [tabdefs]="tabList" [disable]="disabledNav" [tabIndex]="24"></edx-tab-selector>
        </div>
        <edx-action-bar *ngIf="ui<2 && actionId!=-1 && !versionCompare" #actionbar [menuID]="actionId" [target]="this"></edx-action-bar>
        <div #contentPanel class="contentpanel" [ngClass]="{actionbar: actionId!=-1, filtershown:showFilter, graphicalcontainer:versionCompare, addincontainer:versionCompare && (isOfficeAddin || ui>=2)}" [ngSwitch]="tabId" [tabIndex]="0">
          <div *ngIf="versionCompare" class="closecomparediv" [ngClass]="{closereport:!isGraphicalCompare}"  [tabIndex]="24" (click)="closeCompare()" (keyup.enter)="closeCompare()" (keyup.space)="closeCompare()">
            <img class="backtoversions" [ngClass]="{backimg:!isGraphicalCompare}" src="assets/images/arrow_back.svg">
            <div class="backtext">{{this.localizer.getTranslation('METADATA.VERSIONS_ACTIONS.BACK_TO_VERSIONS')}}</div>
          </div>
          <ng-template [ngSwitchCase]="'profile'">
            <edx-form-wrapper #formWrapper [kind]="'profile'" [desc]="currentFile" [readOnly]="true" [refresh]="profileRefresh" (notifyNameChanged)='onNameChanged($event)' (notifyShareChanged)='onShareChanged($event)'></edx-form-wrapper>
          </ng-template>
          <ng-template [ngSwitchCase]="'preview'">
              <div class="preview-iframe" [style.transform]="previewIFrameTransform">
                <iframe #previewIFrame class="preview-iframe"></iframe>
              </div>
            <div *ngIf="showIframeCover()" class="preview-iframe-cover edx_transparent" [edx-gesture-pinch]="this" [style.transform]="previewIFrameTransform"></div>
            <edx-spinner *ngIf="previewIFrameLoading"></edx-spinner>
            <div #dragCover class="preview-iframe-cover edx_transparent edx_hidden"></div>
          </ng-template>
          <ng-template [ngSwitchCase]="'viewer'">
            <div class="viewer-parent">
            
              <edx-version-viewer *ngIf="!videoUrl && !bravaEnabled && versions.length > 1" [ui]="ui" [versions]="versions" [desc]="currentFile"
                [total]="totalVersions" (loadMoreVersions)="loadVersionsForViewer($event)" (versionClicked)="loadPreviewOrViewerOrVideo($event)"></edx-version-viewer>
              <div *ngIf="!videoUrl && !bravaEnabled && versions.length > 1" class="splitter-outter">
                <div class="splitter-inner">
                </div>
              </div>
            
              <div class="preview-iframe" [ngClass]="{hide:!!videoUrl || (bravaEnabled&&currentFile['STATUS']!=='16')}"
                [style.transform]="previewIFrameTransform">
                <iframe #previewIFrame class="preview-iframe"></iframe>
              </div>
              <div *ngIf="showIframeCover()" class="preview-iframe-cover edx_transparent" [edx-gesture-pinch]="this"
                [style.transform]="previewIFrameTransform"></div>
              <edx-spinner *ngIf="previewIFrameLoading"></edx-spinner>
              <div *ngIf="bravaEnabled" [ngClass]="{hide:!!videoUrl}" class="bravacontentpane"
                data-dojo-type="dijit/layout/BorderContainer" data-dojo-props="design:'sidebar', gutters:true, liveSplitters:false"
                id="borderContainer">
                <div data-dojo-type="dijit/layout/ContentPane" data-dojo-props="splitter:false, region:'center'"
                  class="bravacontentpane" id="viewerContainer">
                </div>
              </div>
              <div *ngIf="!bravaEnabled && !videoUrl" #dragCover class="preview-iframe-cover edx_transparent edx_hidden"></div>
              <div class="video-container" [ngClass]="{hide:!videoUrl}">
                <video oncontextmenu="return false;" controls src="{{videoUrl}}" type="{{videoMime}}" disablepictureinpicture
                  controlsList="nodownload"></video>
              </div>

              <div *ngIf="displayZoomButtons()" class="zoom-parent">
                <div role="button" (keyup.space)="updateZoom('1')" (keyup.enter)="updateZoom('1')" (click)="updateZoom('1')"
                  id="edx-zoom-in" class="zoom-in-button" tabindex="0"
                  [title]="zoomInText"
                  (keydown.shift.tab)="navigateToSelectedVersion($event)">
                </div>
                <div role="button" (keyup.space)="updateZoom('-1')" (keyup.enter)="updateZoom('-1')" (click)="updateZoom('-1')"
                  id="edx-zoom-out" class="zoom-out-button" tabindex="0"
                  [title]="zoomOutText">
                </div>
              </div>
            </div>
            
            
            </ng-template>
          <ng-template [ngSwitchCase]="'versions'">
            <ng-template [ngIf]="versionCompare">
              <edx-spinner *ngIf="previewIFrameLoading"></edx-spinner>
              <div *ngIf="bravaEnabled" class="bravacontentpane" data-dojo-type="dijit/layout/BorderContainer" data-dojo-props="design:'sidebar', gutters:true, liveSplitters:false" id="borderContainer">
                <div data-dojo-type="dijit/layout/ContentPane" data-dojo-props="splitter:false, region:'center'" class="bravacontentpane" id="viewerContainer"></div>
              </div>
            </ng-template>
            <ng-template [ngIf]="ui<2 && !versionCompare">
              <div class="list footer">
                <edx-list-table #listtable [schemaId]="'VERSIONS'" [params]="'versions'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="20"></edx-list-table>
              </div>
              <edx-list-info-footer [displayItems]="footerItems" [heading]="footerHeading"></edx-list-info-footer>
            </ng-template>
            <div *ngIf="ui>=2 && !versionCompare" class="gesture-wrapper" [edx-gesture-longpress]="listTable" [edx-gesture-swipe]="listTable" [edx-gesture-doubletap]="listTable" [edx-drag-target]="listTable">
              <edx-list-mobile #listtable [schemaId]="'VERSIONS'" [params]="'versions'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView" [pageSizeIncremental]="20"></edx-list-mobile>
            </div>
          </ng-template>
          <ng-template [ngSwitchCase]="'attachments'">
            <ng-template [ngIf]="ui<2">
              <div class="list footer">
                <edx-list-table #listtable [schemaId]="'ATTACHMENTS'" [params]="'attachments'" [parent]="this" [desc]="currentFile"></edx-list-table>
              </div>
              <edx-list-info-footer [displayItems]="footerItems" [heading]="footerHeading"></edx-list-info-footer>
            </ng-template>
            <div *ngIf="ui>=2" class="gesture-wrapper" [edx-gesture-longpress]="listTable" [edx-gesture-swipe]="listTable" [edx-gesture-doubletap]="listTable" [edx-drag-target]="listTable">
              <edx-list-mobile #listtable [schemaId]="'ATTACHMENTS'" [params]="'attachments'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </div>
          </ng-template>
          <ng-template [ngSwitchCase]="'history'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'HISTORY'" [params]="'history'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="20"></edx-list-table>
              </div>
            </ng-template>
            <ng-template [ngIf]="ui>=2">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'HISTORY'" [params]="'history'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="20" [filters]="filterKeys" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </ng-template>
          </ng-template>
          <ng-template [ngSwitchCase]="'whereused'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'WHERE_USED'" [params]="'references'" [parent]="this" [desc]="currentFile"></edx-list-table>
              </div>
            </ng-template>
            <div *ngIf="ui>=2" class="gesture-wrapper" [edx-gesture-longpress]="listTable" [edx-gesture-swipe]="listTable" [edx-gesture-doubletap]="listTable">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'WHERE_USED'" [params]="'references'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </div>
          </ng-template>
          <ng-template [ngSwitchCase]="'related'">
            <ng-template [ngIf]="ui<2">
              <div class="list">
                <edx-list-table #listtable [schemaId]="'RELATED'" [params]="'associations'" [parent]="this" [desc]="currentFile" [pageSizeIncremental]="25"></edx-list-table>
              </div>
            </ng-template>
            <div *ngIf="ui>=2" class="gesture-wrapper" [edx-gesture-longpress]="listTable" [edx-gesture-swipe]="listTable" [edx-gesture-doubletap]="listTable">
              <edx-list-mobile #listtable [schemaLayout]="true" [schemaId]="'RELATED'" [params]="'associations'" [parent]="this" [desc]="currentFile" [hasHeader]="true" [inlineActionMenuId]="6" [inlineActionTarget]="this" (actionHandler)="actionHandler($event)" [splitView]="splitView"></edx-list-mobile>
            </div>
           </ng-template>
           <ng-template [ngSwitchCase]="'security'">
             <div class="list form footer" [ngClass]="{nomargin:isWorkspaceOnMobile}">
              <ng-template [ngIf]="!isWorkspaceOnMobile">
                <edx-form-wrapper #headerWrapper
                    (notifyPermissionChanged)='onPermissionChanged($event)'
                    [desc]="currentFile" [kind]="'__local_permissions_selector'"
                    [layout]="'header'" (notifySecurityDirty)="onSecurityDirty($event, true)"
                    (notifySecurityReadOnly)="onSecurityReadOnly($event)">
                </edx-form-wrapper>
              </ng-template>
              <edx-list-security #security *ngIf="isRestricted || securityListVisible"
                  [isReadOnly]="isReadOnly" [showFooter]="true" [kind]="list_PRIVATE"
                  [desc]="currentFile" [splitView]="splitView" [inDialog]="false"
                  [autoAdd]="autoAddUsers" (notifySecurityDirty)="onSecurityDirty($event, false)"
                  (notifySecurityReadOnly)="onSecurityReadOnly($event)"
                  [inheritFromPrimaryKey]="inheritFromPrimaryKey"
                  [securedFlexKeyValueList]="securedFlexKeyValueList">
              </edx-list-security>
              <edx-spinner *ngIf="settingSecurity"></edx-spinner>
            </div>
           </ng-template>
        </div>
        <edx-action-bar *ngIf="ui>=2 && actionId!=-1 && !versionCompare" #actionbar [menuTitle]="getName()" [menuIcon]="headerIcon" [menuID]="actionId" [target]="this" ></edx-action-bar>
      </div>
    </div>
