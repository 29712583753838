import { Injectable } from '@angular/core';

import { LocalizeService } from './localize.service';
import { SchemaService, SchemaDef } from './schema.service';
import { ListData } from '../models/base';
import { ColumnDesc } from '../models/column';
import { ListItem } from '../models/list-item';
import { Util } from '../utils/utils.module';

export class CacheItem {
  public id = '';
  public desc = '';
  public data: any = null;
  constructor(idIn: string, descIn: string, rawData?: any) {
    if (idIn) {
      this.id = idIn;
    }
    if (descIn) {
      this.desc = descIn;
    }
    if (rawData) {
      this.data = rawData;
    }
  }
}

@Injectable()
export class LookupService {
  static suggestionCache: any = {};
  static cacheOn = false;
  static MandatoryFields: string[] = ['%PRIMARY_KEY'];
  public emptyListData: ListData = {set:{total:1},list:[{item:[{VISIBLE:true,DATA:'',PROPNAME:'',TITLE:' '}]}]};

  constructor(private schemaService: SchemaService, private localizer: LocalizeService) { }

  private getFilteredList(schema: SchemaDef, lookupFormName: string, lib: string, id: string, key: string, value: any, otherfilter: string, query?: string): Promise<any> {
    let secondaryKey: string = null;
    if (Util.RestAPI.restAPIVersion() >= 0x00160500) {
      secondaryKey = Util.FieldMappings.getLookupSecondaryKey(key);
    }
    let filters = '';
    if (key && key.length) {
      if (secondaryKey) {
        filters = key + '=' + value + ' or ' + secondaryKey + '=' + value;
      } else {
        if (key.startsWith('$edx_outlook_email')) {
          filters = Util.FieldMappings.getLookupContactsFilterKey(key) + '=' + value;
        } else {
          filters = key + '=' + value;
        }
      }
      if (otherfilter) {
        filters =  otherfilter + ' and ' +  filters;
      }
      return this.getList(id, lib, lookupFormName, schema, key, filters, query);
    }
    return Promise.resolve({set:{},list:[]});
  }

  private processList(schemaDef: SchemaDef, id: string, lib: string, data: any[]): ListItem[] {
    const list: ListItem[] = [];
    const nItems: number = data.length;
    const nSchemaCols: number = schemaDef.columns.length;
    const nNonSchemaCols: number = schemaDef.leadingCols ? schemaDef.leadingCols.length : 0;
    let value: any;
    let j: number;
    let schemaCol: ColumnDesc;
    let listItem: any;
    for (let i=0; i<nItems; i++) {
      const dataCol = data[i];
      if (dataCol.item && Array.isArray(dataCol.item)) {
        const item = dataCol.item;
        const nColItems: number = item.length;
        listItem = {};
        let currentSchemaCol = 0;
        for (j=0; j<nColItems; j++) {
          const propName: string = item[j].PROPNAME;
          value = item[j].DATA;
          // Allow all fields through. Do not filter VISIBLE=false since some fields
          // may be invisible in the lookup schema but visible in the profile form schema
          schemaCol = item[j].VISIBLE && j<(nSchemaCols-nNonSchemaCols) ? schemaDef.columns[currentSchemaCol+nNonSchemaCols] : null;
          if (schemaCol && propName!==schemaCol.property) {
            listItem[schemaCol.property] = value;
            if (item[j].VISIBLE) {
              currentSchemaCol ++;
            }
          } else {
            if (!listItem[propName]) {
              listItem[propName] = value;
              if (item[j].VISIBLE) {
                currentSchemaCol ++;
              }
            } else {
              if (LookupService.MandatoryFields.indexOf(propName)>=0) {
                listItem[propName] = value;
                if (item[j].VISIBLE) {
                  currentSchemaCol ++;
                }
              }
            }
          }
        }
        listItem.lib = lib;
      } else {
        listItem = dataCol;
      }
      list.push(listItem);
    }
    return list;
  }

  public getList(id: string, lib: string, profile: string, schemaDef: SchemaDef, key: string, filter?: string, query?: string): Promise<ListData> {
    lib = lib ? lib.toUpperCase() : Util.RestAPI.getPrimaryLibrary();
    return Util.RestAPI.getLookupList(id, lib, profile, key, filter, query).toPromise().then((listData: ListData) => {
      listData.list = this.processList(schemaDef, id, lib, listData.list);
      return listData;
    }, error => this.emptyListData);
  }

  public getLookupColumns(id: string, lib: string, profile: string, key: string): Promise<any> {
    lib = lib ? lib.toUpperCase() : Util.RestAPI.getPrimaryLibrary();
    return Util.RestAPI.getLookupColumns(id,lib,profile,key).toPromise().then((data: any) => {
      if (!!data) {
        Util.RestAPI.cacheLookupColumns(id, lib, profile, JSON.stringify(data));
        return data;
      } else {
        return this.emptyListData;
      }
    }, error => this.emptyListData);
  }

  public validate(lookupFormName: string, lib: string, id: string, key: string, value: any, formType: string, otherfilter?: string, query?: string): Promise<any> {
    lib = lib ? lib.toUpperCase() : Util.RestAPI.getPrimaryLibrary();
    let schema: SchemaDef = this.schemaService.getLookupSchema(id, lib, lookupFormName, formType, key);
    if (schema) {
      return this.getFilteredList(schema, lookupFormName, lib, id, key, value, otherfilter, query);
    } else {
      return this.getLookupColumns(id, lib, lookupFormName, key).then(data => {
        schema = this.schemaService.buildLookupSchema(id, lib, lookupFormName, data, null, formType, key);
        return this.getFilteredList(schema, lookupFormName, lib, id, key, value, otherfilter, query);
      }, error => this.emptyListData);
    }
  }


  public getSuggestionCache(key: string): CacheItem[] {
    if (LookupService.cacheOn === false) {
      LookupService.cacheOn = true;
    }
    let suggestions = [];
    if (LookupService.suggestionCache[key] === undefined) {
      LookupService.suggestionCache[key] = [];
    }
    const jsonData = localStorage.getItem(key);
    if (jsonData) {
      try {
        LookupService.suggestionCache[key] = JSON.parse(jsonData);
      } catch (e) {
        LookupService.suggestionCache[key] = [];
      }
    }
    suggestions = LookupService.suggestionCache[key];
    return suggestions;
  }

  public addToSuggestionCache(key: string, id: string, desc: string, rawData?: any): void {
    let found = false;
    const item = new CacheItem(id, desc, rawData);
    let inCache: CacheItem;
    if (LookupService.suggestionCache[key] === undefined) {
      LookupService.suggestionCache[key] = [];
    }
    for (inCache of LookupService.suggestionCache[key]) {
      if (inCache.id.toLowerCase() === item.id.toLowerCase()) {
        found = true;
        break;
      }
    }
    if (found) {
      // Toggle this item to top of list
      const index = LookupService.suggestionCache[key].indexOf(inCache);
      if (index >= 0) {
        LookupService.suggestionCache[key].splice(index, 1);
        LookupService.suggestionCache[key].unshift(item);
      }
    } else {
      LookupService.suggestionCache[key].unshift(item);
    }
    localStorage.setItem(key, JSON.stringify(LookupService.suggestionCache[key]));
  }

  public toggleToTopOfSuggestionCache(key: string, item: CacheItem): void {
    let index = -1;
    if (LookupService.suggestionCache[key] !== undefined) {
      index = LookupService.suggestionCache[key].indexOf(item);
    }
    if (index > 0) {
      LookupService.suggestionCache[key].splice(index,1);
      LookupService.suggestionCache[key].unshift(item);
      localStorage.setItem(key, JSON.stringify(LookupService.suggestionCache[key]));
    } else if (index === -1) {
      this.addToSuggestionCache(key, item.id, item.desc, item.data);
    }
  }

  public removeFromSuggestionCache(key: string, item: CacheItem): void {
    if (LookupService.suggestionCache[key] !== undefined) {
      const index = LookupService.suggestionCache[key].indexOf(item);
      LookupService.suggestionCache[key].splice(index,1);
      localStorage.setItem(key, JSON.stringify(LookupService.suggestionCache[key]));
    }
  }
}
