<div *ngIf="versions.length !== 1" class="version-viewer-container" [ngClass]="{ mobile: ui >= 2, pinned: pinned }"
  (mouseleave)="showVersionsPane(false)">
  <div *ngIf="ui >= 2" class="current-version" [ngClass]="{ mobile: ui >= 2 }" (mouseenter)="showVersionsPane(true)"
    [attr.aria-label]="getAriaLabel(selectedVersion)">
    <img class="image" [ngClass]="{ mobile: ui >= 2 }" src="assets/images/mime_document2.svg" tabindex="0"
      (keydown)="onKeyDown($event)" (focus)="showVersionsPane(true)" alt="{{selectedVersionLabel}}" id="edx-selected-version" />
    <img *ngIf="!!selectedVersion['status_src']" class="statusicon" [ngClass]="{ mobile: ui >= 2}"
      src="{{selectedVersion['status_src']}}" alt="{{formatStatusText(selectedVersion)}}"  />
    <div class="version-label" [ngClass]="{ mobile: ui >= 2, status: !!selectedVersion['status_src'] }">
      {{ selectedVersionLabel }}
    </div>

  </div>

  <div *ngIf="canShow()" class="versions-list-container" [ngClass]="{ mobile: ui >= 2, pinned: pinned }"
    (mouseleave)="showVersionsPane(false)">
    <div *ngIf="ui >= 2" class="versions-header">
      <div class="pin-button" [ngClass]="{pin: pinned, unpin: !pinned}"
        (click)="pinMobileVersionsPane()" (keyup.space)="pinMobileVersionsPane()" (keyup.enter)="pinMobileVersionsPane()"
        tabindex="0"
        id="edx-pin-button" (keydown)="onKeyDown($event)" [attr.aria-label]="pinLabel" role="button" [title]="pinLabel" >
        </div>
    </div>

    <div *ngIf="ui < 2" class="versions-text" >{{versionsText}}</div>
    <div class="versions-list" role="list">
      <div *ngFor="let version of versions; let i = index" class="listitem" role="listitem" [ngClass]="{
          selected: selectedVersionLabel === version['VERSION_LABEL'],
          mobile: ui >= 2,
          pinned: pinned
        }" tabindex="0" id={{getElementId(i)}} (click)="loadViewer(version,i)" (keyup.space)="loadViewer(version,i)"
        (keyup.enter)="loadViewer(version,i)" (keydown)="onKeyDown($event,i)" [attr.aria-label]="getAriaLabel(version)">
        <img class="image" [ngClass]="{ mobile: ui >= 2 }" src="assets/images/mime_document2.svg" alt="{{version['VERSION_LABEL']}}"/>
        <img *ngIf="!!version['status_src']" class="statusicon" [ngClass]="{ mobile: ui >= 2}"
          src="{{version['status_src']}}" alt="{{formatStatusText(version)}}">
        <div class="version-label" [ngClass]="{ mobile: ui >= 2, status: !!version['status_src'] }">
          {{ version["VERSION_LABEL"] }}
          <div></div>
        </div>
      </div>


    </div>
    <div *ngIf="!hideLoadMore()" class="load-more" id="edx-version-viewer-loadmore" (click)="loadMore()" (keyup.space)="loadMore()"
      (keyup.enter)="loadMore()" (keydown)="onKeyDown($event)" [ngClass]="{mobile: ui >= 2}" tabindex="0"
      [attr.aria-label]="loadMoreText" role="button"  [title]="loadMoreText">
      {{ loadMoreText }}
    </div>
  </div>
</div>