/**
 * Created by kevin on 2016-11-10.
 */

import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  ViewChild
} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {AWContacts, AWFileSystem} from 'appworks-js';
import {FormField, FormFieldDesc, FormUtils} from '../models/form-field';
import {OrientationListener, UserInterface, Util} from '../utils/utils.module';
import {BaseDesc} from '../models/base';
import {ColFormat} from '../models/column';
import {PopupCallback} from '../widgets/popup.component';
import {ListTableComponent, ListTableParent} from '../lists/list-table.component';
import {LocalizeService} from '../services/localize.service';
import {ListSecurityComponent} from '../lists/list-security.component';
import {ListItem} from '../models/list-item';
import {AccessLevel, AccessSearch, SecurityControl} from '../models/security-control';
import {ListService} from '../services/list.service';
import {CacheItem, LookupService} from '../services/lookup.service';
import {ListFolderPickerComponent} from '../lists/list-folder-picker.component';
import {FileDropTargetDirective} from '../directives/drag-target.directive';
import { ValidationUtilities } from '../utils/ValidationUtilities';
import { RecentLocationService } from '../services/recent-location.service';

enum ColumnType {
  CT_VARCHAR  = 0,
  CT_DATE = 1,
  CT_TIME = 2,
  CT_INTEGER  = 4,
  CT_DATETIME = 8,
  CT_MULTIVALUE   = 0x80
}

enum FieldType {
  PRIMARY = 0,
  LOOKUP = 4
}

// "Profile Defaults" lookup Name.
// We hard coded this name as it is already documented and clients know that they should only use this name.
// If they use a different name, "Profile Defaults" lookup will not work.
const CUSTOM_DEFAULT_LOOKUP_NAME = 'DEF_LOOK';

export interface FormController {
  fieldChanged?(field: FormField, control: AbstractControl, formFields: FormField[]): boolean;
  buttonClicked?(field: FormField, control: AbstractControl, formFields: FormField[]): void;
  uploadFiles?(files: File[], filePaths?: string[]): void;
  hasBrowserUploadFiles?(): boolean;
  geteDocsUploadFiles?(): string[];
  fileNameForUpload?(): string;
  appIDForUpload?(): string;
  enableOK?(enable: boolean, inital?: boolean): void;
  getRightForm?(): DynamicFormComponent;
  getHeaderForm?(): DynamicFormComponent;
  getFormName?(): string;
  prefillInitialValuesForForm?(): void;
  allowFirstFocus?(): boolean;
  layoutChanged?(layout: string): void;
  securityDirty?(dirty: boolean): void;
  inheritSecurity?(desc): void;
  getApplyAllChecked?(): boolean;
}
@Component({
  selector: 'edx-dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template:  `
    <form #formEl *ngIf="form" novalidate [formGroup]="form" [ngClass]="{edx_hidden:!formIsVisible(), indialog:inDialog, innotify:inNotify, readonly:readOnly, columnview:formLayout==='column', header:formLayout==='header', mobile:ui>=2, phone: ui===2||ui===4, profile:isProfileForm(), inlineparent:inlineParent, fullheight:!!fileDropTarget, persmisionsselector:isPersmisionsSelector, oai:isOfficeAddin, choosershidden:choosersHidden}" [edx-file-drop-target]="fileDropTarget">
      <ng-template ngFor let-field [ngForOf]="fields" let-isFirst="first" let-i="index">
        <div edx-dynamic-form-row *ngIf="canShowField(field)" [field]="field" [form]="form" [formLayout]="formLayout" [showExtras]="extrasShown" [fullWidth]="fullWidth" [inlineForm]="!!inlineParent"
          [itemIndex]="i" [fieldsLength]="fields.length" [inDialog]="inDialog" [inNotify]="inNotify" [parent]="this" [formReadonly]="readOnly" [single]="isSingleField(field)" [first]="isFirst" [rerender]="useForceRender(field)"
          [ngClass]="{header:formLayout==='header', transparent:!field.isVisible}"></div>
      </ng-template>
    </form>
    <div [style.height]="standInBodyHeight + 'px'"></div>
    <edx-popup *ngIf="lookupShown" [callback]="this" [kind]="'list_1'" [title]="lookupTitle" [ok]="lookupOKTitle" [okDisabled]="lookupOKDisabled" [headerform]="true">
      <edx-search-filter secondheader [desc]="lookupDesc" [noWildcard]="lookupNoWildcard" [lookupIsNumeric]="lookupIsNumeric" [lookupFilter]="lookupFilterValue" [selValue]="lookupInitalKey" [searchValue]="lookupInitalSearch" [list]="lookupTable"></edx-search-filter>
      <edx-list-mobile #lookupTable *ngIf="ui>=2" [callback]="this" [selectedLookupValues]="lookupInitalSearch" [desc]="lookupDesc" [lookupForm]="lookupForm" [lookupKey]="lookupInitalKey" [searchLookup]="fieldPrimaryKey" [parent]="this" [leadingColums]="lookupLeadingColums" [formType]="formKind" [hasFootprint]="true" [viewKind]="!!lookupLeadingColums?0:1"></edx-list-mobile>
      <edx-list-table #lookupTable *ngIf="ui<2" [selectedLookupValues]="lookupInitalSearch" [lookupKey]="lookupInitalKey" [searchLookup]="fieldPrimaryKey" [isParentEmpty] = "isLookupParentEmpty" [desc]="lookupDesc" [lookupForm]="lookupForm" [parent]="this" [leadingColums]="lookupLeadingColums" [formType]="formKind"></edx-list-table>
    </edx-popup>
    <edx-popup #pickerPopup *ngIf="securityShown" [callback]="this" [kind]="securityKind" [width]="dialogWidth" [title]="securityTitle" [ok]="securityOK" [okDisabled]="false">
      <edx-list-security  #security
                          [kind]="pickerPopup.kind"
                          [desc]="securityDesc"
                          [securityList]="securityList"
                          [inDialog]="true"
                          [massProfile]="bMassProfileUpdate"
                          [(trusteesDirective)]="trusteesDirective"
                          [parent]="this">
      </edx-list-security>
    </edx-popup>
    <edx-popup #pickerPopup *ngIf="fileplansShown" [callback]="this" [kind]="'list_2_single_filepart'" [width]="dialogWidth" [desc]="fileplansDesc" [title]="fileplansTitle" [ok]="fileplansOK" [okDisabled]="pickerOKDisabled()" [headerform]="false" [headerformOnRight]="false" [levelDropdownOnRight] = "false">
      <edx-list-folder-picker #fileplans *ngIf="fileplansDesc.type==='fileparts'" [kind]="pickerPopup.kind" [desc]="fileplansDesc" [disableList]="pickerDisableList" id="edx_list_picker"></edx-list-folder-picker>
    </edx-popup>
    <edx-spinner *ngIf="formLayout!=='header' && loadingCount>0"></edx-spinner>
  `
})
export class DynamicFormComponent implements OnChanges, OnDestroy, AfterViewChecked, PopupCallback, ListTableParent, OrientationListener {
  @ViewChild('lookupTable') private lookupTable: ListTableComponent;
  @ViewChild('formEl') formEl: ElementRef;
  @ViewChild('security') private security: ListSecurityComponent;
  @ViewChild('fileplans') private fileplans: ListFolderPickerComponent;
  @Input() desc: BaseDesc;
  @Input() data: any = {};
  @Input() formTemplate: any;
  @Input() inDialog?: boolean = false;
  @Input() inNotify?: boolean = false;
  @Input() readOnly?: boolean = false;
  @Input() choosersHidden?: boolean = false;
  @Input() formKind?: string = null;
  @Input() createType?: string = null;
  @Input() controller?: FormController;
  @Input() layout?: string = null;
  @Input() inlineParent?: DynamicFormComponent = null;
  @Input() fileDropTarget?: FileDropTargetDirective = null;
  @Input() tabIndex = 0;
  public form: FormGroup;
  public fullWidth = false;
  public standInBodyHeight = 0;
  public loadingCount = 0;
  public formLayout: string;
  public lookupShown = false;
  public securityShown = false;
  public fileplansShown = false;
  public trusteesDirective = 'replace';
  public originalAuthor: string;
  public isOkClicked = false;
  public inheritSecurityIsChecked = false;
  public inheritFromPrimaryKey = '';
  public inheritFromTrustees: ListItem[];
  public inheritedFlexTrusteeList: ListItem[];
  public profileDefaultTrusteeList: ListItem[];
  public securedFlexFolderLookupIDs = Util.RestAPI.getLoginReply().LOOKUP_SECURITY;
  public securedLookupKeyList = [];
  public userFlexRights: number;
  public isSecurityChanged = false;
  public profileDefaultInfo: any;
  protected userSecurity: SecurityControl = null;
  protected extrasShown = false;
  public fields: FormField[];
  private lookupField: FormField = null;
  private lookupDesc: BaseDesc = {lib:'',id:'',type:'lookups'};
  private lookupForm = '';
  private lookupTitle = '';
  private lookupOKTitle = '';
  private lookupInitalSearch = '';
  private fieldPrimaryKey = '';
  private isLookupParentEmpty = false;
  private lookupInitalKey: string;
  private lookupFilterValue: string;
  private lookupNoWildcard = false;
  private lookupIsNumeric = false;
  private lookupOKDisabled = true;
  private lookupLeadingColums: number[] = null;
  private formScrollTop = 0;
  private electronDialogShown = false;
  private _placeholders: any;
  private _errors: any;
  private initialValue: any;
  private layoutComplete = false;
  private securityDesc: any = {};
  private securityKind = '';
  private securityTitle = '';
  private securityOK = '';
  private securityList: ListItem[];
  public bMassProfileUpdate = false;
  public copyTree = false;
  private dialogWidth: number = Util.kMaxPopupDialogWidth;
  private savedFormData: any = null;
  private blockValidation = 0;
  private savedFilteredCriteria: any = {};
  private ui: UserInterface;
  private isOfficeAddin: boolean;
  private inlineFormField: FormField = null;
  private isPersmisionsSelector = false;
  private fileplansDesc: any = {};
  private fileplansTitle = '';
  private fileplansOK = '';
  private pickerForm: string = null;
  private pickerLeadingColums: number[] = null;
  private pickerDisableList: ListItem[] = null;
  private isOfficeItemOptionChanged = false;
  private filePathSeparator = Util.Transforms.filePathSeparator();
  public userSecurityList: ListItem[];
  public userSecuritySelection = '';

  constructor(protected listService: ListService, private lookupService: LookupService, private localizer: LocalizeService, private cdr: ChangeDetectorRef,
              private recentLocService: RecentLocationService) {
    this._placeholders = {
      readwrite: {
        TEXT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.TEXT'),
        TEXTAREA: this.localizer.getTranslation('FORMS.PLACEHOLDERS.TEXTAREA'),
        NUMBER: this.localizer.getTranslation('FORMS.PLACEHOLDERS.NUMBER'),
        SELECT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.SELECT'),
        DATE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.DATE'),
        FILE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FILE'),
        FOLDER: this.localizer.getTranslation('FORMS.PLACEHOLDERS.FOLDER'),
        EMAIL: this.localizer.getTranslation('FORMS.PLACEHOLDERS.EMAIL'),
        MESSAGE: this.localizer.getTranslation('FORMS.PLACEHOLDERS.MESSAGE'),
        ADVSEARCH_INPUT: this.localizer.getTranslation('FORMS.PLACEHOLDERS.ADVANCESEARCH_INPUT') // for input fields in advancesearch.
      },
      readonly: {
        TEXT: '',
        TEXTAREA: '',
        NUMBER: '',
        SELECT: '',
        DATE: '',
        FILE: '',
        FOLDER: ''
      }
    };
    this._errors = {
      MISSING: this.localizer.getTranslation('FORMS.ERRORS.MISSING'),
      LONG: this.localizer.getTranslation('FORMS.ERRORS.LONG'),
      LOOKUP: this.localizer.getTranslation('FORMS.ERRORS.LOOKUP'),
      FILE_PICKER: this.localizer.getTranslation('FORMS.ERRORS.FILE_PICKER'),
      EMAIL: this.localizer.getTranslation('FORMS.ERRORS.EMAIL'),
      DOMAIN: this.localizer.getTranslation('FORMS.ERRORS.DOMAIN'),
      FOLDER_PICKER: this.localizer.getTranslation('FORMS.ERRORS.FOLDER_PICKER'),
      INVALID_NAME: this.localizer.getTranslation('FORMS.ERRORS.INVALID_NAME'),
      INVALID_DOCNUM: this.localizer.getTranslation('FORMS.ERRORS.INVALID_DOCNUM'),
      INVALID_DATE: this.localizer.getTranslation('FORMS.ERRORS.INVALID_DATE'),
      PFTA_NOT_RUNNING: this.localizer.getTranslation('FORMS.LOCAL.PFTA.NOT_RUNNING'),
      INVALID_FILE: this.localizer.getTranslation('FORMS.ERRORS.INVALID_FILE'),
      FILE_NOTFOUND: this.localizer.getTranslation('FORMS.ERRORS.FILE_NOTFOUND'),
    };
    this.lookupOKTitle = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
    this.ui = Util.Device.ui;
    this.isOfficeAddin = Util.Device.bIsOfficeAddin;
    this.formLayout = (this.ui<2 || this.readOnly) ? 'page' : 'column';
    Util.Device.registerOrientationListener(this);
  }

  ngOnDestroy() {
    Util.Device.deregisterOrientationListener(this);
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if ((!!changes['formTemplate'] || !!changes['data']) && !!this.formTemplate && this.formTemplate.defs) {
      ++this.blockValidation;
      this.initialValue = {};
      let rights = 255;
      if (!!this.data) {
        if (this.data['rights']) {
          rights = this.data['rights'];
        } else if (this.data['%EFFECTIVE_RIGHTS']) {
          rights = this.data['%EFFECTIVE_RIGHTS'];
        }
        if (this.formKind.startsWith('profile') && !this.createType && !!this.data['DOCUMENTS'] && this.data['DOCUMENTS'].length > 1) {
          this.bMassProfileUpdate = true;
        }
        this.copyTree = this.formKind === 'profile_copy_tree';
        if (this.copyTree) {
          this.data = null; // by making data null, form won't be prefilled in updateFormData method
        }
      }
      this.isPersmisionsSelector = this.formTemplate.name.startsWith('permissions_selector');
      if (!this.userSecurity) {
        this.userSecurity = new SecurityControl(rights);
      } else {
        this.userSecurity.access = rights;
      }
      if (this.formKind.startsWith('profile') || this.formKind === `${Util.kLocalFormPrefix}permissions_selector`) {
        const canUserControlAccess = this.userSecurity.canControlAccess || (this.desc.type === 'searches' && (rights & AccessSearch.VIEW_EDIT) === AccessSearch.VIEW_EDIT)
        if (!this.userSecurity.canEditProfile) {
          this.readOnly = true;
        } else if (!canUserControlAccess) {
          if (this.isPersmisionsSelector) {
            this.readOnly = true;
          }
        }
      }
      if (!!changes['formTemplate']) {
        if (this.formTemplate.extrasshown && !this.extrasShown) {
          this.extrasShown = true;
        }
        this.fullWidth = this.formTemplate.fullwidth;
        this.createFieldsFromTemplate(this.formTemplate.defs);
        this.form.setValue(this.initialValue);
      }
      if (this.data) {
        if (this.desc && this.desc.type === 'workspaces') {
          if (!this.data['X1237']) {
            this.data['X1237'] = this.data['DOCNAME'];
          }
        }
        if (this.formKind === 'profile' || this.formKind === 'profile_uploadfolders' || this.formKind === 'profile_folderfiles' || this.formKind === 'profile_copy' || this.formKind === 'profile_savetoedocs') {
          if (this.createType === 'documents' || this.createType === 'folders' || this.createType === 'workspaces') {
            this.data['LASTEDITDATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_EDIT_DATE'] = Util.Transforms.currentDateTime();
            this.data['CREATION_DATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_PRF_EDIT_DATE'] = Util.Transforms.currentDateTime();
            this.data['LAST_EDITED_BY'] = '';
            this.data['LAST_EDIT_ID'] = '';
            this.data['LAST_PROF_EDIT_ID'] = '';
            this.data['LAST_PRF_EDITED_BY'] = '';
            this.data['TYPIST_ID'] = '';
            this.data['STATUS'] = '';

            const loginReply: any = Util.RestAPI.getLoginReply();
            if (loginReply && loginReply['EFFECTIVE_RIGHTS']['VIEW_UNSECURED'] === 'N') {
              this.data['SECURITY'] = '1';
            }
          }
          Util.FieldMappings.forEachTemplateField(this.fields, false, (field): boolean => {
            if (field.controltype === 'editdate') {
              const name: string = field.name;
              const value: string = this.data[name];
              if (!!value) {
                this.data[name] = this.formatDate(value, field.datatype === '1');
              }
            }
            return true;
          });
        }
        if (this.formKind === 'profile_query_edit') {
          this.formatDateFieldsData();
        }

        this.updateFormData(this.data, false, this.formKind.startsWith('__local_'));
        this.originalAuthor = this.data['AUTHOR_ID'];
      }
      if (this.formKind.startsWith('profile')) {
        if (!!this.createType) {
          if (this.createType !== 'folders' && this.createType !== 'workspaces' && !!this.desc && !!this.desc.id && this.desc.id !== 'recentedits' && !Util.isExternalLib(this.desc.lib)) {
            this.data['%RECENTLY_USED_LOCATION'] = this.recentLocService.getRecentLocationItemInJSON(this.desc);
          }
          if ((this.createType === 'workspaces') && (this.desc.id === '')) {
            this.updateControlValue('SECURITY', '1', true);
          } else {
            const securityControl = this.getControl('SECURITY');
            if (!!securityControl) {
              securityControl.markAsDirty();
            }
          }
          const keys: string[] = this.initialValue ? Object.keys(this.initialValue) : [];
          for (const fieldName of keys) {
            const field: any = fieldName !== 'DOCNAME' && fieldName !== 'X1237' && !fieldName.startsWith('$edx') ? this.getField(fieldName) : null;
            if (field && field.isRequired) {
              const control: FormControl = this.form.controls[fieldName] as FormControl;
              if (control && control.value && control.value.length) {
                control.markAsDirty();
              }
            }
          }
          if (this.createType === 'documents' && this.data['PD_FILEPT_NO']) {
            const fp_control: FormControl = this.form.controls['PD_FILEPT_NO'] as FormControl;
            if (fp_control) {
              fp_control.markAsDirty();
            }
          }
        } else if (this.bMassProfileUpdate && this.desc.type === 'workspaces') {
          this.updateControlValue('SECURITY', '1', true);
        }
        // To set the current user id for created by only when creating documents,folders,workspaces and while copying
        if ((this.formKind === 'profile_copy' || this.createType === 'folders' || this.createType === 'documents' || this.createType === 'workspaces') && this.formKind.startsWith('profile') && !this.formKind.startsWith('profile_query')) {
          this.updateControlValue('TYPIST_ID', Util.RestAPI.getUserID(), true);
        }
        if (this.createType === 'folders') {
          const folderName = Util.RestAPI.getDocNameForUploadFolder();
          if (folderName) {
            this.updateControlValue('DOCNAME', folderName, true);
          }
          this.updateControlValue('APP_ID', 'FOLDER', true);
          this.updateControlValue('FULLTEXT', 'Y', true);
        } else if (this.createType === 'documents') {
          this.fillNameAndAppID();
          this.updateControlValue('FULLTEXT', 'Y', true);
        }
        if (this.savedFormData && !!changes['formTemplate'] && !! changes['data']) {
          const keys: string[] = Object.keys(this.savedFormData);
          for (const key of keys) {
            if (this.formKind === 'profile_query' || (!!Util.FieldMappings.templateField(this.formTemplate.defs, key) && ((!this.getControlValue(key) && !!this.data[key]) || (this.isOfficeItemOptionChanged && this.isOfficeAddin && key !== 'APP_ID') || key === 'RETENTION' || key === 'DOCNAME'))) {
              this.updateControlValue(key, this.savedFormData[key], true);
            }
          }
          this.savedFormData = null;
        }
        if (this.controller && this.controller.prefillInitialValuesForForm) {
          this.controller.prefillInitialValuesForForm();
        } else if (this.formKind === 'profile_query' && !this.data['SEARCH_IN']) {
          // To set the default value for search in dropdown.
          this.updateControlValue('SEARCH_IN', '2', true);
        }
        if (this.formKind==='profile_copy' || this.copyTree || (this.ui>=2 && this.readOnly && this.formKind.startsWith('profile'))) {
          this.setFieldVisibility('DOCNAME', false);
        }
      }
      if (this.formKind === '__local_editsecurity') {
        // Marka a hidden security field as dirty so that we can enable the OK button
        const dummyField_control: FormControl = this.form.controls['SECURITY'] as FormControl;
        if (dummyField_control) {
          dummyField_control.markAsDirty();
        }
      }
      setTimeout(() => {
        --this.blockValidation;
        if (this.controller) {
          if (this.controller.enableOK) {
            setTimeout(() => {
              this.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
          if (this.controller.allowFirstFocus && this.controller.allowFirstFocus()) {
            setTimeout(() => {
              this.focusOnNextEmptyEditable();
            }, 500);
          }
        }
      }, 1);
    }
    this.setLayoutMode();
    if (this.desc) {
      this.lookupDesc.lib = this.desc.lib;
      if (this.isOfficeAddin && this.desc['name'] === 'txt' && this.savedFormData && this.savedFormData['APP_ID']) {
        this.savedFormData['APP_ID'] = '';
      }
    }
    this.cdr.markForCheck();
  }

  ngAfterViewChecked(): void {
    if (!this.layoutComplete) {
      setTimeout(() => {
        this.setLayoutMode();
      }, 1);
    }
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.layoutComplete = false;
    this.setLayoutMode();
  }

  public deviceDidRotate(isPortrait: boolean): void {  // work around iOS/Android cordova crap
    this.cdr.markForCheck();
  }

  private prepareDialogOnDialog(): void {
    if (Util.Device.bIsIOSDevice && !Util.Device.isPhoneLook() && this.formEl && this.formEl.nativeElement) {
      const ele = this.formEl.nativeElement;
      let parentHeight = 0;
      let parent: any = ele.parentElement;
      while (parent) {
        const classList = parent.classList;
        if (classList && classList.contains('body')) {
          parentHeight = parent.clientHeight;
          break;
        }
        parent = parent.offsetParent ? parent.offsetParent : parent.parentElement;
      }
      this.standInBodyHeight = parentHeight;
    }
  }

  private pickerOKDisabled(): boolean {
    return (this.fileplans) ? this.fileplans.okDisabled : false;
  }

  public placeholders(readonly: boolean, extension?: boolean): any {
    if (extension) {
      return '';
    }
    if (readonly) {
      return this._placeholders.readonly;
    } else {
      return this._placeholders.readwrite;
    }
  }

  public setEditable(editable: boolean): void {
    if (this.readOnly===editable) {
      this.readOnly = !editable;
      // loop through all fields and disable/enable controls
      if (!!this.fields && this.fields.length) {
        Util.FieldMappings.forEachTemplateField(this.fields, false, (field): boolean => {
          const control: AbstractControl = this.getControl(field.name);
          if (control) {
            if (this.readOnly || field.isReadonly) {
              control.disable();
            } else {
              control.enable();
            }
          }
          return true;
        });
        this.onResize();
        this.cdr.markForCheck();
      }
    }
  }

  public setFieldEditable(fieldName: string, editable: boolean): void {
    const field: FormField = this.getField(fieldName);
    if (!!field && field.isReadonly === editable) {
      field.isReadonly = !editable;
      const control: AbstractControl = this.getControl(field.name);
      if (control) {
        if (this.readOnly || field.isReadonly) {
          control.disable();
        } else {
          control.enable();
        }
      }
      this.onResize();
      this.cdr.markForCheck();
    }
  }

  public fillNameAndAppID(): void {
    const fileName: string = this.controller.fileNameForUpload ? this.controller.fileNameForUpload() : null;
    const isApplyAllChecked = this.controller.getApplyAllChecked ? this.controller.getApplyAllChecked() : null;
    if (!(!!this.savedFormData && this.savedFormData['DOCNAME'] !== fileName && isApplyAllChecked) && fileName) {
      this.updateControlValue('DOCNAME', fileName, true);
    }
    let appID: string = null;
    if (!!this.data['STORAGE'] && this.data['STORAGE']==='P') {
      appID = 'Paper';
    } else {
      appID = this.controller.appIDForUpload ? this.controller.appIDForUpload() : 'DEFAULT';
    }
    if (appID && appID.length) {
      const appIDs: string[] = appID.split(Util.RestAPI.kMultiFileSeparator);
      const nAppIDs: number = appIDs.length;
      let knownAppID: string = null;
      for (let i=0; i<nAppIDs; i++) {
        if (appIDs[i] !== 'DEFAULT') {
          knownAppID = appIDs[i];
          break;
        }
      }
      if (knownAppID) {
        for (let i=0; i<nAppIDs; i++) {
          if (appIDs[i] === 'DEFAULT') {
            appIDs[i] = knownAppID;
          }
        }
        appID = appIDs.join(Util.RestAPI.kMultiFileSeparator);
      }
    }
    if (appID?.indexOf('DEFAULT') === -1 && appID?.indexOf(Util.RestAPI.kMultiAppIdSeparator) === -1) {
      this.updateControlValue('APP_ID', appID, true);
    } else {
      // force the control to be required
      const appIDField: FormField = this.getField('APP_ID');
      if (appIDField) {
        if (!appIDField.isRequired) {
          appIDField.isRequired = true;
        }
        if (appIDField.isReadonly) {
          appIDField.isReadonly = false;
        }
      }
      const control: FormControl = this.form.controls['APP_ID'] as FormControl;
      if (control) {
        control.enable();
      }
      this.updateControlValue('APP_ID', '', true);
    }
    if (this.data) {
      this.data['APP_ID'] = appID;
    }
  }

  protected formatDate(dateStr: string, dateOnly?: boolean): string {
    return Util.Transforms.formatDate(dateStr,dateOnly);
  }

  private formIsVisible(): boolean {
    return this.form && (!this.lookupShown || !Util.Device.isPhoneLook()) && (!this.fileplansShown || !(Util.Device.bIsIOSDevice && !Util.Device.isPhoneLook()));
  }

  private isSingleField(field: FormField): boolean {
    const extFieldType = !!field.fields && field.fields.length===1 ? field.fields[0].controltype : null;
    return this.isPersmisionsSelector || (!!field.name && field.name.startsWith('$edx_email')) || (this.fields.length===1 && (!field.hasExtension || (extFieldType === 'push' && field.controltype === 'push')));
  }

  public canShowField(field: FormField): boolean {
    if (this.formKind.startsWith('profile_query')) {
      return field.isVisible;
    } else if (this.formKind.startsWith('profile')) {
      const lib = this.desc ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
      let bCanShow: boolean = field.isVisible && (field.isRequired || field.isReadonly || field.isEnabled) && (!field.isLocked || field.isCombo || field.isEnabled);
      if (bCanShow && field.controltype==='box' && field.fields) {
        bCanShow = false;
        for (const subfield of field.fields) {
          if (subfield.isVisible && (subfield.isRequired || subfield.isReadonly || subfield.isEnabled)) {
            bCanShow = true;  // at lease one renderable sub field
            break;
          }
        }
      }
      return bCanShow;
    }
    return field.isVisible;
  }

  public calcBorders(field: FormField): number {
    let borders = 0;
    // set boxBorders to 1 for top and 2 for bottom if last previous visible field was a box or no previous field then no top else if is last field then no bottom
    if (field.controltype === 'box' && field.isVisible && field.fields && field.fields.length) {
      const findIt = (fields: FormField[]) => {
        let previousVisField: FormField = null;
        const nFields: number = fields.length;
        // find out if this is the last shown field
        for (let i=0; i<nFields; i++) {
          const curField = fields[i];
          if (field === curField) {
            let nextVisSibling: FormField;
            for (let j=i+1; j<nFields; j++) {
              if (this.canShowField(fields[j])) {
                nextVisSibling = fields[j];
                break;
              }
            }
            if (!!previousVisField) {
              borders = previousVisField.controltype === 'box' ? (!nextVisSibling ? 0 : 2) : (!nextVisSibling ? 1 : 3);
            } else {
              borders = !nextVisSibling ? 0 : 2;
            }
            break;
          } else if (this.canShowField(curField)) {
            previousVisField = curField;
            if (curField.fields && curField.fields.indexOf(field) !== -1) {
              findIt(curField.fields);
            }
          }
        }
      };
      findIt(this.fields);
    }
    return borders;
  }

  public isGroupInGroup(field: FormField): boolean {
    let parentGroup: FormField = null;
    const findIt = (fields: FormField[]) => {
      const nFields: number = fields.length;
      // find out if this is the last shown field
      for (let i=0; i<nFields; i++) {
        const curField = fields[i];
        if (field === curField) {
          break;
        } else if (curField.fields) {
          if (curField.fields.indexOf(field) !== -1) {
            parentGroup = curField;
            break;
          } else {
            findIt(curField.fields);
          }
        }
        if (!!parentGroup) {
          break;
        }
      }
    };
    findIt(this.fields);
    return !!parentGroup;
  }

  public isInitiallyOpen(field: FormField): boolean {
    return this.getSetGroupBoxOpen(false, field);
  }

  public groupBoxOpenToggled(field: FormField, open: boolean): void {
    this.getSetGroupBoxOpen(true, field, open);
  }

  private getSetGroupBoxOpen(set: boolean, field: FormField, open?: boolean): boolean {
    const key: string = 'edx_fos_'+this.formKind+'_'+this.getFormName()+'_'+this.fields.indexOf(field);
    let isOpen: boolean;
    if (set) {
      localStorage.setItem(key, open?'true':'false');
      isOpen = open;
    } else {
      const value: string = localStorage.getItem(key);
      isOpen = value !== 'false';
    }
    return isOpen;
  }

  public focusOnNextEmptyEditable(field?: FormField): void {
    const allFocusableElements =
      this.formEl && this.formEl.nativeElement
        ? this.formEl.nativeElement.querySelectorAll("[tabindex='0']")
        : null;
    const inputs = [...allFocusableElements].filter(
      (el) =>
        el['tagName'] != 'LI' &&
        el['tagName'] != 'SUMMARY' &&
        !el.classList.contains('lookupbutton')
    );
    const input: HTMLInputElement = this.findNextFocusableInputInForm(inputs, field);
    if (input) {
      input.focus();
    }
  }

  private findNextFocusableInputInForm(inputs: any, field?: FormField): HTMLInputElement {
    let input: HTMLInputElement = null;
    const focusedElementIndex = [...inputs].findIndex(el => el.id == field?.name);
    const nInputs: number = inputs ? inputs.length : 0;
    if (focusedElementIndex !== -1) {
      for (let i=focusedElementIndex; i<nInputs; i++) {
        input = inputs[i] as HTMLInputElement;
        if (!input.disabled && !input.value) {
          if (Util.Device.isMobile() && !Util.Device.bIsOfficeAddin) {
            const field = this.getField(input.name);
            if (!!field && field.isRequired) {
              break;
            }
          } else {
            break;
          }
        }
        input = null;
      }
    } else {
      input = null;
    }
    return input;
  }

  private setLayoutMode(): void {
    if (this.layout) {
      this.formLayout = this.layout;  // programmer override
      if (this.controller && this.controller.layoutChanged) {
        this.controller.layoutChanged(this.formLayout);
      }
      this.layoutComplete = true;
    } else if (!this.layoutComplete) {
      if (this.formEl && this.formEl.nativeElement && this.formEl.nativeElement.offsetWidth) {
        const width: number = this.formEl.nativeElement.offsetWidth;
        const minWidth = 640;
        let layout: string = width > minWidth ? 'page' : 'column';
        if (this.inlineParent && this.inlineParent.formLayout) {
          layout = this.inlineParent.formLayout;  // use parent
        }
        if (layout !== this.formLayout) {
          this.formLayout = layout;
          if (this.controller && this.controller.layoutChanged) {
            this.controller.layoutChanged(this.formLayout);
          }
          this.cdr.markForCheck();
        }
        this.layoutComplete = true;
      }
    }
  }

  private isProfileForm(): boolean {
    return FormUtils.isProfileForm(this.formKind);
  }

  private isSearchForm(): boolean {
    return FormUtils.isSearchForm(this.formKind);
  }

  private isLookupForm(): boolean {
    return FormUtils.isLookupForm(this.formKind);
  }

  private isProfileDefaultsForm(): boolean {
    return FormUtils.isProfileDefaultsForm(this.formKind);
  }

  public isProfileDefaultSearchForm(): boolean {
    return FormUtils.isProfileDefaultSearchForm(this.formKind);
  }

  private updateFormData(data: any, blockValidation?: boolean, makeDirty?: boolean): void {
    if (blockValidation) {
      ++this.blockValidation;
    }
    this.form.patchValue(data, blockValidation ? { emitEvent: false } : {});
    if (makeDirty) {
      for (const field of this.fields) {
        if (!!data[field.name]) {
          const cntrl = this.form.controls[field.name];
          if (!!cntrl) {
            cntrl.markAsDirty();
            break;
          }
        }
      }
    }
    if (blockValidation) {
      setTimeout(() => {
        --this.blockValidation;
        if (this.blockValidation === 0) {
          if (!!data['%CHECKIN_DATE']) {
            const formControl: FormControl = this.form.controls['%CHECKIN_DATE'] as FormControl;
            this.validateField(formControl, this.fields.find(f => f.name === '%CHECKIN_DATE'));
          }
          const checkinLocationName = '%CHECKIN_LOCATION';
          if (this.fields.find(f => f.name === checkinLocationName)) {
            const hasPFTA = Util.RestAPI.hasPFTA();
            const nSelectedItems = this.data?.['DOCUMENTS']?.length ?? 0;
            const isRunOnMobile = (Util.Device.isMobile() && (Util.Device.bIsAndroid || Util.Device.bIsIOSDevice));
            if ((isRunOnMobile && Util.Device.bIsCordova) || (hasPFTA && (nSelectedItems > 1) && (this.formKind === Util.kLocalFormPrefix + 'checkin'))) {
              this.setFieldVisibility(checkinLocationName, false);
            } else {
              this.fireValidation(checkinLocationName);
              if (!hasPFTA && !Util.Device.bIsElectron) {
                this.makeControlNonEditable(checkinLocationName);
              }
            }
          }
        }
      }, 1);
    }
  }

  private makeControlNonEditable(controlName: string) {
    const controlElement: HTMLElement = document.getElementById(controlName);
    controlElement.setAttribute('disabled', 'true');
    controlElement.classList.add('non-editable');
    setTimeout(() => {
      this.cdr.markForCheck();
    }, 1);
  }

  private fireValidation(controlName: string): void {
    const control: AbstractControl = this.form.controls[controlName];
    const field = this.fields.find(f => f.name === controlName);
    if (control && control.asyncValidator && field) {
      const controlValue = control.value;
      field.defaultValue = controlValue + ' ';
      control.markAsTouched();
      control.setValue(controlValue);
      field.defaultValue = controlValue;
      setTimeout(() => {
        this.cdr.markForCheck();
      }, 1);
    }
  }

  private createFieldHook(field: FormField): void {
    const appID = !!this.data ? this.data['APP_ID'] : null;
    const hiddenTypes = ['%FORM_PAPER_APPLICATION','%PRIMARY_FORM','PAPER','Paper','DEFAULT'];
    if (this.formKind.startsWith('profile_editdefs_') && !!appID && field.name==='APP_ID' && hiddenTypes.indexOf(appID)>=0) {
      field.isVisible = false;
    }
  }

  public createFieldsFromTemplate(fields: FormFieldDesc[], securityProp?: any): void {
    const isProfileForm: boolean = this.isProfileForm();
    const isSearchForm: boolean = this.isSearchForm();
    const isLookupForm: boolean = this.isLookupForm();
    const isProfileDefaultsForm: boolean = this.isProfileDefaultsForm();
    const isProfileDefaultSearchForm: boolean = this.isProfileDefaultSearchForm();
    const isExtForm: boolean = this.formKind.startsWith(Util.kCustomFormPrefix);
    const group: any = {};
    const extensionFields: FormField[] = [];
    this.fields = [];
    this.form = null;
    this.securedLookupKeyList = [];
    this.inheritFromPrimaryKey = '';
    this.inheritFromTrustees = [];
    this.inheritedFlexTrusteeList = [];
    if (fields && fields.length) {
      fields.forEach(field => {
        // create single FormField and add it to the fields array
        let bAddField = false;
        const newField: FormField = new FormField(field, this.formKind);
        this.createFieldHook(newField);
        if ((!isSearchForm && !isExtForm && !isLookupForm && !newField.lookup && !!Util.FieldMappings.fieldForDescription(newField)) || newField.extensionParent) {
          extensionFields.push(newField);
        } else if ((!!field.mvinfo || field.name === '%KEYWORD') || (field.fldtype !== 'buffer' && field.fldtype !== 'line' && !(isProfileForm && ((field.name === 'TRUSTEES' && field.fldtype === 'push') || (!isSearchForm && !isProfileDefaultSearchForm && field.name === 'SECURITY' && field.fldtype === 'checkbox'))) && !(field.fldtype === 'push' && this.readOnly) && field.name !== 'EMAIL_BOX')) {
          bAddField = true;
        }
        if ((field.name === 'DOCNAME' && this.formKind !== '__local_copy' && this.desc && this.desc['STATUS'] === '3') || (!isSearchForm && !isProfileDefaultSearchForm && field.name === 'APP_ID')) {
          newField.isReadonly = true;
        }
        // create single FormControl and add to group
        if (!field.fields || field.fields.length === 0) {
          if (newField.controltype !== 'push') {
            const control: FormControl = new FormControl(field.name, this.getValidator(newField), this.getAsyncValidator(newField));
            if (this.readOnly || newField.isReadonly) {
              control.disable();
            }
            if (isProfileDefaultsForm) {
              this.initializeDefaultValueForProfileDefaults(field);
            }
            this.initialValue[field.name] = !isSearchForm && !this.copyTree && field.value ? field.value : '';
            group[field.name] = control;
            //Display the document number field in the default fields list for search forms
            if (newField.isRequired || (isSearchForm && field.name === 'DOCNUM')) {
              newField.isRequired = !isSearchForm && !this.bMassProfileUpdate && !this.copyTree;
            } else if (isProfileForm && (this.ui<2 || field.name !== 'DOCNUM' || !this.readOnly) && !isLookupForm) {
              newField.isOption = true;
            }
          } else if (isProfileForm) {
            newField.isOption = true;
          }
          if (newField.lookup && this.data && this.data[newField.name]) {
            newField.defaultValue = this.data[newField.name];
            if (newField.controltype === 'editdate') {
              if (newField.defaultValue.substring(0,4) === '1753') {
                newField.defaultValue = '';
              }
            }
            // Need to store secured flex folder lookup info
            const foundLookupID = this.securedFlexFolderLookupIDs?.find(key => key === newField.name);
            if (this.desc?.type !== 'lookups' && !this.copyTree && foundLookupID && this.formKind !== 'profile_copy' && !this.isProfileDefaultSearchForm() && !isSearchForm) {
              this.saveSecuredFlexLookupInfo(foundLookupID, newField);
            }
          }
        } else {
          const createSubs = (aNewField: FormField, aField: FormFieldDesc): boolean => {
            // loop through subfields and similarly create fields and controls
            const subFields: FormField[] = [];
            let nRequiredSubFields = 0;
            let rc = true;
            aField.fields.forEach(subfield => {
              // create FormField for each item
              if (isProfileForm && ((subfield.name === 'TRUSTEES' && subfield.fldtype ==='push') || (subfield.name === 'SECURITY' && subfield.fldtype === 'checkbox'))) {
                return false;
              }
              const newSubField: FormField = new FormField(subfield, this.formKind);
              this.createFieldHook(newSubField);
              if ((!isSearchForm && !!Util.FieldMappings.fieldForDescription(newSubField)) || newSubField.extensionParent) {
                extensionFields.push(newSubField);
              } else {
                subFields.push(newSubField);
              }
              if ((subfield.name === 'DOCNAME' && this.desc && this.desc['STATUS'] === '3') || (!isSearchForm && !isProfileDefaultSearchForm && subfield.name === 'APP_ID')) {
                newSubField.isReadonly = true;
              } else if (subfield.name === 'PD_VREVIEW_DATE') {
                newSubField.isReadonly = false;
              }
              if (!subfield.fields || subfield.fields.length === 0) {
                // create FormControl and add to outer group
                if (newSubField.controltype !== 'push') {
                  const control: FormControl = new FormControl(subfield.name, this.getValidator(newSubField), this.getAsyncValidator(newSubField));
                  if (this.readOnly || newSubField.isReadonly) {
                    control.disable();
                  }
                  if (isProfileDefaultsForm) {
                    this.initializeDefaultValueForProfileDefaults(subfield);
                  }
                  this.initialValue[subfield.name] = !isSearchForm && !this.copyTree && subfield.value ? subfield.value : '';
                  group[subfield.name] = control;
                  if (newSubField.isRequired || (isSearchForm && newSubField.name === 'DOCNUM')) {
                    newSubField.isRequired = !isSearchForm && !this.bMassProfileUpdate && !this.copyTree;
                    ++nRequiredSubFields;
                  } else if (isProfileForm) {
                    newSubField.isOption = true;
                  }
                } else if (isProfileForm) {
                  newSubField.isOption = true;
                }
                if (newSubField.lookup && this.data && this.data[newSubField.name]) {
                  newSubField.defaultValue = this.data[newSubField.name];
                  if (newSubField.controltype === 'editdate') {
                    if (newSubField.defaultValue.substring(0,4) === '1753') {
                      newSubField.defaultValue = '';
                    }
                  }
                  // Need to store secured flex folder lookup info
                  const foundLookupID = this.securedFlexFolderLookupIDs?.find(key => key === newSubField.name);
                  if (foundLookupID && !this.copyTree && this.formKind !== 'profile_copy' && !this.isProfileDefaultSearchForm() && !isSearchForm) {
                    this.saveSecuredFlexLookupInfo(foundLookupID, newSubField);
                  }
                }
              } else {
                rc = createSubs(newSubField, subfield);
              }
            });
            if (nRequiredSubFields===0 && isProfileForm) {
              aNewField.isOption = true;
            }
            aNewField.fields = subFields;
            if (subFields.length===0) {
              rc = false;
            }
            return rc;
          };
          bAddField = createSubs(newField, field);
        }
        if (bAddField) {
          this.fields.push(newField);
        }
      });
    }
    if (extensionFields.length>0) {
      this.fullWidth = false;
      const descriptionForFieldList = extensionFields.filter(f => !!f.descriptionForField);
      const extParentFieldList = extensionFields.filter(f => !!f.extensionParent);
      const mappedFieldList = extensionFields.filter(f => !f.descriptionForField && !f.extensionParent);
      let extField: FormField;
      let parentField: FormField;
      for (extField of descriptionForFieldList) {
        parentField = this.getField(Util.FieldMappings.fieldForDescription(extField));
        if (parentField) {
          parentField.addExtensionField(extField, true);
        }
      }
      for (extField of extParentFieldList) {
        parentField = this.getField(extField.extensionParent);
        if (parentField) {
          parentField.addExtensionField(extField, extField.isReadonly);
        }
      }
      for (extField of mappedFieldList) {
        parentField = this.getField(Util.FieldMappings.fieldForDescription(extField));
        if (parentField && this.getFormName() === 'PD_FILE_PART_PROF' && !Util.FieldMappings.isSqlInfoMatchesTillParentNode(parentField.sqlinfo, extField.sqlinfo)) {
          parentField = this.getFieldMatchingSqlInfo(extField.sqlinfo);
        }
        if (parentField && this.getField(parentField.name) && (!parentField.fields || !parentField.fields.length)) {
          parentField.addExtensionField(extField, true);
        } else {
          this.fields.push(extField);
        }
      }
    }
    if (isProfileForm) {
      let control: FormControl;
      if (!isSearchForm && !isProfileDefaultSearchForm) {
        // add in an invisible security field (for profile forms only) and form_name field for both profile and search forms.
        control = new FormControl('SECURITY');
        // security value should be null as the security field is a tristate checkbox in search forms and not an invisible field.
        this.initialValue['SECURITY'] = null;
        group['SECURITY'] = control;
      }
      control = new FormControl('form_name');
      this.initialValue['form_name'] = this.getFormName();
      group['form_name'] = control;
    }
    this.form = new FormGroup(group);
    if (securityProp && securityProp['securityField']) {
      this.fields = securityProp['securityField'];
      this.form = securityProp['form'];
    }
    setTimeout(() => {
      if (this.securedLookupKeyList.length) {
        this.findSecuredFlexLookup();
      }
    }, 1000);
  }

  private initializeDefaultValueForProfileDefaults(field): void {
    if (field && field.fldtype === 'checkbox') {
      field.value = '';
    }
    if (field.fldtype === 'edit' && field.selections?.length) {
      field.selections.unshift({ display: '\0', value:'' });
      field.value = '';
    }
  }

  protected getFormName(): string {
    let formName: string =  this.controller && this.controller.getFormName  ? this.controller.getFormName() : this.desc['FORM_NAME'];

    if (!formName) {
      formName = 'DEFAULT_PROFILE';
      const defProfFrom: any = this.formKind.startsWith('profile_query') ? Util.RestAPI.getDefaultSearchForm() : Util.RestAPI.getDefaultProfileForm();
      if (defProfFrom && defProfFrom.id) {
        formName = defProfFrom.id;
      }
    }
    return formName;
  }

  public useForceRender(field: FormField): number {
    if (field.lookupData !== null || field.rerender !== field.lastRerenderIndex) {
      if (field.rerender === field.lastRerenderIndex) {
        ++field.rerender;
      }
      return field.rerender;
    }
    return 0;
  }

  public needsAsyncValidator(field: FormField): boolean {
    const isSearchForm: boolean = this.isSearchForm();
    // To validate CHECKIN_LOCATION
    if (!isSearchForm && field.lookup &&
      (!field.lookup.startsWith('$edx_') ||
       field.name === '%CHECKIN_LOCATION' ||
       field.lookup ==='$edx_contacts')) {
        return true;
    } else {
      return false;
    }
  }

  protected getValidator(field: FormField): ValidatorFn {
    if (!this.needsAsyncValidator(field)) {
      const that: DynamicFormComponent = this;
      return (c: FormControl): ValidationErrors => {
        if (this.blockValidation === 0 || (field.controltype === 'combo' && field.isRequired)) {
          return that.validateField(c, field);
        }
      };
    }
    return null;
  }

  protected getAsyncValidator(field: FormField): AsyncValidatorFn {
    if (this.needsAsyncValidator(field)) {
      return (control: FormControl): Promise<ValidationErrors> => {
        if (this.blockValidation === 0 && !control.pristine && control.dirty) {
          const value: any = control.value;
          if (field.name === '%CHECKIN_LOCATION') {
            return this.validatePath(control, field, value);
          } else if (!!value && field.defaultValue !== value) {
            return this.validateLookup(control, field, value);
          }
        }
        return new Promise(resolve => {
          let result= null;
          if(field.lookup === '$edx_contacts') {
            if(field.errormessage != null && field.errormessage != '') {
              result = { INVALID: field.errormessage };
            }
          }
          resolve(this.finishValidateField(field, field.errormessage, result));
        });
      };
    }
    return null;
  }

  protected validatePath(control: FormControl, field: FormField, value: any): Promise<ValidationErrors> {
    // To validate if valid location is provided.
    // first check sync if we have any value
    const error: ValidationErrors = this.validateField(control, field);
    if (error) {
      return new Promise(resolve => {
        resolve(error);
      });
    }
    const isFilePicker: boolean = (field.lookup === '$edx_file_picker');
    if (isFilePicker && this.controller && this.controller.hasBrowserUploadFiles && this.controller.hasBrowserUploadFiles()) {
      this.makeControlNonEditable(field.name);
      return Promise.resolve(this.finishValidateField(field, null, null));
    } else {
      let eDocsFileName = '';
      if (isFilePicker && this.controller && this.controller.geteDocsUploadFiles) {
        const files = this.controller.geteDocsUploadFiles();
        //DMIC-9873 Now we support only single checkin. This will be fixed on task DMIC-10084
        eDocsFileName = (files.length > 0 ? files[0].split(this.filePathSeparator).pop() : '');
      }
      return Util.RestAPI.isValidPath(value, field.lookup, eDocsFileName).then(validation => {
        let message: string = null;
        let result: any = null;
        if (!validation.isValid) {
          message = this._errors[validation.errorCode];
          result = { INVALID: message };
          setTimeout(() => {
            this.cdr.markForCheck();
          }, 1);
        }
        return this.finishValidateField(field, message, result);
      });
    }
  }

  protected setParentFilter(field: FormField): string {
    let filter = '';
    let and = '';
    const lookupFormName: string = this.getFormName();
    while (field) {
      const parentField: FormField = this.getField(field.parentField);
      if (parentField) {
        let parent_filter = null;
        const control: FormControl = this.form.controls[parentField.name] as FormControl;
        if (control && control.value) {
          // Must url encode % sign because if the form starts with valid hex values url encoder will
          // ignore the % sign and assume that is already encoded !!!
          // Encode all string values to be able search successfully,when we have special characters in string values.
          parent_filter = '%25' + Util.Transforms.validateQueryValue(lookupFormName) + '.' + Util.Transforms.validateQueryValue(parentField.name) + '=' + Util.Transforms.validateQueryValue(control.value);
        }
        if (parent_filter) {
          filter = filter + and + parent_filter;
          and = '%20and%20';
        }
      }
      field = parentField;
    }
    return filter;
  }

  protected setFullLookupFilter(): string {
    const filters = new Array<string>();
    const formName: string = '%' + this.getFormName();
    const allDataKeys: string[] = Object.keys(this.form.controls); //We have to send all not empty fields of the profile form (Readonly or not).
    for (const key of allDataKeys) {
      const value = this.form.controls[key].value;
      if (value) {
        const filter = this.lookupFilterString(formName, key, value);
        filters.push(filter);
      }
    }
    const lookupFilter: string = filters.join(encodeURI(' and '));
    return lookupFilter;
  }

  private lookupFilterString(formName: string, key: string, value: string): string {
    formName = formName ? Util.Transforms.validateQueryValue(formName) : null;
    key = Util.Transforms.validateQueryValue(key);
    value = Util.Transforms.validateQueryValue(value);
    const filter: string = (formName ? formName + '.' : '') + key + '=' + value;
    return filter;
  }

  protected validateLookup(control: FormControl, field: FormField, value: any): Promise<ValidationErrors> {
    let lookupFormName: string = this.getFormName();
    const fieldPrimaryKey: string = this.getKey(field);
    const fieldSecondaryKey: string = field.descriptionField;
    const lib: string = this.desc && this.desc.lib ? this.desc.lib : Util.RestAPI.getPrimaryLibrary();
    let query = '';
    let queryValue = '';
    let message: string = this._errors.LOOKUP;
    const result: any = { LOOKUP: message };
    const valueIsNumeric: boolean = Number.isInteger(Math.floor(value));
    if (valueIsNumeric) {
      value = value.toString();
    }
    if (!control.value || control.value === field.lastLookupValue) {
      return new Promise(resolve => {
       resolve(this.finishValidateField(field, null, null));
      });
    }
    if (field.lookup === '$edx_contacts') {
      value = value.replace(/\s/g, '').replace(/,/g, ';').split(';').filter(e => e).join(';');
      queryValue = Util.Transforms.validateQueryValue(value);
      const outlookAddressBook = Util.RestAPI.getOutlookPreferences('lastaddressbook');
      lookupFormName = !!outlookAddressBook ? outlookAddressBook : Util.RestAPI.getDefaultAddressBook();
    } else {
      queryValue = Util.Transforms.validateQueryValue(value, '*');
    }
    query = 'max=25&ascending=' + fieldPrimaryKey;
    const otherfilter = this.setParentFilter(field);
    ++this.blockValidation;
    this.setLoading(true);
    return this.lookupService.validate(lookupFormName, lib, field.lookup, fieldPrimaryKey, queryValue, this.formKind, otherfilter, query).then(data => {
      this.setLoading(false);
      // included data.set.total instead of nValues in the condition to check if atlease one value is returned from server.
      if (data && data.set && data.set.total > 0) {
        field.defaultValue = null;
        field.lookupData = [];
        for (const item of data.list) {
          if (item['DISABLED'] === '1' || item['DISABLED'] === 'Y') {
            continue;
          }
          let description: string = item[fieldSecondaryKey];
          if (!description) {
            description = Util.FieldMappings.descriptionValueForField(item, field);
          }
          const lookupItem = new CacheItem(item[fieldPrimaryKey], description, item);
          field.lookupData.push(lookupItem);
          if (data.list.length === 1) {
            this.scanFieldsForRevalidatonOrEvaluation(item,field.name);
          }
        }
        if (!field.scheduledForRevalidation) {
          this.clearFieldValueAndDescOfChildren(field);
        }
        setTimeout(() => {
          --this.blockValidation;
          this.cdr.markForCheck();
        }, 1);
        if (field.lookup === '$edx_contacts') {
          return this.validateEmailLookup(control, field);
        }
        if (data.set.total === 0) {
          return this.finishValidateField(field, message, result);
        } else {
          field.lastLookupValue = control.value;
          return this.finishValidateField(field, null, null);
        }
      } else {
        field.defaultValue = null;
        field.lookupData = [];
        field.lastLookupValue = null;
        ++field.rerender;
        setTimeout(() => {
          --this.blockValidation;
          this.cdr.markForCheck();
        }, 1);

        if (field.lookup === '$edx_contacts') {
          return this.validateEmailLookup(control, field);
        }
        message = this._errors.LOOKUP;
        return this.finishValidateField(field, message, result);
      }
    });
  }

  protected validateEmailLookup(control: FormControl, field: FormField): ValidationErrors {
    const error: ValidationErrors = this.validateField(control, field);
    if (error) {
      return error;
    } else {
      field.defaultValue = control.value;
      field.lookupData = control.value;
      field.lastLookupValue = control.value;
      return this.finishValidateField(field, null, null);
    }
  }

  // the universal validator function
  protected validateField(control: FormControl, field: FormField): ValidationErrors {
    let value: any = control.value;
    let result: any = null;
    let message: string = null;
    const isSearchForm: boolean = this.isSearchForm();
    if (field.isRequired) {
      if (!value) {
        message = this._errors.MISSING;
        result = { MISSING: message };
        field.autoValidated = false;
        this.clearFieldDesc(field);
        // Clear any dependents of this master parent
        this.clearFieldValueAndDescOfDependents(field);
        this.clearFieldValueAndDescOfChildren(field);
      } else if (field.name==='DOCNAME') {
        // check for multiple files being uploaded. the field max is then the individual file name lenghts
        const names: string[] = value.split(Util.RestAPI.kMultiFileSeparator);
        for (const name of names) {
          if (!Util.Transforms.isValidDocName(name)) {
            message = this._errors.INVALID_NAME;
            result = { INVALID: message };
          }
        }
      }
    }
    if (field.controltype === 'editdate') {
      if (field.isRequired && !value) {
        message = this._errors.MISSING;
        result = { MISSING: message };
      } else if (value) {
        value = Util.Transforms.localizedToDMDate(value);
        value = value.toUpperCase();
        if (!Util.Transforms.isSpecialDateFormat(value)) {
          let dmFormatedDate = false;
          const splitStr: string = Util.Transforms.splitableDateFormat(value);
          const secondIsNumeric: boolean = Util.Transforms.splitableDateFormatSecondValueIsNumeric(value);
          const parts: string[] = splitStr ? value.split(splitStr) : [value];
          const nParts: number = parts.length;
          let startDate = '';
          if ((isSearchForm && nParts>0 && nParts<3) || nParts===1) {
            for (let i=0; i<nParts; i++) {
              const part: string = parts[i];
              const partUppper = part.toUpperCase();
              let partDate = '';
              if ((i===0 && partUppper==='%TODAY' && splitStr.toUpperCase()===' MINUS ') || (i>0 && partUppper==='%TODAY' && splitStr.toUpperCase()===' TO ')) {
                dmFormatedDate = true;
              } else {
                dmFormatedDate = false;
                if (i===0 && partUppper.startsWith('%TODAY MINUS ')) {
                  try {
                    dmFormatedDate = Util.isInteger(partUppper.split('%TODAY MINUS ')[1]);
                  } catch (e) { }
                } else if (i===0 || !secondIsNumeric) {
                  dmFormatedDate = Util.Transforms.isValidLocaleDate(part);
                  if (dmFormatedDate) {
                    // Get the proper date value and validate against min and max dates
                    partDate = Util.Transforms.toDMDateString(part);
                    if (partDate < field.minDate || partDate > field.maxDate) {
                      dmFormatedDate = false;
                    }
                  }
                } else {
                  try {
                    dmFormatedDate = Util.isInteger(part);
                  } catch (e) { }
                }
              }
              // For the date range, end date should be greater than start date
              if (nParts > 1 && splitStr.toUpperCase() === ' TO ' && partUppper.indexOf('%TODAY') === -1 && dmFormatedDate) {
                if (i === 0) {
                  startDate = partDate || part;
                } else if (i === 1 && ((partDate || part) < startDate)) {
                  dmFormatedDate = false;
                }
              }
              if (!dmFormatedDate) {
                break;
              }
            }
          }
          if (!dmFormatedDate) {
            message = this._errors.INVALID_DATE;
            result = { INVALID: message };
          }
        }
      }
    }
    if (!isSearchForm && field.maxChars > 0 && value && value.length > field.maxChars) { // to allow multiple lookup values in profile search forms.
      if (field.name==='DOCNAME') {
        // check for multiple files being uploaded. the field max is then the individual file name lengths
        const names: string[] = value.split(Util.RestAPI.kMultiFileSeparator);
        for (const name of names) {
          if (name.length > field.maxChars) {
            message = this._errors.LONG;
            result = { LONG: message };
            break;
          }
        }
      } else {
        message = this._errors.LONG;
        result = { LONG: message };
      }
    }
    if (isSearchForm && field.name === 'DOCNUM' && !!value) {
      const values: string[] = value.toUpperCase().split(' TO ');
      for (const val of values) {
        const filter = /^(\d+(\,|\;|\t|\ )?)+$/;
        if (!filter.test(val)) {
          message = this._errors.INVALID_DOCNUM;
          result = { INVALID: message };
          break;
        }
      }
    }
    if (!!value && (field.name.startsWith('$edx_email') || field.name.startsWith('$edx_outlook_email'))) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      const emailIds =value.replace(/\s/g, '').replace(/,/g, ';').split(';').filter(e => e);
      for (const email of emailIds) {
        const strDomain: string = email.split('.')[1];
        if ( (!filter.test(email)) || (strDomain.length > 3) ) {
          message = this._errors.EMAIL;
          result = { INVALID: message };
        } else {
          // Now check the domain white-list if applicable
          if (this.formTemplate.name === 'shareonedrive') {
            const lib: string = this.data['DOCUMENTS'][0].lib;
            const extAppInfo: any = Util.RestAPI.findExternalApp(lib);
            if (!!extAppInfo['domainswhitelist']) {
              const domain: string = email.split('@')[1];
              if (extAppInfo['domainswhitelist'].indexOf(domain) === -1) {
                message = this._errors.DOMAIN;
                result = { INVALID: message };
              }
            }
          }
        }
      }
    }
    if (!!value && this.isLookupForm()) {
      if (field.isRequired && (value.match(/ /g) || []).length === value.length) {
        message = this._errors.MISSING;
        result = { MISSING: message };
      }
    }
    const validationResult = ValidationUtilities.IsValidInput(value?.toString(), field.validationParameters);
    if (!!validationResult && !validationResult.isValid) {
      message = this.localizer.getTranslation(validationResult.messageKey, validationResult.messageParameters);
      result = { INVALID: message };
    }
    return this.finishValidateField(field, message, result);
  }

  protected finishValidateField(field: FormField, message: string, result: any): ValidationErrors {
    field.errormessage = message;
    // for the date range fields, get the parent (inlineParent) form and enable ok button
    if (field.controltype.indexOf('date') >= 0 && !!this.inlineParent) {
      if (this.inlineParent.form && this.inlineParent.controller.enableOK) {
        setTimeout(() => {
          // pass on the child form valid status
          this.inlineParent.controller.enableOK(this.form.valid, this.inlineParent.form.dirty);
        }, 1);
      }
    } else if (this.form && this.controller.enableOK) {
      setTimeout(() => {
        this.controller.enableOK(this.form.valid, this.form.dirty);
      },250);
    }
    return result;
  }

  public dynamicExtrasToggled(shown: boolean): void {
    this.extrasShown = shown;
    this.cdr.markForCheck();
    if (shown) {
      ++this.blockValidation;
      setTimeout(() => {
        --this.blockValidation;
      }, 5);
    }
  }

  public doEditSecurity(): void {
    if (this.data && ((this.data.type === 'documents') || (this.data.type === 'workspaces') || (this.data.type === 'workspaces') || (this.data.type === 'folders') || (this.data.type === 'flexfolders'))) {
      this.securityDesc = Util.deepCopy(this.desc);  // deep copy as we are going to set type next and it would wack the desc pointer's type
      this.securityDesc.params = 'security';
      if (this.bMassProfileUpdate) {
        this.securityDesc.id = '';
        this.trusteesDirective = 'replace';
      }
    } else {
      // to get profile default trustees, we need formname and appID.
      this.securityDesc = { id:'0',
                            type:this.createType?this.createType:this.bMassProfileUpdate?'mass_profile':this.copyTree?'copy_tree':'',
                            params:'security',
                            lib:Util.RestAPI.getPrimaryLibrary(),
                            isWorkspace:(this.createType === 'workspaces')? 1 : 0,
                            formName : this.data?.['FORMNAME'],
                            appId: this.data?.['APP_ID']
                          };
      if (this.form.controls['AUTHOR_ID']) {
        this.securityDesc['AUTHOR_ID'] = this.form.controls['AUTHOR_ID'].value;
      }
    }
    this.securityKind = 'list_PRIVATE';
    this.securityTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SET_SECURITY');
    this.securityOK = this.localizer.getTranslation('FORMS.BUTTONS.OK');
    this.securityShown = true;
    this.cdr.markForCheck();
  }

  public setLookupFilter(field: FormField): void {
    this.lookupFilterValue = this.setFullLookupFilter();
    if (field.parentField) {
      const parentControl: FormControl = this.form.controls[field.parentField] as FormControl;
      if (parentControl && !parentControl.value) {
        this.isLookupParentEmpty = (this.formKind === 'profile');
      }
    }
  }

  public doLookup(field: FormField): void {
    if (Util.Device.isPhoneLook()) {
      const formBody: HTMLElement = document.getElementById('edx_form_body');
      this.formScrollTop = !!formBody ? formBody.scrollTop : 0;
    }
    if (field.name === 'PD_FILEPT_NO') {
      this.fileplansTitle = this.localizer.getTranslation('FOLDER_ACTIONS.SELECT_FILEPART');
      this.fileplansOK = this.localizer.getTranslation('FORMS.BUTTONS.ADD');
      let library = '';
      if (!!this.lookupDesc && !!this.lookupDesc.lib) {
        library = this.lookupDesc.lib;
      }
      this.fileplansDesc = { id:'', type:'fileparts', lib:library, DOCNAME:this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS') };
      this.fileplansShown = true;
      this.lookupField = field;
      this.prepareDialogOnDialog();
    } else {
      field.errormessage = '';
      this.finishValidateField(field, null, null);
      this.isLookupParentEmpty = false;
      this.lookupIsNumeric = false;
      this.lookupNoWildcard = false;
      this.lookupInitalSearch = '';
      this.lookupField = field;
      this.lookupInitalKey = this.getKey(field);
      this.lookupOKDisabled = true;
      const isSearchForm: boolean = this.formKind.startsWith('profile_query');
      if (field.lookup==='$edx_email_picker') {
        this.chooseEmail(field);
      } else if (field.lookup==='$edx_file_picker') {
        this.chooseFile(field);
      } else if (field.lookup==='$edx_folder_picker') {
        this.chooseDir(field);
      } else if (field.lookup==='$edx_date_range') {
        this.chooseDateRange(field);
      } else {
        const lookupFormName: string = this.getFormName();
        const control: AbstractControl = this.form.controls[field.name];
        if (field.controltype === 'editnumber') {
          this.lookupIsNumeric = true;
          if (control && control.valid && control.value) {
            this.lookupInitalSearch = control.value.toString();
          } else {
            this.lookupNoWildcard = true;
          }
        } else {
          // If lookup button is clicked,instead of tabing,show all the Matters.
          if (control && control.value && control.value !== 'DEFAULT') {
            // Request for fetching lookups for multi valued lookup fields.
            // Replace separator ; with |
            if (control.value.indexOf(';') > 0) {
              // Multivalue lookup field re-selection can be done only for elemental fiels
              // As the parentFilter is encoded value, '%7C' is used for '|'
              const parentFilter: string = this.setParentFilter(field);
              if (!parentFilter || (!!parentFilter && parentFilter.indexOf('%7C') === -1)) {
                this.lookupInitalSearch = control.value.replace(/[;]/g,'|');
              }
            } else {
              // Validated fields should return all values of the lookup and select the single value
              this.lookupInitalSearch = control.value;
            }
          }
        }
        this.lookupInitalSearch = this.lookupInitalSearch || '';
        if (field.lookup !== '$edx_contacts') {
          this.setLookupFilter(field);
        }
        this.lookupDesc.id = field.lookup;
        this.lookupForm = lookupFormName;
        this.fieldPrimaryKey = this.getKey(this.lookupField);
        this.lookupTitle = this.localizer.getTranslation('FORMS.LOOKUPS.TITLE', [field.label]);
        this.lookupLeadingColums = (this.lookupField.lookup === '$edx_contacts' ||
                                   this.formKind.startsWith('profile_query') ||
                                    field.mvinfo ||
                                    this.lookupField.name === '%KEYWORD') ? [ColFormat.SELECTOR] : null;
        if (this.createType === 'documents' && (this.formKind === 'profile' || this.formKind === 'profile_folderfiles') && field.name === 'APP_ID' && field.defaultValue.indexOf('|') !== -1) {
          const filter = this.lookupFilterString(null, 'APP_ID', field.defaultValue);
          this.lookupFilterValue += (this.lookupFilterValue ? encodeURI(' and ') : '') + filter;
        }
        this.lookupShown = true;
        ++this.blockValidation;
      }
    }
  }

  public hasLookupTable(lookup: string): boolean {
    let lib: string = !!this.desc ? this.desc.lib : null;
    if (!lib && this.data && this.data['DOCUMENTS'] && this.data['DOCUMENTS'].length) {
      lib = this.data['DOCUMENTS'].lib;
    }
    if (!lib) {
      lib = Util.RestAPI.getPrimaryLibrary();
    }
    if (lib) {
      lib = lib.toUpperCase();
    }
    return !!Util.RestAPI.getLookupTable(lookup, lib, this.getFormName());
  }

  private getFileExtensions(forElectron: boolean): any {
    let rc: any = null;
    if (this.data && this.data.FILE_EXTENSION) {
      const altExt: string = Util.Transforms.alternateExtension(this.data.FILE_EXTENSION);
      if (forElectron) {
        const exts: string[] = [this.data.FILE_EXTENSION];
        if (altExt) {
          exts.push(altExt);
        }
        rc = exts;
      } else {
        let exts: string = '.' + this.data.FILE_EXTENSION;
        if (altExt) {
          exts += ', .' + altExt;
        }
        rc = exts;
      }
    }
    return rc;
  }

  private gotFiles(files: File[]): void {
    const count = files ? files.length : 0;
    if (files && count) {
      if (this.lookupField) {
        const fieldJSON: any = {};
        let fileName: string;
        for (let i=0; i<count; i++) {
          const file: File = files[i];
          if (i===0) {
            fileName = file.name;
          } else {
            fileName += Util.RestAPI.kMultiFileSeparator + file.name;
          }
        }
        fieldJSON[this.lookupField.name] = fileName;
        this.updateFormData(fieldJSON, true);
        setTimeout(() => {
          this.controller.enableOK(this.form.valid, this.form.dirty);
        }, 1);
      }
      if (this.controller && this.controller.uploadFiles) {
        this.controller.uploadFiles(files);
      }
    }
  }

  private chooseDirOrFile(dir: boolean, field: FormField): void {
    const options: any = {};
    const fs: AWFileSystem = Util.Device.bIsElectron ? new AWFileSystem() : null;
    const control: AbstractControl = this.form.controls[field.name];
    if (control && control.value && control.value!=='DEFAULT') {
      options.defaultPath = control.value;
    }
    this.electronDialogShown = true;
    Util.RestAPI.setAppModal(true);
    const success = (result) => {
      this.electronDialogShown = false;
      Util.RestAPI.setAppModal(false);
      if (result && result.length) {
        this.updateControlValue(field.name, result[0], true);
        if (this.controller && this.controller.uploadFiles) {
          this.controller.uploadFiles(null, result);
        }
      }
    };
    const errorFunc = (err) => {
      this.electronDialogShown = false;
      Util.RestAPI.setAppModal(false);
    };
    if (dir) {
      if (Util.Device.bIsElectron) {
        fs.showDirSelector(options, success, errorFunc);
      } else {
        Util.RestAPI.showDirSelectorWithPFTA(options).then(success);
      }
    } else {
      options.multiSelections = true;
      const exts: string[] = this.getFileExtensions(true) as string[];
      if (exts) {
        options.filters = [{name:this.localizer.getTranslation('TOOLTIP.FILTER'), extensions:exts}];
      }
      fs.showFileSelector(options, success, errorFunc);
    }
  }

  private chooseFile(field: FormField): void {
    if (Util.Device.bIsElectron && !this.electronDialogShown) {
      this.chooseDirOrFile(false, field);
    } else if (Util.Device.bIsCordova) {
      const gotPaths = (filePaths: string[]) => {
        if (filePaths.length) {
          this.updateControlValue(field.name, filePaths[0], true);
          if (this.controller && this.controller.uploadFiles) {
            this.controller.uploadFiles(null, filePaths);
          }
          if (this.lookupField) {
            setTimeout(() => {
              this.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
        }
      };
      Util.RestAPI.pickFromDownloads((list: any, success: boolean) => {
        if (success && list && list.length) {
          const filePaths: string[] = [];
          for (const item of list) {
            const fileName: string = Util.Transforms.lastPathComponent(item.fullPath);
            if (fileName) {
              filePaths.push(fileName);
            }
          }
          gotPaths(filePaths);
        }
      }, (files: File[], paths: string[], success) => {
        if (success) {
          if (files) {
            this.gotFiles(files);
          } else if (paths) {
            gotPaths(paths);
          }
        }
      });
    } else {
      const exts: string = this.getFileExtensions(false) as string;
      Util.RestAPI.pickFiles(exts, (files: File[], paths: string[], success: boolean) => {
        if (success && !!files) {
          this.gotFiles(files);
        }
      });
    }
  }

  private chooseDir(field: FormField): void {
    if ((Util.Device.bIsElectron || Util.RestAPI.pftaVersion() >= 0x00160600) && !this.electronDialogShown) {
      this.chooseDirOrFile(true, field);
    }
  }

  private chooseEmail(field: FormField): void {
    if (Util.Device.bIsCordova && !this.electronDialogShown) {
      const contacts: AWContacts = new AWContacts(contact => {
        let prefEmail: string = null;
        const emails: any[] = contact && contact.emails ? contact.emails : null;
        if (emails && emails.length) {
          for (const email of emails) {
            if (email.pref) {
              prefEmail = email.value;
              break;
            }
          }
          if (!prefEmail) {
            prefEmail = emails[0].value;
          }
        }
        if (prefEmail) {
          this.updateControlValue(field.name, prefEmail, true);
          this.fieldChanged(field);
        }
      }, error => {});
      contacts.pickContact();
    }
  }

  private chooseDateRange(field: FormField): void {
    if (this.inlineFormField) {
      this.inlineFormField = null;
    } else {
      this.inlineFormField = field;
    }
    this.markForCheck();
  }

  public showInlineForm(field: FormField): boolean {
    return this.inlineFormField === field;
  }

  public inlineForm(field: FormField): string {
    if (field.controltype.indexOf('date')>=0) {
      return '__local_date_range';
    }
    return null;
  }

  public updateInlineParent(value: any, closeInlineForm: boolean): void {
    this.updateControlValue(this.inlineFormField.name, value, true);
    this.fieldChanged(this.inlineFormField);
    this.markForCheck();
    if (closeInlineForm) {
      const dateField = document.getElementById(this.inlineFormField.name) as HTMLInputElement;
      if (!!dateField) {
        dateField.focus();
      }
      this.inlineFormField = null;
    }
  }

  public inlineDateRangeChanged(dateRange: string, closeInlineForm: boolean): void {
    const datePicker = document.getElementById('dateField_' + this.inlineFormField.name) as HTMLInputElement;
    if (dateRange && datePicker && !Util.Transforms.isSpecialDateFormat(dateRange)) {
      const splitStr: string = Util.Transforms.splitableDateFormat(dateRange);
      const parts: string[] = splitStr ? dateRange.split(splitStr) : [dateRange];
      if (!Util.Transforms.isSpecialDateFormat(parts[0])) {
        datePicker.value = !!parts[0] ? Util.Transforms.toDMDateString(parts[0]) : parts[0];
      }
    }
    this.updateInlineParent(dateRange, closeInlineForm);
  }

  public inlineFormFieldChanged(form: DynamicFormComponent, field: FormField, control: AbstractControl): void {
    const value: any = control.value;
    if (this.inlineFormField.controltype.indexOf('date')>=0) {
      const dateRange = Util.Transforms.valueToDateRange(value, Util.RestAPI.restAPIVersion()>0x00160700);
      this.inlineDateRangeChanged(dateRange, ['D1', 'W1', 'W2', 'M1', 'M3', 'Y1', 'Y3'].indexOf(value)!==-1);
    } else {
      this.updateInlineParent(value, true);
    }
  }

  public updateControlValue(name: string, value: any, makeDirty?: boolean, translate?: boolean, transArgs?: string[], autoValidated?: boolean): void {
    if (this.form) {
      const control: FormControl = this.form.controls[name] as FormControl;
      // in case the element is the focus we must blur it so validation will run
      const field: FormField = this.getField(name);
      const nativeEl = document.getElementById(name);
      if (nativeEl && nativeEl === document.activeElement && !autoValidated) {
        nativeEl.blur();
      }
      const newValue: any = {};
      if (translate) {
        value = this.localizer.getTranslation(value, transArgs);
      }
      if (field && field.fldtype === 'edit' && (field.datatype === '1' || field.datatype === '8')) {
        value = Util.Transforms.utcTimeRangeToLocalDate(value);
        if (nativeEl?.['validity']?.['valid'] && !control?.valid) {
          field.errormessage = '';
        }
      }
      newValue[name] = value;
      this.updateFormData(newValue, true);
      if (makeDirty) {
        if (control) {
          control.markAsDirty();
        }
      }
      if (!!field && ((field.fldtype==='edit' && field.isReadonly) || field.fldtype==='list' || field.fldtype==='checkbox')) {
        ++field.rerender;
      }
      this.cdr.markForCheck();
      if (this.blockValidation && this.controller.enableOK) {
        setTimeout(() => {
          this.controller.enableOK(this.form.valid, this.form.dirty);
        }, 1);
      }
    }
  }

  public getFieldValue(name: string): any {
    let value: any = null;
    if (this.form) {
      value = this.form.value[name];
      if (!value) {
        if (!!this.form.controls[name]) {
          value = this.form.controls[name].value;
        }
        if (!value && !!this.data) {
          value = this.data[name];
        }
      }
    }
    return value;
  }

  // interface FormController
  public fieldChanged(field: FormField): boolean {
    const control: AbstractControl = this.form.controls[field.name];
    if (!!control) {
      if (this.inlineParent) {
        if (field.scriptTrigger || field.visibilityTriggers) {
          this.controller.fieldChanged(field, control, this.fields);
        }
        this.inlineParent.inlineFormFieldChanged(this, field, control);
      } else if (this.controller && this.controller.fieldChanged) {
        const isSearchForm: boolean = this.formKind.startsWith('profile_query');
        if ((!control.value && (field.isRequired || field.controltype === 'editdate'))) {
          const formControl: FormControl = this.form.controls[field.name] as FormControl;
          this.validateField(formControl, field);
          if (field.scriptTrigger || field.visibilityTriggers) {
            return this.controller.fieldChanged(field, control, this.fields);
          }
          return true;
        } else if (control && control.dirty && ((field.scriptTrigger || field.visibilityTriggers) || control.value!=='DEFAULT' && (control.value || field.isTriStateCheckbox) && (control.touched || field.isCombo || field.isTriStateCheckbox || field.controltype==='radiogroup' || field===this.inlineFormField))) {
          return this.controller.fieldChanged(field, control, this.fields);
        } else if (!control.value && !isSearchForm) {
          this.clearFieldDesc(field);
          this.clearFieldValueAndDescOfDependents(field);
          this.clearFieldValueAndDescOfChildren(field);
        }
      }
    }
    return true;
  }

  public buttonClicked(field: FormField): void {
    if (this.controller && this.controller.buttonClicked) {
      const fieldName: string = field.name;
      let control: AbstractControl = this.getControl(fieldName);
      if (!control) {
        Util.FieldMappings.forEachTemplateField(this.fields, true, (curField: any): boolean => {
          if (curField.name === fieldName) {
            if (!!curField.extensionParent) {
              control = this.getControl(curField.extensionParent);
              return false;
            }
          }
          return true;
        });
      }
      this.controller.buttonClicked(field, control, this.fields);
    }
  }

  public getList(kind: string): any {
    return this.data ? this.data[kind] : null;
  }

  public setSecurityList(data: ListItem[]): void {
    this.securityList = data;
  }

  public getSecurityList(): ListItem[] {
    const securityControl: AbstractControl = this.form && this.form.controls ? this.form.controls['SECURITY'] : null;
    if (securityControl && (securityControl.dirty || this.formKind.startsWith('profile_copy'))) {
      if (securityControl.value === '1') {
        const authorControl: AbstractControl = this.form.controls['AUTHOR_ID'];
        const author = !!authorControl && !!authorControl.value ? authorControl.value : null;
        let bAddedAuthor = false;
        if (this.createType) {
          if (!this.securityList || !this.securityList.length) {
            if (authorControl && authorControl.value) {
              this.securityList = this.listService.addAuthorTypistAsTrustee(authorControl.value, Util.RestAPI.getUserID());
              bAddedAuthor = true;
            }
          }
        }
        if (!this.securityList?.length) { // User selected from public to restricted
          this.securityList = this.listService.addAuthorTypistAsTrustee(this.desc['AUTHOR_ID'], Util.RestAPI.getUserID(), this.desc['AUTHOR_FULL_NAME'], Util.RestAPI.getUserFullName());
          bAddedAuthor = true;
        }
        if (!bAddedAuthor && !!author && !!this.originalAuthor && author !== this.originalAuthor) {
          const authorSecurity = this.securityList.find((a) => a['USER_ID'] === author);
          if (!!authorSecurity) {
            authorSecurity['flag'] = 2;
            authorSecurity['rights'] = AccessLevel.ACCESS_LEVEL_FULL_RM;
          } else {
            this.securityList.push({ flag: 2, USER_ID: author, rights: AccessLevel.ACCESS_LEVEL_FULL_RM } as any);
          }
        }
        return this.securityList;
      } else {
        return null;
      }
    } else if (this.data['SECURITY'] >= '1') {
      return this.securityList;
    }
    return null;
  }

  private formatDocNumsInFormValue(formValue: any): any {
    if (this.formKind.startsWith('profile_query')) {
      const docuNum: string = formValue['DOCNUM'];
      if (docuNum && docuNum.toUpperCase().indexOf(' TO ') === -1) {
        formValue['DOCNUM'] = docuNum.replace(/[\ \t+]/g,';');
      }
    }
    return formValue;
  }

  public getValue(): any {
    return this.formatDocNumsInFormValue(this.form ? this.form.value : {});
  }

  public getAllData(): any {
    const allData: any = this.getValue();
    const originalKeys: string[] = this.data ? Object.keys(this.data) : [];
    const allDataKeys: string[] = Object.keys(allData);
    const allControlKeys: string[] = Object.keys(this.form.controls);
    let key;
    for (key of originalKeys) {
      const value = this.data[key];
      if (!!value) {
        if (allDataKeys.indexOf(key)===-1) {
          allData[key] = value;
        }
        if (typeof value === 'string' && value.includes(',')) {
          const field: any = this.getField(key);
          if (!!field && !!field.mvinfo) {
            allData[key] = value.replace(/,/g, '\\,');
          }
        }
      }
    }
    for (key of allControlKeys) {
      if (this.form.controls[key].dirty && allDataKeys.indexOf(key)===-1) {
        allData[key] = this.form.controls[key].value;
      }
    }
    return allData;
  }

  private finishGetDirty(formValue: any): any {
    const securityControl: AbstractControl = this.form.controls['SECURITY'];
    if (securityControl && securityControl.dirty) {
      formValue['SECURITY'] = securityControl.value;
    }
    if (!!this.data && !!this.data['FORM'] && this.isProfileForm() && !this.formKind.startsWith('profile_query')) {
      formValue['FORM'] = this.data['FORM'];
    }
    return this.formatDocNumsInFormValue(formValue);
  }

  public getDirtyValue(): any {
    let formValue: any = {};
    const isFilterForm: boolean = this.desc && this.desc.type==='searches';
    const isSearchForm: boolean = this.formKind.startsWith('profile_query');
    const isCopyForm: boolean = this.formKind.startsWith('profile_copy');
    const theForm: FormGroup = this.form;
    const addEachDirtyOrNonEmptyField = (theFields: any , allowEmpty: boolean) => {
      for (const curField of theFields) {
        if (curField.fields) {
          addEachDirtyOrNonEmptyField(curField.fields, allowEmpty);
        }
        if (curField.isVisible || !isSearchForm) {
          const control: AbstractControl = theForm.controls[curField.name];
          if (control && (isFilterForm || isCopyForm || control.dirty || !!this.createType) && (control.value || (allowEmpty && !curField.isRequired))) {
            if (isCopyForm && curField.controltype === 'editdate' && curField.isReadonly) {
              formValue[curField.name] =  Util.Transforms.formatDateForDM(control.value);
            } else {
              formValue[curField.name] = curField.controltype.indexOf('number')!==-1 ? control.value?.toString() : (typeof control.value === 'string' && !!curField.mvinfo) ? control.value.replace(/,/g, '\\,').replace(/&/g, '\\&') : control.value;
            }
          }
        }
      }
    };
    formValue = {};
    addEachDirtyOrNonEmptyField(this.fields, !this.createType && !isSearchForm);
    return this.finishGetDirty(formValue);
  }

  public getDirtyAndRequiredValue(): any {
    const formValue: any = {};
    const theForm: FormGroup = this.form;
    const isSearchForm: boolean = this.formKind.startsWith('profile_query');
    const isCopyForm: boolean = this.formKind.startsWith('profile_copy');
    const addEachDirtyAndRequiredField = (theFields: any , allowEmpty: boolean) => {
      for (const curField of theFields) {
        if (curField.fields) {
          addEachDirtyAndRequiredField(curField.fields,allowEmpty);
        }
        const control: AbstractControl = theForm.controls[curField.name];
        if (control && (((control.dirty || isCopyForm) && (control.value || (allowEmpty && !curField.isRequired)) && !curField.isExtension) || curField.isRequired)) {
          let value = control.value;
          if (!value) {
            value = 'DEFAULT';
          }
          if (isCopyForm && curField.controltype === 'editdate' && curField.isReadonly) {
            formValue[curField.name] =  Util.Transforms.formatDateForDM(control.value);
          } else {
            formValue[curField.name] = curField.controltype.indexOf('number')!==-1 ? control.value.toString() : control.value;
          }
        }
      }
    };
    addEachDirtyAndRequiredField(this.fields, !this.createType && !isSearchForm && !this.copyTree);
    return this.finishGetDirty(formValue);
  }

  public getEditableValues(includeDescriptionOfFields: boolean): any {
    const editableData: any = {};
    const allData: any = this.form.value;
    const allDataKeys: string[] = Object.keys(allData);
    for (const key of allDataKeys) {
      const value = this.form.controls[key]?.value;
      if (!!value) {
        if (key === 'SECURITY') {
          editableData[key] = value;
        } else if (key !== 'DOCNAME') {
          const field: any = this.getField(key);
          if (!!field && (field.fldtype === 'edit' || field.fldtype === 'checkbox') && field.isEnabled && !field.isReadonly && field.isVisible) {
            editableData[key] = value;
            if (includeDescriptionOfFields) {
              this.addControlValue(editableData, field.descriptionField);
              for (const auxiliaryKey of field.auxiliary || []) {
                this.addControlValue(editableData, auxiliaryKey);
              }
            }
          }
        }
      }
    }
    return editableData;
  }

  private addControlValue(valueDictionary: any, key: string): void {
    const value = this.form.controls[key]?.value;
    if (!!value) {
      valueDictionary[key] = value;
    }
  }

  public getControl(name: string): AbstractControl {
    return this.form.controls[name];
  }

  public getControlValue(name: string): any {
    return !!this.form.controls[name] ? this.form.controls[name].value : null;
  }

  public getField(name: string): FormField {
    let field: FormField = null;
    const findFieldWithName = theFields => {
      if (theFields) {
        for (const curField of theFields) {
          if (curField.name===name) {
            field = curField;
            return true;
          }
          if (curField.fields) {
            if (findFieldWithName(curField.fields)) {
              return true;
            }
          }
        }
      }
      return false;
    };
    if (name) {
      findFieldWithName(this.fields);
    }
    return field;
  }
  public getFieldsBySqlInfo(sqlInfo: string): FormField[] {
    const fields = [];
    const findField = (formFields) => {
      if (formFields) {
        for (const formField of formFields) {
          if (formField.sqlinfo === sqlInfo) {
            fields.push(formField);
            return true;
          }
          if (formField.fields) {
            if (findField(formField.fields)) {
              return true;
            }
          }
        }
      }
      return false;
    };
    if (sqlInfo) {
      findField(this.fields);
    }
    return fields;
  }

  public getFieldMatchingSqlInfo(sqlInfo: string): FormField {
    let field: FormField = null;
    const findField = theFields => {
      if (!!theFields) {
        theFields.forEach(curField => {
          if (Util.FieldMappings.isSqlInfoMatchesTillParentNode(curField.sqlinfo, sqlInfo)) {
            field = curField;
            return true;
          }
          if (curField.fields) {
            if (findField(curField.fields)) {
              return true;
            }
          }
        });
      }
      return false;
    };
    if (!!sqlInfo) {
      findField(this.fields);
    }
    return field;
  }

  public markForCheck(): void {
    this.cdr.markForCheck();
  }

  public setLoading(loading: boolean): void {
    this.loadingCount += loading ? 1 : -1;
    if (this.loadingCount<0) {
      this.loadingCount = 0;
    }
    this.cdr.markForCheck();
  }

  public isLoading(): boolean {
    return this.loadingCount > 0;
  }

  public userChangingProfileForm(libChanged: boolean, formChanged: boolean): void {
    if (libChanged) {
      if (Util.Device.bIsOfficeAddinOutlook) {
        this.savedFormData = {};
        const dirtyVal = this.getDirtyValue();
        const dirtyValKeys = Object.keys(dirtyVal);
        for (const key of dirtyValKeys) {
          if (Util.isEmailField(key) || key==='DOCNAME') {
            this.savedFormData[key] = dirtyVal[key];
          }
        }
      }
    } else {
      this.savedFormData = this.getDirtyValue();
      this.isOfficeItemOptionChanged = !formChanged;
      if (!formChanged && Util.Device.bIsOfficeAddinOutlook) {
        const allData = this.getAllData();
        const allKeys = Object.keys(allData);
        for (const key of allKeys) {
          if (Util.isEmailField(key) || key==='DOCNAME') {
            this.savedFormData[key] = allData[key];
          }
        }
      }
    }
  }

  public setFieldVisibility(name: string, visible: boolean): void {
    const field: FormField = this.getField(name);
    if (field) {
      if (visible !== field.isVisible) {
        field.isVisible = visible;
        this.cdr.markForCheck();
      }
    }
  }

  public setFieldLabel(name: string, label: string, translate?: boolean): void {
    const field: FormField = this.getField(name);
    if (field) {
      if (label !== field.label) {
        if (translate) {
          label = this.localizer.getTranslation(label);
        }
        field.label = label;
        this.cdr.markForCheck();
      }
    }
  }

  private getKey(field: FormField): string {
    if (!!field) {
      let key = field.name;
      switch (key) {
        case '%KEYWORD':
          key = 'KEYWORD_ID';
          break;
        case 'DEF_LOOK':
          key = 'DEFAULT_ID';
          break;
      }
      return key;
    }
    return null;
  }

  public setFieldValueAndDescOfParents(field: FormField, data: any): void {
    // Ignore unused and invalid fields returned by DM server. Note that CLIENT_ID
    // does NOT contain the actual client value but it is in CLIENT_ID01 !!??
    const ignoreFields = ['%PRIMARY_KEY','lib',field.name];
    if (this.formKind.startsWith('profile_query') && field.name === 'TYPE_ID') {
      ignoreFields.push('DOCTYPE_STORAGE');
    }
    if (data) {
      const keys: string[] = Object.keys(data).filter(prop => !!data[prop]);
      for (const key of keys) {
        if (ignoreFields.indexOf(key)<0) {
          // If alias, get true field name (i.e. CLIENT_ID01 maps into CLIENT_ID)
          const fieldName: string = Util.FieldMappings.aliasToField(key);
          const currField: FormField = this.getField(fieldName);
          if (currField) {
            const value: string = data[key];
            currField.lastLookupValue = value;
            currField.autoValidated = true;
            this.updateControlValue(fieldName, value, true, false, null, currField.autoValidated);
            // Check if there is a description field and set it
            const nameKey: string = Util.FieldMappings.descriptionForField(this.formTemplate, currField);
            if (nameKey) {
              const desc: string =  Util.FieldMappings.descriptionValueForField(data, currField);
              this.setFieldDesc(fieldName, desc);
            }
          }
        }
      }
    }
  }

  public clearFieldValueAndDescOfChildren(fieldIn: FormField): void {
    while (fieldIn && fieldIn.childField) {
      fieldIn = this.getField(fieldIn.childField);
      if (fieldIn) {
        if (fieldIn.retainDefaultValue === false) {
          this.clearFieldValueAndDesc(fieldIn.name);
        } else {
          fieldIn.retainDefaultValue = false;
        }
        // Clear any dependents of this child
        this.clearFieldValueAndDescOfDependents(fieldIn);
      }
    }
  }

  public clearFieldValueAndDescOfDependents(fieldIn: FormField): void {
    if (fieldIn && fieldIn.auxiliary) {
      for (const fieldName of fieldIn.auxiliary) {
        this.clearFieldValueAndDesc(fieldName);
      }
    }
    if (fieldIn && fieldIn.lookups) {
      for (const fieldName of fieldIn.lookups) {
        const lookupField = this.getField(fieldName);
        if (lookupField.childField !== fieldIn.name) {
          this.clearFieldValueAndDesc(fieldName);
          const field: FormField = this.getField(fieldName);
          this.clearFieldValueAndDescOfDependents(field);
        }
      }
    }
  }

  public clearFieldValueAndDesc(fieldName: string): void {
    const map = {};
    const field: FormField = this.getField(fieldName);
    if (field) {
      map[field.name] = '';
      if (!!this.data) {
        this.data[field.name] = '';
      }
      field.autoValidated = false;
      this.clearFieldDesc(field, map);
      const control: FormControl = this.form.controls[fieldName] as FormControl;
      if (control) {
        control.markAsDirty();
      }
    }
  }

  public clearFieldDesc(field: FormField, mapFields: any={}): void {
    if (field) {
      let nameKey = '';
      if (!!field.descriptionField) {
        nameKey = field.descriptionField;
      } else {
        nameKey = Util.FieldMappings.descriptionForField(this.formTemplate, field);
      }
      mapFields[nameKey] = '';
      if (!!this.data) {
        this.data[nameKey] = '';
      }
      this.updateFormData(mapFields, true);
      if (!!field.auxiliary) {
        // If a lookup field has dependent fields then force field validation for every field value
        field.lastLookupValue = '*';
      }
    }
  }

  public getFieldDecriptionName(fieldName: string): string {
    let nameKey = '';
    const field: FormField = this.getField(fieldName);
    if (!!field) {
      if (!!field.descriptionField) {
        nameKey = field.descriptionField;
      } else {
        nameKey = Util.FieldMappings.descriptionForField(this.formTemplate, field);
      }
    }
    return nameKey;
  }

  public setFieldDesc(fieldName: string, fieldDesc: string, mapFields: any = {}): void {
    const nameKey = this.getFieldDecriptionName(fieldName);
    if (!!nameKey) {
      mapFields[nameKey] = fieldDesc;
      this.updateFormData(mapFields, true);
    }
  }

  private updateSecurityDropdown() {
    setTimeout(() => {
      let securityType: number;
      if (this.securityList && this.securityList.length > 0) {
        this.desc['edx_selected_security_choice'] = '1';
        this.updateControlValue('SECURITY', '1', true);
        securityType = 1;
      } else if (
        this.profileDefaultTrusteeList?.length > 0 &&
        !this.profileDefaultInfo['%SECURITY_INHERITS_FROM'] &&
        this.userSecuritySelection === '1'
      ) {
        this.setSecurityList(this.profileDefaultTrusteeList);
        this.desc['edx_selected_security_choice'] = '1';
        this.updateControlValue('SECURITY', '1', true);
        securityType = 1;
      } else if (
        this.data?.['SECURITY'] === '1' &&
        (this.data?.[':INH_LUP_SEC_FROM'] === '0' ||
          this.data?.['INH_LUP_SEC_FROM'] === '0')
      ) {
        this.controller?.inheritSecurity(this.desc);
        this.desc['edx_selected_security_choice'] = '1';
        this.updateControlValue('SECURITY', '1', true);
        securityType = 1;
      } else {
        this.updateControlValue('SECURITY', '0', true);
        securityType = 0;
      }
      if (this.controller.securityDirty) {
        this.controller.securityDirty(true);
      }
      const headerForm = this.controller.getHeaderForm?.();
      if (headerForm) {
        headerForm.updateControlValue('SECURITY', securityType);
        headerForm.setFieldVisibility('$edx_permissions_edit', securityType === 1);
        if (securityType === 1) {
          headerForm.setFieldEditable('SECURITY', this.canUserEditSecurity());
        } else {
          headerForm.setFieldEditable('SECURITY', true);
        }
        this.markForCheck();
      }
    }, 1);
  }

  // **** PopupCallback implementation
  popupCancel(): void {
    if (this.lookupShown) {
      this.lookupShown = false;
      --this.blockValidation;
    }
    if (this.securityShown) {
      this.securityShown = false;
      if (this.security && !this.isSecurityChanged && !this.data?.['DOCNUM']) {
        if (this.security.inheritedFlexTrusteeList) {
          this.inheritedFlexTrusteeList = Util.deepCopy(this.security.inheritedFlexTrusteeList);
          this.securityList = Util.deepCopy(this.security.inheritedFlexTrusteeList);
          this.inheritSecurityIsChecked = true;
        }
      }
    }
    if (this.fileplansShown) {
      this.fileplansShown = false;
    }
    this.standInBodyHeight = 0;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.cdr.markForCheck();

      if (Util.Device.isPhoneLook() && !!this.formScrollTop) {
        const formBody: HTMLElement = document.getElementById('edx_form_body');
        if (!!formBody) {
          formBody.scrollTop = this.formScrollTop;
        }
      }
    }, 1);
  }

  popupOK(): void {
    this.isOkClicked = true;
    if (this.fileplans) {
      const filePartList: ListItem[] = this.fileplans.getSelections();
      const description: string = Util.FieldMappings.descriptionValueForField(filePartList[0], this.lookupField);
      this.lookupField.lookupData = [];
      const filepartNo: string = filePartList[0]['PD_FILEPT_NO'] || filePartList[0].DOCNAME;
      const filePartData = {};
      ['PD_FILE_NAME', 'PD_FILEPT_NO', 'PD_LOCATION_CODE'].forEach(p => filePartData[p] = filePartList[0][p]);
      if (this.fileplans['selectedLocationPath'] === this.localizer.getTranslation('TILE_NAMES.RECENTLY_USED')) {
        const filePartData1 = {
          lib: filePartList[0]['lib'],
          type: 'fileplans',
          id: filePartList[0]['id'],
          name: filePartList[0]['DOCNAME'],
          docNumber: filePartList[0]['DOCNUMBER'],
        };
        Util.RestAPI.getFormData(filePartData1, 'profile', 'PD_SEARCH').toPromise().then(result => {
          filePartData['PD_LOCATION_CODE'] = result['PD_LOCATION_CODE'];
          this.popupCancel();
        }).catch(err => {
          console.error(err);
          this.popupCancel();
        });
      }
      const lookupItem = new CacheItem(filepartNo, description, filePartData);
      this.lookupField.lookupData.push(lookupItem);
      if (!(this.fileplans['selectedLocationPath'] === this.localizer.getTranslation('TILE_NAMES.RECENTLY_USED'))) {
        this.popupCancel();
      }
    } else {
      if (this.security) {
        this.securityList = this.security.getList();
        if (this.security.inheritedFlexTrusteeList) {
          this.inheritedFlexTrusteeList = Util.deepCopy(this.security.inheritedFlexTrusteeList);
          this.inheritSecurityIsChecked = this.security.isChecked;
          this.isSecurityChanged = true;
        }
        if (!this.security.inheritedFlexTrusteeList?.length) {
          this.userSecurityList = Util.deepCopy(this.securityList);
        }
        this.updateSecurityDropdown();
      } else if (this.lookupTable && this.lookupField) {
        if (this.lookupField.name === CUSTOM_DEFAULT_LOOKUP_NAME) {
          const selectedCustomDefaultKey = this.lookupTable.getSelections()[0]?.['%PRIMARY_KEY'];
          if (selectedCustomDefaultKey) {
            this.loadCustomDefaults(selectedCustomDefaultKey);
          }
        } else {
          const fieldJSON: any = {};
          let fieldSecondaryKey: string;
          let secondaryValue: string;
          const sqlORmvinfo: string = this.lookupField.mvinfo || this.lookupField.sqlinfo;
          const items: any[] = this.lookupTable.getSelections();
          const isMultiSelectField: boolean = this.lookupTable.isMultiselectItem();
          const listPrimaryKey: string = this.lookupTable.getPrimaryColumn();
          const fieldPrimaryKey: string = this.lookupField.name === 'APP_ID' ? 'APP_ID' : this.getKey(this.lookupField);
          const isSearchForm: boolean = this.formKind.startsWith('profile_query');
          const primaryValue: string = (isSearchForm || isMultiSelectField) ? (this.lookupTable.getSelectedLookupValues() || '').replace(/[\|]/g, ';') : (items[0]?.[fieldPrimaryKey] || '');
          const defsItem = this.formTemplate.defs.find(i => i.name === fieldPrimaryKey);
          const hasLookupExclusions: boolean = defsItem && !!defsItem._lookupExclusions;
          if (!isMultiSelectField) {
            for (const item of items) {
              this.scanFieldsForRevalidatonOrEvaluation(item, this.lookupField.name);
            }
          }
          if (this.lookupField.fields && this.lookupField.fields.length) {
            fieldSecondaryKey = this.getKey(this.lookupField.fields[0]);
            secondaryValue = items[0][fieldSecondaryKey];
          }
          if (fieldSecondaryKey) {
            fieldJSON[this.lookupField.name] = primaryValue;
            fieldJSON[this.lookupField.fields[0].name] = secondaryValue;
          } else {
            fieldJSON[this.lookupField.name] = primaryValue;
          }
          if (secondaryValue === undefined) {
            const nameKey: string = Util.FieldMappings.secondaryKeyForField(items[0], this.lookupField);
            secondaryValue = items[0][nameKey];
          }
          if (hasLookupExclusions) {
            for (const itm of items) {
              this.removeLookupExclusions(itm, defsItem._lookupExclusions);
            }
          }
          if (items.length === 1 && !isMultiSelectField) {
            this.lookupField.lookupData = [];
            const lookupItem = new CacheItem(primaryValue, secondaryValue, items[0]);
            this.lookupField.lookupData.push(lookupItem);
          } else if (items.length > 0 && isMultiSelectField) {
            this.lookupField.lookupData = [];
            const lookupItem = new CacheItem(primaryValue, '', items);
            this.lookupField.lookupData.push(lookupItem);
          }
          const control = this.form.controls[this.lookupField.name] as FormControl;
          this.updateFormData(fieldJSON, true);
          if (!!control) {
            control.markAsDirty();
          }
          if (this.controller.enableOK) {
            setTimeout(() => {
              this.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
        }
      }
      this.popupCancel();
    }
  }

  removeLookupExclusions(item: any, lookupExclusions: any[]): void {
    for (const field of lookupExclusions) {
      if (!!item[field]) {
        delete item[field];
      }
    }
  }

  getLookupBtnId(): string {
    return !!this.lookupField && !!this.lookupField.name ? 'edx_lkp_btn_' + this.lookupField.name : '';
  }

  private hasLookup(field: FormField): boolean {
    return !!field.lookup && !field.isReadonly && (field.lookup.startsWith('$edx_') || field.lookup.indexOf('@RM.DLL') > -1 || (this.hasLookupTable(field.lookup)));
  }

  private revalidate(field: FormField): boolean {
    if (this.hasLookup(field)) {
      return !field.scheduledForRevalidation && !field.lookupData && !field.autoValidated && field.isRequired;
    }
   return false;
  }

  public doReValidate(field: FormField,value: any): void {
    const control: FormControl = this.form.controls[field.name] as FormControl;
    if (value && ((field.lastLookupValue !== null && field.lastLookupValue !== value) || (field.defaultValue !== null && field.defaultValue !== value))) {
      this.updateControlValue(field.name,value,true);
      if (this.revalidate(field)) {
        field.scheduledForRevalidation = true;
        let parent = this.getField(field.parentField);
        if (parent && !parent.isRequired) {
          parent = null;
        }
        // Do NOT clear children form default values!!!
        const child = this.getField(field.childField);
        if (child) {
          child.retainDefaultValue = true;
        }
        const waitForParent = () => {
          if (parent && parent.scheduledForRevalidation) {
            setTimeout(waitForParent,10);
          } else {
            this.validateLookup(control, field, value);
          }
        };
        setTimeout(waitForParent,10);
      }
    }
  }

  public scanFieldsForRevalidatonOrEvaluation(item: any, selfName?: string): void {
    const enumerableKeys: any = item ? Object.keys(item) : null;
    const rootKeyName: string = selfName ? selfName : '';
    if (enumerableKeys && !!this.createType) {
      for (const key of enumerableKeys) {
        const field = this.getField(key);
        if (field && field.name !== rootKeyName) {
          this.doReValidate(field,item[key]);
        }
      }
    }
  }

  public handleListItemDblClick(table: ListTableComponent, item: ListItem, event: Event, property: string): boolean {
    const handled: boolean = this.canSelectListItem(table, item);
    if (handled) {
      this.popupOK();
    }
    return handled;
  }

  // export interface ListTableParent
  public selectionsUpdated(table: ListTableComponent): void {
    const items: any[] = this.lookupTable ? this.lookupTable.getSelections() : null;
    this.lookupOKDisabled = !(items && items.length);
  }

  public canSelectListItem(table: ListTableComponent, item: ListItem): boolean {
    if (this.lookupTable && !this.formKind.startsWith('profile_query')) {
      if (item['DISABLED'] === '1' || item['DISABLED'] === 'Y') {
        return false;
      }
    }
    return true;
  }

  public isDirty(): boolean {
    return this.form ? this.form.dirty : false;
  }

  public resetDirty(initialData?: any): void {
    const dirtyFields = this.getDirtyValue();
    for (const key in dirtyFields) {
      const field = this.getField(key);
      const control = this.getControl(key);
      if (control) {
        if (field && field.isCheckboxGroup && field.buttonMap && !!initialData && initialData[key]) {
          control.reset(initialData[key]);
        } else {
          if (!!field && field.fldtype==='radiogroup') {
            control.setValue(field.defaultValue);
            this.useForceRender(field);
          }
          control.reset();
        }
      }
    }
    this.inlineFormField = null;
    this.markForCheck();
  }

  public validationBlocking(blockValidation: boolean): void {
    if (blockValidation) {
      ++this.blockValidation;
    } else {
      --this.blockValidation;
    }
  }

  public getCacheKey(fieldName: string): string {
    let key = '';
    if (this.desc) {
      fieldName = Util.FieldMappings.aliasToField(fieldName);
      const field = this.getField(fieldName);
      const lib = this.desc.lib || Util.RestAPI.getPrimaryLibrary();
      key = lib + '.' + fieldName;
      if (field && field.parentField) {
        const parent_values = this.getParentValues(field);
        key += parent_values;
      }
    }
    return key.toUpperCase();
  }

  private getParentValues(field: FormField): string {
    let keyValues = '';
    field = this.getField(field.parentField);
    while (field) {
      keyValues += '.' + this.getFieldValue(field.name);
      field = this.getField(field.parentField);
    }
    return keyValues;
  }

  private loadCustomDefaults(selectedCustomDefaultKey: string) {
    const defaultLookupUrl = this.makeDefaltLookupUrl(this.lookupField, selectedCustomDefaultKey, '');
    Util.RestAPI.get(defaultLookupUrl).subscribe((data) => {
      this.updateViewByCustomProfileDefaults(data['PROFILE_DEFAULTS']);
      this.updateViewByCustomTrusteeDefaults(data['ACL_DEFAULTS']);
    });
  }

  private updateViewByCustomProfileDefaults(customProfileDefaults) {
    const profileDefualts = this.parseCustomProfileDefaults(customProfileDefaults);
    for (const fieldDefault of profileDefualts) {
      const formFields = this.getFieldsBySqlInfo(fieldDefault.sqlInfo);
      for (const formField of formFields) {
        if (fieldDefault.fieldType === FieldType.LOOKUP && !!formField.lookup) {
          const filterKeys = [formField.name, ...(Util.FieldMappings.descMapForField(formField.name) || [])];
          const filter = filterKeys.map(f => `${f}=${fieldDefault.value}*`).join(' or ');
          const fieldLookupUrl = this.makeDefaltLookupUrl(formField, null, filter);
          Util.RestAPI.get(fieldLookupUrl).subscribe((data) => {
            const descriptionName = this.getFieldDecriptionName(formField.name);
            const descriptionValue = data?.list?.[0]?.item?.filter(x => x.PROPNAME === descriptionName && x.VISIBLE)[0]?.DATA;
            this.updateControlValue(formField.name, fieldDefault.value, true, true, null, true);
            this.setFieldDesc(formField.name, descriptionValue);
            this.useForceRender(formField);
          });
        } else {
          this.updateControlValue(formField.name, fieldDefault.value, true, true, null, true);
        }
      }
    }
  }

  private updateViewByCustomTrusteeDefaults(customTrusteeDefaults) {
    const trusteeDefaults = this.parseCustomTrusteeDefaults(customTrusteeDefaults);
    if (trusteeDefaults.length > 0) {
      const currentUser = Util.RestAPI.getUserID();
      let currentUserTrustee = trusteeDefaults.filter(t => t.USER_ID === currentUser)[0];
      if (!currentUserTrustee) {
        currentUserTrustee = { flag: 2, USER_ID: currentUser };
        trusteeDefaults.push(currentUserTrustee);
      }
      currentUserTrustee.rights = AccessLevel.ACCESS_LEVEL_CREATOR;
    }
    this.setSecurityList(trusteeDefaults);
    this.updateSecurityDropdown();
  }

  private makeDefaltLookupUrl(formField, primaryKey, lookupFilter): string {
    const defaultName = primaryKey ? `/defaults/${primaryKey}` : '';
    const key = primaryKey || formField.name;
    const filter = lookupFilter ? `&filter=${lookupFilter}` : '';
    return `/lookups/${formField.lookup}${defaultName}?library=${this.desc.lib}&profile=${this.lookupForm}&max=1&key=${key}${filter}`;
  }

  // Profile default is a comma delimited string with this format:
  // <columnType>;<fieldType>;<sqlInfo>=<value>
  // columnType: Numeric value; refer to ColumnType enum
  // fieldType: Numeric value; refer to FieldType enum
  // sqlInfo:  SQL information of the field. It may contain semicolons.
  // Examples:
  //  0;0;DOCNAME='Def Name',
  //  0;4;AUTHOR.USER_ID;DOCSADM.PEOPLE.SYSTEM_ID='USER2',
  private parseCustomProfileDefaults(customProfileDefaults): any {
    const defaultFields = [];
    if (!!customProfileDefaults) {
      const fields = customProfileDefaults.split(',');
      for (const field of fields) {
        const items = field.split(';');
        if (items.length >= 3) {
          const parts = items.slice(2).join(';').split('=');
          const defaultField = {
            columnType: parseInt(items[0]),
            fieldType: parseInt(items[1]),
            sqlInfo: parts[0],
            value: (parts[1] || '').replace(/^'+/, '').replace(/'+$/, ''),
            toString: () => this
          };
          defaultFields.push(defaultField);
        }
      }
    }
    return defaultFields;
  }

  // Trustee default is a comma delimited string with this format:
  // [U|G]<User or Group name>=<Rights>
  // Example:
  // GDOCS_USERS=63,
  // UUSER2=255,
  private parseCustomTrusteeDefaults(customTrusteeDefaults): any {
    const trusteeList = [];
    if (!!customTrusteeDefaults) {
      const customTrustees = customTrusteeDefaults.split(',');
      for (const customTrustee of customTrustees) {
        const parts = customTrustee.split('=');
        if (parts.length >= 2 && parts[0].length >= 2) {
          const flag = ['G', 'U'].indexOf(parts[0].substring(0, 1).toUpperCase()) + 1; //G = Group, U = User
          if (flag > 0) {
            const trustee = {
              flag,
              USER_ID: parts[0].substring(1),
              rights: parseInt(parts[1])
            };
            trusteeList.push(trustee);
          }
        }
      }
    }
    return trusteeList;
  }

  // Save secured flex lookups info
  public saveSecuredFlexLookupInfo(foundLookupID, field: FormField) {
    const lookupID = field.name;
    const lookupTable = field.lookup;
    const lookupValue = field.defaultValue;
    const childLookupID = field.childField;
    const parentLookupValue = this.data[field.parentField];
    if (this.securedLookupKeyList.length) {
      const keyIndex = this.securedLookupKeyList.findIndex(key => key.lookupID === foundLookupID);
      if (keyIndex === -1) {
        this.securedLookupKeyList.push({lookupTable, lookupID, lookupValue, childLookupID, parentLookupValue});
      }
    } else {
      this.securedLookupKeyList.push({lookupTable, lookupID, lookupValue, childLookupID, parentLookupValue});
    }
    const cacheKey = this.getCacheKey(field.name);
    const cachedItem = this.lookupService.getSuggestionCache(cacheKey);
    const primaryKey = cachedItem?.[0]?.['data']?.['%PRIMARY_KEY'];
    if (primaryKey) {
      this.securedLookupKeyList[this.securedLookupKeyList.length-1]['lookupPrimaryKey'] = primaryKey;
    } else {
      this.getFlexFolderPrimaryKeys();
    }
  }

  // Find secured flex lookups primary keys
  public getFlexFolderPrimaryKeys() {
    const formName = this.lookupForm || this.data?.FORMNAME || this.desc?.['FORMNAME'];
    const securedElement = this.securedLookupKeyList.pop();
    if (!securedElement.lookupPrimaryKey && securedElement.lookupValue && securedElement.lookupTable) {
      const url = `lookups/${securedElement.lookupTable}?library=${this.desc?.lib}&profile=${formName}&key=${securedElement.lookupID}&filter=${securedElement.lookupID}=${securedElement.lookupValue}`;
      Util.RestAPI.get(url).subscribe((data) => {
        if (data.list.length > 1) {
          data.list.forEach((listItem) => {
            const foundClient = listItem.item.find((key) => key.DATA === securedElement.parentLookupValue);
            if (foundClient) {
              securedElement['lookupPrimaryKey'] = listItem.item.find(
                (key) => key.PROPNAME === '%PRIMARY_KEY')?.['DATA'];
            }
          });
        } else {
          securedElement['lookupPrimaryKey'] = data?.list?.[0]?.item?.find(
            (key) => key.PROPNAME === '%PRIMARY_KEY')?.['DATA'];
        }
      });
    }
    this.securedLookupKeyList.unshift(securedElement);
  }

  // Create flex lookup Url
  private makeFlexFolderLookupUrl(): any {
    const formName = this.lookupForm || this.data?.FORMNAME;
    let lookups = '';
    let filter = '';
    let url = '';
    this.securedLookupKeyList.forEach((el) => {
      if (!!el.lookupPrimaryKey) {
        lookups += `${el['lookupID']}=${el['lookupPrimaryKey']},`;
      }
    });
    filter = `&filter=LOOKUP_IDS=${lookups.slice(0, lookups.length - 1)}`;
    if (!lookups) {
      return null;
    } else {
      return url = `/forms/${formName}/defaults?library=${this.desc.lib}${filter}`;
    }
  }

  /* Find secured flex lookup primary key between potential secured flex items */
  public findSecuredFlexLookup() {
    const url = this.makeFlexFolderLookupUrl();
    if (url) {
      Util.RestAPI.get(url).subscribe((data) => {
        const newTrustees = data?.['trustees'];
        if (newTrustees.length) {
          const inheritInfoIndex = newTrustees.findIndex(el => el['USER_ID'] === '@ID_USED');
          const inheritInfo = newTrustees.splice(inheritInfoIndex, 1);
          this.inheritFromPrimaryKey = inheritInfo[0]['rights'];
          this.inheritFromTrustees = newTrustees;
        } else {
          this.inheritFromPrimaryKey = '';
          this.inheritFromTrustees = [];
        }
      });
    }
  }

  public forceExtensionField(field) : boolean{
    return this.desc && (this.desc.type==='searches' || this.desc.type==='workspaces') && field?.name==='$edx_permissions_edit';
  }

  /* Get trustees for secured flex lookup_id and Update security and user rights */
  public updateFlexFolderTrustees() {
    const url = this.makeFlexFolderLookupUrl();
    if (url) {
      Util.RestAPI.get(url).subscribe((data) => {
        const newTrustees = data?.['trustees'];
        if (newTrustees.length) {
          const inheritInfoIndex = newTrustees.findIndex(el => el['USER_ID'] === '@ID_USED');
          const inheritInfo = newTrustees.splice(inheritInfoIndex, 1);
          this.inheritFromPrimaryKey = inheritInfo[0]['rights'];
          this.inheritedFlexTrusteeList = newTrustees;
          this.inheritSecurityIsChecked = true;
          this.userFlexRights = data['rights'];
          this.setSecurityList(newTrustees);
        } else {
          if (!this.userSecurityList?.length && !this.desc.id.startsWith('DV=')) {
            this.setSecurityList([]);
          } else if (this.userSecurityList?.length) {
            this.setSecurityList(this.userSecurityList);
          }
          this.userFlexRights = null;
          this.inheritedFlexTrusteeList = [];
          this.inheritSecurityIsChecked = false;
        }
        this.updateSecurityDropdown();
      });
    }
  }

  /* Need to make sure user has full access level
     to be able to edit a flex item security */
    public canUserEditSecurity(trustees?): boolean {
    const loginReply: any = Util.RestAPI.getLoginReply();
    const group = loginReply?.['GROUP'];
    const user = loginReply?.['USER_ID'];
    const trusteeList = trustees || this.securityList;

    if (!this.userFlexRights && this.userFlexRights !== 0 && trusteeList.length) {
      this.userFlexRights =
        trusteeList.find(
          (trustee) => trustee['USER_ID']?.toLocaleUpperCase() === user
        )?.['rights'] ||
        trusteeList.find(
          (trustee) => trustee['USER_ID']?.toLocaleUpperCase() === group
        )?.['rights'];

      return (
        Number(this.userFlexRights) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.userFlexRights) === AccessLevel.ACCESS_LEVEL_CREATOR
      );
    } else if (!this.userFlexRights && !trusteeList.length) {
      return (
        Number(this.desc['rights']) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.desc['rights']) === AccessLevel.ACCESS_LEVEL_CREATOR ||
        Number(this.desc['%EFFECTIVE_RIGHTS']) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.desc['%EFFECTIVE_RIGHTS']) === AccessLevel.ACCESS_LEVEL_CREATOR
      );
    } else if (this.userFlexRights || Number(this.userFlexRights) === 0) {
      return (
        Number(this.userFlexRights) === AccessLevel.ACCESS_LEVEL_FULL ||
        Number(this.userFlexRights) === AccessLevel.ACCESS_LEVEL_CREATOR
      );
    }
  }
  
  private formatDateFieldsData() {
    if (!this.data || !this.fields) {
      return;
    }
    Util.FieldMappings.forEachTemplateField(this.fields, false, (field): boolean => {
      if (field.controltype === 'editdate') {
        let name: string = field.name;
        this.data[name] = Util.Transforms.getBrowserFormatFromDmDate(this.data[name]);
      }
      return true;
    });
  }

  public resetFormFields() {
    const templateFields: FormFieldDesc[] = this.formTemplate.defs;

      const resetField = (afield: FormFieldDesc) => {
        if (afield.fldtype !== 'push') {
            this.updateControlValue(afield.name, '', true);
        }
      };

      if (templateFields && templateFields.length) {
        templateFields.forEach(tempfield => {
          if (!tempfield.fields || tempfield.fields.length === 0) {
            resetField(tempfield);
          } else {
            const resetSubs = (tempField: FormFieldDesc) => {
              tempField.fields.forEach(subfield => {
                if (!subfield.fields || subfield.fields.length === 0) {
                  resetField(subfield);
                } else {
                  resetSubs(subfield);
                }
              });
            };
            resetSubs(tempfield);
          }
        });
      }

      const headerForm = this.controller.getHeaderForm?.();
      if (headerForm) {
        headerForm.updateControlValue('SECURITY', '2', true);
        this.updateControlValue('SECURITY', '', true);
      }
  }
}
