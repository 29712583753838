import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ElementRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { Util, UserInterface } from '../utils/utils.module';
import { BaseDesc, ListData } from '../models/base';
import { FilterListInterface, ListItem } from '../models/list-item';
import { AccessRights } from '../models/security-control';
import { SelectItem } from '../models/form-field';
import { FormResult } from '../models/command-handler';
import { ListService } from '../services/list.service';
import { DataService } from '../services/data.service';
import { LocalizeService } from '../services/localize.service';
import { SelectComponent } from '../widgets/select.component';
import { ListBaseComponent } from './list-base.component';
import { FavoriteService } from '../services/favorite.service';

enum SplitAction { none=0, expandLeft=1, expandRight=2, collapseLeft=3, collapseRight=4, shiftLeft=5, shiftRight=6 }

@Component ({
  selector: 'edx-list-folder-picker',
  styleUrls: [ 'list-folder-picker.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ListBaseComponent.baseClassInputs,
  templateUrl: 'list-folder-picker.component.html'
})
export class ListFolderPickerComponent extends ListBaseComponent implements OnInit, OnDestroy {
  public splitAction: SplitAction  = SplitAction.none;
  public splitView = false;
  public filteringChild = false;
  public singleSelect = false;
  public dualLists = false;
  public phoneLook = Util.Device.isPhoneLook();
  private isChrome: boolean;
  public showMobileFilter = false;
  public userInterface: UserInterface;
  private swapListOnLoad = false;
  private atRoot = false;
  private savedState: any = null;
  private child: any = { desc:{}, set: {}, list: [], selectedList: [], allItemsIn: false, somethingSelected: false, allSelected: false };
  private crumbs: any[] = [];
  private pickDocs = false;
  private pickDocsOnly = false;
  private pickFilePart = false;
  private pickLibAllowed = false;
  private pickLocations = false;
  private pickPrimaryLibraryOnly = false;
  private somethingSelected = false;
  private allSelected = false;
  private reLoadChild = false;
  private listFilter = '';
  private selectAll = '';
  private libName: string;
  private selectedLocationPath: string = null;
  private createContainerStr: string;
  private selectedList: ListItem[] = [];
  private libSelItems: SelectItem[];
  private libs: any[];
  private originalType: string;
  private _disableList: ListItem[] = null;
  private isExternalLib = false;
  private bCreatingContainer = false;
  private breadCrumbText: string;
  @ViewChild('header') private header: ElementRef;
  @ViewChild('filter') private filterText: FilterListInterface;
  @Output() dismissPicker: EventEmitter<void> = new EventEmitter<void>();
  @Input() kind: string;
  @Input() rights?: number;
  @Input('disableList') set setter(value: ListItem[]) {
    this._disableList = Util.deepCopy(value);
  }

  constructor(protected listService: ListService, protected dataService: DataService, protected localizer: LocalizeService, protected changeRef: ChangeDetectorRef, private elementRef: ElementRef,
              private favService: FavoriteService) {
    super(listService, dataService, localizer, changeRef);
    this.descending = null;
    this.pageSizeIncremental = 50;
    this.isChrome = Util.Device.bIsChrome;
    this.selectAll = this.localizer.getTranslation('PLACEHOLDER.SELECT_ALL');
    this.breadCrumbText = this.localizer.getTranslation('ALT_TEXT.BREADCRUMB');
    this.libs = Util.RestAPI.getLibraries();
  }

  ngOnInit(): void {
    /* Picker list descriptors:
       list_1 - single list that is flat and cannot be navigated
       list_2 - dual list (left parent pane and right child pane) that allows navigation

       Picker type descriptors:
       _single    - select ONLY single item (else list allows selection of multiple items)
       _docs      - can select documents, folders and/or workspaces
       _onlydocs  - can select only documents
       _library   - allow the selection of just the root library
       _locations - show flexfolders, fileplans, public folders and workspaces for each library
       _filepart  - can select ONLY fileplans file-parts (Velimir: This is an ugly kloodge but there seems to be no other way
                    to prevent opening the contents of a file-part when selecting it for command "Change file-part")
       _primarylibrary - allow only primary logged in library to display in the library dropdown
    */
    this.createContainerStr = this.desc.type === 'workspaces' && !this.desc.id ? this.localizer.getTranslation('FOLDER_ACTIONS.NEW_WORKSPACE') : this.localizer.getTranslation('FOLDER_ACTIONS.NEW_FOLDER');
    this.dualLists = this.desc.type !== 'workspaces' && this.desc.type !== 'searches' && this.kind.startsWith('list_2');
    this.pickDocs = this.kind.indexOf('_docs')!==-1;
    this.pickDocsOnly = this.kind.indexOf('_onlydocs')!==-1;
    this.pickFilePart = this.kind.indexOf('_filepart')!==-1;
    this.singleSelect = this.kind.indexOf('_single')!==-1;
    this.pickLibAllowed = this.kind.indexOf('_library')!==-1;
    this.pickLocations = this.kind.indexOf('_locations')!==-1;
    this.pickPrimaryLibraryOnly = this.kind.indexOf('_primarylibrary')!==-1;
    this.originalType = this.desc.type;
    const primaryLib = Util.RestAPI.getPrimaryLibrary();
    if (!this.desc.lib) {
      this.desc.lib = primaryLib;
    }
    this.isExternalLib = Util.isExternalLib(this.desc.lib);
    if (this.desc.id || this.pickLocations) {
      this.setFilterOnFolders(true);
    }
    if (!this.desc.id) {
      this.atRoot = true;
      if (this.originalType==='folders') {
        this.desc.type = undefined;
      }
    }
    if (this.isExternalLib) {
      this.atRoot = true;
    }
    if (!!(this.desc as any).DOCNAME) {
      this.crumbs.push(this.desc);
    }
    this.libSelItems = [];
    if (Util.RestAPI.canUserSaveToRemoteLibrary() && !this.pickPrimaryLibraryOnly) {
      if (!!this.libs && !!this.libs.length) {
        for (const lib of this.libs) {
          if (lib.DISABLED === 'N' && !this.isExternalLib) {
            const libNameUC: string = lib.LIBRARY_NAME.toUpperCase();
            this.libSelItems.push({ value: libNameUC, display: lib.LIBRARY_NAME });
          }
        }
      }
    } else {
      this.libSelItems.push({ value: primaryLib.toUpperCase(), display: primaryLib });
    }
    Util.RestAPI.addToRefreshList(this);
  }

  ngOnDestroy(): void {
    Util.RestAPI.removeFromRefreshList(this);
  }

  private displayLibName(): string {
    let libName: string = !!this.desc ? this.desc.lib : '';
    if (this.isExternalLib) {
      const extAppInfo: any = Util.RestAPI.findExternalApp(this.desc.lib);
      if (extAppInfo && !!extAppInfo['name']) {
        libName = extAppInfo['name'];
      }
    }
    return libName;
  }

  private displayMiniStatus(item: ListItem, property: string): boolean {
    return Util.RestAPI.displayMiniStatus(item, this.desc, property);
  }

  private displayTopMiniStatus(item: ListItem, property: string): boolean {
    return Util.RestAPI.displayTopMiniStatus(item, this.desc, property);
  }

  private formatStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatStatusIcon(item, this.desc);
  }

  private formatTopStatusIcon(item: ListItem): string {
    return Util.RestAPI.formatTopStatusIcon(item, this.desc);
  }

  private formatStatusText(item: ListItem): string {
    return Util.RestAPI.formatStatusText(item);
  }

  private retainScrollPosition(): void {
    setTimeout(() => {
      const openedElement: any = this.elementRef.nativeElement.querySelector('div.item.open');
      if (openedElement && openedElement.scrollIntoView) {
        openedElement.scrollIntoView(false);
      }
    }, 1);
  }

  private disableOK(disable: boolean): void {
    if (disable && this.pickLibAllowed) {
      disable = false;
    }
    this.okDisabled = disable;
    setTimeout(() => {
      this.changeRef.markForCheck();
    }, 1);
  }

  public getSelections(): ListItem[] {
    const fullList: ListItem[] = this.selectedList.concat(this.child.selectedList);
    let uniqueList: ListItem[] = fullList.filter((item, index) => fullList.indexOf(item) === index);
    if (uniqueList.length === 0 && this.pickLibAllowed) {
      uniqueList = [new ListItem({id: undefined, type: this.originalType==='folders'?undefined:this.originalType, lib: this.desc.lib, DOCNAME: this.desc.lib})];
    }
    if (this.pickLocations && uniqueList.length===1 && !!this.selectedLocationPath) {
      uniqueList = Util.deepCopy(uniqueList);
      uniqueList[0]['pickerpath'] = this.selectedLocationPath;
    }
    return uniqueList;
  }

  private restrictToFolders(): boolean {
    return !this.pickDocs && !((this.child.desc.type==='workspaces' && !this.child.desc.id) || (this.child.desc.type==='flexfolders'));
  }

  private filterItemType(): string {
    if (!this.pickDocs) {
      if (this.child.desc.type==='workspaces') {
        return 'W';
      } else if (this.child.desc.type==='folders') {
        return 'F';
      }
      return 'F,W';
    }
    return null;
  }

  private showFavoritesInAddToContainer(lib): boolean {
    const fvItemIndex = this.list.findIndex(i => Util.Transforms.isFavoriteFolder(i.id) && i.type === 'workspaces');
    if (Util.RestAPI.IsFavoritesEnabled()) {
      return fvItemIndex === -1 && lib.LIBRARY_NAME === Util.RestAPI.getPrimaryLibrary();
    } else if (fvItemIndex !== -1) {
      this.list.splice(fvItemIndex,1);
    }
    return false;
  }

  public async listReplaced() {
    super.listReplaced();
    if (this.savedState || this.swapListOnLoad) {
      if (this.savedState) {
        this.copyPrimaryToChild();
        this.restorePrimary();
      } else {
        this.savePrimary();
        this.makeChildPrimary();
        this.restoreChild();
        this.swapListOnLoad = false;
      }
    }
    if ((this.originalType === 'folders' || this.originalType === 'fileparts') && !this.isExternalLib && this.atRoot && (this.crumbs.length < 2 || (this.crumbs.length === 2 && (!this.desc.id && (this.desc['DOCNAME'] !== this.localizer.getTranslation('TILE_NAMES.ALL_FOLDERS')) && (this.desc.type === 'folders' || !this.desc.type)))) && this.desc.type !== 'fileplans') {
      // add in RED and FF if not there.
      let ffItem: ListItem = this.list.find(i => i.id === '' && i.type === 'flexfolders');
      let wsItem: ListItem = this.list.find(i => i.id === '' && i.type === 'workspaces');
      let fpItem: ListItem = this.list.find(i => i.id === '' && i.type === 'fileplans');
      let pfItem: ListItem = this.list.find(i => i.id === 'public' && i.type === 'folders');
      let redItem: ListItem = this.list.find(i => i.id === 'recentedits' && i.type === 'folders');
      let afItem: ListItem = this.list.find(i => !i.id && i.type === 'folders');
      let mfItem: ListItem = this.list.find(i => i.id === 'mine' && i.type === 'folders');
      let rufItem: ListItem = this.list.find(i => i.id === 'recentused' && i.type === 'folders');
      let ruItem: ListItem = this.list.find(i => i.id === 'recentused' && i.type === 'fileplans');
      let gfItem: ListItem = this.list.find(i => i.id === 'groupfavorites' && i.type === 'fileplans');
      const lib = await Util.RestAPI.checkLibrarySettingsByName(this.desc?.lib).toPromise();
      if (!afItem && !this.pickFilePart) {
        afItem = new ListItem({ id: '', type: 'folders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.ALL_FOLDERS') });
        afItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, afItem);
        this.set.total++;
      }
      if (!pfItem && !this.pickFilePart) {
        pfItem = new ListItem({ id: 'public', type: 'folders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.PUBLIC_FOLDERS') });
        pfItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, pfItem);
        this.set.total++;
      }
      if ((!fpItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_RECORDS_MANAGEMENT === 'Y')) {
        fpItem = new ListItem({ id: '', type: 'fileplans', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.FILEPLAN') });
        fpItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, fpItem);
        this.set.total++;
      }
      if (!wsItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_WORKSPACE === 'Y' && !this.pickFilePart) {
        wsItem = new ListItem({ id: '', type: 'workspaces', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.WORKSPACES') });
        wsItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, wsItem);
        this.set.total++;
      }
      if (!ffItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_FLEXFOLDERS === 'Y' && !this.pickFilePart) {
        ffItem = new ListItem({ id: '', type: 'flexfolders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.FLEXFOLDERS') });
        ffItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, ffItem);
        this.set.total++;
      }
      if (!this.pickFilePart) {
        if (!redItem) {
          redItem = new ListItem({ id: 'recentedits', type: 'folders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.RECENTLY_EDITED') });
          redItem['ignoreTypeOnFocus'] = true;
          this.list.splice(0, 0, redItem);
          this.set.total++;
        }
        if (!mfItem) {
          mfItem = new ListItem({ id: 'mine', type: 'folders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.MINE') });
          mfItem['ignoreTypeOnFocus'] = true;
          this.list.splice(0, 0, mfItem);
          this.set.total++;
        }
        if (!rufItem) {
          rufItem = new ListItem({ id: 'recentused', type: 'folders', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.RECENTLY_USED') });
          rufItem['ignoreTypeOnFocus'] = true;
          this.list.splice(0, 0, rufItem);
          this.set.total++;
        }
      }
      if (this.showFavoritesInAddToContainer(lib) && !this.pickLocations) {
        const fvItem = new ListItem(this.favService.getDescForFavorite(this.desc.lib, this.localizer.getTranslation('TILE_NAMES.FAVORITES')));
        fvItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, fvItem);
        this.set.total++;
      }
      if ((!ruItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_RECORDS_MANAGEMENT === 'Y') && (this.originalType === 'fileparts')) {
        ruItem = new ListItem({ id: 'recentused', type: 'fileplans', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.RECENTLY_USED') });
        ruItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, ruItem);
        this.set.total++;
      }
      if ((!gfItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_RECORDS_MANAGEMENT === 'Y') && (this.originalType === 'fileparts')) {
        gfItem = new ListItem({ id: 'groupfavorites', type: 'fileplans', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.GROUP_FAVORITES') });
        gfItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, gfItem);
        this.set.total++;
      }
      if (this._disableList) {
        const ffExcludeItem = this._disableList.find(i => i.id === '' && i.type === 'flexfolders');
        const wsExcludeItem = this._disableList.find(i => i.id === '' && i.type === 'workspaces');
        const fpExcludeItem = this._disableList.find(i => i.id === '' && i.type === 'fileplans');
        const pfExcludeItem = this._disableList.find(i => i.id === 'public' && i.type === 'folders');
        const redExcludeItem = this._disableList.find(i => i.id === 'recentedits' && i.type === 'folders');
        const afExcludeItem = this._disableList.find(i => !i.id && i.type === 'folders');
        const rufExcludeItem = this._disableList.find(i => i.id === 'recentused' && i.type === 'folders');
        const mfExcludeItem = this._disableList.find(i => i.id === 'mine' && i.type === 'folders');
        const ruExcludeItem = this._disableList.find(i => i.id === 'recentused' && i.type === 'fileplans');
        const gfExcludeItem = this._disableList.find(i => i.id === 'groupfavorites' && i.type === 'fileplans');
        if (!ffExcludeItem && !!ffItem) {
          this._disableList.push(ffItem);
        }
        if (!wsExcludeItem && !!wsItem) {
          this._disableList.push(wsItem);
        }
        if (!fpExcludeItem && !!fpItem) {
          this._disableList.push(fpItem);
        }
        if (!pfExcludeItem && !!pfItem) {
          this._disableList.push(pfItem);
        }
        if (!redExcludeItem && !!redItem) {
          this._disableList.push(redItem);
        }
        if (!rufExcludeItem && !!rufItem) {
          this._disableList.push(rufItem);
        }
        if (!mfExcludeItem && !!mfItem) {
          this._disableList.push(mfItem);
        }
        if (!afExcludeItem && !!afItem) {
          this._disableList.push(afItem);
        }
        if (!ruExcludeItem && !!ruItem) {
          this._disableList.push(ruItem);
        }
        if (!gfExcludeItem && !!gfItem) {
          this._disableList.push(gfItem);
        }
      } else {
        this._disableList = [];
        if (!!ffItem) {
          this._disableList.push(ffItem);
        }
        if (!!wsItem) {
          this._disableList.push(wsItem);
        }
        if (!!fpItem) {
          this._disableList.push(fpItem);
        }
        if (!!pfItem) {
          this._disableList.push(pfItem);
        }
        if (!!redItem) {
          this._disableList.push(redItem);
        }
        if (!!mfItem) {
          this._disableList.push(mfItem);
        }
        if (!!rufItem) {
          this._disableList.push(rufItem);
        }
        if (!!afItem) {
          this._disableList.push(afItem);
        }
        if (!!ruItem) {
          this._disableList.push(ruItem);
        }
        if (!!gfItem) {
          this._disableList.push(gfItem);
        }
      }
    } else if (this.originalType === 'filepartworkspaces' && this.crumbs.length < 2) {
      let wsItem: ListItem = this.list.find(i => i.id === '' && i.type === 'workspaces');
      const lib = await Util.RestAPI.checkLibrarySettingsByName(this.desc?.lib).toPromise();
      if (!wsItem && !!lib.EFFECTIVE_RIGHTS && lib.EFFECTIVE_RIGHTS.ENABLE_WORKSPACE === 'Y' && !this.pickFilePart) {
        wsItem = new ListItem({ id: '', type: 'workspaces', lib: this.desc.lib, DOCNAME: this.localizer.getTranslation('TILE_NAMES.WORKSPACES') });
        wsItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, wsItem);
        this.set.total++;
      }
      if (this.showFavoritesInAddToContainer(lib)) {
        const fvItem = new ListItem(this.favService.getDescForFavorite(this.desc.lib, this.localizer.getTranslation('TILE_NAMES.FAVORITES')));
        fvItem['ignoreTypeOnFocus'] = true;
        this.list.splice(0, 0, fvItem);
        this.set.total++;
      }
      if (this._disableList) {
        const wsExcludeItem = this._disableList.find(i => i.id === '' && i.type === 'workspaces');
        const fvExcludeItem = this._disableList.find(i => Util.Transforms.isFavoriteFolder(i.id) && i.type === 'workspaces');
        if (!wsExcludeItem && !!wsItem) {
          this._disableList.push(wsItem);
        }
      } else {
        this._disableList = [];
        if (!!wsItem) {
          this._disableList.push(wsItem);
        }
      }
    }
    if (this.pickLocations) {
      this.list.forEach(item => {
        if (item['NODE_TYPE'] === '%DV_SEARCH') {
          item['STATUS'] = '19';
          item['READONLY'] = 'Y';
        }
      });
    }
    if (this.allSelected) {
      // select all new ones if we have all selected
      this.allSelected = false;
      this.toggleAll(this);
    } else {
      this.somethingSelected = this.selectedList.length !== 0 && this.list.indexOf(this.selectedList[0]) !== -1;
    }
    this.setAllIn(this);
    this.params = null;
    this.changeRef.markForCheck();
    this.retainScrollPosition();
    if (this.reLoadChild) {
      this.reLoadChild = false;
      if (this.restrictToFolders()) {
        this.setFilterOnFolders(true, false, this.child.desc);
      }
      if (this.child.desc.type === 'flexfolders' || this.child.desc.type === 'fileplans' || this.child.desc.type === 'fileparts') {
        this.setSortParams('DOCNAME', null);
      } else {
        this.setSortParams(null, 'LAST_EDIT_DATE');
      }
      this.setLocationQuery(this.child.desc);
      this.fetchMoreChildItems();
    }
    this.disableOK(this.selectedList.length === 0 && this.child.selectedList.length === 0);
    return Promise.resolve();
  }

  public handleFilterChange(filter: string, selKey: string): boolean {
    this.listFilter = filter;
    return this.setFilterOnFolders(this.restrictToFolders(),true);
  }

  public handleFilterSearch(searchString: string): boolean {
    const loadNoFilters = () => {
      if (!this.desc.id && this.desc.type === 'folders') {
        this.params = 'all';
      }
      if (!!this.params || !!this.desc.id || this.showMobileFilter) {
        this.reloadList();
      } else {
        this.list = [];
        this.set.total = 0;
        this.listReplaced();
      }
    };
    if (!!searchString) {
      const searchCriteria: any = {};
      const criteria: any = {};
      const searchForms: any[] = Util.RestAPI.getSearchForms();
      const itemType = this.filterItemType();
      if (searchForms && searchForms.length) {
        searchCriteria['FORM_NAME'] = searchForms[0]['%FORM_NAME'];
      }
      searchCriteria['DESCRIPTION'] = searchString;
      criteria['FULLTEXT_CONTENT_PROFILE'] = searchString;
      if (!!itemType) {
        criteria['ITEM_TYPE'] = itemType;
      }
      searchCriteria['criteria'] = criteria;
      this.reloading = true;
      this.changeRef.markForCheck();
      Util.RestAPI.post({id:'evaluation',lib:Util.RestAPI.getSearchLibQuery(),type:'searches'}, searchCriteria,'' ,'start=0&max='+Util.RestAPI.getDefualtMaxItems()+'&descending=LAST_EDIT_DATE').subscribe((listData: ListData) => {
        this.list = listData.list;
        this.set = listData.set;
        this.listReplaced();
      }, loadNoFilters);
    } else {
      loadNoFilters();
    }
    return false;
  }

  public reloadList(): void {
    if (this.desc.type==='flexfolders'|| this.desc.type==='fileplans' || this.desc.type==='fileparts') {
      this.setSortParams('DOCNAME', null);
    } else if (this.desc.type==='searches') {
      this.listService.setIgnoreSearchLimitWarning(true);
      this.setSortParams(null, 'SYSTEM_ID');
    } else {
      this.setSortParams(null, 'LAST_EDIT_DATE');
    }
    if (this.bCreatingContainer) {
      this.bCreatingContainer = false;
      setTimeout(() => {
        if (!!this.child.desc && !!this.child.desc.type) {
          this.child.list = [];
          this.child.set.total = 0;
          this.reLoadChild = true;
          this.listReplaced();
        } else if (!!this.desc.type) {
          this.list = [];
          this.set.total = 0;
          super.reloadList();
        } else {
          this.reloading = false;
          this.changeRef.markForCheck();
        }
      }, 1000);
    } else {
      if (!!this.desc.type) {
        super.reloadList(true);
      } else {
        this.listReplaced();
      }
    }
  }

  public canShowMore(): boolean {
    return this.pageSizeIncremental > 0 && !this.allItemsIn && this.getList().length > 0;
  }

  public canShowCreateContainer(): boolean {
    const desc = Object.keys(this.child.desc).length !== 0 ? this.child.desc : this.desc;
    const canCreateContainer = desc.type === 'folders' ? (Util.RestAPI.canUserCreatePublicFolders() || desc.id !== 'public') && Util.RestAPI.canUserCreateFolders(): true;
    return !Util.isExternalLib(this.desc.lib) &&  !Util.Transforms.isFavoriteFolder(desc.id) && this.desc.type !== 'fileplans' && this.desc.type !== 'fileparts' && canCreateContainer;
  }

  public canSearchFilter(): boolean {
    return this.child.desc.type !== 'flexfolders' && !Util.isExternalLib(this.desc.lib);
  }

  private resetFilterSearchValue(): void {
    this.listFilter = '';
    this.filterText?.clear();
  }

  private setFilterOnFolders(onlyFolders: boolean, reload?: boolean, desc?: any): boolean {
    const rc = false;
    if (reload) {
      this.allItemsIn = false;
      if (this.splitView) {
        this.savePrimary();
        this.desc = this.child.desc;
        this.list = [];
        this.set = {};
      }
    }
    desc = desc || this.desc;
    if (desc?.type === 'fileplans') {
      onlyFolders = !!desc['PD_FILEPT_NO'];
    }
    if (onlyFolders && !this.pickDocs && !this.pickDocsOnly && !this.pickFilePart && desc.id !== 'public') {
      if ((!!desc.id && !Util.Transforms.isFavoriteFolder(desc.id)) || desc.type === 'folders') { // all folders is id blank string
        this.listService.filter = 'ITEM_TYPE=F and PD_OBJ_TYPE=0' + (Util.RestAPI.restAPIVersion() >= 0x00160700 ? ' and %SEARCH_LEVEL=CURRENT' : '');
        if (this.listFilter && this.listFilter.length) {
          this.listService.filter += ' and ' + this.listFilter;
        }
      } else if ((!desc.id || Util.Transforms.isFavoriteFolder(desc.id)) && desc.type === 'workspaces') {
        this.listService.filter = 'ITEM_TYPE=W';
        if (Util.Transforms.isFavoriteFolder(desc.id) && this.originalType === 'folders') {
          this.listService.filter = 'ITEM_TYPE=F,W';
        }
        if (this.listFilter && this.listFilter.length) {
          this.listService.filter += ' and ' + this.listFilter;
        }
      } else if (!desc.id && desc.type === 'searches' && this.listFilter) {
        this.listService.filter = this.listFilter;
      }
    } else {
      this.listService.filter = this.listFilter;
    }
    if (!desc.id && desc.type === 'folders') {
      this.params = 'all';
    }
    if (reload) {
      this.reloadList();
    }
    return rc;
  }

  private setLocationQuery(item: ListItem): void {
    if (!!item.type && item.type === 'flexfolders' && this.pickLocations) {
      this.listService.query = '&locationsonly';
    }
  }

  private getIconSrc(item: ListItem) {
    return Util.Transforms.iconUrlFromDesc(item);
  }

  private getIconText(item: ListItem) {
    return Util.Transforms.iconAltTextFromDesc(item);
  }

  private getTypeText(item: ListItem) {
    return Util.Transforms.typeTextFromDesc(item);
  }

  private isTwoLine(item: ListItem) {
    return (this.pickDocs || this.pickDocsOnly) && !Util.isContainer(item.type);
  }

  private getTooltip(item: ListItem) {
    let tooltip: string = item.DOCNAME;
    if (!!item['tooltip']) {
      tooltip = item['tooltip'];
    }
    return tooltip;
  }

  private canSelectItem(item: ListItem): boolean {
    let rc = true;
    const curDesc: any = Util.RestAPI.getCurListComponent()?.desc;
    if (Util.Transforms.isFavoriteFolder(item.id)) {
      rc = curDesc?.id!==item.id && !this.pickDocs && !this.pickDocsOnly && !this.pickFilePart && !Util.RestAPI.isFavoriteItemSelected();
      return rc;
    }
    const itemSec = item['%SECURITY'] || item['rights'] || 0xFFFF;
    const itemAccess = +itemSec;
    if (this._disableList) {
      rc = !this._disableList.find(i => Util.isSameDesc(i, item));
    }
    if (rc) {
      if (this.pickDocs) {
        const isFavoriteContainer = Util.Transforms.isFavoriteFolder(curDesc?.id);
        rc = item.type==='documents' || item.type==='folders' || (isFavoriteContainer && item.type==='workspaces');
        if (rc && isFavoriteContainer) {
          rc = !!item['is_favorite']? !item['is_favorite'] : rc;
        }
      } else if (this.pickDocsOnly) {
        rc = item.type==='documents';
      } else if (this.pickFilePart) {
        if (Util.isRMFilePart(item)) {
          if ((!curDesc || (!!curDesc && curDesc.type !== 'workspaces') || this.originalType === 'fileparts') && (item['PD_ACTIVE_STATUS'] === 'C' || !(itemAccess & AccessRights.ACCESS_CAN_ASSIGN_TO_FILEPART))) {
            rc = false;
          }
        } else {
          rc = false;
        }
      } else if (Util.isContainer(item.type)) {
        if (Util.isRMFile(item) || Util.isRMTerm(item) || (this.pickLocations && item.type==='searches') || (item.type === 'fileplans' && item['PD_ACTIVE_STATUS'] === 'C')) {
          rc = false;
        } else if ((item.type === 'workspaces' || item.type === 'folders') && !(itemAccess & AccessRights.ACCESS_EDIT_CONTENT)) {
          rc = false;
        } else if (this.originalType === 'folders' && (item.type === 'flexfolders' && (!this.pickLocations || (item['NODE_TYPE'] !== '%DV_LEVEL_NODE' && item['NODE_TYPE'] !== '%DV_ENUMERATION_NODE')) || item.type === 'searches')) {
          rc = false;
        } else {
          const status: string = item['STATUS'];
          rc = status !== '19' && status !== '3' && item['READONLY'] !== 'Y';
        }
      }
    }
    return rc;
  }

  private canAddToLeft(item): boolean {
    return (!this.splitView && (this.pickDocs || this.pickDocsOnly)) || Util.isContainer(item.type);
  }

  private crumbPath(): string {
    const nCrumbs = this.crumbs.length;
    let path = '';
    let sep = '';
    if (nCrumbs > 1) {
      for (let i=1;i<nCrumbs; i++) {
        const crumb = this.crumbs[i];
        path += sep + crumb['DOCNAME'];
        sep = '/';
      }
    }
    return path;
  }

  private toggleSelected(event: Event, listType: string, item: ListItem) {
    if (this.canSelectItem(item)) {
      let selectedList: ListItem[] = listType==='parent' ? this.selectedList : this.child.selectedList;
      const idx: number = selectedList.indexOf(item);
      if (idx>=0) {
        if (this.singleSelect) {
          selectedList = [];
          this.selectedLocationPath = null;
        } else {
          selectedList.splice(idx, 1);
        }
      } else {
        if (this.singleSelect) {
          this.selectedLocationPath = this.crumbPath();
          selectedList = [item];
        } else {
          selectedList.push(item);
        }
      }
      const somethingSelected: boolean = selectedList.length>0;
      if (listType==='parent') {
        this.somethingSelected = somethingSelected;
        this.allSelected = somethingSelected && selectedList.length===this.list.length;
        this.selectedList = selectedList;
      } else {
        this.child.somethingSelected = somethingSelected;
        this.child.allSelected = somethingSelected && selectedList.length===this.child.list.length;
        this.child.selectedList = selectedList;
      }
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      this.disableOK(this.selectedList.length===0 && this.child.selectedList.length===0);
    }
    return false;
  }

  private toggleAll(obj: any): void {
    obj.allSelected = obj.allSelected ? false : (obj.somethingSelected ? false : true);
    obj.somethingSelected = obj.allSelected;
    obj.selectedList = [];
    if (obj.allSelected) {
      for (const item of obj.list) {
        if (this.canSelectItem(item)) {
          obj.selectedList.push(item);
        }
      }
    }
    this.disableOK(!obj.allSelected || obj.selectedList.length===0);
  }

  private toggleAllChildren(event: Event): void {
    this.toggleAll(this.child);
    if (!!event && event instanceof KeyboardEvent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private toggleAllParents(event: Event): void {
    this.toggleAll(this);
    if (!!event && event instanceof KeyboardEvent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private savePrimary(): void {
    if (!this.phoneLook) {
      this.savedState = { desc: this.desc, set: this.set, list: this.list, selectedList: this.selectedList, allItemsIn: this.allItemsIn };
    }
  }

  private restorePrimary(): void {
    if (this.savedState) {
      this.desc = this.savedState.desc;
      this.set = this.savedState.set;
      this.list = this.savedState.list;
      this.setAllIn(this);
      this.savedState = null;
    }
  }

  private restoreChild(): void {
    if (this.savedState) {
      this.child.desc = this.savedState.desc;
      this.child.set = this.savedState.set;
      this.child.list = this.savedState.list;
      this.child.selectedList = this.savedState.selectedList;
      this.setAllIn(this.child);
      this.savedState = null;
    }
  }

  private makeChildPrimary(): void {
    this.desc = this.child.desc;
    this.set = this.child.set;
    this.allItemsIn = this.child.allItemsIn;
    if (this.pickDocs && this.swapListOnLoad) {
      // remove all non containers from list
      this.list = [];
      for (const item of this.child.list) {
        if (Util.isContainer(item.type)) {
          this.list.push(item);
        }
      }
      this.set.total = this.list.length;
      this.allItemsIn = true;
    } else {
      this.list = this.child.list;
    }
  }

  private copyPrimaryToChild(): void {
    this.child.desc = this.desc;
    this.child.set = this.set;
    this.child.list = this.list;
    this.child.selectedList = this.selectedList;
    this.child.somethingSelected = this.somethingSelected;
    this.child.allSelected = this.allSelected;
    this.setAllIn(this.child);
  }

  public fetchMoreChildItems(): void {
    this.savePrimary();
    this.makeChildPrimary();
    this.setFilterOnFolders(true);
    this.loadList(false);
  }

  private changeDesc(item: ListItem, onlyFolders: boolean): void {
    this.resetFilterSearchValue();
    this.desc = item;
    this.createContainerStr = this.desc.type === 'workspaces' && !this.desc.id ? this.localizer.getTranslation('FOLDER_ACTIONS.NEW_WORKSPACE') : this.localizer.getTranslation('FOLDER_ACTIONS.NEW_FOLDER');
    this.setFilterOnFolders(onlyFolders);
    this.setLocationQuery(item);
    this.list = [];
    this.selectedList = [];
    this.set = {};
    this.allItemsIn = false;
    this.somethingSelected = false;
    this.allSelected = false;
    this.reloadList();
  }

  public containerHeight(): string {
    let rc = 'calc(100% - 2.0625rem)';
    if (this.header && this.header.nativeElement) {
      const height: number = this.header.nativeElement.offsetHeight;
      if (height) {
        rc = 'calc(100% - ' + height + 'px)';
      }
    }
    return rc;
  }

  private setAllIn(obj: any): void {
    if (!obj.set.total) {
      obj.set.total = obj.list.length;
    }
    obj.allItemsIn = obj.list.length >= obj.set.total;
  }

  private createContainerClick(): void {
    const desc = Util.deepCopy(this.phoneLook ? this.desc : this.child.desc);
    if (!!desc.id && isNaN(desc.id) && desc.id !== 'public' && desc.type !== 'flexfolders') {
      desc.id = 'recentedits';
    }
    this.bCreatingContainer = true;
    Util.RestAPI.createContainer(desc.type === 'workspaces' && !desc.id ? 'workspaces' : 'folders', desc).then((result: FormResult) => {
      if (result.success) {
        this.setFilterOnFolders(true, false, desc);
        this.reloading = true;
        this.changeRef.markForCheck();
      } else {
        this.bCreatingContainer = false;
      }
    }, err => {
      this.bCreatingContainer = false;
    });
  }

  private parentItemClicked(item: ListItem, event?: Event): void {
    if (!this.reloading && (!this.pickLocations || item['NODE_TYPE']!=='%DV_SEARCH') && ((!this.splitView && (!(Util.isRMFilePart(item) && this.pickFilePart) && Util.isContainer(item.type))) || this.splitView)) {
      const activeElementClass = document.activeElement?.getElementsByClassName('selector')[0]?.classList?.value;
      if (event?.type === 'keydown' && activeElementClass?.indexOf('selector') >= 0 && activeElementClass?.indexOf('disabled') === -1) {
        this.toggleSelected(null, 'parent', item);
      } else if (this.dualLists && this.child.desc!==item) {
        if (this.splitView) {
          this.crumbs.splice(this.crumbs.length-1,1,item);
        } else {
          this.setSplitAction(SplitAction.expandRight);
          this.crumbs.push(item);
        }
        this.savePrimary();
        this.child.desc = {};
        this.child.set = {};
        this.child.list = [];
        this.child.selectedList = [];
        this.child.allItemsIn = false;
        this.child.somethingSelected = false;
        this.changeDesc(item, (!!this.savedState || this.phoneLook) && !((item.type==='workspaces' && !item.id) || (item.type==='flexfolders')));
        const waitForListLoading = () => {
          if (this.reloading) {
            setTimeout(waitForListLoading, 400);
          } else {
            Util.Transforms.focusOnElementByClassName('item open');
          }
        }
        waitForListLoading();
      } else if (this.desc.type === 'workspaces') {
        this.toggleSelected(null, 'parent', item);
      }
    }
    if (!!event && event instanceof KeyboardEvent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private childItemClicked(item: ListItem, event?: Event): void {
    if ((this.originalType === 'filepartworkspaces') || (this.originalType === 'folders' && event?.type === 'keydown' &&
    document.activeElement?.getElementsByClassName('selector')?.length > 0)) {
      this.toggleSelected(null, 'child', item);
    } else if (!this.reloading && Util.isContainer(item.type) && !(Util.isRMFilePart(item) && this.pickFilePart)) {
      // Clicking on File-Part when selecting it do nothing. Behave like leaf node.
      this.setSplitAction(SplitAction.shiftLeft);
      this.swapListOnLoad = true;
      this.crumbs.push(item);
      this.changeDesc(item, true);
      setTimeout(() => {
        Util.Transforms.focusOnElementByClassName('item open');
      }, 300);
    }
    if (!!event && event instanceof KeyboardEvent) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  private crumbClicked(crumb: any): void {
    if (!this.reloading) {
      const index: number = this.crumbs.indexOf(crumb);
      const lastIndex: number = this.crumbs.length-1;
      if (index>=0 && index<lastIndex) {
        const nBack: number = lastIndex-index;
        this.child.desc = {};
        this.child.set = {};
        this.child.list = [];
        this.child.selectedList = [];
        this.child.allItemsIn = false;
        if (index===0) {
          this.params = null;
          this.setSplitAction(SplitAction.collapseRight);
        } else {
          this.params = 'references';
          this.setSplitAction(SplitAction.shiftRight);
        }
        this.list = [];
        this.set = {};
        this.allItemsIn = false;
        if (this.params) {
          this.crumbs.splice(index+1,nBack);
          this.child.desc = this.crumbs[index] as BaseDesc;
          this.desc = this.crumbs[index-1] as BaseDesc;
          this.params = null;
          this.reLoadChild = true;
        } else {
          if (this.atRoot) {
            if (this.isExternalLib) {
              this.list[0] = this.crumbs[1];
            }
            this.crumbs.splice(1,nBack);
          } else {
            if (index===0) {
              if (!this.isExternalLib) {
                this.crumbs[0].DOCNAME = this.localizer.getTranslation('FOLDER_ACTIONS.CONTAINERS');
              }
            }
            if (this.isExternalLib) {
              this.list[0] = this.crumbs[1];
            }
            this.crumbs.splice(1,nBack-1);
            this.atRoot = true;
          }
          this.desc = this.crumbs[0];
        }
        this.createContainerStr = ((this.desc.type === 'workspaces' && !this.desc.id) || (this.desc.type === undefined && !!this.child.desc && this.child.desc.type === 'workspaces')) ? this.localizer.getTranslation('FOLDER_ACTIONS.NEW_WORKSPACE') : this.localizer.getTranslation('FOLDER_ACTIONS.NEW_FOLDER');
        this.resetFilterSearchValue();
        if (this.pickLocations && (!((this.desc.type==='workspaces' && !this.desc.id) || (this.desc.type==='flexfolders') || (this.desc.type==='fileplans')) || (this.isExternalLib))) {
          this.setFilterOnFolders(true);
        } else {
          this.listService.filter = null;
        }
        this.setLocationQuery(crumb);
        this.reloadList();
      }
    }
  }

  private libChanged(selectComponent: SelectComponent): void {
    const selItem: SelectItem = this.libSelItems.find(i => i.value === selectComponent.value);
    if (this._disableList) {
      this._disableList = this._disableList.filter(i => !(
        (i.id === '' && i.type === 'folders') ||
        (i.id === '' && i.type === 'flexfolders') ||
        (i.id === '' && i.type === 'workspaces') ||
        (i.id === '' && i.type === 'fileplans') ||
        (i.id === 'recentedits' && i.type === 'folders') ||
        (i.id === 'public' && i.type === 'folders'))
      );
      this._disableList.forEach(item => item.lib = selItem.value);
    }
    const libItem: any = { type: this.originalType === 'folders' ? undefined : this.originalType, id: '', lib: selItem.value, DOCNAME: selItem.display };
    this.crumbs = this.crumbs.slice(0, 1);
    this.crumbs[0].lib = libItem.lib;
    this.changeDesc(libItem, true);
    if (this.splitView) {
      this.params = null;
      this.setSplitAction(SplitAction.collapseRight);
    }
  }

  private setSplitAction(action: SplitAction): void {
    this.splitAction = action;
    // work around the crap css rules engine for animation in IE/Edge
    setTimeout(() => {
      this.animationComplete();
      setTimeout(() => {
        this.changeRef.markForCheck();
      }, 1);
    }, 500);
  }

  public animationComplete(): void {
    if (this.splitAction !== SplitAction.none) {
      switch (this.splitAction) {
      case SplitAction.expandLeft:
      case SplitAction.expandRight:
        this.splitView = !this.phoneLook;
        this.showMobileFilter = this.phoneLook;
        break;
      case SplitAction.collapseLeft:
      case SplitAction.collapseRight:
        this.splitView = false;
        this.showMobileFilter = false;
        break;
      }
      this.splitAction = SplitAction.none;
    }
  }

  public showSelectAll(list: ListItem[]): boolean {
    let count = 0;
    for (const listItem of list) {
      if (this.canSelectItem(listItem)) {
        count++;
      }
      if (count === 2) {
        break;
      }
    }
    return count > 1;
  }

  public showChildSelectAll(): boolean {
    return (this.pickDocs && !this.singleSelect && this.showSelectAll(this.child.list)) || (!this.singleSelect && this.showSelectAll(this.child.list));
  }

  public focusOnList(id: string) {
    const activeElement = document.activeElement;
    if (activeElement.tagName !== 'INPUT') {
      const listElement = document.getElementById(id);
      if (listElement) {
        listElement.focus();
      }
    }
  }

  public onKeyDown(event: KeyboardEvent, isParent: boolean): boolean {
    if (event.key) {
      switch (event.key) {
      case 'ArrowDown':
        this.nav(1, event.shiftKey, isParent);
        return false;
      case 'ArrowUp':
        this.nav(-1, event.shiftKey, isParent);
        return false;
      }
    }
    return true;
  }

  protected nav(inc: number, multiSelect: boolean, isParent: boolean): void {
    const currentActive = document.activeElement;
    if (multiSelect) {
      const activeElementIndex: number = +document.activeElement?.id?.split('_')?.splice(-1)[0];
      const index: number = activeElementIndex + inc;
      const updatedItem: ListItem =  isParent ? this.list?.[index] : this.child?.list?.[index];
      this.toggleSelected(null, isParent ? 'parent' : 'child', updatedItem);
    }
    if (inc === 1) {
      (currentActive?.nextElementSibling as HTMLInputElement)?.focus();
    } else {
      (currentActive?.previousElementSibling as HTMLInputElement)?.focus();
    }
  }

  private getLabel(item: any, isParent?: boolean) {
    const isSelectedItem = isParent && !Util.Device.bIsOfficeAddin ? this.child?.selectedList.indexOf(item) >= 0 : this.selectedList.indexOf(item) >= 0;
    const resourceKey = isSelectedItem ? 'ALT_TEXT.SELECTED' : (this.canSelectItem(item) ? 'ALT_TEXT.NOT_SELECTED' : 'ALT_TEXT.NON_SELECTABLE');
    return Util.Transforms.typeTextFromDesc(item) + ' ' + item.DOCNAME + ' ' + this.localizer.getTranslation(resourceKey);
  }
}
