import { Component, OnChanges, Input, Output, OnDestroy, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { UserInterface } from '../utils/device';
import { Util } from '../utils/utils.module';
import { ListData } from '../models/base';
import { LocalizeService } from '../services/localize.service';
import { first } from 'rxjs';
@Component({
  selector: 'edx-version-viewer',
  styleUrls: ['version-viewer.component.scss'],
  templateUrl: 'version-viewer.component.html'
})

export class VersionViewer {

  public selectedVersionLabel = '';
  public selectedVersion: any = null;
  public selectedVersionIndex: number = 0;
  public show = true;
  public pinned = true;
  private loadMoreText: string = '';
  private pinLabel: string = '';
  private versionsText: string = '';
  private start = 0;
  private limit = 5;
  private end = this.limit;
  @Input() versions: any = [];
  @Input() desc: any = null;
  @Input() ui: UserInterface;
  @Input() total = 1;
  @Output() versionClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() loadMoreVersions: EventEmitter<any> = new EventEmitter<any>();
  constructor(private cdr: ChangeDetectorRef, private localizer: LocalizeService) {
    this.loadMoreText = this.localizer.getTranslation('PAGINATOR.MORE');
    this.pinLabel = this.localizer.getTranslation('ALT_TEXT.PINNED');
    this.versionsText = this.localizer.getTranslation('METADATA.TABS.VERSIONS');
  }

  ngOnInit() {
    this.loadViewer(this.versions[0], 0, true);
  }

  public loadViewer(version: any, index: number, isInitial?: boolean) {
    if (version !== this.selectedVersion) {
      this.selectedVersion = version;
      this.selectedVersionLabel = version?.['VERSION_LABEL'];
      this.selectedVersionIndex = index;
      if (!isInitial) {
        this.versionClicked.emit(version?.['VERSION_ID']);
      }
    }
  }

  public showVersionsPane(show) {
    if (this.pinned === false && this.ui >= 2) {
      this.show = show;
    }
  }
  public getElementId(index: string) {
    return 'edx-version-' + index;
  }

  public pinMobileVersionsPane() {
    this.pinned = !this.pinned;
    this.pinLabel = this.localizer.getTranslation('ALT_TEXT.' + (this.pinned ? 'PINNED' : 'UNPINNED'))
  }

  public canShow(): boolean {
    return this.ui >= 2 ? this.show : true;
  }

  public loadMore(): void {
    this.start = this.start + this.limit;
    this.loadMoreVersions.emit({ 'start': this.start, 'end': this.limit });
  }

  public onKeyDown(event: KeyboardEvent, index?: number) {
    const key = event.key;
    event.preventDefault();
    event.stopPropagation();
    switch (key) {
      case 'ArrowUp':
        this.navigateVersionsList(index, -1);
        break;
      case 'ArrowDown':
        this.navigateVersionsList(index, 1);
        break;
      case 'Tab':
        let focusElement = '';
        let focusElementArray = null;
        if (document.activeElement === Util.Transforms.getHTMLElementById('edx-selected-version')) {
          if (event.shiftKey) {
            this.showVersionsPane(false);
            focusElement = '#edx_header_menu';
          } else {
            focusElement = '#edx-pin-button';
          }
        } else if (document.activeElement === Util.Transforms.getHTMLElementById('edx-version-viewer-loadmore')) {
          if (event.shiftKey) {
            focusElement = `#edx-version-${this.versions.length - 1}`;
          } else {
            if (this.ui >= 2) {
              this.showVersionsPane(false);
              focusElement = '#edx-zoom-in';
            } else {
              focusElement = '.contentpanel';
            }
          }
        } else if (document.activeElement === Util.Transforms.getHTMLElementById('edx-version-0') && event.shiftKey) {
          focusElement = '#edx-pin-button';
        } else if (document.activeElement === Util.Transforms.getHTMLElementById('edx-pin-button')) {
          if (event.shiftKey) {
            this.showVersionsPane(false);
            focusElement = '#edx-selected-version';
          } else {
            focusElement = '#edx-version-0';
          }
        } else {
          if (this.ui >= 2) {
            if (event.shiftKey) {
              focusElement = '#edx-pin-button';
            } else {
              focusElementArray = ['#edx-version-viewer-loadmore', '#edx-zoom-in'];
            }
          } else {
            if (event.shiftKey) {
              focusElement = '.contentpanel';
            } else {
              focusElementArray = ['#edx-version-viewer-loadmore', '.contentpanel'];
            }
          }
        }
        if (!!focusElement) {
          Util.Transforms.focusOnElementByQuerySelector(focusElement);
        } else if (!!focusElementArray) {
          Util.Transforms.focusOnElementByAnyQueryFromArray(focusElementArray);
        }
        break;
    }
  }

  public navigateVersionsList(currentIndex: number, shift: number): void {
    currentIndex = currentIndex + shift;
    if (currentIndex >= 0 && currentIndex < this.versions.length) {
      Util.Transforms.focusOnElementById('edx-version-' + currentIndex);
    }
  }

  public getAriaLabel(version): string {
    if (!!version && !!version['STATUS']) {
      return Util.RestAPI.formatStatusText(version) + this.localizer.getTranslation('COLUMN_HEADINGS.VERSIONS.VERSION') + version['VERSION_LABEL'] + (version['VERSION_LABEL'] === this.selectedVersionLabel ? this.localizer.getTranslation('ALT_TEXT.SELECTED') : this.localizer.getTranslation('ALT_TEXT.NOT_SELECTED'));
    }
  }

  public hideLoadMore(): boolean {
    return this.versions.length === this.total;
  }

  public formatStatusText(version: any): string {
    return Util.RestAPI.formatStatusText(version);
  }
}


