/**
 * Created by kevin on 2016-10-06.
 *
 * Bag of static functions to do various data transforms
 */
import { AccessLevel, AccessRights } from '../models/security-control';
import { LocalizeService } from '../services/localize.service';
import { _Device } from './device';

const oneDayInMinutes: number = (24 * 60);
const oneMinuteInMS: number = (60 * 1000);
const oneDayInMS: number = (oneMinuteInMS * oneDayInMinutes);
const kIconBase = 'assets/images/';

class SVGDataService {
  private cache = {};
  private pending = {};

  public dataFromAppIDIcon(appID: string, icon: string): Promise<string> {
    let appIDToCheck: string = appID;
    if (!icon) {
      icon = 'mime_document2.svg';
      appIDToCheck = 'DEFAULT';
    }
    if (!!this.cache[appIDToCheck]) {
      return Promise.resolve(this.cache[appIDToCheck]);
    }
    if (!!this.pending[appIDToCheck]) {
      return new Promise<string>((resolve, reject) => {
        const waitFunc = () => {
          if (!this.cache[appIDToCheck]) {
            setTimeout(waitFunc,100);
          } else {
            delete this.pending[appIDToCheck];
            resolve(this.cache[appIDToCheck]);
          }
        };
        setTimeout(waitFunc,100);
      });
    } else {
      this.pending[appIDToCheck] = true;
    }
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const path: string = location.pathname;
      const pathParts: string[] = path.split('/');
      pathParts.splice(pathParts.length-1, 1);
      const url: string = location.origin + pathParts.join('/') + '/' + kIconBase + icon;
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 0) {
            try {
              if (!!xhr.response) {
                const svgData: string = 'data:image/svg+xml,'+encodeURIComponent(xhr.response);
                this.cache[appIDToCheck] = svgData;
                resolve(svgData);
              } else {
                reject('no response');
              }
            } catch (e) {
              reject(e);
            }
          } else {
            reject(`bad status: ${xhr.status}`);
          }
        }
      };
      xhr.onerror = reject;
      xhr.send();
    });
  }
}

export class _Transforms {
  private svgDataService: SVGDataService = new SVGDataService();
  private iconBase: string = kIconBase;
  private localizer: LocalizeService;
  private device: _Device = null;
  private externalApps: any = null;
  private externalAppsPrefix: string;
  public docIDSeparator ='-#';
  public hyphenSeparator = '-';
  public versionLabelSeparator = '-v';
  private dateRangeTokens: any = {};
  private dateRangeTokensTranslated: any = {};
  public restAPIVers = 0;
  public myUserID = '';
  public DOUBLE_QUOTE = '"';

  constructor(device: _Device) {
    this.device = device;
    if (this.device?.bIsCordova) {
      this.docIDSeparator = '-n';
    }
  }

  private static extToAppMap: any = {
    JPG:            'DOCSIMAGE',
    JPEG:           'DOCSIMAGE',
    JPE:            'DOCSIMAGE',
    TIFF:           'DOCSIMAGE',
    PNG:            'DOCSIMAGE',
    GIF:            'DOCSIMAGE',
    BMP:            'DOCSIMAGE',
    DOC:            'MS WORD',
    DOCX:           'MS WORD',
    XLS:            'MS EXCEL',
    XLSX:           'MS EXCEL',
    PPT:	          'MS POWERPOINT',
    PPTX:           'MS POWERPOINT',
    MSG:            'MS OUTLOOK',
    PDF:            'ACROBAT',
    MP3:            'AUDIO',
    WAV:            'AUDIO',
    M4A:            'VIDEO',
    MP4:            'VIDEO',
    AVI:            'VIDEO',
    MOV:            'VIDEO'
  };

  private static applicationIconMap: any = {
    'MS WORD':          'mime_word.svg',
    'MS EXCEL':         'mime_excel.svg',
    'MS POWERPOINT':    'mime_powerpoint.svg',
    'MS OUTLOOK':       'mime_outlook.svg',
    'MS PUBLISHER':     'mime_publisher.svg',
    'MS VISIO':         'mime_visio.svg',
    'MS PROJECT':       'mime_project.svg',
    VISIO:              'mime_visio.svg',
    'MS ONENOTE':       'mime_onenote.svg',
    ACROBAT:            'mime_pdf.svg',
    PAPER:              'mime_paper.svg',
    MEMO:               'mime_paper.svg',
    DOCSIMAGE:          'mime_image.svg',
    QPW:                'mime_function.svg',
    PRESENTATIONS:      'mime_presentation.svg',
    AUDIO:              'mime_audio.svg',
    VIDEO:              'mime_video.svg',
    FORM:               'mime_form.svg',
    '%PRIMARY_FORM':    'mime_form.svg',
    DEFAULT:            'mime_document.svg',
    _GROUPS_ENABLED:    'mime_group.svg',
    _GROUP_MANAGER:     'mime_person.svg',
    '%FORM_PAPER_APPLICATION': 'mime_paper.svg'
  };

  private static  typeIconMap: any = {
    searches:       'mime_saved_search.svg',
    folders:        'mime_folder.svg',
    folder:         'mime_folder.svg',
    saved_search:   'mime_saved_search.svg',
    unsaved_search: 'mime_unsaved_search.svg',
    flexfolders:    'flexfolder32.svg',
    workspaces:     'mime_workspace.svg',
    urls:           'mime_html.svg',
    favorites:      'title_favorites32.svg',
    libraries:      'library32.svg',
    word:           'mime_word.svg',
    excel:          'mime_excel.svg',
    powerpoint:     'mime_powerpoint.svg',
    outlook:        'mime_outlook.svg',
    publisher:      'mime_publisher.svg',
    visio:          'mime_visio.svg',
    onenote:        'mime_onenote.svg',
    pdf:            'mime_pdf.svg',
    image:          'mime_image.svg',
    lookups:        'generic_lookup.svg'
  };

  private static idIconMap: any = {
    recentedits:  'title_recently_edited36.svg',
    quicksearch:  'tile_custom_view_search.svg',
    checkedout:   'title_assignment.svg'
  };

  private static idFileplanMap: any = {
    Term:           'term_32x32.svg',
    File:           'file_32x32.svg',
    FilePart:       'file_part_32x32.svg',
    'Term-Phone':     'term_16x16.svg',
    'File-Phone':     'file_16x16.svg',
    'FilePart-Phone': 'file_part_16x16.svg'
  };

  private static videoFileExtensions: any = {
    '3GP': 'video/3gpp',
    '3G2': 'video/3gpp2',
    AVI: 'video/x-msvideo',
    UVH: 'video/vnd.dece.hd',
    UVM: 'video/vnd.dece.mobile',
    UVU: 'video/vnd.uvvu.mp4',
    UVP: 'video/vnd.dece.pd',
    UVS: 'video/vnd.dece.sd',
    UVV: 'video/vnd.dece.video',
    FVT: 'video/vnd.fvt',
    F4V: 'video/x-f4v',
    FLV: 'video/x-flv',
    FLI: 'video/x-fli',
    H261: 'video/h261',
    H263: 'video/h263',
    H264: 'video/h264',
    JPM: 'video/jpm',
    JPGV: 'video/jpeg',
    M4V: 'video/x-m4v',
    ASF: 'video/x-ms-asf',
    PYV: 'video/vnd.ms-playready.media.pyv',
    WM: 'video/x-ms-wm',
    WMX: 'video/x-ms-wmx',
    WMV: 'video/x-ms-wmv',
    WVX: 'video/x-ms-wvx',
    MJ2: 'video/mj2',
    TS: 'video/mp2t',
    MXU: 'video/vnd.mpegurl',
    MPEG: 'video/mpeg',
    MP4: 'video/mp4',
    OGG: 'video/ogg',
    OGV: 'video/ogg',
    WEBM: 'video/webm',
    QT: 'video/quicktime',
    MOV: 'video/quicktime',
    MOVIE: 'video/x-sgi-movie',
    VIV: 'video/vnd.vivo'
  };

  private getFile(filePath: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      const path: string = location.pathname;
      const pathParts: string[] = path.split('/');
      pathParts.splice(pathParts.length-1, 1);
      const url: string = location.origin + pathParts.join('/') + '/' + filePath;
      xhr.open('GET', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200 || xhr.status === 0) {
            try {
              if (!!xhr.response) {
                resolve(xhr.response);
              } else {
                reject('not found');
              }
            } catch (e) {
              reject(e);
            }
          } else {
            reject(`bad status: ${xhr.status}`);
          }
        }
      };
      xhr.onerror = reject;
      xhr.send();
    });
  }

  private descImage(desc: any): string {
    const base = this.iconBase;
    let path: string = desc['imgPath'];
    if (path) {
      if (path.indexOf('/')===-1) {
        path = base + path;
      }
      return path;
    }
    return null;
  }

  private titleIconUrlFromDesc_OfficeAddin(desc: any): string {
    const base = this.iconBase;
    if (this.descImage(desc)) {
      return this.descImage(desc);
    }
    if (desc.type==='flexfolders') {
      return base + 'flex_folders32.svg';
    }
    if (desc.type==='workspaces') {
      return base + 'workspaces32.svg';
    }
    if (desc.type==='searches') {
      return base + 'saved_searches32.svg';
    }
    if (desc.type==='fileplans') {
      return base + 'file_plan32.svg';
    }
    if (desc.type === 'requests') {
      return base + 'active_requests32.svg';
    }
    if (desc.type==='activities') {
      return base + 'recent_activites36.svg';
    }
    if (desc.id && desc.id.length) {
      if (desc.type==='folders') {
        if (desc.id==='checkedout') {
          return base + 'checked_out32.svg';
        } else if (desc.id==='public') {
          return base + 'public_folders32.svg';
        } else if (desc.id==='recentedits') {
          return base + 'recently_edited32.svg';
        } else if (desc.id==='deleted') {
          return base + 'deleted36.svg';
        } else if (desc.id==='all') {
          return base + 'all_folders36.svg';
        } else if (desc.id==='templates') {
          return base + 'templates36.svg';
        }
        return base + 'folders32.svg';
      }
    }
    return this.titleIconUrlFromDesc_Generic(desc);
  }

  private titleIconUrlFromDesc_Phone(desc: any): string {
    const base = this.iconBase;
    if (this.descImage(desc)) {
      return this.descImage(desc);
    }
    if (desc.type==='flexfolders') {
      if (!desc.id) {
        return base + 'title_flexfolder48_li.svg';
      } else {
        return base + 'flexfolder32.svg';
      }
    }
    if (desc.type === 'requests') {
      return base + 'active_requests_phone_36x36.svg';
    }
    if (desc.type === 'boxes') {
      return base + 'mime_box_32.svg';
    }
    if (desc.type==='workspaces') {
      if (!desc.id) {
        return base + 'title_workspaces48_li.svg';
      } else {
        if (this.isFavoriteFolder(desc.id)) {
          return this.iconUrlPath('favorites');
        }
        if (desc['IS_SHARED']==='1') {
          return base + 'mime_workspace_shared.svg';
        } else {
          return base + 'mime_workspace.svg';
        }
      }
    }
    if (desc.type==='searches') {
      return base + 'title_search48_li.svg';
    }
    if (desc.type==='fileplans') {
      if (!desc.id) {
        return base + 'file_plan_phone_36x36.svg';
      } else if (desc.id === 'groupfavorites') {
        return base + 'group_fav_phone_36x36.svg';
      } else if (desc.id === 'recentused') {
       return base + 'recent_used_phone_36x36.svg';
      } else if (desc.id && desc.id.startsWith('FP-FilePart')) {
        return base + _Transforms.idFileplanMap['FilePart-Phone'];
      } else if (desc.id && desc.id.startsWith('FP-File')) {
        return base + _Transforms.idFileplanMap['File-Phone'];
      } else if (desc.id && desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm')) {
        return base + _Transforms.idFileplanMap['Term-Phone'];
      }
    }
    if (desc.type==='activities') {
      return base + 'title_recent_activites36_li.svg';
    }
    if (desc.id && desc.id.length) {
      if (desc.type==='folders') {
        if (desc.id==='downloads') {
          return base + 'title_offline48_li.svg';
        }
        if (desc.id==='imports') {
          return base + 'title_imports48_li.svg';
        }
        if (desc.id==='checkedout') {
          return base + 'title_checked_out36.svg';
        } else if (desc.id==='public') {
          return base + 'title_public_folders48_li.svg';
        } else if (desc.id==='recentedits') {
          return base + 'title_recently_edited48_li.svg';
        } else if (desc.id==='deleted') {
          return base + 'deleted_li.svg';
        } else if (desc.id==='all') {
          return base + 'all_folders_li.svg';
        } else if (desc.id==='templates') {
          return base + 'templates_li.svg';
        }
        return base + 'title_folders48_li.svg';
      }
    }
    return this.titleIconUrlFromDesc_Generic(desc);
  }

  private titleIconUrlFromDesc_ExternalApp(desc: any): string {
    const extAppInfo: any = this.findExternalApp(desc.lib);
    const base = this.iconBase;
    return base + extAppInfo['imgPath'];
  }

  private titleIconUrlFromDesc_Generic(desc: any): string {
    const base = this.iconBase;
    if (this.descImage(desc)) {
      return this.descImage(desc);
    }
    if (desc.type==='flexfolders') {
      if (!desc.id) {
        return base + 'title_flexfolder36.svg';
      } else {
        return base + 'flexfolder32.svg';
      }
    }
    if (desc.type === 'requests') {
      return base + 'active_requests_36x36.svg';
    }
    if (desc.type === 'boxes') {
      return base + 'mime_box_32.svg';
    }
    if (desc.type==='workspaces') {
      if (!desc.id) {
        return base + 'title_my_workspace.svg';
      } else {
        if (this.isFavoriteFolder(desc.id)) {
          return this.iconUrlPath('favorites');
        }
        if (desc['IS_SHARED']==='1') {
          return base + 'mime_workspace_shared.svg';
        } else {
          return base + 'mime_workspace.svg';
        }
      }
    }
    if (desc.type==='searches') {
      return base + 'title_search36.svg';
    }
    if (desc.type==='fileplans') {
      if (!desc.id) {
        return base + 'file_plan_36x36.svg';
      } else if (desc.id === 'groupfavorites') {
        return base + 'group_fav_36x36.svg';
      } else if (desc.id === 'recentused') {
        return base + 'recent_used_36x36.svg';
      } else if (desc.id && desc.id.startsWith('FP-FilePart')) {
        return base + _Transforms.idFileplanMap['FilePart'];
      } else if (desc.id && desc.id.startsWith('FP-File')) {
        return base + _Transforms.idFileplanMap['File'];
      } else if (desc.id && desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm')) {
        return base + _Transforms.idFileplanMap['Term'];
      }
    }
    if (desc.type==='activities') {
      return base + 'title_recent_activites36.svg';
    }
    if (desc.id && desc.id.length) {
      if (desc.type==='folders') {
        if (desc.id==='checkedout') {
          return base + 'title_checked_out36.svg';
        } else if (desc.id==='public') {
          return base + 'title_public_folders36.svg';
        } else if (desc.id==='recentedits') {
          return base + 'title_recently_edited36.svg';
        } else if (desc.id==='all') {
          return base + 'title_all_folders36.svg';
        } else if (desc.id==='templates') {
          return base + 'title_templates36.svg';
        } else if (desc.id==='deleted') {
          return base + 'title_deleted36.svg';
        }
        return base + 'title_folders36.svg';
      }
    }
    return this.iconUrlFromDesc(desc);
  }

  private iconUrlFromWorkspaceDesc(desc: any): string {
    if (desc['IS_SHARED']==='1') {
      return this.iconBase + 'mime_workspace_shared.svg';
    } else {
      return this.iconBase + _Transforms.typeIconMap['workspaces'];
    }
  }

  public isFavoriteFolder(id): boolean {
    return id === 'favorites';
  }

  private iconUrlFromSearchDesc(desc: any): string {
    const searchIcon: string = (desc['UNSAVED']==='Y') ? 'unsaved_search' : 'saved_search';
    return this.iconUrlPath(searchIcon);
  }

  private iconUrlPath(iconStr: string): string {
    return this.iconBase + _Transforms.typeIconMap[iconStr];
  }

  public setLocalizer(localizer: LocalizeService): void {
    this.localizer = localizer;
    this.fillDateRangeTokens();
  }

  private fillDateRangeTokens() {
    this.dateRangeTokens = {
      '%TODAY': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.TODAY'),
      ' MINUS ': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.MINUS'),
      ' TO ': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.TO'),
      '%NOT_EMPTY': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.NOT_EMPTY'),
      '%EMPTY': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.EMPTY'),
      ' PLUS ': this.localizer.getTranslation('FORMS.LOCAL.DATE_RANGE.PLUS')
    };
    this.dateRangeTokensTranslated = {};
    for (const key in this.dateRangeTokens) {
      this.dateRangeTokensTranslated[this.dateRangeTokens[key].toLowerCase()] = key;
    }
  }

  public hasIconForAppID(appID: string): boolean {
    return !!_Transforms.applicationIconMap[appID];
  }

  public iconUrlFromDesc(desc: any): string {
    const base = this.iconBase;
    if (this.descImage(desc)) {
      return this.descImage(desc);
    }
    if (_Transforms.idIconMap[desc.id]) {
        return base + _Transforms.idIconMap[desc.id];
      } else if (_Transforms.applicationIconMap[desc.APP_ID]) {
       return base + _Transforms.applicationIconMap[desc.APP_ID];
      } else if (_Transforms.applicationIconMap[desc.APPLICATION]) {
       return base + _Transforms.applicationIconMap[desc.APPLICATION];
      } else if (this.isFavoriteFolder(desc.id)) {
        return this.titleIconUrlFromDesc_Generic(desc);
      } else if (desc.type === 'workspaces') {
        return this.iconUrlFromWorkspaceDesc(desc);
      } else if (desc.type === 'searches') {
        return this.iconUrlFromSearchDesc(desc);
      } else if (_Transforms.typeIconMap[desc.type]) {
        return base + _Transforms.typeIconMap[desc.type];
      } else if (desc.id && desc.id.startsWith('FP-FilePart')) {
        return base + _Transforms.idFileplanMap['FilePart'];
      } else if (desc.id && desc.id.startsWith('FP-File')) {
        return base + _Transforms.idFileplanMap['File'];
      } else if (desc.id && (desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm'))) {
        return base + _Transforms.idFileplanMap['Term'];
      } else if (desc.type === 'fileplans' && !desc.id) {
       return base + 'file_plan_32x32.svg';
      } else if (desc.type === 'fileplans' && desc.id === 'groupfavorites') {
        return base + 'group_fav_32x32.svg';
      } else if (desc.type === 'fileplans' && desc.id === 'recentused') {
        return base + 'recent_used_32x32.svg';
      } else if (desc.type === 'boxes') {
        return base + 'mime_box_32.svg';
      } else if (desc.type === 'requests') {
      if (desc.PD_OBJ_TYPE === '4') {
        return base + 'requests_filepart.svg';
      }
      if (desc.PD_OBJ_TYPE === '5') {
        return base + 'mime_box_32.svg';
      } else {
        return base + 'requests_document.svg';
      }
    } else if (!desc.type && !desc.id && !desc.lib && !!desc.USER_ID && desc.flag===2) {
      return base + 'mime_person.svg';
    } else if (!desc.type && !desc.id && !desc.lib && !!desc.USER_ID && desc.flag===1) {
      return base + 'mime_group.svg';
    } else if (desc.type === 'documents' && (desc['ITEM_TYPE'] === 'M' || desc['%EXTENDED_PROFILE.ITEM_TYPE'] === 'M')) {
      return base + 'mime_paper.svg';
    } else if (desc.type === 'activities') {
      return base + 'recent_activites36.svg';
    } else if (desc.type === 'fileplans') {
      return base + 'file_part_32x32.svg';
    } else {
      return base + 'mime_document2.svg';
    }
  }

  private static applicationTextMap: any = {
    'MS WORD':          'MS WORD',
    'MS EXCEL':         'MS EXCEL',
    'MS POWERPOINT':    'MS POWERPOINT',
    'MS OUTLOOK':       'MS OUTLOOK',
    'MS PUBLISHER':     'MS PUBLISHER',
    'MS VISIO':         'MS VISIO',
    'MS PROJECT':       'MS PROJECT',
    'MS ONENOTE':       'MS ONENOTE',
    VISIO:            'VISIO',
    ACROBAT:          'ACROBAT',
    PAPER:            'PAPER',
    '%FORM_PAPER_APPLICATION': 'PAPER',
    MEMO:             'PAPER',
    DOCSIMAGE:        'DOCSIMAGE',
    QPW:              'QPW',
    PRESENTATIONS:    'PRESENTATIONS',
    FORM:             'FORM',
    '%PRIMARY_FORM':    'FORM',
    DEFAULT:          'DEFAULT',
    _GROUPS_ENABLED:  'SEARCH_SELECTOR.GROUP',
    _GROUP_MANAGER:   'SEARCH_SELECTOR.USER'
  };

  private static  typeTextMap: any = {
    searches:       'TILE_NAMES.QUICK_SEARCH',
    folders:        'FOLDER_ACTIONS.FOLDERS',
    folder:         'ALT_TEXT.FOLDER',
    saved_search:   'ALT_TEXT.SAVED_SEARCH',
    unsaved_search: 'ALT_TEXT.UNSAVED_SEARCH',
    flexfolders:    'TILE_NAMES.FLEXFOLDERS',
    workspaces:     'TILE_NAMES.WORKSPACES',
    workspace:      'ALT_TEXT.WORKSPACE',
    urls:           'ALT_TEXT.URLS',
    libraries:      'COLUMN_HEADINGS.DEFAULT.LIBRARY',
    word:           'MS WORD',
    excel:          'MS EXCEL',
    powerpoint:     'MS POWERPOINT',
    outlook:        'MS OUTLOOK',
    publisher:      'MS PUBLISHER',
    visio:          'MS VISIO',
    onenote:        'MS ONENOTE',
    pdf:            'ACROBAT',
    image:          'DOCSIMAGE'
  };

  private static idTextMap: any = {
    recentedits:  'TILE_NAMES.RECENTLY_EDITED',
    quicksearch:  'TILE_NAMES.QUICK_SEARCH',
    checkedout:   'TILE_NAMES.CHECKED_OUT'
  };

  private static tileNamesMap: any = {
    recentedits:    'TILE_NAMES.RECENTLY_EDITED',
    quicksearch:    'TILE_NAMES.QUICK_SEARCH',
    checkedout:     'TILE_NAMES.CHECKED_OUT',
    flexfolders:    'TILE_NAMES.FLEXFOLDERS',
    workspaces:     'TILE_NAMES.WORKSPACES',
    publicfolders:  'TILE_NAMES.PUBLIC_FOLDERS',
    fileplan:       'TILE_NAMES.FILEPLAN',
    requests:       'TILE_NAMES.RM_REQUESTS',
    templates:      'TILE_NAMES.TEMPLATES',
    allfolders:     'TILE_NAMES.ALL_FOLDERS',
    deleted:        'TILE_NAMES.DELETED',
    GroupFavorites: 'TILE_NAMES.GROUP_FAVORITES',
    RecentUsed:     'TILE_NAMES.RECENTLY_USED',
    Favorites:      'TILE_NAMES.FAVORITES'
  };

  private static idFileplanTextMap: any = {
    Term: 'ALT_TEXT.TERM',
    File: 'ALT_TEXT.FILE',
    FilePart: 'ALT_TEXT.FILE_PART',
    Box: 'ALT_TEXT.RM_BOX',
    Request: 'ALT_TEXT.RM_REQUEST',
  };

  private static typeToSingularMap: any = {
    folders: 'ALT_TEXT.FOLDER',
    flexfolders: 'FORMS.LOCAL.LINK.TYPE_FLEXFOLDERS',
    workspaces: 'ALT_TEXT.WORKSPACE',
    urls: 'FORMS.LOCAL.URL.URL',
    saved_search: 'ALT_TEXT.SAVED_SEARCH',
    unsaved_search: 'ALT_TEXT.UNSAVED_SEARCH',
    activities: 'FORMS.LOCAL.ACTIVITY_SEARCH.ACTIVITY',
    useractivity: 'TILE_NAMES.ACTIVITIES_MINE',
    sharedworkspace: 'ALT_TEXT.SHARED_WORKSPACE'
  };

  private isExternalLib(lib: string): boolean {
    if (!!lib && this.externalAppsPrefix) {
      if (lib.toUpperCase().startsWith(this.externalAppsPrefix.toUpperCase())) {
        return true;
      }
    }
    return false;
  }

  private findExternalApp(lib: string): any {
    let extApp: any = null;
    if (!!this.externalApps) {
      for (const app of this.externalApps) {
        if (app.lib === lib) {
          extApp = app;
          break;
        }
      }
    }
    return extApp;
  }

  public getDMDocName(library: string, docId: number, docName: string, versionLabel?: string): string {
    return library + this.docIDSeparator + docId + (versionLabel ? this.versionLabelSeparator + versionLabel : '') + this.hyphenSeparator + docName;
  }

  public isDMManagedFile(documentName: string): boolean {
    return !!documentName && documentName.includes(this.docIDSeparator) && documentName.includes(this.versionLabelSeparator);
  }

  public focusOnElementByGivenClass(className: string): HTMLElement {
    const element = document.getElementsByClassName(className)[0] as HTMLElement;
    if (!!element) {
      element.focus();
    }
    return element;
  }

  private titleIconTextFromDesc_Generic(desc: any): string {
    let resource: string;
    if (desc.type === 'flexfolders') {
      resource = _Transforms.tileNamesMap['flexfolders'];
    } else if (desc.type === 'requests') {
      resource = _Transforms.tileNamesMap['requests'];
    } else if (desc.type === 'boxes') {
      resource = 'ALT_TEXT.RM_BOXES';
    } else if (desc.type === 'workspaces') {
      resource = _Transforms.tileNamesMap['workspaces'];
    } else if (desc.type === 'searches') {
      resource = _Transforms.tileNamesMap['quicksearch'];
    } else if (desc.type === 'fileplans') {
      if (!desc.id) {
        resource = _Transforms.tileNamesMap['fileplan'];
      } else if (desc.id === 'groupfavorites') {
        resource = _Transforms.tileNamesMap['groupfavorites'];
      } else if (desc.id === 'recentused') {
        resource = _Transforms.tileNamesMap['recentused'];
      } else if (desc.id && desc.id.startsWith('FP-FilePart')) {
        resource = _Transforms.idFileplanTextMap['FilePart'];
      } else if (desc.id && desc.id.startsWith('FP-File')) {
        resource = _Transforms.idFileplanTextMap['File'];
      } else if (desc.id && desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm')) {
        resource = _Transforms.idFileplanTextMap['Term'];
      }
    }
    if (desc.id && desc.id.length) {
      if (desc.type === 'folders') {
        if (desc.id === 'checkedout') {
         resource = _Transforms.tileNamesMap['checkedout'];
        } else if (desc.id === 'public') {
         resource = _Transforms.tileNamesMap['publicfolders'];
        } else if (desc.id === 'recentedits') {
          resource = _Transforms.tileNamesMap['recentedits'];
        } else if (desc.id === 'all') {
          resource = _Transforms.tileNamesMap['allfolders'];
        } else if (desc.id === 'templates') {
          resource = _Transforms.tileNamesMap['templates'];
        } else if (desc.id === 'deleted') {
          resource = _Transforms.tileNamesMap['deleted'];
        }
        resource = 'FOLDER_ACTIONS.FOLDERS';
      }
    }
    if (!resource) {
      resource = this.iconAltTextFromDesc(desc);
    }
    return this.localizer.getTranslation(resource);
  }

  public iconAltTextFromDesc(desc: any, isItem = false): string {
    let resource: string;
    if (_Transforms.idTextMap[desc.id]) {
      resource = _Transforms.idTextMap[desc.id];
    } else if (_Transforms.applicationTextMap[desc.APP_ID]) {
      resource = _Transforms.applicationTextMap[desc.APP_ID];
    } else if (_Transforms.applicationTextMap[desc.APPLICATION]) {
      resource = _Transforms.applicationTextMap[desc.APPLICATION];
    } else if (desc.type === 'workspaces') {
      if (this.isFavoriteFolder(desc.id)) {
        resource = _Transforms.tileNamesMap['Favorites'];
      } else {
        resource = isItem ? _Transforms.typeTextMap['workspace'] : _Transforms.tileNamesMap['workspaces'];
      }
    } else if (desc.type === 'searches') {
      resource = isItem ? (desc['UNSAVED']==='Y' ? _Transforms.typeTextMap['unsaved_search'] : _Transforms.typeTextMap['saved_search']) : _Transforms.tileNamesMap['quicksearch'];
    } else if (_Transforms.typeTextMap[desc.type]) {
      resource = _Transforms.typeTextMap[desc.type];
    } else if (desc.id && desc.id.startsWith('FP-FilePart')) {
      resource = _Transforms.idFileplanTextMap['FilePart'];
    } else if (desc.id && desc.id.startsWith('FP-File')) {
      resource = _Transforms.idFileplanTextMap['File'];
    } else if (desc.id && (desc.id.startsWith('FP-Term') || desc.id.startsWith('FP-NarrowerTerm'))) {
      resource = _Transforms.idFileplanTextMap['Term'];
    } else if (desc.type === 'fileplans' && !desc.id) {
      resource = _Transforms.tileNamesMap['fileplan'];
    } else if (desc.type === 'fileplans' && desc.id === 'groupfavorites') {
      resource = _Transforms.tileNamesMap['groupfavorites'];
    } else if (desc.type === 'fileplans' && desc.id === 'recentused') {
      resource = _Transforms.tileNamesMap['recentused'];
    } else if (desc.type === 'boxes') {
      resource = _Transforms.idFileplanTextMap['Box'];
    } else if (desc.type === 'requests') {
      if (desc.PD_OBJ_TYPE === '4') {
        resource = _Transforms.idFileplanTextMap['Request'];
      }
      if (desc.PD_OBJ_TYPE === '5') {
        resource = _Transforms.idFileplanTextMap['Box'];
      } else {
        resource = _Transforms.idFileplanTextMap['Request'];
      }
    } else if (desc.type === 'lookups') {
      resource = 'ALT_TEXT.LOOKUP_TABLE';
    } else if (!desc.type && !desc.id && !desc.lib && !!desc.USER_ID && desc.flag === 2) {
      resource = 'SEARCH_SELECTOR.USER';
    } else if (!desc.type && !desc.id && !desc.lib && !!desc.USER_ID && desc.flag === 1) {
      resource = 'SEARCH_SELECTOR.GROUP';
    } else if (desc.type === 'documents' && desc['ITEM_TYPE'] === 'M') {
      resource = 'ALT_TEXT.PHYSICAL_DOCUMENT';
    } else {
      resource = 'ALT_TEXT.DEFAULT_DOCUMENT';
    }
    return this.localizer.getTranslation(resource);
  }
  public typeTextFromDesc(item: any, desc?: any): string {
    let resource: string;
    if (item.type === 'activities' && desc?.id === 'mine--') {
      resource = _Transforms.typeToSingularMap['useractivity'];
    } else if (item.type === 'workspaces' && item['IS_SHARED'] === '1') {
      resource = _Transforms.typeToSingularMap['sharedworkspace'];
    } else if (item.type === 'activities' || item.type === 'folders' || item.type === 'workspaces' || item.type === 'urls' || item.type === 'flexfolders') {
      resource = _Transforms.typeToSingularMap[item.type];
    } else if (item.type === 'searches') {
      resource = item['UNSAVED'] === 'Y' ? _Transforms.typeTextMap['unsaved_search'] : _Transforms.typeTextMap['saved_search'];
    } else {
      return this.iconAltTextFromDesc(item);
    }
    return this.localizer.getTranslation(resource);
  }

  public titleIconTextFromDesc(desc: any): string {
    return this.titleIconTextFromDesc_Generic(desc);
  }

  public titleIconUrlFromDesc(desc: any): string {
    if (this.isExternalLib(desc.lib) && !!this.findExternalApp(desc.lib)) {
      return this.titleIconUrlFromDesc_ExternalApp(desc);
    }
    return this.titleIconUrlFromDesc_Generic(desc);
  }

  public tileIconUrlFromDesc(desc: any): string {
    if (this.device.bIsOfficeAddin) {
      return this.titleIconUrlFromDesc_OfficeAddin(desc);
    }
    if (this.device.isPhoneLook()) {
      return this.titleIconUrlFromDesc_Phone(desc);
    }
    if (this.isExternalLib(desc.lib) && !!this.findExternalApp(desc.lib)) {
      return this.titleIconUrlFromDesc_ExternalApp(desc);
    }
    return this.titleIconUrlFromDesc_Generic(desc);
  }

  public svgDataFromAppID(appID: string): Promise<string> {
    return this.svgDataService.dataFromAppIDIcon(appID, _Transforms.applicationIconMap[appID]);
  }

// similar extensions can have same appId like doc, docx have MS_WORD as appId.
// so we need this to retrieve profile defaults related to appID.
  public fileNameToAppID(fileName: string): string {
    const parts: string[] = fileName.split('.');
    const ext: string = parts[parts.length-1].toUpperCase();
    const app: string = _Transforms.extToAppMap[ext];
    return app || '';
  }

  public setExternalApps(apps: any, prefix: string): void {
    this.externalApps = apps;
    this.externalAppsPrefix = prefix;
  }

  public alternateExtension(extension: string): string {
    const ext: string = extension.toUpperCase();
    const app: string = _Transforms.extToAppMap[ext];
    if (app) {
      const keys: string[] = Object.keys(_Transforms.extToAppMap);
      for (const key of keys) {
        if (key !== ext) {
          if (_Transforms.extToAppMap[key]===app) {
            return key;
          }
        }
      }
    }
    return null;
  }

  public getExtensionFromAppID(appID: string): string {
    const app: string = appID.toUpperCase();
    if (app) {
      const keys: string[] = Object.keys(_Transforms.extToAppMap);
      for (const key of keys) {
        if (_Transforms.extToAppMap[key]===app) {
          return key;
        }
      }
    }
    return 'TXT';
  }

  public validateQueryValue(value: string, wildcard: string = '', encodeURI: boolean = true): string {
    let queryValue = ''; //"!-%&@"; // Invalid value
    value = (value || '').toString();
    const valid: string = value.replace(/[* ]/g, '');
    if (valid) {
      // Quotes as part of the value must be escaped
      const nValue = value.length;
      const bAddBackQuotes = nValue > 1 && value[0] === '"' && value[nValue - 1] === '"';
      if (bAddBackQuotes) {
        value = value.substr(1, nValue - 2);
      }
      if (wildcard) {
        value = value.replace(/["]/g, '\\"');
      }
      if (bAddBackQuotes) {
        value = '"' + value + '"';
      }
      // Any multivalue separator ; must be replaced with |
      value = value.replace(/[;]/g, '|');
      // Any illegal chars must be enclosed in simple quotes
      if (value.indexOf(',') >= 0) {
        let enclosingString = '';
        if (wildcard) {
          enclosingString = this.DOUBLE_QUOTE;
        }
        if (encodeURI) {
          value = encodeURIComponent(value);
        }
        queryValue = enclosingString + value + enclosingString + wildcard;
      } else if (encodeURI) {
        queryValue = encodeURIComponent(value) + wildcard;
      } else {
        queryValue = value + wildcard;
      }
    }
    return queryValue;
  }

  public restoreQueryValue(value: string): string {
    let restored: string = value;
    if (!!value) {
      if (!value.includes(',')) {
        restored = restored.replace(/(^"|"$)/g, '');
      }
      // Remove any escaped characters
      restored = restored.replace(/[\\]/g, '');
    }
    return restored;
  }

  /* To make al multi-value search requests unform, adopt a convention where semicollon
     is a delimiter for multiple values. These have to be converted to | for search
     of type FULLTEXT_CONTENT_PROFILE and all individula values that containa comma must be
     quoted since that comma is part of the value (not a DM server value delimiter).

     Filters are separated by comma
  */
  public parseMultiValueSearchText(searchText: string, sep: string=';'): string {
    let searchCriteria = '';
    if (!!searchText && typeof searchText === 'string') {
      const searchValues: any = searchText.split(sep);
      let bar = '';
      for (const item of searchValues) {
        searchCriteria += bar + this.validateQueryValue(item,'',false);
        bar = '|';
      }
    } else {
      searchCriteria = searchText;
    }
    return searchCriteria;
  }

  public restoreMultiValueSearchText(searchTextOrSearchArray: string | string[], sep: string=';'): string {
    let searchCriteria = '';
    if (!!searchTextOrSearchArray) {
      const isArrary = Array.isArray(searchTextOrSearchArray);
      const searchText: string = !isArrary ? (searchTextOrSearchArray as string) : null;
      const searchValues: string[] = isArrary ? (searchTextOrSearchArray as string []) : searchText.split('|');
      let sepChar = '';
      for (const item of searchValues) {
        searchCriteria += sepChar + this.restoreQueryValue(item);
        sepChar = sep;
      }
    }
    return searchCriteria;
  }

  public translateForm(form: any): any {
    if (!!form) {
      const localizer: LocalizeService = this.localizer;
      const locObj = (obj: any) => {
        Object.keys(obj).forEach((key,index) => {
          const value: any = obj[key];
          if (key==='prompt' || key==='display' || key==='label' || key==='value' || key==='buttons') {
            if (typeof value === 'string' && (value.startsWith('FORMS.LOCAL') || value.startsWith('FORMS.BUTTONS'))) {
              obj[key] = localizer.getTranslation(value);
            }
          } else {
            if (typeof value==='object') {
              locObj(value);
            }
          }
        });
      };
      locObj(form);
    }
    return form;
  }

  public formatHTMLEmail(subject: string, body: string,url?: boolean,documents?: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getFile('assets/email-template.html').then(tempalte => {
        const copyrightText: string = this.localizer.getTranslation('SETTINGS.COPYRIGHT', [this.device.year]);
        const trademarkText: string = this.localizer.getTranslation('SETTINGS.TRADEMARK');
        const noteText: string = this.localizer.getTranslation('SETTINGS.NOTE');
        const noReplyText: string = this.localizer.getTranslation('FORMS.LOCAL.SHARE.AUTOMATED_MESSAGE');
        let htmlBody = !!body ? this.trimCR(body, '<br>') : '';
        if (url) {
          htmlBody = this.getDocumentUrlBody(body,documents);
        }
        tempalte = tempalte.replace('$SUBJECT', subject || '');
        tempalte = tempalte.replace('$BODY', htmlBody);
        tempalte = tempalte.replace('$NOTE', noteText);
        tempalte = tempalte.replace('$NOREPLY', noReplyText);
        tempalte = tempalte.replace('$COPYRIGHT', copyrightText);
        tempalte = tempalte.replace('$TRADEMARK', trademarkText);
        resolve(tempalte);
      }, reject);
    });
  }

  private getDocumentUrlBody(body: string, documents: any): string {
    let htmlBody = '';
    for (const document of documents) {
      htmlBody = htmlBody + '<a href="' + document['url'] + '">' + document['DOCNAME'] + '</a>' + '<br>';
    }
    return !!body ? body + '<br>' + htmlBody : htmlBody;
  }

  private trimCR(input: string, replaceStr: string=''): string {
    return input.replace(/(\r\n|\n|\r)/gm,replaceStr);
  }

  private getNamedContentData(input: string, boundary: string, name: string): string {
    let data: string;
    const loc: string = 'Content-Location: http://SD/'+name;
    const start = input.indexOf(loc);
    if (start>=0) {
      data = this.trimCR(input.substr(loc.length+start));
      const end = data.indexOf(boundary);
      if (end>=0) {
        data = this.trimCR(data.substring(0,end));
      }
    }
    return data;
  }

  private makeBase64URL(input: string, boundary: string, attachName: string, mimimetype: string): string {
    return 'data:'+mimimetype+';base64,'+this.getNamedContentData(input, boundary, attachName);
  }

  private findFiles(input: string): any[] {
    const rc: any[] = [];
    let start = input.indexOf('<SMARTDOCUMENT>');
    let end = input.indexOf('</SMARTDOCUMENT>');
    if (start>=0 && end>=0) {
      const smart: string = input.substring(start+15,end);
      const parts: string[] = smart.split('\n');
      for (const part of parts) {
        if (part.startsWith('<DOCUMENT FILENAME="')) {
          let name = part.substr(20);
          end = name.indexOf('"');
          name = name.substring(0,end);
          start = part.indexOf('MIMETYPE="');
          if (start>=0) {
            let mimetype = part.substr(start+10);
            end = mimetype.indexOf('"');
            mimetype = mimetype.substring(0,end);
            if (mimetype.indexOf('image/')>=0 || mimetype.indexOf('MIMETYPE="text/css')>=0) {
              rc.push({
                mimetype,
                name
              });
            } else if (part.indexOf('MIMETYPE="text/css')>=0) {
              rc.push({
                mimetype,
                name
              });
            }
          }
        }
      }
    }
    return rc;
  }

  private sanitizeBody(input: string): string {
    return input.replace(/<a /g, '<a target="_blank"');
  }

  private getBody(input: string, boundary: string): string {
    const start = input.indexOf('<body>');
    let end = input.indexOf('</body>');
    if (start>=0) {
      if (end === -1) {
        const startBody: string = input.substring(start+6);
        end = startBody.indexOf(boundary);
        if (end !== -1) {
          return this.trimCR(startBody.substr(0, end));
        }
      } else {
        return input.substring(start+6,end);
      }
    }
    return '';
  }

  private getStyle(input: string, boundary: string, styleName: string): string {
    return '<style>' + this.getNamedContentData(input, boundary, styleName) + (this.device.isMobile() ? 'table,td{width:auto !important} </style>' : '</style>');
  }

  private getBoundary(input: string): string {
    let boundary = '--';
    const start = input.indexOf('boundary=') + 9;
    if (start > 8) {
      const end = input.substr(start).indexOf(';');
      if (end !== -1) {
        boundary += input.substr(start, end-1);
      }
    }
    return boundary;
  }

  public replaceContentIdWithBase64ForImages(htmlString: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    const images = tempElement.querySelectorAll('img');
    images.forEach((img: HTMLImageElement) => {
      const src = img.getAttribute('src');
      if (src && src.startsWith('cid:')) {
        const contentId = src.substr(4);
        const content = this.extractBase64Content(htmlString, contentId);
        img.setAttribute('src', `data:image/png;base64,${content}`);
      }
    });
    return tempElement.innerHTML;
  }

  public extractBase64Content(mimeMessage: string, contentId: string): string | undefined {
    const parts = mimeMessage.split('------=_NextPart');
    for (const part of parts) {
      if (part.includes(`Content-ID:${contentId}`)) {
        const lines = part.split(/\r?\n/);
        const contentLines = lines.slice(4);
        const base64Content = contentLines.join('');
        return base64Content.trim();
      }
    }
    return undefined;
  }

  private removeChildTextNodes(parentElement: HTMLElement): void {
    if (!!parentElement && !!parentElement.childNodes) {
      let childNode;
      for (let index = 0; index < parentElement.childNodes.length; index++) {
        childNode = parentElement.childNodes[index];
        if (childNode.nodeType !== 1 || childNode.nodeName === '#text') {
          childNode.remove();
          index--;
        }
      }
    }
  }

  private removeDuplicateContentAndSanitizeLinks(htmlString: string): string {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    const anchors = tempElement.querySelectorAll('a');
    anchors.forEach((a: HTMLAnchorElement) => {
      if (a.innerHTML === 'Next' || a.innerHTML === 'Prev') {
        a.remove();
      } else {
        a.target = '_blank';
      }
    });
    let elements;
    for (const tag of ['html', 'meta', 'style', 'title']) {
      elements = tempElement.querySelectorAll(tag);
      if (elements.length > 1) {
        elements.forEach((element: HTMLElement, key: number) => {
          if (key > 0) {
            element.remove();
          }
        });
      }
    }
    this.removeChildTextNodes(tempElement);

    return tempElement.innerHTML;
  }

  public pr1AttachmentToHTML(input: string, maxWidth: number, removeNextParts?: boolean): string {
    input = this.replaceContentIdWithBase64ForImages(input);
    let body: string;
    if (removeNextParts) {
      const match = input.match(/Content-Type:text\/html;([\s\S]*?)charset="UTF-8"([\s\S]*)------=_NextPart--/);
      const htmlContent = match ? match[1] + match[2] : input;
      body = this.sanitizeBody(htmlContent); // Assuming this function is working correctly
      body = body.replace(/------=_NextPart[\s\S]*/g, '');
    } else {
      body = this.removeDuplicateContentAndSanitizeLinks(input);
    }
    const tables = body.match(/<table[^>]*>[\s\S]*?<\/table>/gi);
    if (tables) {
      for (const table of tables) {
        const modifiedTable = this.adjustTable(table, maxWidth);
        body = body.replace(table, modifiedTable);
      }
    }
    return body;
  }

  private adjustTable(table: string, maxWidth: number): string {
    const adjustedTable = table.replace(/<img[^>]+>/gi, (imgTag) => {
      const srcMatch = imgTag.match(/src="([^"]+)"/);
      const widthMatch = imgTag.match(/width="(\d+)"/);
      if (srcMatch && widthMatch) {
        const src = srcMatch[1];
        let width = parseInt(widthMatch[1], 10);
        if (width > maxWidth) {
          const aspectRatio = maxWidth / width;
          const newWidth = maxWidth;
          const newHeight = Math.round(aspectRatio * parseFloat(widthMatch[1]));
          return imgTag.replace(`width="${width}"`, `width="${newWidth}"`).replace(`height="`, `height="${newHeight}"`);
        }
      }
      return imgTag;
    });
    return adjustedTable;
  }

  public accessRightsDescriptor(right: number): string {
    let descriptor: string = null;

    switch (right) {
      case AccessRights.ACCESS_VIEW_PROFILE:
        descriptor = 'SECURITY.RIGHTS.VIEW_PROFILE'; break;
      case AccessRights.ACCESS_EDIT_PROFILE:
        descriptor = 'SECURITY.RIGHTS.EDIT_PROFILE'; break;
      case AccessRights.ACCESS_VIEW_DOCUMENT:
        descriptor = 'SECURITY.RIGHTS.VIEW_DOCUMENT'; break;
      case AccessRights.ACCESS_RETRIEVE_DOCUMENT:
        descriptor = 'SECURITY.RIGHTS.RETRIEVE_DOCUMENT'; break;
      case AccessRights.ACCESS_EDIT_CONTENT:
        descriptor = 'SECURITY.RIGHTS.EDIT_CONTENT'; break;
      case AccessRights.ACCESS_COPY:
        descriptor = 'SECURITY.RIGHTS.COPY'; break;
      case AccessRights.ACCESS_DELETE:
        descriptor = 'SECURITY.RIGHTS.DELETE'; break;
      case AccessRights.ACCESS_CONTROL_ACCESS:
        descriptor = 'SECURITY.RIGHTS.CONTROL_ACCESS'; break;
      case AccessRights.ACCESS_ALLOW_VIEW_PUBLISHED:
        descriptor = 'SECURITY.RIGHTS.ALLOW_VIEW_PUBLISHED'; break;
    }
    return descriptor;
  }

  public accessLevelDescriptor(level: number): string {
    let descriptor: string = null;

    switch (level) {
      case AccessLevel.ACCESS_LEVEL_CUSTOM:
        descriptor = 'SECURITY.LEVELS.CUSTOM'; break;
      case AccessLevel. ACCESS_LEVEL_VIEW_PROFILE:
        descriptor = 'SECURITY.LEVELS.VIEW_PROFILE'; break;
      case AccessLevel. ACCESS_LEVEL_READ_ONLY:
        descriptor = 'SECURITY.LEVELS.READ_ONLY'; break;
      case AccessLevel. ACCESS_LEVEL_NORMAL:
        descriptor = 'SECURITY.LEVELS.NORMAL'; break;
      case AccessLevel. ACCESS_LEVEL_FULL:
        descriptor = 'SECURITY.LEVELS.FULL'; break;
    }
    return descriptor;
  }

  public formatError(error: any): string {
    if (error) {
      let gotErrorJSON = false;
      if (typeof error === 'object') {
        let json: any;
        try {
          if (error.body) {
            error = error.body;
            json = JSON.parse(error);
          } else if (error.response) {
            json = JSON.parse(error.response);
          } else {
            json = error.json();
          }
          if (json) {
            if (json.ERROR) {
              error = json.ERROR;
              gotErrorJSON = (typeof error !== 'object');
            } else if (json.data && (typeof error === 'object') && (error.status === 206 || error.responseCode === 206)) {
              error = json;
            }
          }
        } catch (e) {}
        if (!gotErrorJSON) {
          if (error.ERROR) {
            error = error.ERROR;
          } else if (error.error && error.error.eventPhase === undefined) {
            if (typeof error.error === 'string') {
              error = JSON.parse(error.error);
            } else {
              error = error.error;
            }
          }
          if (error.ERROR) {
            error = error.ERROR;
          }
          if (error.rapi_code && this.localizer && error.rapi_code === '0X800401E5') {
            error = this.localizer.getTranslation('GENERIC_ERRORS.NOT_AUTHORIZED_REMOTE_LIBRARY');
          }
          if (error.message) {
            error = error.message;
          } else if (error.status) {
            error = +error.status + ' - ' + error.statusText;
          } else if ((error.rapi_code || error.status === 0) && this.localizer) {
            if (!error.rapi_code) {
              error.rapi_code = 0;
            }
            error = this.localizer.getTranslation('RAPI_ERRORS.' + error.rapi_code);
          } else if (error.exception) {
            if (error.exception.message) {
              error = error.exception.message;
            } else if (error.exception.code) {
              error = error.exception.code;
            } else {
              error = error.exception;
            }
          } else if (error.data && (error.data['error-list'] || error.data.error_list)) {
            let errorText = '';
            const errorItems: any[] = error.data['error-list'] || error.data.error_list;
            for (const item of errorItems) {
              errorText += '\n' + item.object + ':\n' + item.message;
              if (item.code) {
                const rapi_msg: string = this.localizer.getTranslation('RAPI_ERRORS.' + item.code);
                errorText += ' ' + rapi_msg;
              }
            }
            error = errorText;
          } else if (!!error.pfta_err) {
            if (!!error.pfta_err.rapi_code) {
              const rapi_msg: string = this.localizer.getTranslation('RAPI_ERRORS.' + error.pfta_err.rapi_code);
              error = this.localizer.getTranslation('GENERIC_ERRORS.PFTA.ASYNC_ERROR', [rapi_msg]);
            } else if (!!error.pfta_err.address) {
              error = this.localizer.getTranslation('GENERIC_ERRORS.PFTA.ASYNC_ADDRESS_ERROR', [error.pfta_err.code, error.pfta_err.address]);
            } else {
              error = this.localizer.getTranslation('GENERIC_ERRORS.PFTA.ASYNC_ERROR', [error.pfta_err.code]);
            }
          }
        }
      }
    }
    return error;
  }

  public rapiError(error: any): string {
    let gotErrorJSON = false;
    if (typeof error === 'object') {
      let json: any;
      try {
        if (error.body) {
          error = error.body;
          json = JSON.parse(error);
        } else {
          json = error.json();
        }
        if (json && json.ERROR) {
          error = json.ERROR;
          gotErrorJSON = (typeof error !== 'object');
        }
      } catch (e) {}
      if (!gotErrorJSON) {
        if (error.ERROR) {
          error = error.ERROR;
        }
        if (error.rapi_code) {
          return error.rapi_code;
        }
      }
    }
    return null;
  }

  private localeStringDateToDate(date: string): Date {
    const locale: string = this.device.getUserLanguage(true);
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    const delimiter = formatObj.find(x => x.type === 'literal')?.value;
    const splitDate = (date ?? '').split(delimiter);
    const formatArray = formatObj.filter(ele => ele.type !== 'literal').map(ele => ele.type);
    const day = splitDate[formatArray.indexOf('day')]?.split(' ')[0];
    const month = splitDate[formatArray.indexOf('month')]?.split(' ')[0];
    let year = splitDate[formatArray.indexOf('year')]?.split(' ')[0];
    year = !!year && year.length === 4 ? year : '';
    let newDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
    const isValidDate =
      newDate.getDate() === parseInt(day, 10) &&
      newDate.getMonth() + 1 === parseInt(month, 10) &&
      newDate.getFullYear() === parseInt(year, 10);
    newDate = !isNaN(newDate.getDate()) && isValidDate ? newDate : null;
    return newDate;
  }

  public toDMDateString(date: string): string {
    const jsDate = this.localeStringDateToDate(date);
    return this.formatDateForDM(jsDate?.toString(),true);
  }

  public isValidLocaleDate(date: string): boolean {
    const jsDate = this.localeStringDateToDate(date);
    return !!jsDate;
  }

  public getDateFormat(): string {
    const date = new Date();
    const locale: string = this.device.getUserLanguage(true);
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(date);
    const format = formatObj.map(ele => {
      switch (ele.type) {
        case 'day':
          return 'DD';
        case 'month':
          return 'MM';
        case 'year':
          return 'YYYY';
        default:
          return ele.value;
      }
    }).join('');
    return format;
  }

  public currentDateTime(): string {
    const date = new Date();
    const currentDateformat = [date.getFullYear(),date.getMonth() + 1,date.getDate()].join('/') + ' ' + [date.getHours(), date.getMinutes(), date.getSeconds()].join(':');
    return this.formatDate(currentDateformat);
  }

  public formatDate(dateStr: string, dateOnly?: boolean, checkDmFormat?: boolean): string {
    let result = '';
    if (dateStr && dateStr.length && dateStr !== '0 UTC' && dateStr.substring(0,4) !== '1753') {
      const locale: string = this.device.getUserLanguage(true);
      const currentLocalDate = new Date().toLocaleDateString(locale);
      if (dateStr.split(' ')[0] === currentLocalDate) {
        return dateStr;
      }
      if (checkDmFormat && !this.isDMDateString(dateStr)) {
        return dateStr;
      }
      const conformDateStr: string = dateStr.indexOf('z')>0 || dateStr.indexOf('Z')>0 ? dateStr : dateStr.replace(/-/g, '/');  // safari does no like - wants /
      const date: Date = new Date(conformDateStr);
      result = date.toLocaleDateString(locale);
      if (!dateOnly) {
        result += ' ' + date.toLocaleTimeString(locale);
      }
    }
    return result;
  }

  public formatSplitableDate(dateStr: string, forDM: boolean, dateOnly?: boolean, checkDmFormat? :boolean): string {
    const special = this.splitableDateFormat(dateStr);
    const splitDate = dateStr.toUpperCase().split(special);
    const date1 = this.isSpecialDateFormat(splitDate[0]) ? splitDate[0] : (forDM ? this.toDMDateString(splitDate[0]) : this.formatDate(splitDate[0], dateOnly, checkDmFormat));
    let date2;
    if (!isNaN(parseInt(splitDate[1])) && !splitDate[1].includes('/') && !splitDate[1].includes('-')) {
      date2 = parseInt(splitDate[1])
    }
    else {
      date2 = this.isSpecialDateFormat(splitDate[1]) ? splitDate[1] : (forDM ? this.toDMDateString(splitDate[1]) : this.formatDate(splitDate[1], dateOnly, checkDmFormat));
    }
    return date1 + special + date2;
  }
 
  public formatDateForDM(dateStr: string, dateOnly?: boolean): string {
    let result = '';
    if (!!dateStr) {
      const date: Date = new Date(dateStr);
      const monthNum = date.getMonth()+1;
      const month = monthNum<10 ? ('0' + monthNum) : ('' + monthNum);
      const dayNum = date.getDate();
      const day = dayNum<10 ? ('0' + dayNum) : ('' + dayNum);
      result = date.getFullYear()+'-'+month+'-'+day;
      if (!dateOnly) {
        const hoursNum = date.getHours();
        const hours = hoursNum===0 ? '00' : hoursNum<10 ? ('0' + hoursNum) : ('' + hoursNum);
        const minutesNum = date.getMinutes();
        const minutes = minutesNum===0 ? '00' : minutesNum<10 ? ('0' + minutesNum) : ('' + minutesNum);
        result += ' '+hours+':'+minutes+':00';
      }
    }
    return result;
  }

  public isDMDateString(dateStr: string): boolean {
    if (dateStr && !isNaN(Number(dateStr.split('-').join('')))) {
      const date = dateStr.split('-');
      if (date.length === 3) {
        const year = date[0];
        const month = date[1];
        const day = date[2];
        return year.length === 4 && !isNaN(new Date(Number(year), Number(month) - 1, Number(day)).getDate());
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public offsetLocalDateToUTCTimeRange(dateStr: string): string {
    if (this.restAPIVers < 0x00160500 && !this.isSpecialDateFormat(dateStr) && !this.splitableDateFormat(dateStr)) {
      const nowDate: Date = new Date();
      const tmzOffset: number = nowDate.getTimezoneOffset();
      if (tmzOffset !== 0) {
        const tmz24Offset: number = tmzOffset > 0 ? tmzOffset : (oneDayInMinutes + tmzOffset);
        const tmzHours: number = Math.floor(tmz24Offset/60);
        const tmzHoursStr: string = tmzHours===0 ? '00' : tmzHours<10 ? ('0' + tmzHours) : ('' + tmzHours);
        const tmzMinutes: number = tmz24Offset % 60;
        const tmzMinutesStr: string = tmzMinutes===0 ? '00' : tmzMinutes<10 ? ('0' + tmzMinutes) : ('' + tmzMinutes);
        let utcDate: Date;
        let monthNum: number;
        let month: string;
        let day: string;
        let hours: string;
        let minutes: string;
        const appleComptibleDateStr: string = dateStr.replace(/[-]/g, '/');
        if (tmzOffset > 0) {
          utcDate = new Date(appleComptibleDateStr + ' ' + tmzHoursStr + ':' + tmzMinutesStr + ':00');
        } else {
          // back a day
          let localDate: Date = new Date(appleComptibleDateStr + ' 00:00:00 UTC');
          localDate = new Date(localDate.getTime() - oneDayInMS);
          monthNum = localDate.getMonth()+1;
          month = monthNum<10 ? ('0' + monthNum) : ('' + monthNum);
          day = localDate.getDate()<10 ? ('0' + localDate.getDate()) : ('' + localDate.getDate());
          utcDate = new Date(localDate.getFullYear()+'/'+month+'/'+day+' ' + tmzHoursStr + ':' + tmzMinutesStr + ':00');
        }
        const utcDateMS: number = utcDate.getTime();
        const otherDate: Date = new Date(utcDateMS + oneDayInMS);
        monthNum = utcDate.getMonth()+1;
        month = monthNum<10 ? ('0' + monthNum) : ('' + monthNum);
        day = utcDate.getDate()<10 ? ('0' + utcDate.getDate()) : ('' + utcDate.getDate());
        hours = utcDate.getHours()===0 ? '00' : utcDate.getHours()<10 ? ('0' + utcDate.getHours()) : ('' + utcDate.getHours());
        minutes = utcDate.getMinutes()===0 ? '00' : utcDate.getMinutes()<10 ? ('0' + utcDate.getMinutes()) : ('' + utcDate.getMinutes());
        const utcDateStr: string = utcDate.getFullYear()+'-'+month+'-'+day+' '+hours+':'+minutes+':00';
        monthNum = otherDate.getMonth()+1;
        month = monthNum<10 ? ('0' + monthNum) : ('' + monthNum);
        day = otherDate.getDate()<10 ? ('0' + otherDate.getDate()) : ('' + otherDate.getDate());
        hours = otherDate.getHours()===0 ? '00' : otherDate.getHours()<10 ? ('0' + otherDate.getHours()) : ('' + otherDate.getHours());
        minutes = otherDate.getMinutes()===0 ? '00' : otherDate.getMinutes()<10 ? ('0' + otherDate.getMinutes()) : ('' + otherDate.getMinutes());
        const otherDateStr: string = otherDate.getFullYear()+'-'+month+'-'+day+' '+hours+':'+minutes+':00';
        return utcDateStr + ' TO ' + otherDateStr;
      }
    }
    return dateStr;
  }

  // inverse of offsetLocalDateToUTCTimeRange
  public utcTimeRangeToLocalDate(dateStr: string): string {
    if (this.restAPIVers < 0x00160500 && !this.isSpecialDateFormat(dateStr)) {
      const nowDate: Date = new Date();
      const tmzOffset: number = nowDate.getTimezoneOffset();
      const times: string[] = dateStr ? dateStr.split(' TO ') : [];
      if (times.length === 2 && tmzOffset !== 0) {
        const date0: Date = new Date(times[0]);
        const date1: Date = new Date(times[1]);
        if (Math.abs(date0.valueOf() - date1.valueOf()) < (oneDayInMS * 2)) {
          const date: Date = tmzOffset > 0 ? date0 : date1;
          const monthNum: number = date.getMonth()+1;
          const dayNum: number = date.getDate();
          const month: string = monthNum < 10 ? ('0' + monthNum) : monthNum.toString();
          const day: string = dayNum < 10 ? ('0' + dayNum) : dayNum.toString();
          dateStr = date.getFullYear()+'-'+month+'-'+day;
        }
      }
    }
    return dateStr;
  }

  public valueToDateRange(value: string, moves: boolean): string {
    //"DATE_BUTTONS": "D1:Today|W1;Last week|W2;Last 2 weeks|M1;Last month|M3;Last 3 months|Y1;Last year|Y3;Last 3 years"
    const now: Date = new Date();
    let monthNum: number = now.getMonth()+1;
    let dayNum: number = now.getDate();
    let yearNum: number = now.getFullYear();
    let month: string = monthNum < 10 ? ('0' + monthNum) : monthNum.toString();
    let day: string = dayNum < 10 ? ('0' + dayNum) : dayNum.toString();
    let endStr: string = ''+yearNum+'-'+month+'-'+day;
    let startDate: Date = new Date(endStr.replace(/[-]/g, '/'));
    let startStr: string;
    const modifyDate = (initialDate: Date, days: number, months: number, years: number): Date => {
      initialDate.setDate(initialDate.getDate() + days);
      initialDate.setMonth(initialDate.getMonth() + months);
      initialDate.setFullYear(initialDate.getFullYear() + years);
      return initialDate;
    };
    if (moves) {
      endStr = this.localizeDateRange('%TODAY');
    }
    switch (value) {
    case 'D1':
      return this.localizeDateRange(endStr);
    case 'W1':
      startDate = modifyDate(startDate, -6, 0, 0); // today plus 6 days is one week
      break;
    case 'W2':
      startDate = modifyDate(startDate, -13, 0, 0); // today plus 13 days is two weeks
      break;
    case 'M1':
      startDate = modifyDate(startDate, 0, -1, 0);
      break;
    case 'M3':
      startDate = modifyDate(startDate, 0, -3, 0);
      break;
    case 'Y1':
      startDate = modifyDate(startDate, 0, 0, -1);
      break;
    case 'Y3':
      startDate = modifyDate(startDate, 0, 0, -3);
      break;
    default:
      return null;
    }
    if (moves) {
      const kMSperDay = 1000 * 60 * 60 * 24;
      const utcEnd = Date.UTC(yearNum, monthNum-1, dayNum);
      const utcStart = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      const nDays = Math.floor((utcEnd - utcStart) / kMSperDay);
      startStr = '%TODAY MINUS ' + nDays;
    } else {
      monthNum = startDate.getMonth()+1;
      dayNum = startDate.getDate();
      yearNum = startDate.getFullYear();
      month = monthNum < 10 ? ('0' + monthNum) : monthNum.toString();
      day = dayNum < 10 ? ('0' + dayNum) : dayNum.toString();
      startStr = ''+yearNum+'-'+month+'-'+day;
    }
    return this.localizeDateRange(startStr + ' TO ' + endStr);
  }

  public localizeDateRange(value): string {
    for (const key in this.dateRangeTokens) {
      const val = this.dateRangeTokens[key];
      const regex = new RegExp(key, 'g');
      if (value.indexOf(key) > -1 && !!val) {
        value = value.replace(regex, val);
      }
    }
    return value;
  }

  public localizedToDMDate(value): string {
    let valueLower = value.toLowerCase();
    for (const key in this.dateRangeTokensTranslated) {
      const val = this.dateRangeTokensTranslated[key];
      const regex = new RegExp(key, 'g');
      if (valueLower.indexOf(key) > -1 && !!val) {
        valueLower = valueLower.replace(regex, val);
      }
    }
    return valueLower === value.toLowerCase() ? value : valueLower;
  }

  public isSpecialDateFormat(value: string): boolean {
    const specials: string[] = ['%TODAY', 'EMPTY', 'NOT_EMPTY', this.dateRangeTokens['%TODAY'].toUpperCase(), this.dateRangeTokens['%EMPTY'].toUpperCase(), this.dateRangeTokens['%NOT_EMPTY'].toUpperCase()];
    const ucValue: string = value.toUpperCase();
    for (const special of specials) {
      if (ucValue.indexOf(special) !== -1) {
        return true;
      }
    }
    return false;
  }

  public splitableDateFormat(value: string): string {
    const specials: string[] = [' TO ', ' MINUS ', ' PLUS '];
    const ucValue: string = value.toUpperCase();
    for (const special of specials) {
      if (ucValue.indexOf(special)>=0) {
        return special;
      }
    }
    return null;
  }

  public splitableDateFormatSecondValueIsNumeric(value: string): boolean {
    const specials: string[] = [' MINUS ', ' PLUS '];
    const ucValue: string = value.toUpperCase();
    for (const special of specials) {
      if (ucValue.indexOf(special)>=0) {
        return true;
      }
    }
    return false;
  }

  public getTomorrowDate(): string {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return this.formatDate(tomorrow.toDateString(), true);
  }

  public isValidDocName(word: string): boolean {
    const lAngleIndx = word.indexOf('<');
    const rAngleIndx = word.indexOf('>');
    return !(lAngleIndx !== -1 && rAngleIndx !== -1 && lAngleIndx < rAngleIndx);
  }

  public escapeFileName(fileName: string): string {
    return fileName.replace(/[<>:"\/\\|?*]/g, '_');
  }

  public lastPathComponent(path: string): string {
    if (path && path.length) {
      const parts: string[] = path.split('/');
      return parts[parts.length-1];
    }
    return path;
  }

  public b64EncodeUnicode(str: string): string {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(parseInt('0x' + p1, 16))));
  }

  public b64DecodeUnicode(str: string): any {
    return decodeURIComponent(atob(str).replace(/(.)/g, (match, p) => {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = '0' + code;
      }
      return '%' + code;
    }));
  }

  public b64UrlDecode(str: string): any {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string');
    }
    try {
      return this.b64DecodeUnicode(output);
    } catch (err) {
      return atob(output);
    }
  }

  public decodeJWT(token: string): any {
    const decodeTokenComponent = (value: string) => JSON.parse(this.b64UrlDecode(value.split('.')[0]));
    const [headerEncoded, payloadEncoded, signature] = token.split('.');
    const [header, payload] = [headerEncoded, payloadEncoded].map(decodeTokenComponent);
    return payload;
  }

  public trimEnd(inputString: string, trim: string): string {
    const trimEscape = trim.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regEx = new RegExp(trimEscape + '+$');
    const outStr = inputString.replace(regEx, '');
    return outStr;
  }

  public stripLabel(oldLabel: string): string {
    const result: string = oldLabel ? oldLabel.replace(/[*:\[\]{}()]/g, '') : '';
    return result;
  }

  public splitFormatOptions(format: string, key: string): any[] {
    //example Format: [=A]Archive;[=D]Delete;[=K]Keep;[=O]Optical;[=T]Template
    const formatMap = [];
    if (!!format) {
      const items: string[] = format.split(';');
      let subitems: string[];
      let value: string;
      let display: string;
      for (const item of items) {
        subitems = item.split(']');
        if (subitems.length > 1) {
          value = subitems[0].slice(subitems[0].indexOf('=') + 1);
          display = subitems[1];
          formatMap.push({ value, display });
        }
      }

      if (key === 'STORAGE') {
        formatMap.push({ value: 'P', display: this.localizer.getTranslation('FORMS.BUTTONS.PAPER') });
      }
    }
    return formatMap;
  }

  public filePathSeparator(): string {
    return this.device.bIsWindows ? '\\' : '/';
  }

  public isValidateUrl(url: string) {
    const regexURL = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexURL.test(url);
  }

  public isCaseInsensitiveEqual(textA: string, textB: string): boolean {
    return textA?.localeCompare(textB, undefined, { sensitivity: 'accent' }) === 0;
  }

  public isFileVideo(fileExt: string) {
    return !!fileExt && !!_Transforms.videoFileExtensions[fileExt.toUpperCase()];
  }

  public getVideoMimeType(fileExt: string) {
    return !!fileExt ? _Transforms.videoFileExtensions[fileExt.toUpperCase()] : _Transforms.videoFileExtensions.MP4;
  }

  public focusOnHTMLElement(element: HTMLElement): boolean {
    if (!!element) {
      element.focus();
      return true;
    }
    return false;
  }

  public getHTMLElementByClassName(className: string): HTMLElement {
    return (document.getElementsByClassName(className)?.[0] as HTMLElement);
  }

  public getHTMLElementById(id: string): HTMLElement {
    return (document.getElementById(id) as HTMLElement);
  }

  public focusOnElementByClassName(className: string): boolean {
    return this.focusOnHTMLElement(this.getHTMLElementByClassName(className));
  }

  public focusOnElementById(id: string): boolean {
    return this.focusOnHTMLElement(document.getElementById(id));
  }

  public focusOnElementByQuerySelector(query: string): boolean {
    return this.focusOnHTMLElement(document.querySelectorAll<HTMLElement>(query)?.[0]);
  }

  public focusOnElementByAnyQueryFromArray(queries: string[]): void {
    for (const query of queries) {
      if (this.focusOnElementByQuerySelector(query)) {
        break;
      }
    }
  }

  public focusOnElementByAnyClassFromArray(classes: string[]): void {
    for (const className of classes) {
      if (this.focusOnElementByClassName(className)) {
        break;
      }
    }
  }

  public scrollIntoViewIfNeededUsingClassName(className: string): void {
    const selectedElement = document.getElementsByClassName(className) as any;
    if (selectedElement && selectedElement[0]) {
      selectedElement[0].scrollIntoViewIfNeeded();
    }
  }

  public getInfoIconPath(): string {
    return this.iconBase + 'notification_information.svg';
  }

  public parseJsonString(jsonStr: string): any {
    let object = {};
    if (!!jsonStr) {
      try {
        object = JSON.parse(jsonStr);
      } catch (e) {
        object = {};
      }
    }
    return object;
  }

  public getBrowserFormatFromDmDate(value: string): string {
    if (!!value && !this.isSpecialDateFormat(value)) {
      let checkDmFormat: boolean = true;
      let dateOnly: boolean = true;

      return !this.splitableDateFormat(value) ? this.formatDate(value, dateOnly, checkDmFormat) : this.formatSplitableDate(value, false, dateOnly, checkDmFormat);
    }
    return value;
  }
}
