/**
 * Created by kevin on 2016-11-10.
 */
import { Component, Input, OnChanges, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField, SelectItem } from '../models/form-field';
import { DynamicFormComponent } from './dynamic-form.component';
import { Util, UserInterface } from '../utils/utils.module';
import { LocalizeService } from '../services/localize.service';
import { LookupService, CacheItem } from '../services/lookup.service';
import { ValidationUtilities } from '../utils/ValidationUtilities';
import { UploadService } from '../services/upload.service';

@Component({
  selector: 'edx-dynamic-form-field',
  styleUrls: ['dynamic-form-field.component.scss'],
  templateUrl: 'dynamic-form-field.component.html'
})
export class DynamicFormFieldComponent implements OnChanges {
  @Input() field: FormField;
  @Input() form: FormGroup;
  @Input() parent: DynamicFormComponent;
  @Input() inDialog: boolean;
  @Input() inNotify: boolean;
  @Input() showExtras: boolean;
  @Input() formReadonly: boolean;
  @Input() first: boolean;
  @Input() formLayout: string;
  @Input() extensionShown: boolean;
  @Input() single?: boolean;
  @Input() rerender = 0;
  @Input() fieldLabel?: string = null;
  @Input() itemIndex?: number = -1;
  @Input() fieldsLength?: number = -1;
  @Input() tabIndex = 0;
  @ViewChild('editInput') editInput: ElementRef;
  @ViewChild('dropList') dropList: ElementRef;
  @ViewChild('datePicker') datePicker: ElementRef;
  public ui: UserInterface;
  public iOS: boolean;
  public android: boolean;
  public safari: boolean;
  public firefox: boolean;
  public edge: boolean;
  public ie: boolean;
  public officeAddin: boolean;
  public officeAddinDesktop: boolean;
  public viewReadonly: boolean;
  public showStr: string;
  public moreStr: string;
  private active = false;
  private inFocus = false;
  private ignoreLookupBlur = false;
  private masterList: CacheItem[] = [];
  private suggestions: CacheItem[] = [];
  private currentItem: CacheItem = null;
  private ulLeft: string = null;
  private ulTop: string = null;
  private usingLookupData = false;
  private showingHits = false;
  private lastSelectedValue = '';
  private height = '';
  private bgImage: string = null;
  private mdFuncSig: any = null;
  private datepickerStr = '';

  constructor(private localizer: LocalizeService, private lookupService: LookupService, private uploadService: UploadService ) {
    this.ui = Util.Device.ui;
    this.iOS = Util.Device.bIsIOSDevice;
    this.android = Util.Device.bIsAndroid;
    this.firefox = Util.Device.bIsFirefox;
    this.safari = Util.Device.bIsSafari;
    this.edge = Util.Device.bIsEdge;
    this.ie = Util.Device.bIsIE;
    this.officeAddin = Util.Device.bIsOfficeAddin;
    this.officeAddinDesktop = Util.Device.bIsOfficeAddinDesktop;
    this.showStr = this.localizer.getTranslation('FORMS.BUTTONS.SHOW');
    this.moreStr = this.localizer.getTranslation('FORMS.BUTTONS.MORE');
    this.datepickerStr = this.localizer.getTranslation('ALT_TEXT.DATEPICKER');
  }

  ngOnChanges(): void {
    const descReadOnly = !!this.parent && this.parent.desc && (['3', '18', '19'].includes(this.parent.desc['STATUS']) || this.parent.desc['READONLY'] === 'Y' || (this.parent.desc['STORAGE'] === 'T' && !Util.RestAPI.canUserManageTemplates()));
    if ((!this.field.isReadonly && descReadOnly && this.parent.formKind === '__local_permissions_selector') || (this.field?.name === 'SECURITY' && this.uploadService.isInitialForm() === false)) {
      this.field.isReadonly = true;
    }
    this.viewReadonly = this.field.isReadonly || this.formReadonly || !this.field.isEnabled;
    if ((this.field.controltype === 'textarea' || this.field.controltype === 'edittext') && this.parent.formKind ==='profile_uploadfolders' && this.field.name === 'DOCNAME') {
      this.viewReadonly = true;
    }
    this.tabIndex = !!this.parent ? this.parent.tabIndex : 0;
    this.active = this.hasLookup() && !this.viewReadonly;
    if (this.field.controltype === 'edittext') {
      this.height = 1.75 * this.field.rows + 'rem';
    }
    // Rerender is incremented in DynamicFormComponent to trigger rerendering so that
    // field.lookupData can be passed to the this component OnChange
    this.imgButtonDefault();
    setTimeout(() => {
      if (this.field.rerender !== this.field.lastRerenderIndex && this.field.lookupData !== null && this.editInput) {
        this.field.lastRerenderIndex = this.field.rerender;
        if (this.field.lookupData.length === 1) {
          // Single hit on lookup
          this.itemSelected(this.field.lookupData[0], null);
          this.field.lookupData = null;
        } else {
          this.masterList = Util.deepCopy(this.field.lookupData) as CacheItem[];
          this.field.lookupData = null;
          if (this.masterList.length === 0) {
            // No hit on lookup - use suggestion list
            this.showHideSuggestions(false);
            this.parent.clearFieldValueAndDesc(this.field.name);
            this.parent.clearFieldValueAndDescOfDependents(this.field);
            this.parent.clearFieldValueAndDescOfChildren(this.field);
            this.refreshDropDownList();
          } else {
            // Multiple hit on lookup
            if (!this.field.scheduledForRevalidation) {
              // Use hit list
              this.showHideSuggestions(true);
              this.suggestions = this.getSuggestions();
              this.positionList();
            } else {
              // Ignore multiple hits on auto-validation of lookup
              this.field.scheduledForRevalidation = false;
              this.field.autoValidated = true;
            }
          }
        }
      } else {
        // Active lookup fields that were auto-validated must retain their lastSelectedValue at rerender time
        if (this.active && this.field.autoValidated) {
          this.lastSelectedValue = this.field.lastLookupValue;
        }
        this.checkLayout();
      }
    }, 1);
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (Util.Device.bIsAndroid) {
      setTimeout(() => {
        this.checkLayout();
      }, 1);
    }
  }

  public getAltLabel(field: FormField): string {
    if (['$edx_file_picker','$edx_folder_picker'].indexOf(field.lookup) !== -1) {
      return this.localizer.getTranslation('ALT_TEXT.LOCATION_CHOOSER');
    }
    return this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.name]);
  }

  public getAriaLabel(field: FormField): string {
    return this.stripLabel(this.localizer.getTranslation('TOOLTIP.SELECT_LOOKUP',[field.label]));
  }

  public hasLabel(): boolean {
    return !!this.field.label && this.field.fldtype !== 'box' && this.field.fldtype !== 'guidetext' && this.field.fldtype !== 'text'
          && !this.field.isExtension && this.field.fldtype!=='list' && (this.field.fldtype !== 'push' || (this.field.fldtype === 'push' && !!this.field.checkLabel))
          && ((this.field.fldtype !== 'checkbox' || !this.formReadonly || this.formLayout !== 'column') && !this.isTileSettingsCheckbox());
  }

  private stripLabel(oldLabel: string): string {
    return Util.Transforms.stripLabel(oldLabel);
  }

  private inputType(): string {
    return this.field.name.startsWith('$edx_email') || this.field.name.startsWith('$edx_outlook_email') ? 'email' : this.field.name.startsWith('$edx_url') ? 'url' : this.field.isPassword ? 'password' : 'text';
  }

  private maxLen(): number {
    let max: number = null;
    if (!this.parent.formKind.startsWith('profile_query') && !this.field.mvinfo && this.field.maxChars > 0) {
      // let the validator handle max len as we could have many file names
      if (this.field.name !== 'DOCNAME') {
        max = this.field.maxChars;
      }
    }
    return max;
  }

  private hasDatePicker(): boolean {
    return (this.ui !== 0 || !this.safari);
  }

  public isTileSettingsCheckbox(): boolean {
    return ['__local_preferences_tiles', '__local_preferences_general', '__local_group_tile_settings'].indexOf(this.parent.formKind) !== -1 && ['checkbox', 'checkboxgroup'].indexOf(this.field.controltype) !== -1;
  }

  public canShowErrorMessage(): boolean {
    return (!!this.field.errormessage && this.parent.formKind === 'shortcuts' && this.field.fldtype !== 'text') || !this.viewReadonly && this.field.controltype !== 'editnumber' && this.field.controltype !== 'editdate' && !this.isTileSettingsCheckbox();
  }

  private placeholders(key: string, isExtension?: boolean): string {
    let result = '';
    if (this.field.lookup==='$edx_file_picker') {
      key = 'FILE';
    } else if (this.field.lookup==='$edx_folder_picker') {
     key = 'FOLDER';
    } else if (this.field.name.startsWith('$edx_email') || this.field.name.startsWith('$edx_outlook_email')) {
      key = 'EMAIL';
    } else if (this.field.name==='$edx_folder_picker') {
      key = 'MESSAGE';
    } else if (this.field.name.startsWith('$edx_advsearch_') && (this.field.name!=='$edx_advsearch_exact_phrase' && this.field.name!=='$edx_advsearch_word_prox')) {
      key = 'ADVSEARCH_INPUT';
    }
    if (this.parent) {
      result = this.field.controltype === 'editdate' ? Util.Transforms.getDateFormat(): this.parent.placeholders(this.viewReadonly, isExtension)[key];
    }
    return result;
  }

  public getAriaLabelForCheckboxInGroup(subField: FormField) {
    let ariaLabel = '';
    if (subField) {
      if (!!subField.ariaLabel) {
        ariaLabel = subField.ariaLabel;
      } else {
        const fieldNameParts = subField.name.split('___');
        if (fieldNameParts && fieldNameParts.length > 0) {
          ariaLabel = fieldNameParts[1] + ' ' + fieldNameParts[0];
        } else {
          ariaLabel = subField.checkLabel;
        }
      }
    }
    return ariaLabel;
  }

  public buttonClicked(event: Event): void {
    if (this.parent) {
      this.parent.buttonClicked(this.field);
    }
  }

  public shiftCheckBoxFocus(event: KeyboardEvent, cbIndex: number): void {
    if (cbIndex >= 0 && cbIndex < this.fieldsLength) {
      const checkboxId = this.getCheckboxId(cbIndex);
      if (!!checkboxId) {
        const cbElement = document.getElementById(checkboxId) as HTMLElement;
        if (!!cbElement) {
          cbElement.focus();
        }
      }
    }
  }

  public getCheckboxId(index: number, subFieldIndex?: number): string {
    return !!this.fieldLabel ? ('edx_cb_' + this.fieldLabel.toLowerCase().replace(' ', '_') + '_' + index + (subFieldIndex >= 0 ? '_' + subFieldIndex : '')) : (!!this.field.name && (this.field.name === 'dont_show' || this.field.name === 'auto') ? 'edx_cbfooter_' + index : null);
  }

  private doLookup(event: Event): void {
    if (this.parent) {
      event.preventDefault();
      event.stopPropagation();
      this.parent.doLookup(this.field);
    }
  }

  private doPreLookup(event: Event): void {
    this.ignoreLookupBlur = true;
    this.parent.validationBlocking(true);
  }

  public hasLookup(): boolean {
    return !!this.field.lookup && !this.field.isReadonly && (this.field.lookup.startsWith('$edx_') || this.field.lookup.indexOf('@RM.DLL') > -1 || (this.parent && this.parent.hasLookupTable(this.field.lookup)));
  }

  public wantsValidateOnBlur(): boolean {
    return this.hasLookup() && this.parent && this.parent.needsAsyncValidator && this.parent.needsAsyncValidator(this.field);
  }

  public updateControlValue(value: string, makeDirty: boolean, subField?: FormField): void {
    if (this.parent) {
      const fieldName = subField ? subField.name : this.field.name;
      this.parent.updateControlValue(fieldName, value, makeDirty);
    }
  }

  public fieldChanged(): boolean {
    let changed = false;
    if (this.parent && this.field) {
      changed = this.parent.fieldChanged(this.field);
    }
    return changed;
  }

  public editTextFocus(event: Event): void {
    // time for the keyboard to move the view up on mobile
    const delayDropDown = Util.Device.isMobile() && !Util.Device.bIsOfficeAddin ? 300 : 1;
    if (this.field.lookup && this.field.lookup.startsWith('$edx_')) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.inFocus = true;
    setTimeout(() => {
      this.refreshDropDownList();
      this.suggestions = this.getSuggestions();
      this.positionList();
    }, delayDropDown);
  }

  public editTextBlur(event: Event): void {
    if (!this.ignoreLookupBlur && this.parent && this.field && !!this.editInput && !!this.editInput.nativeElement) {
      if ((this.field.lastLookupValue === this.editInput.nativeElement.value) && (this.lastSelectedValue !== this.editInput.nativeElement.value) && !this.field.autoValidated && !this.field.name?.startsWith('$edx_outlook_email')) {
        this.editInput.nativeElement.value = '';
        this.lastSelectedValue = '';
        this.field.lastLookupValue = '';
        this.parent.updateControlValue(this.field.name, this.editInput.nativeElement.value, true);
        this.parent.setFieldDesc(this.field.name, '');
      }
      this.parent.fieldChanged(this.field);
    }
    if (this.ignoreLookupBlur || (!!this.editInput && !!this.editInput.nativeElement && this.field.lastLookupValue !== this.editInput.nativeElement.value)) {
      this.ignoreLookupBlur = false;
    }
    this.inFocus = false;
  }

  private checkboxChanged(checkboxValue: string, event?: Event) {
    if (!!event && event.type === 'keydown') {
      event.preventDefault();
    }
    // update the form control
    let oldValue: string; let newValue: string;
    //get the checkbox group control
    const control: any = this.parent ? this.parent.getControl(this.field.name) : {};
    //check whether the checkbox is readonly(disabled) or not.
    if (!this.isCheckboxReadonly(checkboxValue)) {
      oldValue = control.value || '';
      if (checkboxValue) {
        //toggle the checkboxes
        oldValue = (oldValue.indexOf(checkboxValue) >= 0) ? oldValue.replace(checkboxValue, '') : oldValue + ',' + checkboxValue;
      }
      //to get the values in correct order, get the checkbox buttons
      const buttonsMap: SelectItem[] = this.field.buttonMap;
      if (buttonsMap) {
        //get the selected checkbox values and join them with ','
        newValue = buttonsMap.filter((button) => (oldValue.indexOf(button.value) >= 0))
          .map((button) => button.value).join(',');
        //update the field value with the selected checkbox values
        if (this.parent) {
          this.parent.updateControlValue(this.field.name, newValue, true);
        }
      }
    }
  }

  private isCheckboxChecked(checkboxValue: string): boolean {
    const control: any = this.parent ? this.parent.getControl(this.field.name) : {};
    const fieldValue: string = control.value || '';
    return fieldValue.indexOf(checkboxValue) >= 0;
  }

  private setDateFormat(event: Event, fieldName: string): void {
    const element: any = event.currentTarget as HTMLInputElement;
    const fieldValue = element ? element.value : '';
    let date = '';
    if (fieldValue) {
      date = Util.Transforms.formatDate(fieldValue,true);
    }
    this.parent.updateControlValue(fieldName, date, true);
    this.fieldChanged();
  }

  private dateInputChanged(event: Event): void {
    if (this.datePicker) {
      const element: any = event.currentTarget as HTMLInputElement;
      const fieldValue = element ? element.value : '';
      this.datePicker.nativeElement.value = Util.Transforms.isValidLocaleDate(fieldValue) ? Util.Transforms.toDMDateString(fieldValue) : '';
    }
    this.fieldChanged();
  }

  private getDescriptionForTooltip(): string {
    let description: string = null;
    if (((this.field.isExtension && !this.field.defaultValue) || this.viewReadonly || this.field.name === '%CHECKIN_LOCATION') && (this.field.name !== 'DOCNUM' || !isNaN(+this.parent.getFieldValue(this.field.name)))) {
      description = this.parent.getFieldValue(this.field.name);
    }
    if (!description && !this.viewReadonly) {
      description = this.placeholders(this.field.controltype, this.field.isExtension);
    }
    description = description || '';
    if (!!this.field.validationParameters) {
      description += this.field.validationParameters.toString();
    }
    return description;
  }

  public isCheckboxReadonly(checkboxvalue: string): boolean {
    if (this.field.disabledCheckbox && this.field.disabledCheckbox.length > 0) {
      const field = this.field.disabledCheckbox.filter(button => button.value === checkboxvalue);
      if (field.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // Drop down "look-ahead" list methods
  private refreshDropDownList(): void {
    if (this.active) {
      if (!this.usingLookupData) {
        const cacheKey = this.parent.getCacheKey(this.field.name);
        this.masterList = this.lookupService.getSuggestionCache(cacheKey);
      } else {
         this.usingLookupData = false;
      }
    }
    this.checkLayout();
  }

  private checkDismissSuggestions(event: Event): void {
    const dropListEl = !!this.dropList ? this.dropList.nativeElement : null;
    const isChildOrTarget = (el): boolean => {
      while (!!el) {
        if (el === dropListEl) {
          return true;
        }
        el = el.parentElement;
      }
      return false;
    };
    if (event.target && !isChildOrTarget(event.target)) {
      event.stopPropagation();
      event.preventDefault();
      setTimeout(() => {
        this.showHideSuggestions(false);
        this.parent.markForCheck();
      }, 1);
    }
  }

  private showHideSuggestions(show: boolean): void {
    this.usingLookupData = show;
    if (show) {
      this.mdFuncSig = this.checkDismissSuggestions.bind(this);
      addEventListener('mousedown', this.mdFuncSig);
    } else {
      removeEventListener('mousedown', this.mdFuncSig);
      this.mdFuncSig = null;
      this.showingHits = false;
    }
  }

  private checkLayout(): void {
    if (this.editInput) {
      const formBody = document.getElementById('edx_form_body') || this.parent.formEl.nativeElement;
      const formBodyRect = !!formBody ? formBody.getBoundingClientRect() : { height: window.innerHeight, top: 0 };
      const input = this.editInput.nativeElement;
      let offsetTop = input.getBoundingClientRect().top - formBodyRect.top;
      setTimeout(() => {
        const ulHeight: number = this.dropList && this.dropList.nativeElement ? this.dropList.nativeElement.clientHeight : 0;
        if ((offsetTop + input.offsetHeight + ulHeight) > formBodyRect.height) {
          offsetTop = input.offsetTop - ulHeight;
        } else {
          offsetTop = input.offsetTop + input.offsetHeight;
        }
        this.ulTop = offsetTop.toString() + 'px';
        this.ulLeft = input.offsetLeft + 'px';
        this.parent.markForCheck();
      }, 0);
    }
  }

  private positionList(): void {
    if (this.editInput) {
      const input = this.editInput.nativeElement;
      const top: number =  input.offsetTop + input.offsetHeight;
      this.ulTop  = top + 'px';
      this.ulLeft = input.offsetLeft + 'px';
      this.checkLayout();
      this.parent.markForCheck();
    }
  }

  private scrollIntoView(index: number): void {
    if (this.dropList && this.dropList.nativeElement) {
      const currentScrollTop: number = this.dropList.nativeElement.scrollTop;
      const ulHeight: number = this.dropList.nativeElement.offsetHeight;
      const curLI: HTMLLIElement = Array.from(this.dropList.nativeElement.children)[index] as HTMLLIElement;
      const liTop = curLI.offsetTop;
      const liHeight = curLI.offsetHeight;
      if ((liTop < currentScrollTop) || ((currentScrollTop+ulHeight)-liTop < liHeight)) {
        this.dropList.nativeElement.scrollTop = liTop;
      }
    }
  }

  private nav(inc: number): void {
    if (this.showingHits && !!this.suggestions && this.suggestions.length) {
      const item: CacheItem = this.currentItem;
      let index: number = this.suggestions.indexOf(item);
      const doInc = () => {
        index += inc;
        if (index<0) {
          index = 0;
        } else if (index >= this.suggestions.length) {
          index = this.suggestions.length-1;
        }
      };
      doInc();
      if (this.suggestions[index].id === '-') {
        doInc();
      }
      this.currentItem = this.suggestions[index];
      this.scrollIntoView(index);
    }
  }

  public editTextKeyDown(event: KeyboardEvent): void {
    const key = event.key;
    if (key?.length === 1 && !ValidationUtilities.IsValidCharacterInput(key, this.field.validationParameters)) {
      event.preventDefault();
    }
  }

  public editTextKeyUp(event: KeyboardEvent): void {
    if (this.active) {
      switch (event.key) {
        case ' ': {
          if (this.currentItem) {
            this.itemSelected(this.currentItem, event);
          }
          event.preventDefault();
          event.stopPropagation();
          this.parent.fieldChanged(this.field);
          // Synthetic blur since onKeyUp would activate validation always
          if (!!this.field.lookup && this.field.lookup.indexOf('@RM.DLL') === -1 && !this.parent?.formKind?.startsWith('profile_query')) {
            this.editInput.nativeElement.blur();
          }
          break;
        }
        case 'ArrowDown': {
          this.nav(1);
          break;
        }
        case 'ArrowUp': {
          this.nav(-1);
          break;
        }
        default: {
          this.currentItem = null;
          this.suggestions = this.getSuggestions();
          if (this.form && this.parent && this.parent.controller && this.parent.controller.enableOK) {
            setTimeout(() => {
              this.parent.controller.enableOK(this.form.valid, this.form.dirty);
            }, 1);
          }
          this.checkLayout();
          break;
        }
      }
    }
  }

  public getSuggestions(): CacheItem[] {
    let sublist: CacheItem[] = [];
    let isFound = false;
    let addedDash = false;
    let shouldAddDash = false;
    let tokens: string[] = [];
    const inputElemet = this.editInput.nativeElement;
    const query = inputElemet.value.toLowerCase();

    if (!!this.field.hits && !!this.field.hits.length) {
      let hits = this.field.hits;
      if (!!hits[0].parent) {
        hits = hits[0].value;
      }
      sublist = hits.map(h => new CacheItem(h.value,h.desc,''+h.rank+'%'));
      shouldAddDash = true;
    }
    for (const item of this.masterList) {
      isFound = false;
      if (query.length) {
        tokens = item.desc?.split(' ');
        tokens?.push(item.id);
        for (const token of tokens) {
          if (token.toLowerCase().indexOf(query) === 0) {
            isFound = true;
          }
        }
      }
      if ((isFound || query.length === 0) && !sublist.find(i => i.id === item.id)) {
        if (!addedDash && shouldAddDash) {
          sublist.push(new CacheItem('-','-'));
          addedDash = true;
        }
        sublist.push(item);
      }
    }
    this.showingHits = true;
    return sublist;
  }

  private deleteSuggestion(item: CacheItem, event?: Event): void {
    const cacheKey = this.parent.getCacheKey(this.field.name);
    if (cacheKey) {
      this.lookupService.removeFromSuggestionCache(cacheKey, item);
      this.refreshDropDownList();
      this.suggestions = this.getSuggestions();
      this.checkLayout();
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  }

  private itemSelected(item: CacheItem, event?: Event): void {
      const scheduledForRevalidate = () => {
      if (!this.field.scheduledForRevalidation) {
        // Adjust parent fields
        this.parent.setFieldValueAndDescOfParents(this.field, item.data);
        // Togle value to top of suggestions
        const cacheKey = this.parent.getCacheKey(this.field.name);
        if (cacheKey) {
          this.lookupService.toggleToTopOfSuggestionCache(cacheKey, item);
        }
      }
      if (event) {
        // Presence of event indicates a physically selected item froma a suggestion list
        // either with a mouse, tab or tap on mobile device
        if (item && item.data) {
          this.parent.scanFieldsForRevalidatonOrEvaluation(item.data, this.field.name);
        }
      }
    };
    if (item.id === '-') {
      this.usingLookupData = true;
    } else {
      // Selecting an item from the lookupData or suggestions does not need validation
      this.parent.validationBlocking(true);
      // Do not clear child fields if same item selected again
      if (this.lastSelectedValue !== item.id) {
        this.parent.clearFieldValueAndDescOfChildren(this.field);
        this.lastSelectedValue = item.id;
      }
      this.editInput.nativeElement.value = item.id;
      // Set field value and description
      if(this.field.lookup === '$edx_contacts') {
        if(this.editInput.nativeElement.value == '') {
          this.field.errormessage = this.localizer.getTranslation('FORMS.ERRORS.EMAIL');
        } else{
          this.field.errormessage = '';
        }
    }
      this.parent.updateControlValue(this.field.name, this.editInput.nativeElement.value, true);
      this.parent.setFieldDesc(this.field.name, item.desc);
      if (this.showingHits) {
        let setChild = false;
        const childField = this.parent.getField(this.field.childField);
        if (!!childField && childField.hits) {
          const parentHits = childField.hits.find(h => h.parent===item.id);
          if (!!parentHits && !!parentHits.value && !!parentHits.value.length) {
            this.parent.updateControlValue(childField.name, parentHits.value[0].value, true);
            this.parent.setFieldDesc(childField.name, parentHits.value[0].desc);
            setChild = true;
          }
        }
        if (!setChild) {
          scheduledForRevalidate();
        }
      } else {
        scheduledForRevalidate();
      }
      if (this.field.scheduledForRevalidation) {
        this.field.scheduledForRevalidation = false;
        // This will prevent recursion on fields that are recursed in lookups (like revalidate CLIENT_ID
        // but then you attempt to revalidate it again because it appears in the MATTER_ID lookup also!!)
        this.field.autoValidated = true;
      }
      this.field.lastLookupValue = item.id;
      this.suggestions = [];
      this.inFocus = false;
      this.usingLookupData = false;
      // Find out if this is a secured flex lookup
      const lookupID = this.parent.securedFlexFolderLookupIDs?.find(key => key === this.field.name);
      const isProfileDefaultSearch = this.parent.isProfileDefaultSearchForm();
      if (lookupID && !this.parent.bMassProfileUpdate && !this.parent.formKind.startsWith('profile_query') && !isProfileDefaultSearch) {
        this.findSecuredFlexTrustees(lookupID, item);
      }
      setTimeout(() => {
        this.parent.validationBlocking(false);
        this.parent.focusOnNextEmptyEditable(this.field);
      }, 1);
    }
  }

  private imgButtomModify(mod: string): void {
    if (!!this.field.buttonImg) {
      const icon: string = this.field.buttonImg.split('.svg')[0];
      this.bgImage = 'url(assets/images/'+icon+'_' + mod + '.svg)';
    }
  }

  private imgButtonHover(): void {
    this.imgButtomModify('mo');
  }

  private imgButtonDown(): void {
    this.imgButtomModify('md');
  }

  private imgButtonDefault(): void {
    if (!!this.field.buttonImg) {
      this.bgImage = 'url(assets/images/'+this.field.buttonImg+')';
    }
  }

  public editTextPaste(event: ClipboardEvent) {
    if (this.inputType()==='url') {
      const el = event.target as HTMLInputElement;
      let data = event.clipboardData.getData('text/html');
      const start: number = data.indexOf('"http');
      if (start !== -1) {
        data = data.substr(start+1);
        const end: number = data.indexOf('"');
        if (end !== -1) {
          data = data.substr(0, end);
          el.value = data;
          setTimeout(() => {
            el.blur();
          }, 100);
        }
      }
    }
  }

  private getRadioCheckboxId(fieldName: string, index: number) {
    return 'edx_' + fieldName + '_' + index;
  }

  private focusOnRadioCheckbox(fieldName: string,index: number) {
    const id = this.getRadioCheckboxId(fieldName,index);
    const element = document.getElementById(id);
    if (!!element) {
      element.focus();
    }
  }

  // Get flexfolder lookup Information and trustees
  private findSecuredFlexTrustees(lookupID, item: CacheItem) {
    const lookupPrimaryKey = item.data['%PRIMARY_KEY'];
    const lookupValue = item.data[lookupID];
    const keyIndex = this.parent.securedLookupKeyList.findIndex(key => key.lookupID === lookupID);
    const childField = this.parent.getField(this.field.childField);
    const childLookupID = childField ? childField.name : '';
    if (this.parent.securedLookupKeyList.length) {
      if (keyIndex !== -1) {
        const childKeyIndex = this.parent.securedLookupKeyList.findIndex(key => key.lookupID === childLookupID);
        if (childKeyIndex !== -1) {
          this.parent.securedLookupKeyList[keyIndex] = {lookupID, lookupValue, childLookupID, lookupPrimaryKey};
          this.parent.securedLookupKeyList.splice(childKeyIndex,1);
        } else {
          this.parent.securedLookupKeyList[keyIndex] = {lookupID, lookupValue, childLookupID, lookupPrimaryKey};
        }
      } else {
        this.parent.securedLookupKeyList.push({lookupID, lookupValue, childLookupID, lookupPrimaryKey});
      }
    } else {
      this.parent.securedLookupKeyList.push({lookupID, lookupValue, childLookupID, lookupPrimaryKey});
    }
    this.parent.updateFlexFolderTrustees();
  }

  public isShowFooterOptions(field: FormField): boolean {
    return field.name === 'edx_show_footer_options';
  }
}
