import { Component, Input, ElementRef, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChange } from '@angular/core';

import { Util } from '../utils/utils.module';
import { ListItem } from '../models/list-item';
import { dragHoleKey } from '../models/tile';
import { LocalizeService } from '../services/localize.service';
import { ListService } from '../services/list.service';
import { DataService } from '../services/data.service';
import { ListBaseComponent } from './list-base.component';
import { SecurityControl } from '../models/security-control';
import { GestureKind, GestureListener } from '../directives/gesture.directive';

export interface ListTilesContainerInterface {
  createTile(listItem: ListItem, atIndex: number): void;
  dropHandled(): void;
  listItemDragStarted(listItem: ListItem, element: ElementRef): void;
  listItemDragMoved(listItem: ListItem, x: number, y: number, element: ElementRef): void;
  listItemDragEnded(listItem: ListItem, event: any, element: ElementRef): void;
  isHidden(): boolean;
}

@Component ({
  selector: 'edx-list-tile',
  styleUrls: [ 'list-tile.component.scss' ],
  providers: [ListService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ListBaseComponent.baseClassInputs,
  template: `
    <edx-spinner *ngIf="reloading" [mini]="true"></edx-spinner>
    <div *ngIf="!reloading && getList().length === 0" class="error-message" [attr.aria-label]="noLoadMessage" tabindex="0">{{noLoadMessage}}</div>
    <div class="listcontainer" [edx-gesture-swipe]="this">
      <edx-list-item-tile *ngFor="let item of getList(); let i=index;" class="edx_list_item_tile" [id]="'edx_list_item_tile_'+i" [desc]="item" [parent]="this" [tilesize]="desc.size" [showHover]="showHoverItem===item"></edx-list-item-tile>
    </div>
  `
})
export class ListTileComponent extends ListBaseComponent implements OnChanges, GestureListener {
  @Input() tcc: ListTilesContainerInterface;
  public dragFolderHilited: ListItem = null;
  public isOfficeAddin: boolean = Util.Device.bIsOfficeAddin;
  public showHoverItem: ListItem = null;
  private draggingListItem: ListItem = null;
  private draggingListItemIndex = -1;
  private noLoadMessage: string;
  private historyActions: string[] = null;

  constructor(protected listService: ListService, protected dataService: DataService, protected localizer: LocalizeService, protected changeRef: ChangeDetectorRef) {
    super(listService, dataService, localizer, changeRef);
    listService.max = Util.RestAPI.getTileMaxRows();
    listService.setIgnoreSearchLimitWarning(true);
    this.noLoadMessage = this.localizer.getTranslation('PLACEHOLDER.NO_ITEMS');
  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    const chng: any = changes['desc'];
    if (chng) {
      const newDesc: any = chng.currentValue;
      if (newDesc.name===dragHoleKey || newDesc.lib===dragHoleKey) {
        this.reloading = false;
        return;
      }
      if (newDesc.id==='recentedits' || newDesc.id==='checkedout' || newDesc.id==='deleted' || newDesc.id==='public' || (newDesc.id==='' && newDesc.type==='workspaces') || (newDesc.id==='' && newDesc.type==='searches')) {
        Util.RestAPI.addToRefreshList(this);
      }
      this.reloading = true;
      setTimeout(() => {
        if (this.reloading) {
          this.reloading = false;
          this.changeRef.markForCheck();
        }
      }, 30000);
    }
    super.ngOnChanges(changes);
  }

  public listReplaced(): void {
    super.listReplaced();
    this.changeRef.markForCheck();
  }

  public listItemPreDragStart(listItem: ListItem, element: ElementRef) {
    this.draggingListItemIndex = this.list.indexOf(listItem);
    this.draggingListItem = new ListItem(listItem);
    this.list.splice(this.draggingListItemIndex,0,this.draggingListItem);
    this.tcc.listItemDragStarted(listItem, element);
  }
  public listItemDragMoved(listItem: ListItem, x: number, y: number, element: ElementRef) {
    this.tcc.listItemDragMoved(listItem, x, y, element);
  }
  public listItemDragEnded(listItem: ListItem, event: any, element: ElementRef) {
    this.tcc.listItemDragEnded(listItem, event, element);
    this.list.splice(this.draggingListItemIndex,1);
    this.draggingListItem = null;
    this.draggingListItemIndex = -1;
  }
  public listItemDragOver(listItem: ListItem, event: any): void {
    if (Util.dragHasJSON(event.dataTransfer)) {
      Util.RestAPI.showDropBanner(listItem.DOCNAME, 'HEADER.DRAG_CREATE_REF');
    } else {
      Util.RestAPI.showDropBanner(listItem.DOCNAME);
    }
    this.dragFolderHilited = listItem;
  }
  public listItemDragLeave(listItem: ListItem): void {
    if (this.dragFolderHilited===listItem) {
      this.dragFolderHilited = null;
    }
  }
  public listItemDrop(listItem: ListItem): void {
    this.tcc.dropHandled();
    if (this.dragFolderHilited===listItem) {
      // delay the reseting of the dragged into folder so the tile will ignore this drop
      setTimeout(() => {
        this.dragFolderHilited = null;
      }, 1);
    }
  }
  public overDragFolder(): ListItem {
    return this.dragFolderHilited;
  }
  public createTile(listItem: ListItem, atIndex: number): void {
    this.tcc.createTile(listItem, atIndex);
  }
  public profileItem(listItem: ListItem, securityControl: SecurityControl): void {
    this.listService.openMetadata(this.list,this.set,securityControl, listItem);
  }
  public emailItem(listItem: ListItem): void {
    Util.RestAPI.setCurList([listItem],this.set);
    Util.RestAPI.getAppComponent().doCommand('email');
  }
  public shareItem(listItem: ListItem): void {
    Util.RestAPI.setCurList([listItem],this.set);
    Util.RestAPI.getAppComponent().doCommand('shareonedrive');
  }

  public openViewer(listItem: ListItem, securityControl: SecurityControl): void {
    this.listService.openMetadata(this.list,this.set,securityControl, listItem, 'viewer');
  }
  
  public isHidden(): boolean {
    return this.tcc.isHidden();
  }

  public handleGesture(gesture: GestureKind, elem: ElementRef, target: any): boolean {
    if (!this.officeAddin) {
      const itemEl: any = Util.findSelfOrParentWithClass(target, 'edx_list_item_tile');
      const itemIndex: number = !!itemEl && !!itemEl.id && itemEl.id.startsWith('edx_list_item_tile_') ? parseInt(itemEl.id.substr(19)) : NaN;
      if (!isNaN(itemIndex)) {
        const item: ListItem = this.list[itemIndex];
        if (gesture===GestureKind.SwipeLeft) {
          this.showHoverItem = item;
          return true;
        } else if (gesture===GestureKind.SwipeRight) {
          this.showHoverItem = null;
          return true;
        }
      }
    }
    return false;
  }

  public formatAction(action: number, activityDesc?: string): string {
    let result: string;
    if (!this.historyActions) {
      this.historyActions = [];
      for (let actionIndex = 0; actionIndex <= Util.kMaxActivityTypes; actionIndex++) {
        if (this.desc.type === 'fileplans' && !!activityDesc) {
          this.historyActions.push(this.localizer.getTranslation('HISTORY_ACTIONS.' + actionIndex) + ': ' + activityDesc);
        } else {
          this.historyActions.push(this.localizer.getTranslation('HISTORY_ACTIONS.' + actionIndex));
        }
      }
    }
    if (action >= 0 && action < this.historyActions.length) {
      if ((this.desc.type === 'fileplans' || action === 49) && activityDesc) {
        result = this.historyActions[action] + ': ' + activityDesc;
      } else if (action === 0 && activityDesc) {
        result = activityDesc;
      } else {
        result = this.historyActions[action];
      }
    }
    return result;
  }
}
