import { Injectable } from '@angular/core';
import { AccountInfo, AuthenticationResult, Configuration, LogLevel, PublicClientApplication, SilentRequest, SsoSilentRequest } from '@azure/msal-browser';
import { Util } from '../utils/utils.module';

declare let Office: any;
const ssoDataKey = 'edx_sso_data';
const redirectStatusKey = 'redirectStatus';


@Injectable()
export class oAuth2Service {
  private bInitConfigDone = false;
  public currentToken: string;
  private isOfficeAddin: boolean;
  private isTeamsAddin: boolean;
  private loginResolve = null;
  private loginReject = null;
  private isRedirectSignin = Util.RestAPI.siteConfigurations.redirectSignin;
  private ssoData: any = null;
  private ssoScopes: string[];
  private ssoUserName: string = null;
  private msalInstance: PublicClientApplication = null;

  constructor() {
    const waitInitConfig = () => {
      if (Util.Device.initialized()) {
        this.isOfficeAddin = Util.Device.bIsOfficeAddin && !Util.Device.bIsOfficeAddinWeb && !!Office && !!Office.context;
        this.isTeamsAddin = Util.Device.bIsTeamsAddIn;
        this.firstInitializeMsal();
        this.bInitConfigDone = true;
      } else {
        setTimeout(waitInitConfig, 100);
      }
    };
    waitInitConfig();
  }

  private getMsalConfig(authData): Configuration {
    const authority = Util.Transforms.trimEnd(authData.authority.trim(), '/v2.0');
    const authorityURL = new URL(authority);
    const isAAD = this.isAzureActiveDirectory(authorityURL.hostname);
    const isADFSInAddin = authority.includes('adfs') && this.isOfficeAddin;
    //Reference: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
    const msalConfig: Configuration = {
      auth: {
        clientId: authData.client_id,
        authority: authority,
        redirectUri: Util.getRootSiteUrl(),
        postLogoutRedirectUri: Util.getRootSiteUrl(),
        navigateToLoginRequestUrl: true,
        knownAuthorities: [authorityURL.hostname],
        protocolMode: (isAAD ? 'AAD' : "OIDC")
      },
      cache: {
        cacheLocation: 'localStorage', // or 'sessionStorage'
        storeAuthStateInCookie: false,
      },
      system: {
        allowRedirectInIframe: true,
        windowHashTimeout: (isADFSInAddin ? 60000 :500),
        iframeHashTimeout: (isADFSInAddin ? 60000 :500),
        loadFrameTimeout: 0,
        loggerOptions: {
          logLevel: LogLevel.Error,
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
            }
          },
          piiLoggingEnabled: false
        },
      }
    };
    return msalConfig;
  }

  //Reference: https://learn.microsoft.com/en-us/entra/identity-platform/authentication-national-cloud#microsoft-entra-authentication-endpoints
  private isAzureActiveDirectory(authorityHostName: string): boolean {
    const entraEndpoints = [
      'login.microsoftonline.us',
      'login.partner.microsoftonline.cn',
      'login.microsoftonline.com'
    ];
    return entraEndpoints.includes(authorityHostName.toLocaleLowerCase());
  }

  public static get redirectStatus(): string {
    return sessionStorage[redirectStatusKey];
  }

  public static set redirectStatus(value: string) {
    if (value) {
      sessionStorage[redirectStatusKey] = value;
    } else {
      sessionStorage.removeItem(redirectStatusKey);
    }
  }

  public getSSOData(): any {
    return this.ssoData || JSON.parse(localStorage.getItem(ssoDataKey));
  }

  public isSSOEnabled(): boolean {
    return this.getSSOData()?.oidc_enabled;
  }

  private initializeMsal(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      this.ssoData = this.getSSOData();
      if (!this.msalInstance && this.ssoData?.oidc_enabled) {
        const msalConfig = this.getMsalConfig(this.ssoData);
        this.msalInstance = new PublicClientApplication(msalConfig);
        this.msalInstance.initialize()
          .then(() => {
            console.log('MSAL init success');
            resolve();
          }).catch(err => {
            console.log(err);
            reject()
          });

      } else {
        resolve();
      }
    });
  }

  private async firstInitializeMsal() {
    await this.initializeMsal();
    const redirectStatus = oAuth2Service.redirectStatus;
    if (this.msalInstance && !!redirectStatus) {
      this.msalInstance.handleRedirectPromise().then(res => {
        oAuth2Service.redirectStatus = 'done';
        if (res != null && res.account != null) {
          this.setAccount(res.account);
        } else {
          console.log('No Account');
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  public setAccount(account: AccountInfo) {
    this.ssoUserName = account.username;
    this.msalInstance.setActiveAccount(account);
  }

  public doSilentSignIn(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      await this.initializeMsal();
      let silentMethod;
      const account = this.msalInstance.getAllAccounts()[0];
      if (account) {
        const accessTokenRequest: SilentRequest = {
          scopes: this.ssoScopes,
          account: account,
          prompt: 'none'
        };
        silentMethod = this.msalInstance.acquireTokenSilent(accessTokenRequest);
      } else {
        const silentRequest: SsoSilentRequest = {
          scopes: this.ssoScopes,
          loginHint: this.ssoUserName,
          prompt: 'none'
        };
        silentMethod = this.msalInstance.ssoSilent(silentRequest);
      }
      silentMethod.then((authenticationResult: AuthenticationResult) => {
        const activeAccount = authenticationResult?.account;
        if (activeAccount) {
          this.setAccount(activeAccount);
          this.currentToken = authenticationResult.accessToken;
          resolve(this.currentToken);
        } else {
          reject();
        }
      }).catch((err) => {
        console.log(err);
        reject();
      });
    });
  }

  public login(singleSignOnData: any): Promise<string> {
    const rc = new Promise<string>((resolve, reject) => {
      this.loginResolve = resolve;
      this.loginReject = reject;
    });

    const loginSuccess = (accessToken) => {
      if (accessToken) {
        this.currentToken = accessToken;
        this.loginResolve(this.currentToken);
      } else {
        this.loginReject('No Token');
      }
      this.loginResolve = null;
      this.loginReject = null;
    }

    const doLogin = () => {
      const loginRequest = {
        scopes: this.ssoScopes,
      };
      if (this.isRedirectSignin) {
        const redirectStatus = oAuth2Service.redirectStatus;
        if (!!redirectStatus) {
          console.log('It is already redirected');
        } else {
          oAuth2Service.redirectStatus = 'started';
          this.msalInstance.loginRedirect(loginRequest).then(() => {
            console.log('Login redirect');
          }).catch((err) => {
            console.log(err);
          });
        }
      } else {
        this.msalInstance.loginPopup(loginRequest).then((response: AuthenticationResult) => {
          this.setAccount(response.account);
          loginSuccess(response.accessToken);
        }).catch(err => {
          console.log(err);
          this.loginReject(err);
          this.loginResolve = null;
          this.loginReject = null;
        });
      }
    }

    const doOfficeRedirectSignIn = () => {
      Office.onReady((info) => {
        if (window.location.href.indexOf(Util.getRootSiteUrl()) !== -1) {
          const waitSignin = () => {
            const redirectStatus = oAuth2Service.redirectStatus;
            if (oAuth2Service.redirectStatus !== 'started') {
              doMsalSignIn();
            } else {
              setTimeout(() => {
                waitSignin();
              }, 100);
            }
          }
          waitSignin();
        }
      });
    }

    const doSignIn = () => {
      if (this.isOfficeAddin && this.isRedirectSignin) {
        doOfficeRedirectSignIn()
      } else {
        doMsalSignIn();
      }
    }

    const doMsalSignIn = () => {
      this.doSilentSignIn().then(token => {
        loginSuccess(this.currentToken);
      }).catch(() => {
        const redirectStatus = oAuth2Service.redirectStatus;
        if (!!redirectStatus) {
          this.logout().then(() => { })
        } else {
          doLogin();
        }
      });
    }

    const waitInit = () => {
      if (this.bInitConfigDone) {
        this.ssoData = singleSignOnData;
        this.ssoScopes = this.ssoData.scope.trim().split(' ').filter(x => x);
        this.ssoData.scope = this.ssoScopes.join(' ');
        localStorage.setItem(ssoDataKey, JSON.stringify(this.ssoData));
        doSignIn();
      } else {
        setTimeout(waitInit, 100);
      }
    };

    waitInit();
    return rc;
  }

  public logout(): Promise<void> {
    oAuth2Service.redirectStatus = null;
    if (this.msalInstance) {
      const logoutRequest = {
        postLogoutRedirectUri: Util.getRootSiteUrl(),
        logoutHint: this.ssoUserName
      }
      if (this.isRedirectSignin) {
        return this.msalInstance.logoutRedirect(logoutRequest);
      } else {
        return this.msalInstance.logoutPopup(logoutRequest);
      }
    } else {
      return new Promise<void>((resolve, reject) => {
        this.reset()
        resolve();
      });
    }
  }

  public reset(): void {
    this.serverChanged();
  }

  public serverChanged(): void {
    localStorage.removeItem(ssoDataKey);
  }
}
